import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { sortBy } from 'lodash'

import { useSelector } from 'actions/store'
import { useQueues } from 'components/cases/reviews/queues/useQueues'
import { getAccountReviewTypes, getCurrentAccount, getOrganizationTeammates } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'

import { DashboardTypeEnum, FeatureFlag, FilterOptionV2, Queue, TransactionDirection } from 'types/api'
import { Option } from 'types/hb'

import { normalizeRequest } from 'utils/query/normalization'

import { useDashboardContext } from '../components/DashboardContextProvider'
import { useDashboardConfig } from '../react/dashboards.config'
import { useDashboardImplicitFilters } from '../react/useDashboardImplicitFilters'

type FilterCreatorProps = {
  teammates: ReturnType<typeof getOrganizationTeammates>
  reviewTypes: ReturnType<typeof getAccountReviewTypes>
  currentAccount: ReturnType<typeof getCurrentAccount>
  filters: FilterOptionV2[]
  queues: Queue[]
  aggsKey?: string
}

const booleanOptions = [
  { display: 'True', value: 'true' },
  { display: 'False', value: 'false' },
]
const makeBooleanOptions = () => booleanOptions

const directionOptions = [
  { display: 'Credit', value: TransactionDirection.Credit },
  { display: 'Debit', value: TransactionDirection.Debit },
  { display: 'Transfer', value: TransactionDirection.Transfer },
]
const makeDirectionOptions = () => directionOptions

const makeBuildMap =
  (filterOptions: FilterOptionV2[]) =>
  (displayKey: 'key' | 'displayName' = 'key') =>
  () => {
    return sortBy(
      filterOptions?.map((option) => ({ display: option[displayKey] ?? '', value: option.key })) ?? [],
      'display'
    )
  }

const useDashboardQueues = () => {
  const { queues } = useQueues()
  if (useFeatureFlag(FeatureFlag.ReviewsToQueues)) {
    return queues
  }
  return []
}

const UNASSIGNED = {
  display: 'Unassigned',
  // Special sentinel value. See filters in app/domain/search/elasticsearch/dsl/filter/
  value: 'UNASSIGNED',
}

const getRelations =
  ({ teammates, currentAccount, queues }: Omit<FilterCreatorProps, 'reviewTypes' | 'filters' | 'aggsKey'>) =>
  () => {
    const teammateValues = sortBy(
      teammates
        .filter((tm) => tm.token !== currentAccount.token)
        .map((tm) => ({
          display: tm.fullName,
          value: tm.token,
        })),
      'display'
    )

    return [
      UNASSIGNED,
      {
        display: `Me (${currentAccount.shortName})`,
        value: currentAccount.token,
      },
      ...queues.map((q) => ({ display: q.name, value: q.token })),
      ...teammateValues,
    ]
  }

const getQueues = (queues: Queue[]) => () => {
  const queueValues = sortBy(
    queues.map((q) => ({
      display: q.name,
      value: q.token,
    })),
    'display'
  )

  return [UNASSIGNED, ...queueValues]
}

const reviewsFilterCreator = ({
  teammates,
  reviewTypes,
  currentAccount,
  filters,
  queues,
  aggsKey,
}: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)

  function getHeaderFilterValues(): (() => Option[]) | null {
    switch (aggsKey) {
      case 'review_type_canonical_id':
        return () =>
          sortBy(
            filters.map((t) => ({
              display: reviewTypes.find((rt) => rt.canonicalId === t.key)?.name ?? t.key,
              value: t.key,
            })),
            'display'
          )
      case 'assignee':
        return getRelations({ teammates, currentAccount, queues: [] })
      case 'approvers':
        return getRelations({ teammates, currentAccount, queues })
      case 'queue':
        return getQueues(queues)

      case 'tags':
      case 'investigation_tags':
      case 'filing_status':
      case 'state':
      case 'status':
        return buildMap('displayName')
      default:
        return buildMap('key')
    }
  }
  return getHeaderFilterValues
}

const profilesFilterCreator = ({ filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)
  function getHeaderFilterValues(): (() => Option[]) | null {
    switch (aggsKey) {
      case 'type':
        return buildMap('displayName')

      default:
        return buildMap('key')
    }
  }
  return getHeaderFilterValues
}

const informationRequestsFilterCreator = ({ teammates, currentAccount, filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)
  function getHeaderFilterValues(): (() => Option[]) | null {
    switch (aggsKey) {
      case 'status':
      case 'form_type':
        return buildMap('displayName')
      case 'createdBy':
        return getRelations({ teammates, currentAccount, queues: [] })
      default:
        return buildMap('key')
    }
  }
  return getHeaderFilterValues
}

const filingsFilterCreator = ({ teammates, currentAccount, filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)
  function getHeaderFilterValues(): (() => Option[]) | null {
    switch (aggsKey) {
      case 'subdivisions':
        return buildMap('key')
      case 'filer':
      case 'assignee':
        return getRelations({ teammates, currentAccount, queues: [] })
      default:
        return buildMap('displayName')
    }
  }
  return getHeaderFilterValues
}

const ctrFilingsFilterCreator = ({ teammates, currentAccount, filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)
  function getHeaderFilterValues(): (() => Option[]) | null {
    switch (aggsKey) {
      case 'subdivisions':
        return buildMap('key')
      case 'filer':
      case 'assignee':
        return getRelations({ teammates, currentAccount, queues: [] })
      default:
        return buildMap('displayName')
    }
  }
  return getHeaderFilterValues
}

const transactionsFilterCreator = ({ filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)

  const getHeaderFilterValues = (): (() => Option[]) | null => {
    switch (aggsKey) {
      case 'direction':
        return makeDirectionOptions
      case 'flagged':
        return makeBooleanOptions
      case 'currency_issuing_country_code':
        return buildMap('key')
      default:
        return buildMap('displayName')
    }
  }
  return getHeaderFilterValues
}

const automationRuleExecutionsFilterCreator = ({ filters, aggsKey }: FilterCreatorProps) => {
  const buildMap = makeBuildMap(filters)

  const getHeaderFilterValues = (): (() => Option[]) | null => {
    switch (aggsKey) {
      case 'rule.name':
        return buildMap('key')
      default:
        return buildMap('displayName')
    }
  }
  return getHeaderFilterValues
}

const getHeaderFilterValuesMap: Record<
  DashboardSlice,
  (creatorProps: FilterCreatorProps) => () => (() => Option[]) | null
> = {
  [DashboardTypeEnum.AutomationExecutions]: automationRuleExecutionsFilterCreator,
  [DashboardTypeEnum.Reviews]: reviewsFilterCreator,
  [DashboardTypeEnum.Profiles]: profilesFilterCreator,
  [DashboardTypeEnum.InformationRequests]: informationRequestsFilterCreator,
  [DashboardTypeEnum.Transactions]: transactionsFilterCreator,
  [DashboardTypeEnum.Filings]: filingsFilterCreator,
  [DashboardTypeEnum.CtrFilings]: ctrFilingsFilterCreator,
}

const useGetHeaderValues = () => {
  const dashboardContextValue = useDashboardContext()
  return getHeaderFilterValuesMap[dashboardContextValue]
}
const useFilterOptions = (column?: string, query?: string) => {
  const { filters } = useDashboardConfig()
  const { metadataV2Query, selectMetadataV2 } = filters
  const { implicitFilter: implicitFilterInput } = useDashboardImplicitFilters()

  const appliedFilters = implicitFilterInput ? normalizeRequest(implicitFilterInput) : implicitFilterInput

  const { data, loading, error } = useQuery(metadataV2Query, {
    fetchPolicy: 'cache-and-network',
    variables: { search: appliedFilters, columns: [column], query },
    skip: !column,
  })

  return { data: selectMetadataV2(data)?.filterOptions, loading, error }
}

const EMPTY_RESPONSE: FilterOptionV2[] = []
export const useGetHeaderFilterValuesV2 = (aggsKey?: string, query?: string) => {
  const teammates = useSelector(getOrganizationTeammates)
  const reviewTypes = useSelector(getAccountReviewTypes)
  const currentAccount = useSelector(getCurrentAccount)
  const queues = useDashboardQueues()

  const { data: filterOptions = EMPTY_RESPONSE, loading, error } = useFilterOptions(aggsKey, query)

  const makeGetHeaderFilterValues = useGetHeaderValues()
  const getHeaderFilterValues = useMemo(
    () =>
      makeGetHeaderFilterValues({ teammates, reviewTypes, currentAccount, filters: filterOptions, queues, aggsKey }),
    [makeGetHeaderFilterValues, teammates, reviewTypes, currentAccount, filterOptions, queues, aggsKey]
  )

  return { getHeaderFilterValues, loading, error }
}
