import { gql } from '@apollo/client'

export const DatasourceTriggerDataFragment = gql`
  fragment DatasourceTriggerData on Query {
    datasources @include(if: $datasourcesEnabled) {
      token
      name
      columns {
        name
        type
      }
    }
  }
`

export const TriggerFilterEditorDataSources = gql`
  ${DatasourceTriggerDataFragment}
  query TriggerFilterEditorDataSources($datasourcesEnabled: Boolean!) {
    ...DatasourceTriggerData
  }
`
