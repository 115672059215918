import { useCallback, useEffect, useMemo, useState } from 'react'

import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import { Select, MenuItem, OutlinedInput } from '@mui/material'

import classnames from 'classnames'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import { smartFromNow } from 'helpers/uiHelpers'

import { useInterval } from 'hooks'
import { useDateFormatter } from 'hooks/DateFormatHooks'

import { useStyles } from './HbPagination.styles'
import {
  getPageRange,
  getPageCount,
  isWithinRange,
  getPageBasedOnPageSize,
  filterPageSizeChoices,
  getEntriesCountText,
} from './HbPagination.utils'

export type PageSizeChoice = 25 | 50 | 100
const DEFAULT_PAGE_SIZE_CHOICES: PageSizeChoice[] = [25, 50, 100]
const DATE_FORMAT = 'MMM D, YYYY'
const PAGINATION_PAGE_COUNT_SELECTOR_ID = 'pagination-page-count-selector'

type BaseProps = {
  onPageChange: (page: number) => void
  page: number
  pageSize: number

  className?: string
  customEntriesText?: string
  enableNumberInput?: boolean
  hideIfOnlyOnePage?: boolean
  lastUpdated?: string | number
  loading?: boolean
  maxEntries?: number
  onRefresh?: () => void
  pageSizeChoices?: Array<number>
  total?: number
}

export type HbPaginationProps = (
  | {
      // determines whether or not a new page number is computed when a
      // new page size is selected
      computePageOnPageSizeChange: true
      onPageSizeChange?: (newPageSize: number, computedPage: number) => void
    }
  | {
      computePageOnPageSizeChange?: false
      onPageSizeChange?: (newPageSize: number) => void
    }
) &
  BaseProps

/**
 * @note `enableNumberInput` allows users to type in a page number to jump to.
 * This functionality will only work with offset pagination and not cursor pagination.
 */
export const HbPagination = (props: HbPaginationProps) => {
  const {
    computePageOnPageSizeChange,
    onPageChange,
    page,
    pageSize,

    className: classNameProp,
    customEntriesText,
    enableNumberInput = false,
    lastUpdated,
    loading = false,
    hideIfOnlyOnePage,
    maxEntries = Infinity,
    onPageSizeChange,
    onRefresh,
    pageSizeChoices = DEFAULT_PAGE_SIZE_CHOICES,
    total = 0,
  } = props

  const styles = useStyles()

  const filteredPageSizeChoices = useMemo(
    () => filterPageSizeChoices({ pageSizeChoices, pageSize, totalEntries: total }),
    [pageSizeChoices, pageSize, total]
  )

  const [inputPageValue, setInputPageValue] = useState<number | ''>(page)

  useEffect(() => {
    setInputPageValue(page)
  }, [page])

  const [start, end] = getPageRange({ page, pageSize, totalEntries: total })

  const pageCount = getPageCount(pageSize, total)

  const onPageChangeValidated = (newPage: number) => {
    if (isWithinRange(1, pageCount, newPage)) {
      onPageChange(newPage)
    }
  }

  const dateFormatter = useDateFormatter()

  const formatLastUpdatedAtDate = useCallback(() => {
    if (lastUpdated) {
      return smartFromNow(lastUpdated, (d) => dateFormatter(d, '', DATE_FORMAT))
    }
    return undefined
  }, [lastUpdated, dateFormatter])

  // Make the "Last updated" section update every 30 seconds so that
  // the text is more up-to-date (ie prevent "1 minute ago" from being
  // displayed for much longer than 1 minute)
  const formattedLastUpdatedAtDate = useInterval(formatLastUpdatedAtDate, 30_000)

  const entriesCountText = customEntriesText || getEntriesCountText({ start, end, totalEntries: total, maxEntries })

  const pageSizeInRange = useMemo(() => {
    if (!pageSizeChoices?.length) {
      return false
    }
    if (pageSizeChoices?.includes(pageSize)) {
      return true
    }

    // Pagination page size must be a value in pageSizeChoices
    return false
  }, [pageSize, pageSizeChoices])

  // If all of the items fit on the smallest page, don't show the pagination
  // Otherwise, show the pagination component or else the user won't be able to
  // reduce their page size if total < pageSize selection
  // filteredPageSizeChoices is sorted in ascending order
  const minPageSize = filteredPageSizeChoices.length > 0 ? filteredPageSizeChoices[0] : pageSize
  if (hideIfOnlyOnePage && Math.ceil((total ?? 0) / minPageSize) === 1) {
    return null
  }

  return (
    <div className={classnames(styles.root, styles.flexCenter, classNameProp)} data-testid="pagination">
      <div className={styles.flexCenter}>
        {entriesCountText ? (
          <HbText
            data-testid="pagination-entries-text"
            size="s"
            color="secondary"
            className={classnames([styles.section, styles.truncate])}
          >
            {entriesCountText}
          </HbText>
        ) : null}
        {filteredPageSizeChoices?.length && onPageSizeChange && pageSizeInRange && start ? (
          <div className={classnames(styles.flexCenter, styles.section)}>
            <HbText color="secondary" size="s" tag="label" id={PAGINATION_PAGE_COUNT_SELECTOR_ID}>
              View
            </HbText>
            <Select
              labelId={PAGINATION_PAGE_COUNT_SELECTOR_ID}
              disabled={filteredPageSizeChoices?.length === 1 || loading}
              className={classnames(styles.smallLeftMargin, styles.textColor)}
              classes={{
                select: classnames(styles.input, styles.flexCenter),
              }}
              variant="outlined"
              value={pageSize}
              onChange={(ev) => {
                const newPageSize = Number(ev.target.value)
                if (computePageOnPageSizeChange) {
                  // Calculates the page based on the new page size so that the first
                  // entry on that page is included.
                  // For example:
                  // - if page size is 10 and page is 6, the page range would be 51-60
                  // - if the page size then changes to 50, page would be set to 2 and the page range would be 51-100
                  // Can be used be the caller of `onPageSizeChange`.
                  const computedPage = getPageBasedOnPageSize({ start, pageSize: newPageSize, totalEntries: total })
                  onPageSizeChange(newPageSize, computedPage)
                } else {
                  onPageSizeChange(newPageSize)
                }
              }}
            >
              {filteredPageSizeChoices.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : null}
        <div className={classnames(styles.flexCenter, styles.section)}>
          {formattedLastUpdatedAtDate && (
            <>
              <HbText size="s" color="secondary" className={classnames([styles.smallRightMargin, styles.truncate])}>
                Last updated {formattedLastUpdatedAtDate}.
              </HbText>
              {onRefresh && (
                <HbButton
                  label="Refresh"
                  size="small"
                  variant="textSecondary"
                  className={classnames(styles.refreshButton, styles.textColor)}
                  onClick={onRefresh}
                />
              )}
            </>
          )}
        </div>
      </div>
      {pageCount ? (
        <div className={classnames([styles.flexCenter, styles.fitContent])}>
          <HbButton
            aria-label="Previous Page"
            label="Previous Page"
            size="small"
            className={classnames(styles.smallRightMargin, styles.textColor)}
            onClick={() => {
              onPageChangeValidated(page - 1)
            }}
            disabled={inputPageValue === '' || inputPageValue <= 1 || loading}
            Icon={ChevronLeftRounded}
            iconOnly
          />
          {enableNumberInput ? (
            <OutlinedInput
              className={classnames(styles.smallRightMargin, styles.textColor, styles.numberInputWrapper)}
              classes={{
                input: classnames(styles.input, styles.textColor, styles.flexCenter),
              }}
              disabled={loading || pageCount === 1}
              value={inputPageValue}
              type="number"
              inputProps={{
                min: 1,
                max: pageCount,
                role: 'textbox',
              }}
              onBlur={() => {
                if (inputPageValue === '') {
                  setInputPageValue(page)
                }
              }}
              onChange={(ev) => {
                const newPage = ev.target.value
                // Allow clearing of input
                if (newPage === '') {
                  setInputPageValue('')
                  return
                }
                // Allow typing current page if input was blank
                if (Number(newPage) === page) {
                  setInputPageValue(page)
                  return
                }
                onPageChangeValidated(Number(newPage))
              }}
            />
          ) : (
            <HbText size="s" color="secondary" className={styles.smallRightMargin}>
              {page}
            </HbText>
          )}
          <HbText size="s" color="secondary" data-testid="pagination-page-count">
            of {pageCount}
          </HbText>
          <HbButton
            aria-label="Next Page"
            label="Next Page"
            size="small"
            className={classnames(styles.smallLeftMargin, styles.textColor)}
            onClick={() => {
              onPageChangeValidated(page + 1)
            }}
            disabled={inputPageValue === '' || inputPageValue >= pageCount || loading}
            Icon={ChevronRightRounded}
            iconOnly
          />
        </div>
      ) : null}
    </div>
  )
}
