import React, { useMemo } from 'react'

import { QueryResult, useQuery } from '@apollo/client'

import { TransactionEntry } from 'actions/transactionsActions'
import { AddTransaction } from 'components/cases/Tabs/Transactions/Table/TableControlsHeader'
import {
  AUTOMATION_RULE_EXECUTIONS_METADATA_QUERY_V2,
  AUTOMATION_RULE_EXECUTIONS_COLUMNS_QUERY_V2,
} from 'dashboards/automationRuleExecutions/gql/searchAutomationRuleExecutions.queries'
import {
  FILINGS_METADATA_QUERY_V2,
  FILINGS_COLUMNS_QUERY_V2,
  CTR_FILINGS_COLUMNS_QUERY,
  CTR_FILINGS_METADATA_QUERY,
} from 'dashboards/filings/gql/searchFilings.queries'
import {
  INFORMATION_REQUESTS_METADATA_QUERY_V2,
  INFORMATION_REQUESTS_COLUMNS_QUERY_V2,
} from 'dashboards/informationRequests/gql/searchInformationRequests.queries'

import { ProfilesBatchActionsMenu } from 'dashboards/profiles/BatchActionsMenu/BatchActionsMenu'
import ProfilesAddMenuItems from 'dashboards/profiles/components/Header/filtering/ProfilesAddMenuItems'
import { PROFILES_COLUMNS_QUERY_V2, PROFILES_METADATA_QUERY_V2 } from 'dashboards/profiles/gql/searchProfiles.queries'

import { useAvailableBatchActions } from 'dashboards/profiles/hooks/useGetAvailableBatchActions'
import { ReviewsBatchActionsMenu } from 'dashboards/reviews/ReviewsBatchActionsMenu/ReviewsBatchActionsMenu'

import ReviewsAddMenuItems from 'dashboards/reviews/components/Header/filtering/ReviewsAddMenuItems'
import { REVIEW_COLUMNS_QUERY_V2, REVIEW_METADATA_QUERY_V2 } from 'dashboards/reviews/gql/searchReviews.queries'
import {
  TRANSACTIONS_COLUMNS_QUERY_V2,
  TRANSACTIONS_METADATA_QUERY_V2,
} from 'dashboards/transactions/gql/searchTransactions.queries'
import { useFeatureFlag } from 'hooks'
import { DashboardSlice, NUM_DASHBOARD_ROWS_UPPER_LIMIT } from 'reducers/dashboards/dashboards.constants'
import {
  AutomationExecutionsDashboardEntry,
  FilingsReportDashboardEntry,
  TodoProfilesDashboardEntry,
} from 'reducers/dashboards/mappers'
import { DashboardColumn, DashboardEntry } from 'reducers/reviewsReducer'

import { DashboardTypeEnum, FeatureFlag, FilterOptionV2 } from 'types/api'

import { ExportCases } from '../components/Dashboard/ExportCases'
import { useDashboardContext } from '../components/DashboardContextProvider'

import { SearchResultReview } from './search'

export type BatchActionSelectUIState = 'all-selected' | 'some-selected' | 'none-selected'

// Some forced visibility columns *may* be reorderable. Batch should never be.
const batchColumn = { apiName: 'batch', title: '', filterable: false, reorderable: false }
const makeForcedVisibilityColumnsConfig = <T extends string, U extends string, V extends boolean>(
  columns: { title: T; apiName: U; filterable: V; reorderable: V }[]
): {
  forcedVisibilityColumnDisplayNames: Record<U, T>
  forcedVisibilityColumns: U[]
  forcedVisibilityColumnsFilterability: Record<U, V>
  forcedVisibilityColumnsReorderability: Record<U, number>
} => {
  const displayNameHash: Record<U, T> = {} as Record<U, T>
  const filterableHash: Record<U, V> = {} as Record<U, V>
  const reorderableHash: Record<U, number> = {} as Record<U, number>

  return {
    forcedVisibilityColumns: columns.map((column) => column.apiName),
    forcedVisibilityColumnDisplayNames: columns.reduce((acc, cur) => {
      acc[cur.apiName] = cur.title
      return acc
    }, displayNameHash),
    forcedVisibilityColumnsFilterability: columns.reduce((acc, cur) => {
      acc[cur.apiName] = cur.filterable
      return acc
    }, filterableHash),
    forcedVisibilityColumnsReorderability: columns.reduce((acc, cur, index) => {
      acc[cur.apiName] = cur.reorderable ? index : -1
      return acc
    }, reorderableHash),
  }
}

export const injectUpperLimitIndicator = (totalEntries: number): string =>
  totalEntries >= NUM_DASHBOARD_ROWS_UPPER_LIMIT ? `${totalEntries}+` : `${totalEntries}`

type DashboardConfig = {
  emptyState: {
    title: string
    message: string
    buttonText: string | null
  }
  table: {
    emptyMessage: string
    uniqueKey: string
    batchSelectDisabled: string
    batchColumnEnabled: boolean
    isRowSelected: (row: unknown, selectedItems: Record<string, boolean>) => boolean
  } & ReturnType<typeof makeForcedVisibilityColumnsConfig>
  filterBannerActionButton: {
    placeholderText: string
  }
  header: {
    title: string

    BatchActionsMenu: React.FunctionComponent
    AddMenuItems: null | ((props: { openNewDialog: (...args: unknown[]) => void }) => JSX.Element)
    AddMenuButton: null | ((props: { openNewDialog: (...args: unknown[]) => void; iconOnly: boolean }) => JSX.Element)
    exportCsv?: boolean
  }
  filters: {
    ExportAction: ((props: { className: string }) => JSX.Element) | null
    columnsV2Query: Parameters<typeof useQuery>[0]
    metadataV2Query: Parameters<typeof useQuery>[0]
    selectMetadataV2: (
      data: QueryResult['data']
    ) => { filterOptions?: FilterOptionV2[]; columns?: DashboardColumn[]; staticColumns?: DashboardColumn[] } | null
  }
  filterControls: {
    isGlobalSearchEnabled?: boolean
    isFiltersEnabled: boolean
    shouldSetDefaultView: boolean
  }
}

export const dashboardConfigMap: Record<DashboardSlice, DashboardConfig> = {
  [DashboardTypeEnum.Reviews]: {
    emptyState: {
      title: 'Nothing to see here!',
      message:
        'Try adjusting your filters or checking you have the proper permissions. Or start a new case to get going.',
      buttonText: 'Start Case',
    },
    table: {
      emptyMessage: 'No matching reviews found.',
      uniqueKey: 'reviewToken',
      batchSelectDisabled: 'This case cannot be selected. Please contact customer support for assistance.',
      batchColumnEnabled: true,
      isRowSelected: (row: DashboardEntry, selectedItems: Record<string, boolean>) =>
        row.reviews?.some((review: SearchResultReview) => !!selectedItems[review.token]),
      ...makeForcedVisibilityColumnsConfig([batchColumn]),
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Case Filters',
    },
    header: {
      title: 'case',
      BatchActionsMenu: ReviewsBatchActionsMenu,
      AddMenuItems: ReviewsAddMenuItems,
      AddMenuButton: null,
      exportCsv: true,
    },
    filters: {
      ExportAction: ExportCases,
      columnsV2Query: REVIEW_COLUMNS_QUERY_V2,
      metadataV2Query: REVIEW_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchReviewMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
      isGlobalSearchEnabled: true,
    },
  },
  [DashboardTypeEnum.Profiles]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'Create a profile to get started.',
      buttonText: 'Create profile',
    },
    table: {
      emptyMessage: 'No matching profiles found.',
      uniqueKey: 'token',
      batchSelectDisabled: 'This profile cannot be selected.',
      batchColumnEnabled: true,
      isRowSelected: (row: TodoProfilesDashboardEntry, selectedItems: Record<string, boolean>) =>
        !!selectedItems[row.token],
      ...makeForcedVisibilityColumnsConfig([
        batchColumn,
        { apiName: 'displayName', title: 'Profile', filterable: true, reorderable: true },
      ]),
    },
    header: {
      title: 'profile',
      BatchActionsMenu: ProfilesBatchActionsMenu,
      AddMenuItems: ProfilesAddMenuItems,
      AddMenuButton: null,
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Profile Filters',
    },
    filters: {
      ExportAction: null,
      columnsV2Query: PROFILES_COLUMNS_QUERY_V2,
      metadataV2Query: PROFILES_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchProfilesMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
      isGlobalSearchEnabled: true,
    },
  },
  [DashboardTypeEnum.InformationRequests]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'Create a request on a case to get started.',
      buttonText: null,
    },
    table: {
      emptyMessage: 'No matching requests found.',
      uniqueKey: 'token',
      batchSelectDisabled: 'This request cannot be selected.',
      batchColumnEnabled: false,
      isRowSelected: (row: TodoProfilesDashboardEntry, selectedItems: Record<string, boolean>) =>
        !!selectedItems[row.token],
      ...makeForcedVisibilityColumnsConfig([
        { apiName: 'title', title: 'Title', filterable: false, reorderable: true },
        // TODO reinstate when batch functionality implemented { apiName: 'batch', title: '' },
      ]),
    },
    header: {
      title: 'Sent Requests',
      BatchActionsMenu: () => null,
      AddMenuItems: null,
      AddMenuButton: null,
      exportCsv: true,
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Request Filters',
    },
    filters: {
      ExportAction: null,
      columnsV2Query: INFORMATION_REQUESTS_COLUMNS_QUERY_V2,
      metadataV2Query: INFORMATION_REQUESTS_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchInformationRequestsMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
    },
  },
  [DashboardTypeEnum.Transactions]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'Add new transactions.',
      buttonText: 'Add transaction',
    },
    table: {
      emptyMessage: 'No matching transactions found.',
      uniqueKey: 'transactionId',
      batchSelectDisabled: 'This transaction cannot be selected. Please contact customer support for assistance.',
      batchColumnEnabled: true,
      isRowSelected: (txn: TransactionEntry, selectedItems: Record<string, boolean>) => !!selectedItems[txn.token],
      ...makeForcedVisibilityColumnsConfig([
        batchColumn,
        { apiName: 'flagged', title: 'Flag', filterable: true, reorderable: false },
      ]),
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Case Filters',
    },
    header: {
      title: 'transactions',
      BatchActionsMenu: () => null,
      AddMenuItems: null,
      AddMenuButton: AddTransaction,
    },
    filters: {
      ExportAction: null,
      columnsV2Query: TRANSACTIONS_COLUMNS_QUERY_V2,
      metadataV2Query: TRANSACTIONS_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchTransactionsMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: false,
    },
  },
  [DashboardTypeEnum.Filings]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'Create a filing to get started.',
      buttonText: null,
    },
    table: {
      emptyMessage: 'No matching filings found.',
      uniqueKey: 'token',
      batchSelectDisabled: 'This filing cannot be selected.',
      batchColumnEnabled: false,
      isRowSelected: (row: FilingsReportDashboardEntry, selectedItems: Record<string, boolean>) =>
        !!selectedItems[row.token],
      ...makeForcedVisibilityColumnsConfig([]),
    },
    header: {
      title: 'SAR Filings',
      BatchActionsMenu: () => null,
      AddMenuItems: null,
      AddMenuButton: null,
      exportCsv: true,
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Filings"',
    },
    filters: {
      ExportAction: null,
      columnsV2Query: FILINGS_COLUMNS_QUERY_V2,
      metadataV2Query: FILINGS_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchFilingsMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
    },
  },
  [DashboardTypeEnum.CtrFilings]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'Create a filing to get started.',
      buttonText: null,
    },
    table: {
      emptyMessage: 'No matching filings found.',
      uniqueKey: 'token',
      batchSelectDisabled: 'This filing cannot be selected.',
      batchColumnEnabled: false,
      isRowSelected: (row: FilingsReportDashboardEntry, selectedItems: Record<string, boolean>) =>
        !!selectedItems[row.token],
      ...makeForcedVisibilityColumnsConfig([]),
    },
    header: {
      title: 'CTR Filings',
      BatchActionsMenu: () => null,
      AddMenuItems: null,
      AddMenuButton: null,
      exportCsv: true,
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Filings"',
    },
    filters: {
      ExportAction: null,
      columnsV2Query: CTR_FILINGS_COLUMNS_QUERY,
      metadataV2Query: CTR_FILINGS_METADATA_QUERY,
      selectMetadataV2: (data) => data?.searchCtrFilingsMetadata,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
    },
  },
  [DashboardTypeEnum.AutomationExecutions]: {
    emptyState: {
      title: 'Nothing to see here!',
      message: 'No matching executions found! Try adjusting your filters or making sure your automation is enabled.',
      buttonText: null,
    },
    table: {
      emptyMessage: 'No matching executions found.',
      uniqueKey: 'token',
      batchSelectDisabled: 'This execution cannot be selected.',
      batchColumnEnabled: false,
      isRowSelected: (row: AutomationExecutionsDashboardEntry, selectedItems: Record<string, boolean>) =>
        !!selectedItems[row.token],
      ...makeForcedVisibilityColumnsConfig([]),
    },
    header: {
      title: 'Runs',
      BatchActionsMenu: () => null,
      AddMenuItems: null,
      AddMenuButton: null,
      exportCsv: true,
    },
    filterBannerActionButton: {
      placeholderText: 'e.g. "My Automation Runs"',
    },
    filters: {
      ExportAction: null,
      columnsV2Query: AUTOMATION_RULE_EXECUTIONS_COLUMNS_QUERY_V2,
      metadataV2Query: AUTOMATION_RULE_EXECUTIONS_METADATA_QUERY_V2,
      selectMetadataV2: (data) => data?.searchAutomationRuleExecutionsMetadataV2,
    },
    filterControls: {
      isFiltersEnabled: true,
      shouldSetDefaultView: true,
    },
  },
} as const

type DashboardConfigMap = typeof dashboardConfigMap

export const useDashboardConfig = (): DashboardConfigMap[keyof DashboardConfigMap] => {
  const dashboardContextValue = useDashboardContext()

  const enableDownloadFilings = useFeatureFlag(FeatureFlag.EnableDownloadFilings)
  const { hasAvailableBatchActions } = useAvailableBatchActions()

  return useMemo(() => {
    const dashboardConfig = dashboardConfigMap[dashboardContextValue]
    switch (dashboardContextValue) {
      case DashboardTypeEnum.Filings: {
        const { header, ...rest } = dashboardConfig
        return {
          ...rest,
          header: {
            ...header,
            exportCsv: enableDownloadFilings,
          },
        }
      }
      case DashboardTypeEnum.CtrFilings: {
        const { header, ...rest } = dashboardConfig
        return {
          ...rest,
          header: {
            ...header,
            exportCsv: enableDownloadFilings,
          },
        }
      }
      case DashboardTypeEnum.Profiles: {
        const { table, ...rest } = dashboardConfig
        return {
          ...rest,
          table: {
            ...table,
            batchColumnEnabled: hasAvailableBatchActions,
          },
        }
      }
      default:
        return dashboardConfig
    }
  }, [dashboardContextValue, enableDownloadFilings, hasAvailableBatchActions])
}

export default dashboardConfigMap
