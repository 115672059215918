import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useFormikContext } from 'formik'

import MaintenanceWindowMessage from 'components/auth/MaintenanceWindowMessage'
import { CircleArrowRightIcon } from 'icons/CircleArrowRightIcon'
import { Theme } from 'types/hb'

import { HbButton } from '../../HbComponents/HbButton'
import { HbText } from '../../HbComponents/Text/HbText'
import Loader from '../Loader'
import { ShowAfterDelay } from '../ShowAfterDelay'

export const STEP_BUTTON_HEIGHT = 54

const useStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to root element */
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  /* Styles applied to step title */
  title: {
    fontSize: 42,
    lineHeight: 'normal',
  },

  /* Styles applied to step description */
  description: {
    marginTop: theme.spacing(3),
    maxWidth: '500px',
  },

  /* Styles applied to HB logo */
  logo: {
    display: 'block',
    margin: 'auto',
    paddingTop: '40px',
  },

  /* Styles applied to DialogPaper component */
  paper: {
    marginTop: theme.spacing(1.5),
  },

  /* Styles applied to headline typography */
  headline: {
    marginBottom: '30px',
  },

  /* Styles applied to the steps `actions` container */
  actions: {
    display: 'flex',
    marginTop: theme.spacing(),
    gap: theme.spacing(),
  },

  button: {
    height: STEP_BUTTON_HEIGHT,
    flex: 1,
  },
}))

export interface Props {
  children: React.ReactNode
  additionalActions?: React.ReactNode
  description?: React.ReactNode
  /* Optional callback, if not passed in, no back button is shown */
  onBack?: () => void
  onNext: () => void
  title: React.ReactNode
  nextLabel?: string
}

export default function Step({
  children,
  additionalActions,

  description,
  onBack,
  onNext,
  nextLabel,
  title,
}: Props) {
  const { isSubmitting } = useFormikContext()
  const classes = useStyles()

  return (
    <form className={classes.root}>
      <HbText className={classes.title}>{title}</HbText>
      {description && <HbText className={classes.description}>{description}</HbText>}
      <MaintenanceWindowMessage
        message="Hummingbird will have a scheduled maintenance period from 5PM-5:30PM PT on Tuesday, 8/13. The service may be unavailable during this time."
        showUntil={new Date('2024-08-14T00:00:00.000Z')}
      />

      <div className={classes.paper}>
        {children}

        {isSubmitting && (
          <ShowAfterDelay>
            <Loader indicatorType="linear" />
          </ShowAfterDelay>
        )}
        <div className={classes.actions}>
          {onBack && <HbButton variant="textSecondary" label="Back" onClick={onBack} />}

          <HbButton
            className={classes.button}
            data-id="next-button"
            type="submit"
            disabled={isSubmitting}
            label={nextLabel || 'Next'}
            onClick={onNext}
            Icon={CircleArrowRightIcon}
            iconPlacement="right"
          />
        </div>
      </div>

      {additionalActions}
    </form>
  )
}
