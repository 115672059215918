import { omit } from 'lodash'

import { OtherInfoEntry } from 'types/api'

import { CustomFieldLabelSearchQuery } from './__generated__/queries.generated'

export const OTHER_INFO_FIELD_NAME = 'otherInfo'

export const MAX_LABEL_LENGTH = 256

export const otherInfoTextAreaClassName = 'otherInfo__textArea'

export type CustomFieldLabelSearchData = CustomFieldLabelSearchQuery['customFieldLabelSearch']

export const sanitizeOtherInfo = (entry: OtherInfoEntry) => omit(entry, ['__typename'])
