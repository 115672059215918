import { useContext } from 'react'

import { gql } from '@apollo/client'
import { Assignment, Work } from '@mui/icons-material'

import classnames from 'classnames'

import { PhoneNumber } from 'libphonenumber-js'

import { identity } from 'lodash'

import { flagSwitch } from 'components/Feature'
import { HbText } from 'components/HbComponents/Text/HbText'
import {
  EntityInformationContext,
  EntityInformationContextValue,
} from 'components/entities/Information/EntityInformationContext'
import { BusinessInformationFragment } from 'components/entities/Information/__generated__/BusinessInformation.generated'
import SARFieldAdornment from 'components/library/SARFieldAdornment'
import { LabeledField, TextField } from 'components/material/Form'
import { displayAddress } from 'helpers/uiHelpers'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'

import { EmailFieldIcon } from 'icons/EmailFieldIcon'
import { IdFieldIcon } from 'icons/IdFieldIcon'
import { LinkFieldIcon } from 'icons/LinkFieldIcon'
import { LocationFieldIcon } from 'icons/LocationFieldIcon'
import { PhoneFieldIcon } from 'icons/PhoneFieldIcon'
import {
  Business,
  BusinessTypeMetadata,
  FeatureFlag,
  LegalNameMetadata,
  LibraryIdentityDocument,
  OtherInfoLabelTypeEnum,
} from 'types/api'

import { AddressMap } from '../AddressMap'
import { BasicInfoFields } from '../Edit/BasicInfoFields'
import {
  AddressEdit,
  AddressField,
  displayPhone,
  EmailEdit,
  EmailField,
  ExternalIdEditable,
  getLabelProp,
  OtherInfoEditable,
  PhoneEdit,
  PhoneField,
  useInlineFieldStyles,
  UsernameEdit,
  UsernameField,
  WebsiteEdit,
  WebsiteField,
} from '../Edit/FieldEdit'
import {
  IdentityDocumentFieldSet,
  IdentityDocumentFieldSetEdit,
  getIdentityDocumentDiffKey,
} from '../Edit/FieldSetEdit'
import { TinEditBlock } from '../Edit/TinEdit'
import {
  createGetMetadataDiffKey,
  createGetSingleActiveDefaultMetadataValue,
  createInfoField,
  Editable,
  EditableArray,
  EditProps,
  getStringValueMetadataDiffKey,
  InfoFieldArray,
  InfoFieldSeparator,
  MetadataInfoFieldArray,
} from '../InformationFields'
import { RevisionProps } from '../InformationFields.types'

import { BasicInformationSection } from './BasicInformationSection'
import { EditableSection } from './EntityInformationLayout'
import { OTHER_INFO_ENTRY_FRAGMENT } from './OtherInfoEntry.queries'

type BusinessPhoneNumber = Omit<PhoneNumber, 'number'> & { number: string }

export const LegalNameField = createInfoField<string>(
  (name) => name,
  () => 'Legal name'
)

export function LegalNameEdit({ name }: EditProps) {
  return (
    <LabeledField
      FieldComponent={TextField}
      name={name()}
      placeholder="Legal name"
      adornment={<SARFieldAdornment field="4: Individual's last name or entity's legal name" />}
    />
  )
}

const dbaLabel = 'Doing Business As (DBA)'

export const DbaNameField = createInfoField<string>(
  (name) => name,
  () => dbaLabel
)

export function DbaNameEdit({ name }: EditProps) {
  return (
    <LabeledField
      FieldComponent={TextField}
      name={name()}
      placeholder={dbaLabel}
      adornment={<SARFieldAdornment field="9: Alternate name, e.g., AKA - individual or trade name, DBA - entity" />}
    />
  )
}

export const formatBusinessType = (b: Business) => {
  const { label, naicsCode } = b
  return [label, naicsCode].filter((s) => !!s).join(', ')
}

const BusinessTypeField = createInfoField<Business>(formatBusinessType)

export function BusinessTypeEdit({ noLabels }: { noLabels?: boolean } & Partial<EditProps>) {
  const classes = useInlineFieldStyles()

  return (
    <div className={classes.root}>
      <LabeledField
        classes={{ root: classes.fill }}
        FieldComponent={TextField}
        name="businessType.label"
        adornment={<SARFieldAdornment field="10: Occupation or type of business" />}
        {...getLabelProp('Type of Business', noLabels)}
      />
      <LabeledField
        classes={{ root: classnames(classes.fill, classes.second) }}
        FieldComponent={TextField}
        name="businessType.naicsCode"
        adornment={<SARFieldAdornment field="10a: NAICS Code" />}
        {...getLabelProp('NAICS Code', noLabels)}
      />
    </div>
  )
}

const mergingFeatureFlags = [FeatureFlag.EnableLibraryMergingBusinesses]

const PrimaryFieldSubText = ({ label }: { label: string }) => {
  return (
    <>
      <HbText color="secondary" size="s" tag="span">
        {label}
      </HbText>
      <InfoFieldSeparator />
      <HbText color="secondary" size="s" tag="span">
        Primary
      </HbText>
    </>
  )
}

const LegacyLegalNamesEditBlock = () => {
  return (
    <EditableArray name="legalNames" defaultValue="">
      {(params) => (
        <InfoFieldArray<string>
          getDiffKey={identity}
          placeholder="Legal Name"
          params={params}
          Icon={Assignment}
          Read={LegalNameField}
          Edit={LegalNameEdit}
        />
      )}
    </EditableArray>
  )
}

const PrimaryLegalNameSubText = () => {
  return (
    <>
      <HbText color="secondary" size="s" tag="span">
        Legal Name
      </HbText>
      <InfoFieldSeparator />
      <HbText color="secondary" size="s" tag="span">
        Primary
      </HbText>
    </>
  )
}

export const PrimaryLegalNameField = createInfoField<string>(
  (value) => value,
  () => <PrimaryLegalNameSubText />
)

interface LegalNameMetadataReadProps extends RevisionProps {
  data: LegalNameMetadata
}

const LegalNameMetadataRead = ({ data, ...revisionProps }: LegalNameMetadataReadProps) => {
  if (data.active) return <PrimaryLegalNameField data={data.value} {...revisionProps} />
  return <LegalNameField data={data.value} {...revisionProps} />
}

const LegalNameMetadataEdit = ({ name }: { name: (key?: string) => string }) => (
  <LabeledField
    FieldComponent={TextField}
    name={name('value')}
    placeholder="Legal name"
    adornment={<SARFieldAdornment field="4: Individual's last name or entity's legal name" />}
  />
)

const LEGAL_NAMES_FIELD_NAME = 'legalNamesMetadata'

const getLegalNamesMetadataDefaultValue = createGetSingleActiveDefaultMetadataValue(LEGAL_NAMES_FIELD_NAME)

const RedesignLegalNamesEditBlock = () => {
  return (
    <EditableArray name={LEGAL_NAMES_FIELD_NAME} getDefaultValue={getLegalNamesMetadataDefaultValue}>
      {(params) => (
        <MetadataInfoFieldArray<LegalNameMetadata>
          getDiffKey={getStringValueMetadataDiffKey}
          allowRemovingActive
          placeholder="Legal Names"
          params={params}
          Icon={Assignment}
          Read={LegalNameMetadataRead}
          Edit={LegalNameMetadataEdit}
        />
      )}
    </EditableArray>
  )
}

const LegalNamesEditBlock = flagSwitch(
  FeatureFlag.EnableLibraryMergingBusinesses,
  LegacyLegalNamesEditBlock,
  RedesignLegalNamesEditBlock
)

const DbaNamesEditBlock = () => {
  return (
    <EditableArray name="dbaNames" defaultValue="">
      {(params) => (
        <InfoFieldArray<string>
          getDiffKey={identity}
          placeholder={dbaLabel}
          params={params}
          Icon={Assignment}
          Read={DbaNameField}
          Edit={DbaNameEdit}
        />
      )}
    </EditableArray>
  )
}

const PrimaryDbaNameSubText = () => <PrimaryFieldSubText label="Legal Name" />

export const PrimaryDbaNameField = createInfoField<string>(
  (value) => value,
  () => <PrimaryDbaNameSubText />
)

const BUSINESS_TYPES_FIELD_NAME = 'businessTypes'

const LegacyBusinessTypeEdit = () => (
  <Editable
    name="businessType"
    placeholder="Type of Business"
    Icon={Work}
    Read={BusinessTypeField}
    Edit={BusinessTypeEdit}
  />
)

interface BusinessTypeMetadataRead extends RevisionProps {
  data: BusinessTypeMetadata
}

const PrimaryBusinessTypeMetadataRead = createInfoField<Business>(formatBusinessType, () => 'Primary')
const BusinessTypeMetadataRead = ({ data, ...revisionProps }: BusinessTypeMetadataRead) => {
  if (data.active) return <PrimaryBusinessTypeMetadataRead data={data.value} {...revisionProps} />
  return <BusinessTypeField data={data.value} {...revisionProps} />
}

const BusinessTypeMetadataEdit = ({
  active,
  name: getFieldName,
}: {
  active: boolean
  name: (key?: string) => string
}) => {
  const classes = useInlineFieldStyles()
  const name = getFieldName('value')
  const isSecondary = !active
  return (
    <div className={classes.root}>
      <LabeledField
        classes={{ root: classes.fill }}
        name={`${name}.label`}
        adornment={isSecondary ? null : <SARFieldAdornment field="10: Occupation or type of business" />}
        {...getLabelProp('Type of Business')}
      />
      <LabeledField
        classes={{ root: classnames(classes.fill, classes.second) }}
        name={`${name}.naicsCode`}
        adornment={isSecondary ? null : <SARFieldAdornment field="10a: NAICS Code" />}
        {...getLabelProp('NAICS Code')}
      />
    </div>
  )
}

const getBusinessTypeMetadataDefaultValue = createGetSingleActiveDefaultMetadataValue(BUSINESS_TYPES_FIELD_NAME)
const getBusinessTypeMetadataDiffKey = createGetMetadataDiffKey<BusinessTypeMetadata>(formatBusinessType)

const RedesignBusinessTypeEdit = () => (
  <EditableArray name={BUSINESS_TYPES_FIELD_NAME} getDefaultValue={getBusinessTypeMetadataDefaultValue}>
    {(params) => (
      <MetadataInfoFieldArray<BusinessTypeMetadata>
        getDiffKey={getBusinessTypeMetadataDiffKey}
        allowRemovingActive
        placeholder="Type of Business"
        params={params}
        Icon={Work}
        Read={BusinessTypeMetadataRead}
        Edit={BusinessTypeMetadataEdit}
      />
    )}
  </EditableArray>
)

const BusinessTypeEditBlock = flagSwitch(
  FeatureFlag.EnableLibraryMergingBusinesses,
  LegacyBusinessTypeEdit,
  RedesignBusinessTypeEdit
)

const getPhoneDiffKey = (value: BusinessPhoneNumber) => displayPhone(value) ?? ''

function BasicInformationSectionWrapper() {
  const { data } = useContext<EntityInformationContextValue<BusinessInformationFragment>>(EntityInformationContext)

  return (
    <BasicInformationSection>
      <ExternalIdEditable />

      <LegalNamesEditBlock />
      <DbaNamesEditBlock />
      <BusinessTypeEditBlock />
      <EditableArray name="emailAddresses" defaultValue="">
        {(params) => (
          <InfoFieldArray<string>
            getDiffKey={identity}
            placeholder="Email address"
            params={params}
            Icon={EmailFieldIcon}
            Read={EmailField}
            Edit={EmailEdit}
          />
        )}
      </EditableArray>
      <EditableArray name="websites" defaultValue="">
        {(params) => (
          <InfoFieldArray<string>
            getDiffKey={identity}
            placeholder="Website"
            params={params}
            Icon={LinkFieldIcon}
            Read={WebsiteField}
            Edit={WebsiteEdit}
          />
        )}
      </EditableArray>

      <EditableArray name="usernames" defaultValue="">
        {(params) => (
          <InfoFieldArray<string>
            getDiffKey={identity}
            placeholder="Username"
            params={params}
            Icon={IdFieldIcon}
            Read={UsernameField}
            Edit={UsernameEdit}
          />
        )}
      </EditableArray>

      <TinEditBlock featureFlags={mergingFeatureFlags} />

      <EditableArray name="addresses">
        {(params) => (
          <InfoFieldArray<DisplayAddressAddressFragment>
            getDiffKey={displayAddress}
            placeholder="Address"
            params={params}
            Icon={LocationFieldIcon}
            Read={AddressField}
            Edit={AddressEdit}
            childrenFirst
          >
            {params.mode === 'read' && <AddressMap addresses={data.addresses} />}
          </InfoFieldArray>
        )}
      </EditableArray>
      <EditableArray name="phoneNumbers">
        {(params) => (
          <InfoFieldArray<BusinessPhoneNumber>
            getDiffKey={getPhoneDiffKey}
            placeholder="Phone number"
            params={params}
            Icon={PhoneFieldIcon}
            Read={PhoneField}
            Edit={PhoneEdit}
          />
        )}
      </EditableArray>
      <BasicInfoFields labelType={OtherInfoLabelTypeEnum.Business} />
    </BasicInformationSection>
  )
}

export function BusinessInformation() {
  return (
    <>
      <BasicInformationSectionWrapper />
      <EditableSection
        title="Identity Documents"
        name="identityDocuments"
        addLabel="Add identity document"
        displayAddWhenEmpty
      >
        {(params) => (
          <InfoFieldArray<LibraryIdentityDocument>
            getDiffKey={getIdentityDocumentDiffKey}
            params={params}
            Read={IdentityDocumentFieldSet}
            Edit={IdentityDocumentFieldSetEdit}
          />
        )}
      </EditableSection>
      <OtherInfoEditable otherInfoLabelType={OtherInfoLabelTypeEnum.Business} />
    </>
  )
}

BusinessInformation.fragments = {
  entry: gql`
    fragment BusinessInformation on LibraryBusiness {
      archived
      externalId
      businessType {
        label
        naicsCode
      }
      businessTypes {
        active
        value {
          label
          naicsCode
        }
      }
      dbaNames
      legalNames
      legalNamesMetadata {
        active
        value
      }
      addresses {
        ...AddressEdit
      }
      emailAddresses
      phoneNumbers {
        extension
        number
        type
      }
      usernames
      websites
      tins {
        number
        type
      }
      tinsMetadata {
        active
        value {
          number
          type
        }
      }
      identityDocuments {
        type
        typeOther
        nationalityCountryCode
        number
        issuingStateCode
        issuingCountryCode
        expiresOn
      }
      otherInfo {
        ...OtherInfoEntryFields
      }
    }
    ${AddressEdit.fragments.entry}
    ${OTHER_INFO_ENTRY_FRAGMENT}
  `,
}
