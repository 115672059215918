import { ComponentType } from 'react'

import Alarm from '@mui/icons-material/Alarm'
import Apartment from '@mui/icons-material/Apartment'
import BarChart from '@mui/icons-material/BarChart'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MailOutline from '@mui/icons-material/MailOutline'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined'
import PendingActions from '@mui/icons-material/PendingActions'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import StorageIcon from '@mui/icons-material/Storage'
import { useTheme } from '@mui/material'

import { Hue } from 'components/colors'

import AutomationsIcon from 'icons/Automations'
import { BusinessIcon } from 'icons/BusinessIcon'
import { CustomInvestigateIcon } from 'icons/CustomInvestigateIcon'
import { MiddeskIcon } from 'icons/Logos/MiddeskIcon'
import { Zap } from 'icons/Zap'

import { AutomationActionType, AutomationDomainType } from 'types/api'
import { CSS } from 'types/hb'

type StyleCircleProps = {
  variant?: 'circle'
  size?: number
}

type StylePlainProps = {
  variant?: 'plain'
  size?: never
}

export type AutomationIconStyleProps = {
  grey?: boolean
  color: Hue
} & (StyleCircleProps | StylePlainProps)

const useCss = ({ color, size = 6, variant, grey }: AutomationIconStyleProps) => {
  const theme = useTheme()
  const baseStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: grey ? theme.palette.styleguide.textGreyDark : theme.palette.styleguide[`${color}Medium`],
  }
  switch (variant) {
    case 'circle':
      return {
        ...baseStyles,
        width: theme.spacing(size),
        height: theme.spacing(size),
        borderRadius: theme.spacing(size),
        border: `1px solid ${theme.palette.styleguide.borderLight}`,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        background: theme.palette.styleguide.white,
      }
    default:
      return baseStyles
  }
}

type ScheduleStep = 'schedule' | 'schedule_action'
export type RecipeStep = AutomationActionType | AutomationDomainType | 'sample' | ScheduleStep | 'select_organization'

export const ICON_MAPPING: Record<RecipeStep, { Icon: ComponentType; color: Hue }> = {
  [AutomationDomainType.Case]: {
    Icon: CustomInvestigateIcon,
    color: 'mint',
  },
  [AutomationDomainType.Filing]: {
    Icon: Zap,
    color: 'mint',
  },
  [AutomationDomainType.Person]: {
    Icon: PersonOutlinedIcon,
    color: 'mint',
  },
  [AutomationDomainType.Business]: {
    Icon: BusinessIcon,
    color: 'mint',
  },
  [AutomationDomainType.Datasource]: {
    Icon: StorageIcon,
    color: 'mint',
  },
  [AutomationDomainType.Review]: {
    Icon: PendingActions,
    color: 'mint',
  },
  [AutomationDomainType.MiddeskBusiness]: {
    Icon: MiddeskIcon,
    color: 'mint',
  },
  [AutomationActionType.Comment]: {
    Icon: ModeCommentOutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.Notification]: {
    Icon: MailOutline,
    color: 'plum',
  },
  [AutomationActionType.ReviewDigestNotification]: {
    Icon: MailOutline,
    color: 'plum',
  },
  [AutomationActionType.Tag]: {
    Icon: LabelOutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.AssignReviewToQueue]: {
    Icon: PersonAddAlt1OutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.CreateInvestigation]: {
    Icon: Zap,
    color: 'plum',
  },
  [AutomationActionType.CreateReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.CancelReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.CompleteReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.NoOp]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.OpenReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.SnoozeReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.UpdateReviewDueDate]: {
    Icon: PendingActions,
    color: 'plum',
  },
  [AutomationActionType.WriteOtherInfo]: {
    Icon: EditOutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.LockInvestigation]: {
    Icon: LockOutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.UnlockInvestigation]: {
    Icon: LockOutlinedIcon,
    color: 'plum',
  },
  [AutomationActionType.WorkflowActionReview]: {
    Icon: PendingActions,
    color: 'plum',
  },
  schedule: {
    Icon: Alarm,
    color: 'plum',
  },
  schedule_action: {
    Icon: AutomationsIcon,
    color: 'plum',
  },
  sample: {
    Icon: BarChart,
    color: 'plum',
  },
  select_organization: {
    Icon: Apartment,
    color: 'plum',
  },
}

export function AutomationIcon({
  Icon,
  color,
  size,
  variant = 'circle',
  overrideCss,
  grey,
}: {
  overrideCss?: CSS
  Icon: ComponentType | null
} & AutomationIconStyleProps) {
  const cssPropsBase = { color, grey }
  const css = useCss(variant === 'circle' ? { size, variant, ...cssPropsBase } : { variant, ...cssPropsBase })

  return <div css={[css, overrideCss]}>{Icon ? <Icon /> : null}</div>
}

export function RecipeStepIcon({
  type,
  size,
  overrideCss,
  variant = 'circle',
  grey,
}: {
  type?: RecipeStep | null
  overrideCss?: CSS
} & Omit<AutomationIconStyleProps, 'color'>) {
  const { Icon, color } = type ? ICON_MAPPING[type] : { Icon: null, color: 'mint' as Hue }

  const props = {
    Icon,
    color,
    overrideCss,
    grey,
  }

  return variant === 'circle' ? (
    <AutomationIcon variant="circle" size={size} {...props} />
  ) : (
    <AutomationIcon variant="plain" {...props} />
  )
}
