import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { AddCircleOutlineOutlined, DeleteSweep, FullscreenExitRounded, FullscreenRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/styles'

import { useParams } from 'react-router-dom'

import { deleteTransactionBlock } from 'actions/importingActions'
import { useDispatch, useSelector } from 'actions/store'

import { setTransactionFullScreen } from 'actions/transactionsActions'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useCaseLock } from 'components/cases/hooks/useCaseLock'
import ConfirmDialog from 'components/library/ConfirmDialog'
import FilterButton from 'dashboards/reviews/components/Header/filtering/FilterButton'
import {
  Props as FilterControlsProps,
  FilterControlProps,
  useStyles as useFilterControlsStyles,
} from 'dashboards/reviews/components/Header/filtering/FilterControls'

import HideButton from 'dashboards/reviews/components/Header/filtering/HideButton'
import SortButton from 'dashboards/reviews/components/Header/filtering/SortButton'
import { ViewSettings } from 'dashboards/reviews/components/Header/filtering/ViewSettings'

import { SEARCH_TRANSACTIONS_QUERY } from 'dashboards/transactions/gql/searchTransactions.queries'
import { getTransactionsFullScreen, hasPermission } from 'helpers/stateHelpers'
import { useToggle } from 'hooks'
import { BadgePermissionsEnum } from 'types/api'

const useAddTransactionCss = ({ iconOnly }: { iconOnly: boolean }) => {
  const theme = useTheme()
  return {
    icon: {
      color: theme.palette.background.light,
      ...(iconOnly ? {} : { marginRight: theme.spacing(0.5) }),
    },
  }
}

export const AddTransaction = ({ openNewDialog, iconOnly }: { openNewDialog: () => void; iconOnly: boolean }) => {
  const css = useAddTransactionCss({ iconOnly })

  const { caseToken } = useParams<{ caseToken: string }>()
  const { isLocked } = useCaseLock(caseToken)
  return (
    <HbButton
      Icon={AddCircleOutlineOutlined}
      iconCss={css.icon}
      label="Add transaction"
      onClick={openNewDialog}
      size="small"
      variant="primary"
      disabled={isLocked}
      iconOnly={iconOnly}
      tooltip={iconOnly}
    />
  )
}

export const FullScreenButton = ({ disabled, size = 'small', iconOnly }: FilterControlProps) => {
  const isFullScreen = useSelector(getTransactionsFullScreen)
  const dispatch = useDispatch()
  const handleExitFullScreen = () => {
    dispatch(setTransactionFullScreen(false))
  }
  const handleEnterFullScreen = () => {
    dispatch(setTransactionFullScreen(true))
  }
  if (isFullScreen) {
    return (
      <HbButton
        disabled={disabled}
        size={size}
        Icon={FullscreenExitRounded}
        iconOnly={iconOnly}
        label="Exit Full Screen"
        onClick={handleExitFullScreen}
        variant="secondary"
      />
    )
  }

  return (
    <HbButton
      disabled={disabled}
      size={size}
      Icon={FullscreenRounded}
      iconOnly={iconOnly}
      label="Full Screen"
      onClick={handleEnterFullScreen}
      variant="secondary"
    />
  )
}

export const DeleteAllButton = ({ disabled, size = 'small', iconOnly }: FilterControlProps) => {
  const dispatch = useDispatch()
  const { caseToken } = useParams<{ caseToken: string }>()
  const gqlClient = useApolloClient()
  const { value: confirmOpen, toggle } = useToggle(false)
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await dispatch(deleteTransactionBlock(caseToken))
    setLoading(false)
    gqlClient.refetchQueries({ include: [SEARCH_TRANSACTIONS_QUERY] })
    toggle()
  }

  return (
    <>
      <HbButton
        disabled={disabled}
        size={size}
        Icon={DeleteSweep}
        iconOnly={iconOnly}
        label="Delete All"
        onClick={toggle}
        variant="secondary"
      />
      <ConfirmDialog
        title="Delete All Transactions?"
        confirmText="Delete All"
        confirmVariant="warning"
        ConfirmIconComponent={DeleteSweep}
        open={confirmOpen}
        onClose={toggle}
        onConfirm={handleDelete}
        loading={loading}
      >
        <HbText>This action will delete all transactions in the case and cannot be undone.</HbText>
      </ConfirmDialog>
    </>
  )
}

export const NewFilterControls = (props: FilterControlsProps) => {
  const { controlsContainer, ...sharedStyles } = useFilterControlsStyles()

  const canDelete = useSelector((state) => hasPermission(state, BadgePermissionsEnum.DeleteAllTransactions))

  const theme = useTheme()

  const zIndex = theme.zIndex.modal

  const sharedProps = {
    zIndex,
    ...sharedStyles,
    ...props,
  }

  return (
    <div className={controlsContainer}>
      <FullScreenButton {...sharedProps} />
      <FilterButton {...sharedProps} />
      <SortButton {...sharedProps} />
      <HideButton {...sharedProps} />
      {canDelete ? <DeleteAllButton {...sharedProps} /> : null}
      <ViewSettings {...sharedProps} />
    </div>
  )
}
