import React, { Key } from 'react'

import { styled } from '@mui/material'

import { setFlashError } from 'actions/errorActions'
import { useDispatch, useSelector } from 'actions/store'

import { HbCheckbox } from 'components/material/Form'
import { stopEvent } from 'helpers/uiHelpers'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { DashboardDensity } from 'reducers/userSettingsReducer'

import { ToggleBatchSelect } from './types'

const StyledCheckbox = styled(HbCheckbox)(({ theme }) => ({
  margin: theme.spacing(-1, 0, -1, 0.75),
  padding: theme.spacing(0.5),
  width: 36,
  height: 36,
}))

interface HasToken {
  token: string
}

interface Props<Item extends HasToken> {
  density?: DashboardDensity
  disabledMessage?: string
  disabled: boolean
  item: Item
  getBatchShiftClickSelection?: (token: string) => Key[]
  toggleBatchSelect?: ToggleBatchSelect<Item>
}

export const useToggleBatchSelected = (getBatchShiftClickSelection: (token: React.Key) => React.Key[]) => {
  const dispatch = useDispatch()
  const batchSelectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const toggleBatchSelect = (e: React.MouseEvent, token: string, disabled: boolean, disabledMessage: string) => {
    const isBatchSelected = !!batchSelectedItems[token]
    stopEvent(e)
    if (disabled) {
      dispatch(setFlashError(disabledMessage))
      return
    }
    if (e.shiftKey) {
      const batchShiftClickSelection = getBatchShiftClickSelection(token)
      dispatch(batchActionsActions.batchSelectItems.set({ tokens: batchShiftClickSelection, value: !isBatchSelected }))
    } else {
      dispatch(batchActionsActions.batchSelectItems.set({ tokens: [token], value: !isBatchSelected }))
    }
  }
  return toggleBatchSelect
}

export default function BatchActionSelectCell<Item extends HasToken>({
  density,
  disabledMessage = 'Batch selection is disabled',
  disabled,
  item,
  getBatchShiftClickSelection = (token) => [token],
  toggleBatchSelect: toggleBatchSelectProp,
}: Props<Item>) {
  const isBatchSelected = useSelector((state) => batchActionsSelectors.isBatchSelectedByToken(state, item.token))

  const toggleBatchSelect = useToggleBatchSelected(getBatchShiftClickSelection)
  const handleToggleBatchSelected = (e: React.MouseEvent) => {
    toggleBatchSelect(e, item.token, disabled, disabledMessage)
    toggleBatchSelectProp?.(e, item, disabled, disabledMessage)
  }

  return (
    <StyledCheckbox
      onClick={handleToggleBatchSelected}
      checked={isBatchSelected}
      disabled={disabled}
      aria-checked={isBatchSelected ? 'true' : 'false'}
      data-testid="batch-action-cell"
      size={density === 'compact' ? 's' : 'md'}
    />
  )
}
