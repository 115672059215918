import { AutomationActionType, AutomationDomainType, AutomationRuleType } from 'types/api'

import { ExploreAutomationsQuery } from './ExploreAutomations/__generated__/ExploreAutomations.queries.generated'

export interface Tag {
  display: string
  value: string
}

export const recipeTags: Record<string, Tag> = {
  comment: {
    display: 'Comment',
    value: 'comment',
  },
  notification: {
    display: 'Notification',
    value: 'notification',
  },
  nothing: {
    display: 'Nothing',
    value: 'nothing',
  },
  scheduled: {
    display: 'Scheduled',
    value: 'scheduled',
  },
  digest: {
    display: 'Digest',
    value: 'digest',
  },
  email: {
    display: 'Email',
    value: 'email',
  },
  case: {
    display: 'Case',
    value: 'case',
  },
  filing: {
    display: 'Filing',
    value: 'filing',
  },
  review: {
    display: 'Review',
    value: 'review',
  },
  person: {
    display: 'Person',
    value: 'person',
  },
  business: {
    display: 'Business',
    value: 'business',
  },
  crm: {
    display: 'CRM',
    value: 'crm',
  },
  tagging: {
    display: 'Tagging',
    value: 'tagging',
  },
  middesk_business: {
    display: 'Middesk Business',
    value: 'middesk_business',
  },
}

export const orderedRecipeCategories = Object.values(recipeTags).sort((a, b) => (a.display > b.display ? 1 : -1))
export const getFilteredRecipeCategories = (isMiddeskEnabled: boolean) =>
  orderedRecipeCategories.filter((category) => (category === recipeTags.middesk_business ? isMiddeskEnabled : true))

export const tagMapping: Record<AutomationActionType | AutomationDomainType, Array<Tag>> = {
  [AutomationActionType.Comment]: [recipeTags.comment],
  [AutomationActionType.Notification]: [recipeTags.notification, recipeTags.email],
  [AutomationActionType.ReviewDigestNotification]: [recipeTags.review, recipeTags.scheduled, recipeTags.digest],
  [AutomationDomainType.Case]: [recipeTags.case],
  [AutomationDomainType.Datasource]: [],
  [AutomationDomainType.Filing]: [recipeTags.filing, recipeTags.case, recipeTags.review],
  [AutomationDomainType.Person]: [recipeTags.person, recipeTags.crm],
  [AutomationDomainType.Business]: [recipeTags.business, recipeTags.crm],
  [AutomationDomainType.Review]: [recipeTags.review, recipeTags.case],
  [AutomationActionType.Tag]: [recipeTags.tagging],
  [AutomationActionType.CreateReview]: [recipeTags.review],
  [AutomationActionType.CancelReview]: [recipeTags.review],
  [AutomationActionType.CompleteReview]: [recipeTags.review],
  [AutomationActionType.OpenReview]: [recipeTags.review],
  [AutomationActionType.SnoozeReview]: [recipeTags.review],
  [AutomationDomainType.MiddeskBusiness]: [recipeTags.middesk_business],
  [AutomationActionType.CreateInvestigation]: [recipeTags.review, recipeTags.case],
  [AutomationActionType.AssignReviewToQueue]: [recipeTags.review],
  [AutomationActionType.UpdateReviewDueDate]: [recipeTags.review],
  [AutomationActionType.WriteOtherInfo]: [recipeTags.review],
  [AutomationActionType.WorkflowActionReview]: [recipeTags.review],
  [AutomationActionType.NoOp]: [recipeTags.nothing],
  [AutomationActionType.LockInvestigation]: [recipeTags.review, recipeTags.case, recipeTags.filing],
  [AutomationActionType.UnlockInvestigation]: [recipeTags.filing],
}

export type TagType = keyof typeof recipeTags

export const getRecipeTags = (template: ExploreAutomationsQuery['automationRuleTemplates'][number]): Array<Tag> => {
  if (template == null) {
    return []
  }

  const { actionType } = template

  if (actionType == null) {
    return []
  }

  const baseTags = [...tagMapping[actionType]]

  if (template.__typename === 'TriggeredRuleTemplate' || template.__typename === 'ScheduledWithTriggerRuleTemplate') {
    if (template.domainType == null) {
      return []
    }
    baseTags.push(...tagMapping[template.domainType])
  }
  if (template.automationType === AutomationRuleType.ScheduleWithTrigger) {
    baseTags.push(recipeTags.scheduled)
  }

  return [...new Set(baseTags)].sort()
}
