import { useRef } from 'react'

import { CheckRounded, DensityMediumRounded, DensitySmallRounded, SettingsRounded } from '@mui/icons-material'

import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { useDispatch, useSelector } from 'actions/store'
import { setUserSettings } from 'actions/userSettingsActions'
import { HbButton } from 'components/HbComponents/HbButton'

import { HbPopper } from 'components/HbComponents/HbPopper'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useToggle } from 'hooks'

import { DashboardDensity } from 'reducers/userSettingsReducer'
import { Theme } from 'types/hb'

import { FilterControlProps } from './FilterControls'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
  },
  icon: {
    color: 'inherit',
    width: 24,
    height: 24,
    minWidth: 'initial',
    marginRight: theme.spacing(),
  },
  listItem: {
    '&:hover': {
      background: theme.palette.background.contrastLight,
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
  listItemPrimaryText: {
    fontWeight: theme.fontWeight.bold,
    ...theme.typography.md,
    color: 'inherit',
  },
  popper: {
    width: 250,
  },
  switchLabel: {
    marginBottom: 0,
  },
}))

const densityOptionsConfig = [
  {
    Icon: DensityMediumRounded,
    label: 'Normal',
    value: 'normal' as const,
  },
  {
    Icon: DensitySmallRounded,
    label: 'Compact',
    value: 'compact' as const,
  },
]

export const ViewSettings = ({ buttonActive, popoverShared, size = 'small', zIndex }: FilterControlProps) => {
  const usage = useUsage()
  const dispatch = useDispatch()

  const ref = useRef<HTMLButtonElement>(null)

  const open = useToggle(false)

  const handleClick = () => {
    open.toggle()
  }

  const dashboardDensitySetting = useSelector((state) => state.userSettings.density)

  const setDashboardSetting = (density: DashboardDensity) => {
    usage.logEvent({ name: 'dashboard:viewSetting:clicked', data: { density } })
    dispatch(setUserSettings({ density }))
  }

  const classes = useStyles()

  return (
    <>
      <HbButton
        className={classNames(classes.button, open.value && buttonActive)}
        iconOnly
        Icon={SettingsRounded}
        label="View"
        onClick={handleClick}
        ref={ref}
        size={size}
        tooltip
        variant="textSecondary"
      />
      <HbPopper
        classes={{ popper: classes.popper }}
        anchorEl={ref.current}
        isOpen={open.value}
        zIndex={zIndex}
        onClose={handleClick}
      >
        <div className={popoverShared}>
          <List dense>
            <ListSubheader>Density</ListSubheader>
            {densityOptionsConfig.map(({ label, value, Icon }) => (
              <ListItem
                onClick={() => {
                  setDashboardSetting(value)
                  handleClick()
                }}
                key={value}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <Icon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemPrimaryText }} primary={label} />
                {dashboardDensitySetting === value && <CheckRounded />}
              </ListItem>
            ))}
          </List>
        </div>
      </HbPopper>
    </>
  )
}
