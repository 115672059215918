import { makeRequiredStyles, StyleClass } from 'components/utils/styles'
import { blend, opacify } from 'helpers/colors'
import { Theme } from 'types/hb'

import { tableBodyCellHeights } from './Tables.constants'

type TableRowClassKey = keyof ReturnType<typeof useStyles>

export type TableRowOverrides = StyleClass<TableRowClassKey>

// Adds a right-side shadow to a table column when applied to cells in the column
export const COLUMN_BOX_SHADOW = '0px 0px 6px 0px rgba(0, 0, 0, 0.20)'
export const COLUMN_BOX_SHADOW_CLIP_PATH = 'inset(0px -6px 0px 0px)' // Clip the shadow to on the top/bottom/left
export const MINIMUM_BATCH_COLUMN_WIDTH = 77

export const useStyles = makeRequiredStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.styleguide.lightGray1,

    '&$selectable': {
      cursor: 'pointer',
    },
    '&$selectable:hover $bodyCell:not($stickyCell)': {
      backgroundColor: opacify(theme.palette.styleguide.blue, 0.1),
    },
    '&$keyboardFocused td:first-child': {
      position: 'relative',
    },
    '&$keyboardFocused td:first-child:before': {
      backgroundColor: theme.palette.styleguide.blue,
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      top: 0,
      width: theme.spacing(0.5),
    },

    '& $stickyCell': {
      display: 'none',
    },
    '&:hover $stickyCell': {
      display: 'table-cell',
    },
  },

  bodyCell: {
    padding: 0,
    '&$stickyCell': {
      '& $cellContent': {
        padding: 0,
      },
    },
  },

  bodyCellCompact: {
    minHeight: tableBodyCellHeights.compact,
  },

  bodyCellBatchColumnEnabled: {
    '&:nth-of-type(2)': {
      position: 'sticky',
      boxShadow: COLUMN_BOX_SHADOW,
      clipPath: COLUMN_BOX_SHADOW_CLIP_PATH,
      zIndex: 2,
    },
  },

  stickyCell: {
    right: 0,
    padding: 0,
    position: 'absolute',
    top: 'auto',
    minWidth: theme.spacing(7),
    marginTop: 0,
    background: `linear-gradient(to right, transparent, ${blend(
      theme.palette.styleguide.blue,
      theme.palette.styleguide.white,
      0.1
    )} 20%)`,
  },

  cellContent: {
    minHeight: tableBodyCellHeights.normal,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(2)}`,
    ...theme.typography.sizes.md,
  },

  cellContentCompact: {
    '&&': {
      minHeight: tableBodyCellHeights.compact,
    },
  },

  flashing: {},
  selectable: {},
  keyboardFocused: {},
  selected: {
    background: theme.palette.action.selected,
  },
}))
