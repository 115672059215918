// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { ComponentPropsWithoutRef } from 'react-markdown/lib/ast-to-react'

import FilterControls from 'dashboards/reviews/components/Header/filtering/FilterControls'
import { DashboardViewSelector } from 'dashboards/shared/components/Dashboard/DashboardViewSelector'
import { DashboardHeader } from 'dashboards/shared/components/Header/DashboardHeader'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    ...theme.typography.sizes.xl,
    fontWeight: 500,
  },
  main: {
    paddingRight: theme.spacing(),
  },
}))

const FilterControlsWrapper = () => <FilterControls size="medium" iconOnly={false} />
const DashboardViewSelectorWrapper = () => <DashboardViewSelector size="medium" />
export const ReportDashboardHeader = (props: ComponentPropsWithoutRef<typeof DashboardHeader>) => {
  const classes = useStyles()
  return (
    <DashboardHeader
      {...props}
      titleTag="h1"
      styleOverrides={{ PageHeader: classes }}
      FilterControlsComponent={FilterControlsWrapper}
      ViewSelectorComponent={DashboardViewSelectorWrapper}
    />
  )
}
