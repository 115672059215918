import { useSelector } from 'actions/store'
import { SelectField, LabeledField } from 'components/material/Form'
import { LabeledFieldProps, SelectWithOtherField, SelectWithOtherProps } from 'components/material/Form/FieldElements'
import { getEnum } from 'helpers/stateHelpers'

interface SharedProps {
  enum: string
  skipValues?: string[]
}

export type EnumFieldProps = SharedProps &
  Omit<LabeledFieldProps<typeof SelectField>, 'options' | 'adornment' | 'FieldComponent'>

export function EnumField(props: EnumFieldProps) {
  const { enum: enumName, skipValues = [], ...otherProps } = props
  const options = useSelector((state) => getEnum(state, enumName))

  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  const finalProps = {
    FieldComponent: SelectField,
    options: filteredOptions,
    adornment: 'NONE',
    ...otherProps,
  }

  return <LabeledField {...(finalProps as LabeledFieldProps<typeof SelectField>)} />
}

export function EnumFieldWithOther(props: SharedProps & Omit<SelectWithOtherProps, 'options'>) {
  const { enum: enumName, name, label, skipValues = [], ...otherProps } = props

  const options = useSelector((state) => getEnum(state, enumName))
  const filteredOptions = options.filter(({ value }) => !skipValues.includes(value))

  return <SelectWithOtherField name={name} label={label} options={filteredOptions} {...otherProps} />
}
