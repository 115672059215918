// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { useField } from 'formik'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import { QuestionMatcherProps } from 'components/cases/report/survey/questions/QuestionTypes'
import { NumberInput } from 'components/material/Form'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

const useStyles = makeStyles(() => ({
  redesignTextRoot: {
    margin: 0,
  },
}))

interface NumberInputV2Prop extends InputProps {
  className?: string
  noSpacing?: boolean
  numberProps?: QuestionMatcherProps['numberProps']
}

export const NumberInputV2 = (props: NumberInputV2Prop) => {
  const {
    readOnly,
    label,
    sublabel,
    autosave,
    isErroneousChecker,
    name,
    disabled,
    errors,
    testId,
    inputContainerClassName,
    className: classNameProp,
    noSpacing,
    numberProps,
  } = props

  const styles = useStyles()
  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })

  if (readOnly) {
    return (
      <InputResponse className={inputContainerClassName} label={label}>
        {field.value || '-'}
      </InputResponse>
    )
  }
  const className = classNames(classNameProp, styles.redesignTextRoot)
  const inputTestId = getInputTestId(label, testId)

  // hack to fix typescript error
  const variantProp = { variant: 'outlined' as const }
  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      testId={testId}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={inputContainerClassName}
      htmlFor={name}
      noSpacing={noSpacing}
    >
      <NumberInput
        className={className}
        numberProps={numberProps}
        field={field}
        form={form}
        meta={meta}
        {...variantProp}
        InputProps={{
          error: isErroneous,
          id: name,
          'aria-describedby': getHelperTextId(name),
        }}
        name={name}
        testId={inputTestId}
        fullWidth
        margin="normal"
        disabled={disabled}
        size="small"
      />
    </InputContainer>
  )
}
