import { List, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton, ListItemButton } from '@mui/material'

import { AddIcon, DeleteIcon } from 'icons'
import { Template } from 'reducers/narrativeTemplatesReducer'

interface Props {
  templates: Template[]
  onSelect: (template: Template) => void
  onDelete: (template: Template) => void
}

function NarrativeTemplateList({ templates, onSelect, onDelete }: Props) {
  return (
    <List disablePadding>
      {templates.map((template) => (
        <ListItemButton key={template.token} divider onClick={() => onSelect(template)}>
          <ListItemText primary={template.name} />
          <ListItemSecondaryAction>
            <IconButton aria-label="Delete" onClick={() => onDelete(template)} size="large">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      ))}
      <ListItemButton onClick={() => onSelect({ name: '', content: '' })}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        New Template
      </ListItemButton>
    </List>
  )
}

export default NarrativeTemplateList
