import { gql } from '@apollo/client'

const AUTOMATION_RULE_TEMPLATE_BASE_FIELDS = gql`
  fragment AutomationRuleTemplateBaseFields on AutomationRuleTemplateBase {
    token
    name
    actionText
    enabled
    actionType
    sampleRate
    description
    setup
    triggerName
    automationTemplateCategory {
      token
    }
    actionParams {
      commentParams {
        text
        smartValuesEnabled
      }
      notificationParams {
        sendToReviewAssignee
        smartValuesEnabled
        subject
        body
      }
      createInvestigationParams {
        description
        name
        smartValuesEnabled
        reviewTypeCanonicalId
        narrative
      }
      assignReviewToQueueParams {
        unassignAccount
        queueToken
      }
      createReviewParams {
        dueDateRelativeIncrementUnit
        dueDateRelativeIncrementValue
        smartValuesEnabled
        assigneeToken
        queueToken
        narrative
        otherInfoEntries: actionOtherInfoLabels {
          label
          value
        }
      }
      cancelReviewParams {
        cancellationReason
      }
      snoozeReviewParams {
        snoozeUntilDateRelativeIncrementValue
        snoozeUntilDateRelativeIncrementUnit
        description
        snoozeTimingPolicy
      }
      tagParams {
        tagDefinitionToken
      }
      updateReviewDueDateParams {
        updateToDateRelativeIncrementValue
        updateToDateRelativeIncrementUnit
        relativeToDueDate
        relativeToCreatedDate
      }
      writeOtherInfoParams {
        otherInfoEntries: actionOtherInfoLabels {
          label
          value
        }
        overwrite
      }
    }
  }
`

const TRIGGERED_RULE_TEMPLATE_FIELDS = gql`
  fragment TriggeredRuleTemplateFields on TriggeredRuleTemplate {
    automationTriggerTemplate {
      description
      domainType
      snapshotFilters
    }
  }
`

const SCHEDULED_RULE_TEMPLATE_FIELDS = gql`
  fragment ScheduledRuleTemplateFields on ScheduledRuleTemplate {
    scheduleConfig
  }
`

const SCHEDULED_WITH_TRIGGER_RULE_TEMPLATE_FIELDS = gql`
  fragment ScheduledWithTriggerRuleTemplateFields on ScheduledWithTriggerRuleTemplate {
    automationTriggerTemplate {
      description
      domainType
      snapshotFilters
    }
    scheduleConfig
  }
`

export const TOGGLE_AUTOMATION_RULE_TEMPLATE = gql`
  fragment UseToggleTemplateAutomationRuleTemplate on AutomationRuleTemplate {
    ... on AutomationRuleTemplateBase {
      token
      enabled
    }
  }
`

export const TOGGLE_AUTOMATION_RULE_TEMPLATE_QUERY = gql`
  mutation ToggleAutomationRuleTemplate($input: EnableDisableAutomationTemplateInput!) {
    enableDisableAutomationTemplate(input: $input) {
      automationTemplate {
        ...UseToggleTemplateAutomationRuleTemplate
      }
    }
  }
  ${TOGGLE_AUTOMATION_RULE_TEMPLATE}
`

export const TOGGLE_AUTOMATION_TEMPLATE_VISIBLE = gql`
  fragment ToggleAutomationTemplateVisibleAutomationRuleTemplate on AutomationRuleTemplate {
    ... on AutomationRuleTemplateBase {
      enabled
    }
    ...UseToggleTemplateAutomationRuleTemplate
  }
  ${TOGGLE_AUTOMATION_RULE_TEMPLATE}
`

export const AUTOMATION_RULE_TEMPLATE = gql`
  query AutomationRuleTemplate($token: ID!) {
    automationRuleTemplate(token: $token) {
      __typename
      ...AutomationRuleTemplateBaseFields
      ...TriggeredRuleTemplateFields
      ...ScheduledRuleTemplateFields
      ...ScheduledWithTriggerRuleTemplateFields
      ...ToggleAutomationTemplateVisibleAutomationRuleTemplate
    }
  }
  ${AUTOMATION_RULE_TEMPLATE_BASE_FIELDS}
  ${TRIGGERED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_WITH_TRIGGER_RULE_TEMPLATE_FIELDS}
  ${TOGGLE_AUTOMATION_TEMPLATE_VISIBLE}
`

export const DELETE_AUTOMATION_RULE_TEMPLATE = gql`
  mutation DeleteAutomationRuleTemplate($input: DeleteAutomationTemplateInput!) {
    deleteAutomationTemplate(input: $input) {
      automationTemplate {
        ... on AutomationRuleTemplateBase {
          token
        }
      }
    }
  }
`

export const UPDATE_AUTOMATION_RULE_TEMPLATE = gql`
  mutation UpdateAutomationRuleTemplate($input: UpdateAutomationTemplateInput!) {
    updateAutomationTemplate(input: $input) {
      automationTemplate {
        __typename
        ...AutomationRuleTemplateBaseFields
        ...TriggeredRuleTemplateFields
        ...ScheduledRuleTemplateFields
        ...ScheduledWithTriggerRuleTemplateFields
        ...ToggleAutomationTemplateVisibleAutomationRuleTemplate
      }
    }
  }
  ${AUTOMATION_RULE_TEMPLATE_BASE_FIELDS}
  ${TRIGGERED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_WITH_TRIGGER_RULE_TEMPLATE_FIELDS}
  ${TOGGLE_AUTOMATION_TEMPLATE_VISIBLE}
`

export const CREATE_AUTOMATION_RULE_TEMPLATE = gql`
  mutation CreateAutomationTemplate($input: CreateAutomationTemplateInput!) {
    createAutomationTemplate(input: $input) {
      automationTemplate {
        __typename
        ...AutomationRuleTemplateBaseFields
        ...TriggeredRuleTemplateFields
        ...ScheduledRuleTemplateFields
        ...ScheduledWithTriggerRuleTemplateFields
        ...ToggleAutomationTemplateVisibleAutomationRuleTemplate
      }
    }
  }
  ${AUTOMATION_RULE_TEMPLATE_BASE_FIELDS}
  ${TRIGGERED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_RULE_TEMPLATE_FIELDS}
  ${SCHEDULED_WITH_TRIGGER_RULE_TEMPLATE_FIELDS}
  ${TOGGLE_AUTOMATION_TEMPLATE_VISIBLE}
`
