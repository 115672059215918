import { Chip, Tooltip } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { Placement } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import { CountPill } from 'components/library/CountPill'
import { useContainerDimensions } from 'components/library/Resize/hooks'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useSearchRequestInfo } from 'dashboards/shared/react/getNamedQueryGroups'
import { FilterListIcon } from 'icons'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { Theme } from 'types/hb'
import { mapSearchRequestFilterKeys } from 'utils/query/api.types'

import CustomViewControls from './CustomViewControls'

const MAX_FILTERS_SHOWN = 3

const useStyles = makeStyles((theme: Theme) => ({
  bannerContainer: {
    height: 63,
    backgroundColor: theme.palette.background.medium,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  filteredByContainer: {
    display: 'flex',
    color: 'inherit',
    '& > span': { display: 'flex', alignItems: 'center' },
  },
  viewTitle: { pointerEvents: 'none' },
  editingCustom: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.text.white,
  },
  chipContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    padding: `0 ${theme.spacing(0)} 0 ${theme.spacing()}`,
  },
  filterChip: {
    color: 'inherit',
    borderColor: 'inherit',
    ...theme.typography.sizes.md,
    height: 26,
  },
  countTooltip: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.largeContainer.borderRadius,
    color: 'inherit',
    display: 'flex',
    gap: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    boxShadow: '0px 3px 26px 0px rgba(0, 0, 0, 0.12)',
  },
  typeCountPill: {
    borderRadius: 40,
    justifyContent: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.white,
  },
}))

export const useColumnTitles = () => {
  return useDashboardColumns().filterableColumns.reduce((acc, c) => {
    acc[c.apiName] = c.title
    return acc
  }, {} as { [apiName: string]: string })
}

const FilterSummaryBanner = ({ showCustomViewControls = true }: { showCustomViewControls?: boolean }) => {
  const classes = useStyles()
  const { containerWidth, getContainerRef } = useContainerDimensions()

  const dashboardsSelectors = useDashboardSelectors()
  const appliedFilters = useSelector(dashboardsSelectors.filters.applied.all)
  const filterKeys = mapSearchRequestFilterKeys(appliedFilters).filter((key) => !!key)

  const currentViewTitle = useSelector(dashboardsSelectors.currentViewTitle)

  const { isCustom, isEditing, isCreating } = useSearchRequestInfo()

  const columnTitles = useColumnTitles()

  // if there aren't any defined filter keys, then there's nothing to show in the banner
  if (filterKeys.length === 0) return null

  const isEditingCustom = isCustom && isEditing
  return (
    <div
      className={classnames(classes.bannerContainer, isEditingCustom && classes.editingCustom)}
      ref={getContainerRef}
    >
      <div className={classes.filteredByContainer}>
        {isCustom && !isCreating ? (
          <HbButton
            label={currentViewTitle}
            variant="textSecondary"
            Icon={FilterListIcon}
            className={classnames(classes.viewTitle, isEditing && classes.editingCustom)}
          />
        ) : (
          <>
            <HbText size="lg" color={isEditingCustom ? 'white' : undefined}>
              Filtered by{' '}
            </HbText>
            <div className={classes.chipContainer}>
              {filterKeys.slice(0, MAX_FILTERS_SHOWN).map((filterKey) => {
                return (
                  <Chip
                    key={filterKey}
                    className={classes.filterChip}
                    variant="outlined"
                    label={columnTitles[filterKey]}
                  />
                )
              })}
              {filterKeys.length > MAX_FILTERS_SHOWN && (
                <Tooltip
                  classes={{ tooltip: classes.countTooltip }}
                  title={
                    <>
                      {filterKeys.slice(MAX_FILTERS_SHOWN).map((filterKey) => {
                        return (
                          <Chip
                            key={filterKey}
                            className={classes.filterChip}
                            variant="outlined"
                            label={columnTitles[filterKey]}
                          />
                        )
                      })}
                    </>
                  }
                  placement={Placement.Top}
                >
                  <CountPill className={classes.typeCountPill}>
                    <HbText size="md" color="inherit">
                      +{filterKeys.length - MAX_FILTERS_SHOWN}
                    </HbText>
                  </CountPill>
                </Tooltip>
              )}
            </div>
          </>
        )}
      </div>
      {showCustomViewControls && <CustomViewControls isIconOnlyButtons={!!containerWidth && containerWidth < 930} />}
    </div>
  )
}

export default FilterSummaryBanner
