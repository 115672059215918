import { useCallback, useEffect, useRef } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { capitalize } from 'lodash'

import { useDispatch, useSelector } from 'actions/store'
import { useLongProcessSnackbars } from 'components/HbComponents/HbNotistack/HbNotistackSnackbar.hooks'
import { removeCurrentSearch as _removeCurrentSearch, updateUIState } from 'reducers/screeningReducer'
import { openTab } from 'reducers/tabReducer'
import { JobStatus, LibraryTypeEnum, ScreeningSearchProvider } from 'types/api'
import { navigateToInternalUrl } from 'utils/navigationHelpers'
import { assertExhaustive } from 'utils/typeAssertions'

import { DEFAULT_REQUEST_ERROR_MSG, POLL_INTERVAL_MS } from './Screening.constants'

import { ScreeningCurrentSearch } from './ScreeningTab.types'
import {
  ScreeningSearchAsyncJobStatusQuery,
  ScreeningSearchAsyncJobStatusQueryVariables,
} from './__generated__/ScreeningSnackbars.generated'

const SCREENING_JOB_QUERY = gql`
  query screeningSearchAsyncJobStatus(
    $libraryType: String!
    $provider: ScreeningSearchProvider!
    $investigationToken: ID!
    $libraryToken: ID!
    $searchQuery: ScreeningSearchQueryParameters!
  ) {
    screeningSearchProvider(provider: $provider) {
      screeningSearchAsyncJobStatus(
        investigationToken: $investigationToken
        libraryToken: $libraryToken
        searchQuery: $searchQuery
        libraryType: $libraryType
      ) {
        status
        token
        errorMessage
      }
    }
  }
`

const useNavigateToCaseWithScreeningTab = () => {
  const dispatch = useDispatch()
  return useCallback(
    async ({ libraryToken, investigationToken, provider }: ScreeningCurrentSearch) => {
      const tab = {
        type: 'sanctionsScreening' as const,
        entityToken: libraryToken,
        provider,
        label: 'Screening',
      }
      if (window.location.pathname !== `/dashboard/cases/${investigationToken}`) {
        // If not on the case that the search originated from, navigate to the case first
        await dispatch(
          navigateToInternalUrl(undefined, '/dashboard/cases/:caseToken', { caseToken: investigationToken })
        )
      }
      dispatch(openTab({ tab }))
    },
    [dispatch]
  )
}

const ScreeningSnackbar = (props: { currentSearch: ScreeningCurrentSearch }) => {
  const { currentSearch } = props
  const navigateToTab = useNavigateToCaseWithScreeningTab()
  const dispatch = useDispatch()
  const updateLongProcessSnackbars = useLongProcessSnackbars()
  const [pollJob, { stopPolling }] = useLazyQuery<
    ScreeningSearchAsyncJobStatusQuery,
    ScreeningSearchAsyncJobStatusQueryVariables
  >(SCREENING_JOB_QUERY, {
    pollInterval: POLL_INTERVAL_MS,
    fetchPolicy: 'network-only',
  })

  const hasStartedSearch = useRef<boolean>(false)

  const removeCurrentSearch = useCallback(() => {
    dispatch(_removeCurrentSearch({ jobToken: currentSearch.jobToken }))
  }, [dispatch, currentSearch.jobToken])

  const handleLoading = useCallback(
    (snackbarID: string) => {
      const { provider } = currentSearch
      const { name } = currentSearch.searchFields

      const snackbarKey = updateLongProcessSnackbars(snackbarID, {
        status: 'loading',
        message: `Loading ${capitalize(provider)} screening results for ${name}`,
      })
      dispatch(
        updateUIState({
          status: 'polling',
          snackbarKey,
          ...currentSearch,
        })
      )
    },
    [currentSearch, dispatch, updateLongProcessSnackbars]
  )

  const handleError = useCallback(
    (message: string, snackbarID: string) => {
      removeCurrentSearch()
      updateLongProcessSnackbars(snackbarID, {
        status: 'error',
        message,
      })
    },
    [removeCurrentSearch, updateLongProcessSnackbars]
  )

  const handleSuccess = useCallback(
    (snackbarID: string) => {
      const { provider } = currentSearch
      const { name } = currentSearch.searchFields
      removeCurrentSearch()
      dispatch(
        updateUIState({
          status: 'processed',
          ...currentSearch,
          results: [],
        })
      )
      updateLongProcessSnackbars(snackbarID, {
        status: 'success',
        message: `${capitalize(provider)} screening results for ${name} are ready`,
        buttonProps: {
          text: 'View results',
          onClick: () => {
            navigateToTab(currentSearch)
          },
        },
      })
    },
    [currentSearch, dispatch, removeCurrentSearch, updateLongProcessSnackbars, navigateToTab]
  )

  useEffect(() => stopPolling, [stopPolling])

  useEffect(() => {
    const { libraryToken } = currentSearch
    const snackbarID = `${libraryToken}:${currentSearch.jobToken}`

    if (hasStartedSearch.current) {
      return
    }

    handleLoading(snackbarID)

    pollJob({
      variables: {
        ...currentSearch,
        searchQuery: currentSearch.searchFields,
        libraryType: LibraryTypeEnum.People,
        provider: ScreeningSearchProvider.Minerva,
      },
      onError: (error) => {
        handleError(error.message || DEFAULT_REQUEST_ERROR_MSG, snackbarID)
      },
      onCompleted: ({ screeningSearchProvider }) => {
        const status = screeningSearchProvider?.screeningSearchAsyncJobStatus?.status
        switch (status) {
          case JobStatus.Enqueued:
          case JobStatus.Started:
            break
          case JobStatus.Failed:
          case undefined: {
            handleError(
              screeningSearchProvider?.screeningSearchAsyncJobStatus?.errorMessage || DEFAULT_REQUEST_ERROR_MSG,
              snackbarID
            )
            break
          }
          case JobStatus.Processed: {
            handleSuccess(snackbarID)
            break
          }
          default:
            assertExhaustive(status)
        }
      },
    })

    hasStartedSearch.current = true
  }, [currentSearch, handleError, handleLoading, handleSuccess, pollJob])

  // This component returns null and is just for running hooks, and so using
  // a hook instead seems prefereable. But A hook can't replace this because
  // we need to call `useLazyQuery` for each new screening search and hooks can't be called in loops.
  // This copies the pattern from MiddeskSnackbars.tsx!
  return null
}

export const ScreeningSnackbars = () => {
  const currentSearches = useSelector((state) => state.screening.currentSearches)
  return (
    <>
      {currentSearches.map((currentSearch) => (
        <ScreeningSnackbar key={currentSearch.jobToken} currentSearch={currentSearch} />
      ))}
    </>
  )
}
