import React, { ReactNode } from 'react'

import { TextField as TextFieldBase, TextFieldProps } from '@mui/material'

import { Field, FastField, FieldAttributes, FieldProps } from 'formik'
// Get a base text field from Material UI

import { InputError } from 'components/HbComponents/Form/InputError'
import { valueIsHyperlink } from 'helpers/uiHelpers'
import { useAutoFocus } from 'hooks'

import { NO_AUTOFILLS } from './FormConstants'
import { InputLinkAdornment } from './InputLinkAdornment'
import { getErrorDetails } from './formikMaterial'

export type TextInputProps = TextFieldProps & {
  children?: ReactNode
  testId?: string
  linkUrls?: boolean
  multivalued?: boolean
}

interface InputEndAdornmentsProps {
  value: unknown
  linkUrls: boolean
  otherAdornments: ReactNode
}

function InputEndAdornments({ value, linkUrls, otherAdornments }: InputEndAdornmentsProps) {
  let linkButton: ReactNode = null
  if (linkUrls) {
    linkButton = <InputLinkAdornment value={value} />
  }

  return (
    <>
      {linkButton}
      {otherAdornments}
    </>
  )
}

function getInputEndAdornments({ value, linkUrls, otherAdornments }: InputEndAdornmentsProps) {
  if (!(linkUrls && valueIsHyperlink(value)) && !otherAdornments) {
    return null
  }

  return <InputEndAdornments value={value} linkUrls={linkUrls} otherAdornments={otherAdornments} />
}

export const fieldToTextField = ({
  field,
  form,
  disabled = false,
  autoComplete = NO_AUTOFILLS,
  testId,
  linkUrls = false,
  ...props
}: TextInputProps & FieldProps<unknown>) => {
  const { isSubmitting } = form

  const { fieldError, showError } = getErrorDetails(field, form)
  const value = field.value === 0 ? 0 : field.value || ''

  return {
    ...props,
    ...field,
    InputProps: {
      ...props.InputProps,
      endAdornment: getInputEndAdornments({ value, linkUrls, otherAdornments: props.InputProps?.endAdornment }),
    },
    autoComplete,
    'data-testid': testId,
    value,
    error: showError,
    helperText: showError ? (
      <InputError fieldName={props.name} tag="span" clientError={fieldError} />
    ) : (
      props.helperText
    ),
    disabled: isSubmitting || disabled,
  }
}

// Exposes a wrapped implementation of the Material-UI TextField component that supports
// formatted embedding in Formik Fields as a component.
// bad external types
export const TextInput = React.forwardRef<HTMLDivElement, TextInputProps & FieldProps<unknown>>((props, ref) => {
  const withAutoFocusProps = useAutoFocus(props)
  return <TextFieldBase {...fieldToTextField(withAutoFocusProps)} ref={ref} />
})

type HbFieldProps = TextFieldProps &
  FieldAttributes<unknown> & {
    linkUrls?: boolean
  }

export const TextField = React.forwardRef<unknown, HbFieldProps>((props, ref) => (
  <Field {...props} innerRef={ref} component={TextInput} />
))

export const FastTextField = React.forwardRef<unknown, HbFieldProps>((props, ref) => (
  <FastField {...props} innerRef={ref} component={TextInput} />
))
