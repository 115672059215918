import { keyBy } from 'lodash'
import { AnyAction } from 'redux'

import * as actions from 'actions/reviewsActions'

import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { ObjectTagFragment } from 'components/tags/__generated__/Tags.queries.generated'
import { SearchResultReview } from 'dashboards/shared/react/search'
import { IconName } from 'icons/types'
import { ReviewPreview } from 'reducers/investigationsReducer.types'
import { ColorNameEnum, FilingJurisdictionEnum, FilterableColumn, HbMoney, Queue, ReviewStatesEnum } from 'types/api'
import { Account, BasicAccount, OtherInfo } from 'types/hb'

export type DashboardColumn = FilterableColumn & {
  attribute: string
  isDragDisabled?: boolean
  isResizeDisabled?: boolean
  isReorderable?: boolean
}

type DashboardEntryInboundRequest = SearchResultReview['inboundRequest']

export interface DashboardEntry {
  investigationToken: string
  investigationName: string | null | undefined
  investigationBlocked: boolean
  investigationTags: ObjectTagFragment[]
  inboundRequest?: DashboardEntryInboundRequest | null
  reviewToken: string
  netActivity: HbMoney | null
  commentCount: number
  createdAt: string
  updatedAt: string
  sumAllTransactions: HbMoney | null
  sumFlaggedTransactions: HbMoney | null
  approvalAssignments: ReviewApprovalAssignment[]
  reviews: SearchResultReview[] // Dashboard uses the search API
}

export type Token = string

export type ActivityTagCategory = 'PRODUCTS' | 'ACTIVITIES' | 'INSTRUMENTS' | 'INDICATORS'

export interface ActivityTag {
  name: string
  token: string
  category: ActivityTagCategory
}

export type TaskSlug = string

export type TaskType =
  | 'case_information'
  | 'ctr_filing_details'
  | 'filing_details'
  | 'fintrac_filing_details'
  | 'goaml_filing_details'
  | 'goaml_filing_report_id'
  | 'survey'
  | 'dispute_survey'
  | 'tagging'
  | 'text_entry'
  | 'research'
  | 'net_activity'
  | 'create_review_settings'
  | 'alert_review'
  | 'cyber_event_indicators'
  | 'request_for_information_details'
  | 'request_for_information_response_view'
  | 'request_for_information_prepopulation'
  | 'tip_view'
  | 'fiu_filing_report_identifier'
  | 'information_sharing'

export interface Task<State = any, Properties = any> {
  status: 'INVALID' | 'VALID'
  version: number
  meta: {
    token: Token
    type: TaskType
    title: string
    slug: TaskSlug
    properties: Properties
    icon: IconName
    headline: string
    headlineSubtext?: string
  }
  errors: string[]
  state: State
}

type $Effect<T> = {
  type: T
  token: string
  defaults: Record<string, any>
}

export type Effect =
  | $Effect<'assign_review'>
  | $Effect<'create_comment'>
  | $Effect<'create_review'>
  | $Effect<'create_peer_approval'>
  | $Effect<'create_peer_approval_decision'>
  | $Effect<'create_filing'>
  | $Effect<'lock_case'>
  | $Effect<'unlock_case'>
  | $Effect<'create_referral'>
  | $Effect<'snooze_review'>
  | $Effect<'create_alert_suppression_rule'>
  | $Effect<'chainalysis_post_alert_status_and_comment'>
  | $Effect<'elliptic_update_transaction_analysis_status'>

export type WorkflowIconName =
  | 'alarm'
  | 'narrative'
  | 'format_quote'
  | 'label_unusual'
  | 'label_outlined'
  | 'info_document_outlined'
  | 'search_document_outlined'
  | 'recent_actors'
  | 'report_problem_outlined'
  | 'edit'
  | 'products_instruments'
  | 'gavel'
  | 'custom_investigate'
  | 'input'
  | 'send'
  | 'transaction'
  | 'tune'
  | 'no_encryption'

export type ButtonType = 'primary' | 'secondary' | 'dismiss'
export interface Choice {
  token: Token
  key: string
  label: string
  icon: WorkflowIconName
  status: string
  pastParticiple: string
  accountType: string
  remainingRequirements: string[]
  decisionModalButtonTitle: string
  confirmationTitle: string
  effects: Effect[]
  proceedTo?: string
  buttonType: ButtonType
}

export interface Decision {
  createdAt: string
  choice: Choice
  previousChoices: Choice[]
  arbiter: BasicAccount
  comment?: string
}

export type ActionSlug = string

export interface ActionError {
  key: string
  errors: string[]
  label: string
  type?: string | null
  token?: string | null
}

export interface ClickableLibraryActionError extends ActionError {
  type: LibraryGqlQueryType
  token: string
}
export interface ClickableFinancialInstitutionActionError extends ActionError {
  type: 'filing_institutions'
  token: string
}
export interface ClickableTransactionActionError extends ActionError {
  type: 'transactions'
  token: string
}

export type ClickableActionError =
  | ClickableLibraryActionError
  | ClickableFinancialInstitutionActionError
  | ClickableTransactionActionError

export interface ActionWarning {
  key: string
  warnings: string[]
  label: string
}

export type ActionType =
  | 'decision'
  | 'united_states_filing'
  | 'united_states_ctr_filing'
  | 'goaml_filing'
  | 'fintrac_filing'

export interface Action {
  meta: {
    token: Token
    icon: IconName
    type: ActionType
    slug: ActionSlug
    title: string
    headline?: string
    headlineSubtext?: string
    decisionTitle?: string
  }
  actionErrors: ActionError[]
  actionWarnings: ActionWarning[]
  tasks: Task[]
  choices: Choice[]
  decision: Decision
  complete: boolean
  blockedReason?: string
}

export interface Workflow {
  tasks: Task[]
  actions: Action[]
}

export interface ReviewDocument {
  format: string
  title: string
  url: string
}

export type ReviewAmountAlgorithm = 'NO_AMOUNT' | 'AMOUNT_UNKNOWN' | 'SUM_TRANSACTIONS' | 'SUM_FLAGGED' | 'CUSTOM'

export type ReviewActivityDateAlgorithm = 'RANGE_TRANSACTIONS' | 'RANGE_FLAGGED' | 'CUSTOM'

export type ReviewStateMachineState = `${ReviewStatesEnum}`

type FilingResponseError = {
  field: string
  formReference: string
  message: string
  severity: string
  code: string
  value: string
}

export interface ReviewFiling {
  createdAt: string
  state: string
  batchFilingToken: string
  response: {
    bsaId: string
    createdAt: Date
    errors: FilingResponseError[]
  }
}

export type ApprovalDecision = null | 'APPROVE' | 'REQUEST_CHANGES'

export interface ReviewApprovalAssignment {
  token: string
  decision: ApprovalDecision
  decisionMadeAt: string
  assignee: Account | null
  queue: Queue | null
}

export interface ReviewTimelineEvent {
  actorAvatarColor: string
  actorAvatarVariant: number
  actorInitials: string
  actorShortName: string
  icon:
    | 'assign_approval'
    | 'completed'
    | 'creation'
    | 'information'
    | 'merge_data'
    | 'message_sent'
    | 'remove_information'
    | 'author'
    | 'remove_tag'
    | 'request_approval'
    | 'tag'
    | 'timeline'
    | 'transactions'
    | 'uncompleted'
    | 'cancel_request'
    | 'send'
    | 'send_accepted'
    | 'send_warning'
    | 'send_rejected'
    | 'text_entry'
    | 'assigned_review'
    | 'reassigned_review'
    | 'unassigned_review'
  body: { [key: string]: string }
  summary: string
  timestamp: string
  type: string
  token: string
}

type DataExclusion = {
  type: string
  token?: string
}

interface ReviewQueueBadge {
  token: string
  color: ColorNameEnum
  displayName: string
}

export interface ReviewQueue {
  token: string
  badge: ReviewQueueBadge
}

export type Review = ReviewPreview & {
  assignee: Account | null
  timelineStartedAt: string
  documents: ReviewDocument[]
  otherInfo: OtherInfo
  amountInvolvedString: string
  activityStartedOn: string
  activityEndedOn: string
  activityTags: ActivityTag[]
  alertsCount: number
  amountAlgorithm: ReviewAmountAlgorithm
  activityDateAlgorithm: ReviewActivityDateAlgorithm
  displayState: string
  state: ReviewStateMachineState
  filingType: string
  filingTypeString: string
  filings?: ReviewFiling[]
  isReadyForReview: boolean
  decisions: string[]
  openedAt: string
  queue: ReviewQueue | null
  tags: string[]
  stage: string
  referringOrganizationName: string
  timelineEvents: ReviewTimelineEvent[]
  timelineTruncated: boolean
  jurisdiction: FilingJurisdictionEnum
  // TODO(jsu): there exist additional properties on the full review that should be listed here
  dataExclusions?: DataExclusion[]
}

export type SurveyTag = string
export type SurveyResponseTag = string

export interface ReviewState {
  byToken: { [token: string]: Review }
  currentReviewTokens: { [token: string]: Token }
  workflowsByToken: { [token: string]: Workflow }
}

function getInitialState(): ReviewState {
  return {
    byToken: {}, // Index of reviews by review token
    currentReviewTokens: {}, // Index of current review tokens by investigation token
    workflowsByToken: {},
  }
}

const reviewsReducer = (state = getInitialState(), action: AnyAction): ReviewState => {
  switch (action.type) {
    case actions.UPDATE_REVIEW: {
      const byToken = { ...state.byToken }
      const updatedReview = {
        ...(byToken[action.token] || {}),
        ...action.updates,
      }
      byToken[action.token] = updatedReview

      return { ...state, byToken }
    }
    case actions.RECORD_REVIEW_NOT_FOUND: {
      const updates = {
        byToken: { ...state.byToken, [action.token]: false },
      }
      return { ...state, ...updates }
    }
    case actions.SET_REVIEW_WORKFLOW: {
      const { tasks, actions: workflowActions } = action.workflow as Workflow
      const workflowsByToken = { ...state.workflowsByToken }

      const existingWorkflow = state.workflowsByToken[action.token] || { tasks: [], actions: [] }
      const tasksByToken = keyBy(
        existingWorkflow.actions.flatMap((workflowAction) => workflowAction.tasks),
        (task) => task.meta?.token
      )
      // If an individual task has a higher version, it's been updated in the mean time and this response should *not* overwrite it.
      const getMostUpToDateVersionOfTask = (task: Task, byToken: Record<string, Task>) => {
        const taskInState = byToken[task.meta.token]
        if (!taskInState) {
          return task
        }
        return task.version >= taskInState.version ? task : taskInState
      }

      workflowsByToken[action.token] = {
        tasks,
        actions: workflowActions?.map((workflowAction) => ({
          ...workflowAction,
          tasks: workflowAction.tasks.map((task) => getMostUpToDateVersionOfTask(task, tasksByToken)),
        })),
      }

      return { ...state, workflowsByToken }
    }
    case actions.SET_REVIEW_WORKFLOW_TASK: {
      const workflowsByToken = { ...state.workflowsByToken }
      const workflow = workflowsByToken[action.token]

      const tasks = workflow.tasks.map((task) => (task.meta.token === action.task.meta.token ? action.task : task))

      const workflowActions = workflow.actions.map((workflowAction) => {
        const actionTasks = workflowAction.tasks.map((task) =>
          task.meta.token === action.task.meta.token ? action.task : task
        )

        return { ...workflowAction, tasks: actionTasks }
      })

      const newWorkflow = { ...workflow, tasks, actions: workflowActions }
      workflowsByToken[action.token] = newWorkflow
      return { ...state, workflowsByToken }
    }
    case actions.SET_REVIEW_WORKFLOW_ACTION: {
      const workflowsByToken = { ...state.workflowsByToken }
      const workflow = workflowsByToken[action.token]

      const workflowActions = workflow.actions.map((existingWorkflowAction) =>
        existingWorkflowAction.meta.token === action.action.meta.token ? action.action : existingWorkflowAction
      )

      const newWorkflow = { ...workflow, actions: workflowActions }
      workflowsByToken[action.token] = newWorkflow
      return { ...state, workflowsByToken }
    }

    default: {
      return state
    }
  }
}

export default reviewsReducer
