import React, { useMemo } from 'react'

import {
  BarChartRounded,
  InboxRounded,
  SearchRounded,
  PeopleOutlineRounded,
  CategoryRounded,
  ContactSupportOutlined,
} from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { automationActions } from 'components/pages/automations/AutomationsPage'
import { ProfilesDashboardGate } from 'dashboards/profiles/ProfilesDashboardGate'
import { actions } from 'dashboards/shared/react/search'
import { hasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import AutomationsIcon from 'icons/Automations'
import AbrigoLogo from 'images/abrigo-logo.png'
import AppNavBg from 'images/appnav-bg.svg'
import CapgeminiLogo from 'images/capgemini-logo.png'
import FinastraLogo from 'images/finastra-logo.svg'
import FisLogo from 'images/fis-logo.png'
import GrasshopperLogo from 'images/grasshopper-logo.svg'
import KpmgLogo from 'images/kpmg-logo.webp'
import LegitScriptLogo from 'images/legitscript-logo.png'
import HbLogo from 'images/logomark.png'
import MarqetaLogo from 'images/marqeta-logo.svg'
import MastercardLogo from 'images/mastercard-symbol.svg'
import MiddeskLogo from 'images/middesk-logo.png'
import StarbucksLogo from 'images/starbucks-logo.svg'
import VisaLogo from 'images/visa-logo.svg'

import { ApplicationTheme } from 'reducers/applicationReducer'
import { dashboardSelectorsMap } from 'reducers/dashboards/dashboards.selectors'
import { calculateNextQuery } from 'reducers/dashboards/queryStringTools'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'

import { CUSTOM_INTERCOM_LAUNCHER_ID } from 'utils/intercom'
import { navigateToCompiledUrl, navigateToInternalUrl } from 'utils/navigationHelpers'

import AppNavButton from './AppNavButton'

import HeaderAccountMenu from './links/HeaderAccountMenu'
import HeaderNotifications from './links/HeaderNotifications'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    background: `linear-gradient(94deg, #C5EFFCFF 0%, #D2D5FD77 70%, #dfb9fd00 100%), url('${AppNavBg}'), linear-gradient(106deg, #C5E1FC 0%, #DFB9FD 100%)`,
    borderRight: `1px solid ${theme.palette.dividers.light}`,

    '&$mastercard': {
      background: '#111111',
    },

    '&$fis': {
      background: '#090825',
    },

    '&$starbucks': {
      background: 'rgb(211,229,222)',
    },

    '&$capgemini': {
      background: 'rgb(233,233,233)',
    },

    '&$abrigo, &$legitscript, &$kpmg': {
      background: '#ffffff',
    },

    '&$marqeta': {
      background: '#2A206A',
    },

    '&$finastra': {
      background: 'linear-gradient(45deg,#120428,#4B004B)',
    },

    '&$middesk': {
      background: 'rgb(248,247,242)',
    },

    '&$grasshopper': {
      background: 'rgb(233,244,238)',
    },

    '&$visa': {
      background: '#011e4d',
    },
  },
  icon: {
    width: 40,
    maxHeight: 40,
    outline: 'none',

    margin: theme.spacing(2, 0, 4, 0),

    '& img': {
      width: 46, // The nose of the Hummingbird should stick out a bit
    },

    '& svg': {
      width: 'auto',
      height: 'auto',

      '& g': {
        fill: theme.palette.accent,
      },
    },

    '&$mastercard': {
      width: 'auto',
      margin: theme.spacing(2.5, 0, 2.5, 0),

      '& img': {
        width: 48,
      },
    },

    '&$fis': {
      width: 'auto',
      margin: theme.spacing(2.5, 0, 2.5, 0),

      '& img': {
        width: 48,
      },
    },

    '&$starbucks': {
      width: 'auto',
      margin: theme.spacing(1.75, 0, 2.5, 0),

      '& img': {
        width: 46,
      },
    },

    '&$capgemini': {
      width: 'auto',
      margin: theme.spacing(2, 0, 4, 0),

      '& img': {
        width: 44,
      },
    },

    '&$abrigo': {
      width: 'auto',
      margin: theme.spacing(1.5, 0, 4, 0),

      '& img': {
        width: 46,
      },
    },

    '&$marqeta': {
      width: 'auto',
      margin: theme.spacing(3, 0, 4, 0),

      '& img': {
        width: 40,
      },
    },

    '&$finastra': {
      width: 'auto',
      margin: theme.spacing(-4.5, -0.25, 10.5, 0),

      '& img': {
        width: 74,
      },
    },

    '&$legitscript': {
      marginLeft: theme.spacing(-1),

      '& img': {
        width: 44,
      },
    },

    '&$kpmg': {
      marginLeft: theme.spacing(-1.5),
      marginTop: theme.spacing(2.25),

      '& img': {
        width: 54,
      },
    },

    '&$middesk': {
      width: 'auto',
      margin: theme.spacing(2.5, 0, 4, 0.5),

      '& img': {
        width: 36,
      },
    },

    '&$grasshopper': {
      width: 'auto',
      margin: theme.spacing(2, 0, 4, 0),

      '& img': {
        marginLeft: 8,
        width: 50,
      },
    },

    '&$visa': {
      width: 'auto',
      margin: theme.spacing(1, 0, 4, 0),

      '& img': {
        width: 72,
      },
    },
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    overflowY: 'auto',
    flex: 1,
    padding: theme.spacing(0.5, 2),
  },
  bottomSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  poweredByText: {
    marginBottom: theme.spacing(),
    lineHeight: '12px',
    fontSize: '13px',
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
  },
  mastercard: {},
  fis: {},
  starbucks: {},
  capgemini: {},
  abrigo: {},
  marqeta: {},
  finastra: {},
  legitscript: {},
  middesk: {},
  kpmg: {},
  grasshopper: {},
  visa: {},
}))

function Logo({ theme }: { theme: ApplicationTheme }) {
  switch (theme) {
    case 'mastercard':
      return <img src={MastercardLogo} alt="Mastercard Logo" />
    case 'visa':
      return <img src={VisaLogo} alt="Visa Logo" />
    case 'fis':
      return <img src={FisLogo} alt="FIS Logo" />
    case 'starbucks':
      return <img src={StarbucksLogo} alt="Starbucks Logo" />
    case 'capgemini':
      return <img src={CapgeminiLogo} alt="Capgemini Logo" />
    case 'abrigo':
      return <img src={AbrigoLogo} alt="Abrigo Logo" />
    case 'marqeta':
      return <img src={MarqetaLogo} alt="Marqeta Logo" />
    case 'finastra':
      return <img src={FinastraLogo} alt="Finastra Logo" />
    case 'legitscript':
      return <img src={LegitScriptLogo} alt="LegitScript Logo" />
    case 'kpmg':
      return <img src={KpmgLogo} alt="KPMG Logo" />
    case 'middesk':
      return <img src={MiddeskLogo} alt="Middesk Logo" />
    case 'grasshopper':
      return <img src={GrasshopperLogo} alt="Grasshopper Logo" />
    default:
      return <img src={HbLogo} alt="Hummingbird Logo" />
  }
}

function useThemeClass(theme: ApplicationTheme) {
  const classes = useStyles()
  switch (theme) {
    case 'mastercard':
      return classes.mastercard
    case 'fis':
      return classes.fis
    case 'starbucks':
      return classes.starbucks
    case 'capgemini':
      return classes.capgemini
    case 'abrigo':
      return classes.abrigo
    case 'marqeta':
      return classes.marqeta
    case 'finastra':
      return classes.finastra
    case 'legitscript':
      return classes.legitscript
    case 'kpmg':
      return classes.kpmg
    case 'middesk':
      return classes.middesk
    case 'grasshopper':
      return classes.grasshopper
    case 'visa':
      return classes.visa
    default:
      return ''
  }
}

function isDarkTheme(theme: ApplicationTheme) {
  switch (theme) {
    case 'starbucks':
    case 'capgemini':
    case 'abrigo':
    case 'legitscript':
    case 'middesk':
    case 'kpmg':
    case 'grasshopper':
      return false
    default:
      return true
  }
}

function needsExtraContrast(theme: ApplicationTheme) {
  switch (theme) {
    case 'marqeta':
    case 'finastra':
    case 'grasshopper':
    case 'visa':
      return true
    default:
      return false
  }
}

export function AppNav() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const theme = useSelector((state) => state.application.theme)
  const themeClass = useThemeClass(theme)
  const isDark = isDarkTheme(theme)
  const extraContrast = needsExtraContrast(theme)

  const reviewValidFilters = useSelector(dashboardSelectorsMap.reviews.filters.applied.valid)
  const nextReviewDashboardSearch = useMemo(
    () => calculateNextQuery({ request: reviewValidFilters, currentPathName: '/dashboard/cases' }),
    [reviewValidFilters]
  )

  const profileValidFilters = useSelector(dashboardSelectorsMap.profiles.filters.applied.valid)
  const nextProfileDashboardSearch = useMemo(
    () => calculateNextQuery({ request: profileValidFilters, currentPathName: '/dashboard/library' }),
    [profileValidFilters]
  )
  const intercomHasBooted = useSelector((state) => state.application.intercomHasBooted)
  const canReadAnalytics = useSelector((state) => hasPermission(state, BadgePermissionsEnum.ManageAnalytics))
  const hasFilingsDashboardPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ReadFilingsDashboard)
  )
  const canViewFilingReportingDashboard =
    useFeatureFlag(FeatureFlag.EnableFilingReportingDashboard) || hasFilingsDashboardPermission
  // The requests dashboard is within analytics, yet analysts who need
  // the dashboard may not have permissions to view analytics.
  // So we need to check both the badge and the feature flag to determine
  // if the analytics button is visible.
  const canSeeAnalyticsButton = canReadAnalytics || canViewFilingReportingDashboard

  const canSeeAutomationsButton = useFeatureFlag(FeatureFlag.EnableOrganizationAutomationsFrontend)

  const goTo = (action: string, search?: string) => (event: React.MouseEvent) => {
    const searchParams = search ? `?${search}` : ''
    const newUrl = `/dashboard/${action}${searchParams}`
    dispatch(navigateToCompiledUrl(event, newUrl))
  }

  const navigateToAutomations = () => (event: React.MouseEvent) => {
    dispatch(automationActions.navigateToAutomations(event))
  }

  return (
    <nav className={classNames(classes.root, themeClass)}>
      <Link
        to={{ pathname: '/dashboard/cases', search: nextReviewDashboardSearch }}
        tabIndex={-1}
        className={classNames(classes.icon, themeClass)}
      >
        <Logo theme={theme} />
      </Link>

      <div className={classes.topSection}>
        <AppNavButton
          label="Cases"
          aria-current={location.pathname === '/dashboard/cases'}
          onClick={goTo('cases', nextReviewDashboardSearch)}
          Icon={InboxRounded}
        />
        <ProfilesDashboardGate>
          <AppNavButton
            label="Profiles"
            onClick={goTo('library', nextProfileDashboardSearch)}
            aria-current={location.pathname === '/dashboard/library'}
            Icon={PeopleOutlineRounded}
          />
        </ProfilesDashboardGate>
        {canSeeAnalyticsButton && (
          <AppNavButton
            label="Analytics"
            aria-current={location.pathname.startsWith('/dashboard/analytics')}
            onClick={goTo('analytics')}
            Icon={BarChartRounded}
          />
        )}
        <AppNavButton
          label="Search"
          aria-current={location.pathname.startsWith('/search')}
          onClick={() => {
            dispatch(actions.navigateToSearch())
          }}
          Icon={SearchRounded}
        />

        <AppNavButton
          label="Apps"
          aria-current={location.pathname === '/apps'}
          onClick={(e) => {
            dispatch(navigateToInternalUrl(e, '/apps'))
          }}
          Icon={CategoryRounded}
        />

        {canSeeAutomationsButton && (
          <AppNavButton
            label="Automations"
            aria-current={location.pathname.startsWith('/automations')}
            onClick={navigateToAutomations()}
            Icon={AutomationsIcon}
          />
        )}
      </div>

      <div className={classes.bottomSection}>
        {theme !== 'default' && (
          <HbText className={classes.poweredByText} color={extraContrast ? 'disabled' : 'secondary'} size="s">
            Powered by
            <br />
            <HbText color={isDark ? 'white' : 'primary'} size="md">
              Hummingbird
            </HbText>
          </HbText>
        )}
        {intercomHasBooted && (
          <AppNavButton id={CUSTOM_INTERCOM_LAUNCHER_ID} label="Contact Support" Icon={ContactSupportOutlined} />
        )}
        <HeaderNotifications />
        <HeaderAccountMenu />
      </div>
    </nav>
  )
}
