import { isNil } from 'lodash'
import { JsonPrimitive } from 'type-fest'

import { SnakeDataTypeKey } from 'actions/importingFields.types'

export const dataHelpText = (dataType: SnakeDataTypeKey | 'import_file_type'): string[] => {
  switch (dataType) {
    case 'import_template': {
      return ['Upload data from a custom data format.']
    }
    case 'individual': {
      return [
        'Paste in any information you have on people who are involved in this case. You can add more than one person if needed. We will help you organize the information.',
        'You can paste in multiple people at once by including a header row and comma-separated values.',
      ]
    }
    case 'business': {
      return [
        'Add businesses that are involved in this case. This may include businesses operated by the people in this case, or businesses that were used as part of a larger operation.',
      ]
    }
    case 'institution': {
      return [
        'Add financial institutions that are involved in this case. The filing institution linked to your account will automatically be included.',
      ]
    }
    case 'payment_card': {
      return [
        'Add information about a payment card that was involved in this case. Provide as much information as you have available.',
      ]
    }
    case 'bank_account': {
      return [
        'Add information about bank accounts that were involved in this case. If possible, include the status of the accounts with their respective banks (e.g. open, closed, suspended, etc.)',
      ]
    }
    case 'transaction_imports': {
      return [
        'Add transactions that may be related to this case. Once imported, you can explore transaction charts, summary statistics, and details in the Investigate section of this case.',
      ]
    }
    case 'device': {
      return [
        'Add information about the devices and IP addresses involved in this case. This may include devices used by the people in this case and IP addresses that they used to access your service.',
      ]
    }
    case 'crypto_address': {
      return [
        'Add information about the crypto addresses involved in this case. Provide as much information as you have available.',
      ]
    }
    case 'product': {
      return [
        'Add information about the products involved in this case. Provide as much information as you have available.',
      ]
    }
    case 'attachment': {
      return [
        'Attach files related to this case. You can include any file type: spreadsheets, documents, images, and anything else you want to associate with the case.',
      ]
    }
    case 'import_file_type': {
      return [
        "We can only import data from CSV, Microsoft Excel, and plain text files. Other file types can be attached to your case, but we can't import data from them.",
      ]
    }
    default: {
      return [`Unknown Entity Type ${dataType}!`]
    }
  }
}

export const jsonPrimitiveToDisplayString = (value: JsonPrimitive | undefined, fallback = '-') =>
  isNil(value) ? fallback : String(value).trim() || fallback

export const humanizeList = (array: Array<string>) => {
  const len = array.length
  if (len === 0) {
    return ''
  }
  if (len === 1) {
    return array[0]
  }
  const i = len - 1
  return `${array.slice(0, i).join(', ')}${len >= 3 ? ',' : ''} and ${array[i]}`
}

export const filterStringifyArray = (arr: Array<JsonPrimitive | undefined>, delimiter = ' ') => {
  return arr
    .map((val) => (isNil(val) ? val : typeof val === 'string' ? val.trim() : String(val)))
    .filter(Boolean)
    .join(delimiter)
}
