import { gql } from '@apollo/client'

export const OTHER_INFO_ENTRY_FRAGMENT = gql`
  fragment OtherInfoEntryFields on OtherInfoEntry {
    label
    value
    managed
    displayAs
  }
`
