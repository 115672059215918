import { gql } from '@apollo/client'

export const CUSTOM_FIELD_LABEL_SEARCH_QUERY = gql`
  query CustomFieldLabelSearch($labelType: OtherInfoLabelTypeEnum!) {
    customFieldLabelSearch(labelType: $labelType) {
      token
      label
      managed
    }
  }
`
