import profilesActionMap from 'dashboards/profiles/BatchActionsMenu/batchActionMap'
import reviewsActionMap from 'dashboards/reviews/ReviewsBatchActionsMenu/batchActionMap'
import transactionsActionMap from 'dashboards/transactions/TransactionsBatchActionsMenu/batchActionMap'
import { BatchActionTypeEnum, DashboardTypeEnum } from 'types/api'

import { useDashboardContext } from '../DashboardContextProvider'

import { ActionMap } from './types'

const actionMapMap: {
  [key in DashboardTypeEnum]: ActionMap
} = {
  [DashboardTypeEnum.Reviews]: reviewsActionMap,
  [DashboardTypeEnum.Transactions]: transactionsActionMap,
  [DashboardTypeEnum.Profiles]: profilesActionMap,
  [DashboardTypeEnum.InformationRequests]: {},
  [DashboardTypeEnum.Filings]: {},
  [DashboardTypeEnum.CtrFilings]: {},
  [DashboardTypeEnum.AutomationExecutions]: {},
}

export const useBatchActionLookups = () => {
  const dashboardType = useDashboardContext()
  const actionMap = actionMapMap[dashboardType]

  const getDialog = (action: BatchActionTypeEnum) => actionMap[action]?.Dialog
  const getLabel = (action: BatchActionTypeEnum) => actionMap[action]?.label
  const getIcon = (action: BatchActionTypeEnum) => actionMap[action]?.Icon
  const getDialogIcon = (action: BatchActionTypeEnum) => actionMap[action]?.DialogIcon
  return {
    getDialog,
    getLabel,
    getIcon,
    getDialogIcon,
  }
}
