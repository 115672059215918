import { useEffect, useRef } from 'react'

import { FilterListRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useDispatch, useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { HbText } from 'components/HbComponents/Text/HbText'
import KeyCapture from 'components/library/KeyCapture'
import { useToggle } from 'hooks'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { CombinatorEnum, OperatorEnum } from 'types/api'
import { Theme } from 'types/hb'
import { isSearchFilterWithId, isValidFilter } from 'utils/query/api.types'

import { FilterControlProps } from '../FilterControls'
import useLogDashboardFilterInteraction from '../hooks/useLogDashboardFilterInteraction'
import { AddButton } from '../sharedComponents'

import FilterGroup from './FilterGroup'

const useStyles = makeStyles((theme: Theme) => ({
  filterPopover: { minWidth: 564 }, // TODO large min width set to try and avoid resizing UX experience
  allFilters: {
    display: 'grid',
    grid: 'auto-flow minmax(40px, max-content) / 100px auto auto 1fr 48px',
    paddingRight: 10,
    gap: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
  },
}))

export interface MenuItemProps {
  value: CombinatorEnum | OperatorEnum
  title: string
}

function FilterButton({
  popoverShared,
  popoverHeader,
  addButtons,
  buttonActive,
  disabled,
  size = 'small',
  iconOnly,
  zIndex,
}: FilterControlProps) {
  const logAction = useLogDashboardFilterInteraction()
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const classes = useStyles()
  const remoteOpen = useSelector(dashboardsSelectors.filters.remoteOpen)
  const open = useToggle(remoteOpen || false)
  const ref = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const appliedFilters = useSelector(dashboardsSelectors.filters.applied.all)
  const parentSearchGroup = appliedFilters.filter
  const parentGroupCombinator = parentSearchGroup.combinator

  const onCombinatorChange = (newCombo: CombinatorEnum) => {
    if (parentSearchGroup) {
      const { id, children } = parentSearchGroup

      dispatch(
        dashboardsActions.filters.updateGroupById({
          _type: 'SearchGroupWithId',
          id,
          combinator: newCombo,
          children,
        })
      )
    }
  }

  // check if any search groups are present, display changes if so
  const hasSearchGroups = parentSearchGroup?.children.find(
    (child) => isSearchFilterWithId(child) || child.children.length > 0
  )
  const displayText = hasSearchGroups ? 'In this view, show records' : 'No filter conditions added to this view yet'

  const lastFilter = parentSearchGroup?.children[parentSearchGroup.children.length - 1]
  const lastFilterIsValid = !lastFilter || !isSearchFilterWithId(lastFilter) || isValidFilter(lastFilter)

  const deleteHandler = (componentId?: string) => {
    if (componentId) {
      dispatch(dashboardsActions.filters.deleteComponentById.action({ id: componentId }))
    }
  }

  const handleClick = () => {
    if (remoteOpen) {
      dispatch(dashboardsActions.filters.remoteOpen(false))
    } else {
      if (!open.value) {
        logAction('dashboard:filter:clicked')
      }
      open.toggle()
    }
  }

  // on unmount/cleanup, turn off the remoteOpen flag if on
  useEffect(() => {
    return () => {
      if (remoteOpen) dispatch(dashboardsActions.filters.remoteOpen(false))
    }
  }, [remoteOpen, dispatch, dashboardsActions])

  const isOpen = remoteOpen || open.value

  return (
    <>
      <HbButton
        ref={ref}
        Icon={FilterListRounded}
        className={classnames({ [buttonActive]: open.value })}
        size={size}
        label="Filter"
        variant="secondary"
        onClick={handleClick}
        disabled={disabled}
        iconOnly={iconOnly}
        tooltip={iconOnly}
      />
      <KeyCapture active={isOpen}>
        <HbPopper
          id="column-filter-popper"
          classes={{ content: classes.filterPopover }}
          anchorEl={ref.current}
          isOpen={isOpen}
          onClose={handleClick}
          placement="bottom-start"
          zIndex={zIndex}
        >
          <div className={popoverShared}>
            <div className={popoverHeader}>
              <HbText color="secondary" size="md" noSelection>
                {displayText}
              </HbText>
            </div>
            <div className={classes.allFilters}>
              <FilterGroup
                index={-1}
                onDelete={deleteHandler}
                groupId={parentSearchGroup?.id}
                parentCombinator={parentGroupCombinator}
                onParentCombinatorChange={onCombinatorChange}
                addButtonStyles={addButtons}
              />
            </div>
            <div className={addButtons}>
              <AddButton
                label="Add filter"
                disabled={!lastFilterIsValid}
                onClick={() => {
                  // create initial 'invalid' filter entry
                  dispatch(
                    dashboardsActions.filters.createComponentById.action({
                      _type: 'SearchFilterWithId',
                      parentID: parentSearchGroup?.id,
                      id: '',
                      field: '',
                      predicate: { values: [], operator: OperatorEnum.Is },
                    })
                  )
                }}
              />
              <AddButton
                label="Add filter group"
                disabled={!lastFilterIsValid}
                onClick={() => {
                  dispatch(
                    dashboardsActions.filters.createComponentById.action({
                      _type: 'SearchGroupWithId',
                      parentID: parentSearchGroup?.id,
                      id: '', // ID will be created by the reducer
                      combinator: parentGroupCombinator || CombinatorEnum.And,
                      children: [],
                    })
                  )
                }}
              />
            </div>
          </div>
        </HbPopper>
      </KeyCapture>
    </>
  )
}

export default FilterButton
