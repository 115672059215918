import { Ref, useCallback, useContext, useEffect, useRef, useState } from 'react'

// TODO: Use HbPopper.
// eslint-disable-next-line no-restricted-imports
import { Button, ClickAwayListener, Grow, Paper, Popper, styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import classnames from 'classnames'

import { useDispatch, useSelector } from 'actions/store'
import Loader from 'components/library/Loader'
import { TableComponentOverrides } from 'components/library/Table/Table'
import { HbCheckbox } from 'components/material/Form'

import { DashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { BatchActionSelectUIState } from 'dashboards/shared/react/dashboards.config'
import { opacify } from 'helpers/colors'
import { stopEventPropagation } from 'helpers/uiHelpers'
import { useToggle } from 'hooks'
import { ArrowDownwardIcon, ArrowUpwardIcon } from 'icons'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { SortEnum } from 'types/api'
import { Stringable, Theme, WithStyles } from 'types/hb'

import AdvancedFilterControls from './AdvancedFilterControls'
import { Criteria } from './Criteria'
import DefaultHeaderCell from './DefaultHeaderCell'
import FilterControl from './FilterControl'
import { Option, ValueSelector } from './ValueSelector'

const styles: Styles<Theme, object> = (theme: Theme) => ({
  root: {
    position: 'relative',
  },
  sortable: {
    '&, &[data-react-beautiful-dnd-drag-handle="1"]': {
      cursor: 'pointer',
    },
    '&:hover': {
      color: theme.palette.text.medium,
    },
  },
  popper: {
    zIndex: theme.zIndex.speedDial,
  },
  lastNonStickyHeader: {},
  menuList: {
    width: 325,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0`,
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortToggle: {
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.dividers.light}`,
    display: 'flex',
    justifyContent: 'space-around',
  },
  sortToggleButton: {
    width: '45%',
    fontWeight: 400,
  },
  sortToggleButtonLabel: {},
  sortToggleIcon: {
    color: theme.palette.accent,
    paddingRight: theme.spacing(0.5),
    display: 'none',
  },
  selectedSort: {
    backgroundColor: opacify(theme.palette.accent, 0.1),
    '& $sortToggleIcon': {
      display: 'inherit',
    },
  },
  filterControl: {
    marginLeft: theme.spacing(),
  },
  advancedFilterControls: { gap: theme.spacing(0.25) },
  okButton: {
    minWidth: '100px',
  },
  cancelButton: {
    marginRight: theme.spacing(),
    minWidth: '100px',
  },
  ascendingButton: {},
  descendingButton: {},
})

const StyledCheckbox = styled(HbCheckbox)(({ theme }) => ({
  // compensate for the right-hand border in the inner container,
  // which slightly nudges content out of alignment with the column body cells
  marginLeft: theme.hbUnit(0.5) + 1,
}))

function UnstyledBatchSelectCell(props: Props) {
  const {
    field,
    onChange,
    criteria,
    headerCellRef,
    createValues,
    canSelectValues,
    clearOnAll,
    classes,
    sortable,
    styleOverrides,
    isBatch,
    batchActionSelectUIState,
    onBatchSelectAll,
    label,
    columnResizingEnabled = false,
    disableBatchAction = false,
    ...rest
  } = props
  const dispatch = useDispatch()
  const handleChange = () => {
    if (batchActionSelectUIState !== 'none-selected') {
      dispatch(batchActionsActions.batchSelectItems.clear())
    } else if (onBatchSelectAll) {
      onBatchSelectAll()
    }
  }
  const ariaCheckedMap = {
    'all-selected': 'true',
    'some-selected': 'mixed',
    'none-selected': 'false',
  } as const

  return (
    <DefaultHeaderCell
      classes={{
        root: classnames(classes.root, classes.sortable),
        lastNonStickyHeader: classes.lastNonStickyHeader,
      }}
      headerCellRef={headerCellRef}
      styleOverrides={styleOverrides?.DefaultHeaderCell}
      data-testid={label}
      rootClassName="batch-select-cell"
      columnResizingEnabled={columnResizingEnabled}
      {...rest}
    >
      <StyledCheckbox
        checked={batchActionSelectUIState === 'all-selected'}
        disabled={disableBatchAction}
        indeterminate={batchActionSelectUIState === 'some-selected'}
        onChange={handleChange}
        data-testid="batch-select-all"
        aria-checked={ariaCheckedMap[batchActionSelectUIState ?? 'none-selected']}
        uncheckedBackgroundColor="contrastLight"
      />
    </DefaultHeaderCell>
  )
}

export const BatchSelectCell = withStyles(styles)(UnstyledBatchSelectCell)

export interface Props extends WithStyles<typeof styles> {
  field: string
  label: string
  selectedValues?: string[]
  onChange: (criteria: Criteria) => void
  onOpen?: () => void
  loadingColumns?: boolean
  lastNonSticky?: boolean
  batchActionSelectUIState?: BatchActionSelectUIState
  onBatchSelectAll?: () => void
  sticky?: boolean
  clearOnAll?: boolean // If true, selecting all values clears the selection and produces an empty array filter
  sortable?: boolean
  filterable?: boolean /** Only supported in dashboard contexts */
  isBatch?: boolean
  criteria?: Criteria
  headerCellRef?: Ref<HTMLTableCellElement>
  styleOverrides?: Pick<TableComponentOverrides, 'DefaultHeaderCell'>
  direction?: SortEnum
  directionIndex?: number
  columnResizingEnabled?: boolean
  disableBatchAction?: boolean

  /** @deprecated Legacy client-side filtering */
  createValues?: () => Option[]
  /** @deprecated Legacy client-side filtering */
  canSelectValues?: 'none' | 'single' | 'multiple'
}

export function SortAndFilterHeaderCellBase(props: Props) {
  const [open, setOpen] = useState(false)
  const {
    field,
    onChange,
    onOpen,
    loadingColumns,
    criteria,
    headerCellRef,
    createValues,
    canSelectValues = 'none',
    clearOnAll = false,
    classes,
    sortable,
    filterable,
    styleOverrides,
    isBatch,
    onBatchSelectAll,
    batchActionSelectUIState,
    direction: directionProp,
    directionIndex: directionIndexProp,
    ...rest
  } = props
  const dashboardContext = useContext(DashboardContext)
  const isInDashboardContext = !!dashboardContext
  const localCriteria = criteria && criteria.field === field ? criteria : undefined

  const [direction, setDirection] = useState(localCriteria?.direction)
  const [selectedValues, setSelectedValues] = useState(localCriteria?.values || [])
  const iconRef = useRef<HTMLDivElement>(null)

  const enableControls = canSelectValues !== 'none' || sortable || filterable

  const enableAdvancedControls = enableControls && isInDashboardContext

  const handleSetSelectedValues = useCallback((stringables: Array<Stringable>) => {
    setSelectedValues(stringables.filter(Boolean).map((s) => s.toString()))
  }, [])

  const updateOpen = useCallback(
    (value: boolean) => {
      setOpen(value)
      if (onOpen && !open) onOpen()
    },
    [onOpen, open]
  )

  useEffect(() => {
    if (localCriteria) {
      setDirection(localCriteria.direction)
      setSelectedValues(localCriteria.values || [])
    } else {
      setDirection(undefined)
      setSelectedValues([])
    }
  }, [localCriteria])

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (iconRef.current && iconRef.current?.contains(event.target as Node)) {
      return
    }

    updateOpen(false)
  }

  const handleOpenMenu = () => {
    updateOpen(true)
  }

  const openAdvancedControls = useToggle(false)
  const anchorRef = useRef<HTMLTableCellElement | null>(null)
  const setHeaderRef = useCallback(
    (ref: HTMLTableCellElement | null) => {
      if (typeof headerCellRef === 'function') {
        headerCellRef(ref)
      }
      anchorRef.current = ref
    },
    [headerCellRef]
  )
  const handleAdvancedHeaderClick = () => {
    openAdvancedControls.toggle()
  }

  const handleHeaderClick = () => {
    if (canSelectValues !== 'none') {
      handleOpenMenu()
    } else if (sortable) {
      // Toggle between directions, default to Asc if undefined
      const newDirection = (directionProp || direction) === SortEnum.Asc ? SortEnum.Desc : SortEnum.Asc
      setDirection(newDirection)
      onChange({
        field,
        display: props.label,
        direction: newDirection,
        values: selectedValues,
      })
    }
  }

  // Need to update to pass values to the search request
  const handleValueSelectionChange = (option: Option) => {
    if (selectedValues.includes(option.value?.toString() ?? null)) {
      setSelectedValues(selectedValues.filter((v) => v !== option.value))
    } else {
      setSelectedValues(selectedValues.concat([option.value?.toString() ?? null]))
    }
  }

  // TODO figure out where the best place to put this is
  if (isBatch) {
    return <BatchSelectCell {...props} />
  }

  return (
    <DefaultHeaderCell
      classes={{
        root: classnames(classes.root, classes.sortable, enableAdvancedControls && classes.advancedFilterControls),
        lastNonStickyHeader: classes.lastNonStickyHeader,
      }}
      headerCellRef={setHeaderRef}
      onClick={enableAdvancedControls ? handleAdvancedHeaderClick : handleHeaderClick}
      styleOverrides={styleOverrides?.DefaultHeaderCell}
      data-testid={props.label}
      {...rest}
    >
      {enableAdvancedControls && (
        <AdvancedFilterControls
          field={field}
          isOpen={openAdvancedControls.value}
          onClose={() => openAdvancedControls.toggle()}
          anchorEl={anchorRef.current || undefined}
          sortable={sortable}
        />
      )}
      {!enableAdvancedControls && enableControls && (
        <FilterControl
          className={classes.filterControl}
          label={props.label}
          iconRef={iconRef}
          filtering={!!localCriteria}
          direction={directionProp || localCriteria?.direction}
          directionIndex={directionIndexProp && directionIndexProp > 0 ? directionIndexProp : undefined}
          filterEnabled={canSelectValues !== 'none'}
        />
      )}
      <Popper
        open={open}
        anchorEl={iconRef.current}
        placement="bottom-end"
        onKeyDown={stopEventPropagation}
        transition
        className={classes.popper}
      >
        {({ TransitionProps }: any) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center right' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.menuList}>
                  {sortable && (
                    <div className={classes.sortToggle}>
                      <Button
                        aria-pressed={direction === SortEnum.Asc}
                        classes={{
                          root: classnames(classes.sortToggleButton, classes.ascendingButton, {
                            [classes.selectedSort]: direction === SortEnum.Asc,
                          }),
                        }}
                        onClick={() => setDirection(SortEnum.Asc)}
                      >
                        <ArrowUpwardIcon className={classes.sortToggleIcon} />
                        Sort A &#8594; Z
                      </Button>
                      <Button
                        aria-pressed={direction === SortEnum.Desc}
                        classes={{
                          root: classnames(classes.sortToggleButton, classes.descendingButton, {
                            [classes.selectedSort]: direction === SortEnum.Desc,
                          }),
                        }}
                        onClick={() => setDirection(SortEnum.Desc)}
                      >
                        <ArrowDownwardIcon className={classes.sortToggleIcon} />
                        Sort Z &#8594; A
                      </Button>
                    </div>
                  )}
                  <Loader variant="local" loading={!!loadingColumns} />

                  {canSelectValues !== 'none' && (
                    <ValueSelector
                      values={createValues?.() ?? []}
                      clearOnAll={clearOnAll}
                      selectedValues={selectedValues}
                      setSelectedValues={handleSetSelectedValues}
                      handleValueSelectionChange={handleValueSelectionChange}
                      multiple={canSelectValues === 'multiple'}
                    />
                  )}

                  <div className={classes.buttons}>
                    <Button
                      className={classes.cancelButton}
                      onClick={(event) => {
                        event.stopPropagation()
                        updateOpen(false)
                      }}
                      data-testid="button_Cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.okButton}
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation()
                        onChange({
                          field,
                          display: props.label,
                          direction,
                          values: selectedValues,
                        })
                        updateOpen(false)
                      }}
                      data-testid="button_OK"
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </DefaultHeaderCell>
  )
}

// Only used in in places that don't have a meaningful sort.
function NonDashboardSortAndFilterHeaderCell({ criteria, ...props }: Props) {
  return <SortAndFilterHeaderCellBase {...props} directionIndex={0} />
}

export const StyledNonDashboardSortAndFilterHeaderCell = withStyles(styles)(NonDashboardSortAndFilterHeaderCell)

function DashboardSortAndFilterHeaderCell({ criteria, ...props }: Props) {
  const selectors = useDashboardSelectors()
  const sorts = useSelector(selectors.sorts.valid) ?? []

  const redesignDirection = sorts.find((sort) => sort.sortField === criteria?.field)?.sortDir
  const redesignDirectionIndex = sorts.findIndex((sort) => sort.sortField === criteria?.field) + 1

  return (
    <SortAndFilterHeaderCellBase {...props} direction={redesignDirection} directionIndex={redesignDirectionIndex} />
  )
}

function SortAndFilterHeaderCellContextGuard(props: Props) {
  const dashboardContext = useContext(DashboardContext)
  const isInDashboardContext = !!dashboardContext

  const shouldRenderDashboardVariant = isInDashboardContext

  const Component = shouldRenderDashboardVariant
    ? DashboardSortAndFilterHeaderCell
    : NonDashboardSortAndFilterHeaderCell
  return <Component {...props} />
}

export default withStyles(styles)(SortAndFilterHeaderCellContextGuard)
