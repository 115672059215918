import React, { ReactNode } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'
import { CloseButton } from 'components/cases/information/CloseButton'
import Loader from 'components/library/Loader'
import { stopEventPropagation } from 'helpers/uiHelpers'
import { Theme } from 'types/hb'

const useHeaderStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: theme.spacing(2),
    padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,

    '&$noIcon': {
      gridTemplateColumns: 'auto',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: '1 1 auto',
  },
  icon: {
    width: 22,
    placeSelf: 'center',
  },
  noIcon: {},
}))

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100vw',
    maxWidth: 'calc(100vw - 80px)',
    margin: theme.spacing(5),
    borderRadius: 24,
    overflow: 'visible',
  },
  content: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  contentLarge: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  footer: {
    margin: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.dividers.light}`,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
  footerLarge: {
    padding: `${theme.spacing(3)} ${theme.spacing(7)}`,
  },
}))

interface HeaderProps {
  title: string
  onClose: (event: React.MouseEvent) => void
  IconComponent?: React.ComponentType<{ className?: string }>
  headerContent?: ReactNode
  classes?: { header?: string }
}

export function ComplexDialogHeader(props: HeaderProps) {
  const { title, IconComponent, headerContent, classes = {}, onClose } = props

  const { header, icon: iconStyle, title: titleStyle, titleContainer, noIcon } = useHeaderStyles()

  return (
    <DialogTitle
      className={classnames(header, classes.header, {
        [noIcon]: !IconComponent,
      })}
    >
      {IconComponent && <IconComponent className={iconStyle} />}
      <div className={titleContainer}>
        <HbText className={titleStyle} size="xl" noWrap>
          {title}
        </HbText>
        <CloseButton close={onClose} />
      </div>
      {headerContent}
    </DialogTitle>
  )
}

export function ComplexDialogContent({
  className,
  children,
  size = 'small',
}: {
  className?: string
  children: ReactNode
  size?: 'small' | 'large'
}) {
  const { content, contentLarge } = useStyles()

  return (
    <DialogContent className={classnames(size === 'small' ? content : contentLarge, className)}>
      {children}
    </DialogContent>
  )
}

export function ComplexDialogActions({
  children,
  size = 'small',
  className,
}: {
  children: ReactNode
  size?: 'small' | 'large'
  className?: string
}) {
  const { footer: footerStyle, footerLarge } = useStyles()

  return (
    <DialogActions
      classes={{
        root: classnames(footerStyle, { [footerLarge]: size === 'large' }, className),
      }}
    >
      {children}
    </DialogActions>
  )
}

export interface Props {
  title: string
  onClose: () => void
  headerContent?: ReactNode
  IconComponent?: React.ComponentType<{ className: string }>
  footer: ReactNode
  children: ReactNode
  footerSize?: 'large' | 'small'
  contentSize?: 'large' | 'small'
  loading?: boolean
  classes?: { header?: string; content?: string; paper?: string; footer?: string }
}

export function ComplexDialog(props: Props) {
  const {
    IconComponent,
    title,
    onClose,
    footer,
    classes = {},
    headerContent,
    loading,
    children,
    contentSize = 'large',
    footerSize = 'large',
  } = props

  const { paper } = useStyles()

  return (
    <Dialog
      data-testid="information_dialog"
      open
      classes={{ paper: classnames(paper, classes.paper) }}
      onClose={onClose}
      onKeyPress={stopEventPropagation}
      onKeyDown={stopEventPropagation}
    >
      <ComplexDialogHeader
        title={title}
        IconComponent={IconComponent}
        headerContent={headerContent}
        classes={classes}
        onClose={onClose}
      />
      <ComplexDialogContent size={contentSize} className={classes.content}>
        {children}
      </ComplexDialogContent>
      {loading && <Loader variant="local" />}
      <ComplexDialogActions size={footerSize} className={classes.footer}>
        {footer}
      </ComplexDialogActions>
    </Dialog>
  )
}
