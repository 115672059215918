import pick from 'lodash/pick'
import { AnyAction } from 'redux'

import * as actions from 'actions/userSettingsActions'
import { mergeDashboardSettings } from 'actions/userSettingsActions'
import { QueryName } from 'dashboards/shared/components/Dashboard/NamedQueryGroups.types'
import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'
import { DashboardTypeEnum } from 'types/api'

export type PersistedDashboardColumn = {
  apiName: string
  hidden: boolean
}

type DashboardColumns = Record<DashboardSlice, PersistedDashboardColumn[]>
type RecentDashboardviews = Record<DashboardSlice, QueryName<DashboardSlice>>

export type DashboardDensity = 'compact' | 'normal'

export interface UserSettingsState {
  hideCharts?: boolean
  dashboardColumns: DashboardColumns
  recentDashboardViews: RecentDashboardviews
  density: DashboardDensity
}

const getInitialState = (): UserSettingsState => ({
  dashboardColumns: {
    [DashboardTypeEnum.Reviews]: [],
    [DashboardTypeEnum.Profiles]: [],
    [DashboardTypeEnum.InformationRequests]: [],
    [DashboardTypeEnum.Transactions]: [],
    [DashboardTypeEnum.Filings]: [],
    [DashboardTypeEnum.CtrFilings]: [],
    [DashboardTypeEnum.AutomationExecutions]: [],
  },
  recentDashboardViews: {
    [DashboardTypeEnum.Reviews]: 'my-cases',
    [DashboardTypeEnum.Profiles]: 'all-profiles',
    [DashboardTypeEnum.InformationRequests]: 'all-requests',
    [DashboardTypeEnum.Transactions]: 'all-transactions',
    [DashboardTypeEnum.Filings]: 'all-filings',
    [DashboardTypeEnum.CtrFilings]: 'all-filings',
    [DashboardTypeEnum.AutomationExecutions]: 'all-runs',
  },
  density: 'normal',
})

export const makeUserSettingsReducer =
  (customState?: ReturnType<typeof getInitialState>) =>
  (state = customState ?? getInitialState(), action: AnyAction): UserSettingsState => {
    switch (action.type) {
      case actions.SET_USER_SETTINGS: {
        return {
          ...state,
          ...action.userSetting,
        }
      }
      case actions.SET_USER_DASHBOARD_SETTING: {
        return {
          ...state,
          ...mergeDashboardSettings(action.payload, pick(state, 'dashboardColumns', 'recentDashboardViews')),
        }
      }
      default: {
        return state
      }
    }
  }

const userSettingsReducer = makeUserSettingsReducer()

export default userSettingsReducer
