import { ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogContent, DialogContentProps, DialogActions } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import { SxProps } from '@mui/system'

import { snakeCase } from 'lodash'

import { HbButton, Props as HbButtonProps } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import KeyCapture from 'components/library/KeyCapture'
import Loader from 'components/library/Loader'
import { mergeOverrideStyles } from 'components/utils/styles'

import { Theme } from 'types/hb'

import { BasicSize } from './HbComponents.types'

interface HbDialogHeaderProps {
  id: string // for a11y purposes
  title: ReactNode
  className: string
  showCloseButton: boolean
  onClose: () => void
}

const HbDialogHeader = ({ title, className, showCloseButton, onClose, id }: HbDialogHeaderProps) => {
  return (
    <DialogTitle className={className} id={id}>
      <HbText size="xl">{title}</HbText>
      {showCloseButton && <HbButton label="Close modal" iconOnly Icon={Close} onClick={onClose} />}
    </DialogTitle>
  )
}
export type Props = {
  open: boolean
  showCloseButton?: boolean
  onClose: () => void
  classes?: Partial<ReturnType<typeof useStyles>>
  header?: ReactNode
  onConfirm?: () => void
  loading?: boolean
  TransitionProps?: TransitionProps
  confirmButtonDisabled?: boolean
  cancelButtonDisabled?: boolean
  confirmText?: string
  confirmVariant?: HbButtonProps['variant']
  cancelText?: string
  size?: BasicSize
  sx?: SxProps
} & (
  | {
      content: ReactNode
      actions?: ReactNode
      children?: never
    }
  | {
      content?: never
      actions?: never
      children: ReactNode
    }
) &
  (
    | {
        id?: string // If id is ommitted, use snakeCase'd title
        title: string
      }
    | {
        id: string // id for a11y purposes
        title: Exclude<ReactNode, string>
      }
  )

type HbDialogActionsProps = Pick<
  Props,
  | 'onClose'
  | 'cancelButtonDisabled'
  | 'actions'
  | 'cancelText'
  | 'confirmButtonDisabled'
  | 'confirmText'
  | 'confirmVariant'
  | 'loading'
  | 'onConfirm'
>

export function HbDialogActions(props: HbDialogActionsProps) {
  const {
    onClose,
    cancelButtonDisabled,
    actions: _actions,
    cancelText,
    confirmButtonDisabled,
    confirmText,
    confirmVariant,
    onConfirm,
    loading,
  } = props

  const actions = _actions || (
    <>
      <HbButton
        onClick={onClose}
        variant="textSecondary"
        disabled={cancelButtonDisabled || loading}
        label={cancelText || 'Cancel'}
      />
      <HbButton
        onClick={onConfirm}
        disabled={confirmButtonDisabled || loading}
        label={confirmText || 'Confirm'}
        variant={confirmVariant}
      />
    </>
  )

  return (
    <>
      {loading && <Loader variant="local" />}
      <DialogActions sx={{ gap: 1, px: 1, mt: 3 }}>{actions}</DialogActions>
    </>
  )
}

export const HbDialogContent = ({
  children,
  sx,
  className,
}: Pick<DialogContentProps, 'children' | 'sx' | 'className'>) => {
  return (
    <DialogContent className={className} sx={{ px: 3, ...sx }}>
      {children}
    </DialogContent>
  )
}

const sizes: Record<BasicSize, number> = {
  s: 540,
  md: 720,
  lg: 1080,
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: ({ size = 'md' }: Pick<Props, 'size'>) => {
    return {
      width: sizes[size],
      maxWidth: '100vw',
      overflow: 'visible',
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 3),
  },
  content: {},
}))

export const HbDialog = ({
  id,
  title,
  open,
  onClose,
  content,
  showCloseButton = true,
  classes: overrideClasses,
  header,
  TransitionProps: transitionProps,
  size = 'md',
  sx,
  onConfirm,
  children,
  ...rest
}: Props) => {
  const classes = useStyles({ size })
  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)
  const titleId = `dialog_title_${typeof title === 'string' && !id ? snakeCase(title) : id}`

  return (
    <KeyCapture>
      <Dialog
        open={open}
        onClose={onClose}
        sx={sx}
        classes={{ paper: mergedClasses.paper }}
        aria-labelledby={titleId}
        TransitionProps={transitionProps}
      >
        {header || (
          <HbDialogHeader
            id={titleId}
            className={mergedClasses.header}
            title={title}
            showCloseButton={showCloseButton}
            onClose={onClose}
          />
        )}
        {children || (
          <>
            <HbDialogContent className={mergedClasses.content}>{content}</HbDialogContent>
            <HbDialogActions {...rest} onClose={onClose} onConfirm={onConfirm} />
          </>
        )}
      </Dialog>
    </KeyCapture>
  )
}
