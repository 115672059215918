import { useMemo, useRef } from 'react'

import { Grid, Button } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import { Formik, Form } from 'formik'

import { useSelector } from 'actions/store'
import TypeAheadEditor, { EditorApi } from 'components/library/TypeAheadEditor'
import { SelectField, TextField } from 'components/material/Form'
import { getOrganizationReviewTypes } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { Template, TemplateError } from 'reducers/narrativeTemplatesReducer'
import { FeatureFlag } from 'types/api'
import { WithStyles, Theme } from 'types/hb'

const styles = (theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(4),
  },
  spacing: {
    marginBottom: theme.spacing(2.5),
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    ...theme.typography.sizes.md,
  },
  button: {
    marginBottom: theme.spacing(2),
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
  },
})

interface Props extends WithStyles<typeof styles> {
  template: Template
  error: TemplateError
  onSubmit: (template: Template) => void
  onCancel: () => void
}

function NarrativeTemplateForm(props: Props) {
  const { classes, template, onSubmit, onCancel, error } = props
  const reviewTypes = useSelector(getOrganizationReviewTypes)
  const reviewTypeOptions = useMemo(
    () => [{ value: 'any', display: 'Any' }].concat(reviewTypes.map((t) => ({ value: t.token, display: t.name }))),
    [reviewTypes]
  )
  const editorApi = useRef<EditorApi>(null)
  const liquidEnabled = useFeatureFlag(FeatureFlag.EnableSmartValuesInNarrativeTemplates)

  return (
    <Formik enableReinitialize initialValues={template} onSubmit={onSubmit}>
      {({ handleChange, handleSubmit, isSubmitting }) => (
        <Form className={classes.root} onSubmit={handleSubmit}>
          {error && <div className={classes.error}>{error}</div>}
          <TextField
            fullWidth
            required
            label="Template Name"
            name="name"
            value={template.name}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <SelectField
            fullWidth
            label="Review Type"
            name="applicableReviewTypeToken"
            value={template.applicableReviewTypeToken}
            options={reviewTypeOptions}
            defaultOptionValue="any"
            className={classes.spacing}
          />
          <TypeAheadEditor
            initialValue={template.content}
            placeholder="Write out your narrative template here..."
            onChange={(value: string) => handleChange({ target: { name: 'content', value } })}
            ref={editorApi}
            richText={{ liquid: liquidEnabled }}
          />
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button className={classes.button} onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isSubmitting}
                className={classes.button}
                type="submit"
                variant="outlined"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default withStyles(styles)(NarrativeTemplateForm)
