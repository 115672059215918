import { gql, useQuery } from '@apollo/client'
import { Grid, Dialog, DialogTitle, DialogContent } from '@mui/material'

import { connect } from 'react-redux'

import * as narrativeTemplatesActions from 'actions/narrativeTemplatesActions'
import Loader from 'components/library/Loader'

import { useHbMutation } from 'hooks/ApolloHelpers'
import { useDeleteNarrativeTemplateQuery } from 'hooks/SettingsHooks'
import { Template, TemplateError } from 'reducers/narrativeTemplatesReducer'

import NarrativeTemplateForm from './NarrativeTemplateForm'
import NarrativeTemplateList from './NarrativeTemplateList'
import {
  FetchNarrativeTemplatesQuery,
  FetchNarrativeTemplatesQueryVariables,
  UpsertNarrativeTemplateMutation,
  UpsertNarrativeTemplateMutationVariables,
} from './__generated__/NarrativeTemplateDialog.generated'

export const NARRATIVE_TEMPLATE_EDITOR_FIELDS = gql`
  fragment NarrativeTemplateEditorFields on NarrativeTemplate {
    token
    name
    content
    applicableReviewType {
      token
    }
  }
`

export const FETCH_NARRATIVE_TEMPLATES = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  query FetchNarrativeTemplates {
    narrativeTemplates {
      ...NarrativeTemplateEditorFields
    }
  }
`

const UPSERT_NARRATIVE_TEMPLATE = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  mutation UpsertNarrativeTemplate($input: UpsertNarrativeTemplateInput!) {
    upsertNarrativeTemplate(input: $input) {
      narrativeTemplate {
        ...NarrativeTemplateEditorFields
      }
    }
  }
`

export const DELETE_NARRATIVE_TEMPLATE = gql`
  ${NARRATIVE_TEMPLATE_EDITOR_FIELDS}
  mutation DeleteNarrativeTemplate($input: DeleteNarrativeTemplateInput!) {
    deleteNarrativeTemplate(input: $input) {
      narrativeTemplate {
        ...NarrativeTemplateEditorFields
      }
    }
  }
`

interface OwnProps {
  open: boolean
  onClose: () => void
  onNewTemplate?: () => void
}

interface StateProps {
  activeTemplate: Template
  loading: boolean
  error: TemplateError
  setActiveTemplate: (template: Template | null) => void
}

function NarrativeTemplateDialog(props: StateProps & OwnProps) {
  const { activeTemplate, loading, open, onClose, setActiveTemplate, onNewTemplate = () => {}, error } = props

  const [upsertNarrativeTemplate] = useHbMutation<
    UpsertNarrativeTemplateMutation,
    UpsertNarrativeTemplateMutationVariables
  >(UPSERT_NARRATIVE_TEMPLATE, {
    logEvent: { name: 'settings:updateNarrativeTemplates:clicked' },
    refetchQueries: [FETCH_NARRATIVE_TEMPLATES],
  })

  const { deleteNarrativeTemplate } = useDeleteNarrativeTemplateQuery()

  const fetchResult = useQuery<FetchNarrativeTemplatesQuery, FetchNarrativeTemplatesQueryVariables>(
    FETCH_NARRATIVE_TEMPLATES
  )

  if (!fetchResult.data) {
    return null
  }

  const templates = fetchResult.data.narrativeTemplates

  const onDialogClose = async () => {
    setActiveTemplate(null)
    onClose()
  }

  const onTemplateSubmit = async (template: Template) => {
    const input = {
      applicableReviewTypeToken:
        template.applicableReviewTypeToken === 'any' ? null : template.applicableReviewTypeToken,
      content: template.content,
      name: template.name,
      token: template.token,
    }
    const result = await upsertNarrativeTemplate({
      variables: { input },
    })

    if (!result.errors) {
      onNewTemplate()
      onDialogClose()
    }
  }

  const onTemplateDelete = async (template: Template) => {
    await deleteNarrativeTemplate({
      variables: { input: { token: template.token ?? '' } },
    })
    setActiveTemplate(null)
  }

  if (!open) {
    return null
  }

  const templatesFormValues = templates.map((t) => ({
    token: t.token,
    name: t.name,
    content: t.content,
    applicableReviewTypeToken: t.applicableReviewType?.token,
  }))

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onDialogClose} disableEscapeKeyDown>
      {loading && <Loader variant="local" />}
      <DialogTitle>Manage Narrative Templates</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <NarrativeTemplateList
              templates={templatesFormValues}
              onSelect={setActiveTemplate}
              onDelete={onTemplateDelete}
            />
          </Grid>
          <Grid item xs={8}>
            <NarrativeTemplateForm
              error={error}
              key={activeTemplate?.token} // needed due to the text editor
              onSubmit={onTemplateSubmit}
              onCancel={onClose}
              template={activeTemplate || { name: '', content: '' }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default connect(
  (state: any, ownProps: OwnProps) => ({
    loading: state.view.loading.narrativeTemplates,
    templates: state.narrativeTemplates.generic,
    activeTemplate: state.narrativeTemplates.activeTemplate,
    error: state.narrativeTemplates.error,
    open: ownProps.open,
    onClose: ownProps.onClose,
  }),
  {
    setActiveTemplate: narrativeTemplatesActions.setActiveTemplate,
  }
)(NarrativeTemplateDialog)
