import { useCallback } from 'react'

import { DashboardTypeEnum, LibraryEntryTypeEnum } from 'types/api'

import { useDashboardContext } from '../components/DashboardContextProvider'

const ENTITY_TYPES = [LibraryEntryTypeEnum.People, LibraryEntryTypeEnum.Businesses]
const ACCOUNT_TYPES = [
  LibraryEntryTypeEnum.BankAccounts,
  LibraryEntryTypeEnum.CryptoAddresses,
  LibraryEntryTypeEnum.PaymentCards,
]

export const caseSubjectFieldsMap: Record<DashboardTypeEnum, Record<string, LibraryEntryTypeEnum[]>> = {
  [DashboardTypeEnum.Reviews]: {},
  [DashboardTypeEnum.Profiles]: {},
  [DashboardTypeEnum.InformationRequests]: {},
  [DashboardTypeEnum.Transactions]: {
    sourceEntity: ENTITY_TYPES,
    sourceAccount: ACCOUNT_TYPES,
    destinationEntity: ENTITY_TYPES,
    destinationAccount: ACCOUNT_TYPES,
  },
  [DashboardTypeEnum.Filings]: {},
  [DashboardTypeEnum.CtrFilings]: {},
  [DashboardTypeEnum.AutomationExecutions]: {},
} as const

export const useCaseSubjectFieldMap = () => {
  const dashboardContextValue = useDashboardContext()
  return caseSubjectFieldsMap[dashboardContextValue]
}

export const useCaseSubjectField = () => {
  const filtersMap = useCaseSubjectFieldMap()
  return useCallback<(field: string) => LibraryEntryTypeEnum[] | null>(
    (fieldApiName) => filtersMap[fieldApiName],
    [filtersMap]
  )
}
