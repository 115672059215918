import { useCallback } from 'react'

import { fieldHasUpperBound } from 'dashboards/reviews/components/Header/filtering/FilterButton/helpers'
import { DashboardTypeEnum } from 'types/api'

import { useDashboardContext } from '../components/DashboardContextProvider'

export const searchableFiltersMap: Record<DashboardTypeEnum, Record<string, string>> = {
  [DashboardTypeEnum.Reviews]: {
    tags: 'tags',
    investigationTags: 'investigation_tags',
    triggers: 'alerts',
  },
  [DashboardTypeEnum.Profiles]: {},
  [DashboardTypeEnum.InformationRequests]: {},
  [DashboardTypeEnum.Transactions]: {},
  [DashboardTypeEnum.Filings]: {},
  [DashboardTypeEnum.CtrFilings]: {},
  [DashboardTypeEnum.AutomationExecutions]: {},
} as const

export const useSearchableFiltersMap = () => {
  const dashboardContextValue = useDashboardContext()
  return searchableFiltersMap[dashboardContextValue]
}

export const useSearchableField = (fieldApiName: string) => {
  const filtersMap = useSearchableFiltersMap()

  return filtersMap[fieldApiName]
}

export const useIsSearchableField = () => {
  const filtersMap = useSearchableFiltersMap()

  const getIsSearchable = useCallback(
    (fieldApiName: string) => !!filtersMap[fieldApiName] || fieldHasUpperBound(fieldApiName),
    [filtersMap]
  )

  return getIsSearchable
}
