import type { StrictLogicalExpression } from 'types/automations'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AutomationFiltersNode: { input: StrictLogicalExpression; output: StrictLogicalExpression; }
  Cidr: { input: any; output: any; }
  /** A date or time object */
  DateOrTime: { input: string; output: string; }
  /** A partial date, with at least a year */
  DateParts: { input: string & { __type: 'DateParts' }; output: string & { __type: 'DateParts' }; }
  Decimal: { input: string; output: string; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  /** A time of day like 13:00, 11:01:02 or 1pm */
  TimeOfDay: { input: any; output: any; }
  /** Represents untyped JSON */
  Untyped: { input: any; output: any; }
  /** A month from a specific year */
  YearMonth: { input: any; output: any; }
};

/** A support access request */
export type AccessRequest = {
  __typename?: 'AccessRequest';
  account?: Maybe<Account>;
  accountBadgeExpiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedBy?: Maybe<Account>;
  badge: Badge;
  createdAt: Scalars['ISO8601DateTime']['output'];
  desiredBy?: Maybe<Account>;
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rejectedBy?: Maybe<Account>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  requestedBy: Account;
  revokedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  revokedBy?: Maybe<Account>;
  state: AccessRequestStateEnum;
  supportReason: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** The connection type for AccessRequest. */
export type AccessRequestConnection = {
  __typename?: 'AccessRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccessRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AccessRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccessRequestEdge = {
  __typename?: 'AccessRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccessRequest>;
};

/** The state of an access request */
export enum AccessRequestStateEnum {
  /**
   * Access requests that have been approved by a HB Customer or Internal HB User.
   *           An account badge and account was created. Request is now valid and the user of the request has access
   *           to the given organization and badge permissions.
   */
  Approved = 'APPROVED',
  /**
   * Access requests that are no longer valid. Any request that had an account badge expiration
   *           date, where the date is now less that the current date has this state.
   *           Requests that were Approved or in a Needs Review state can be moved to this state.
   */
  Expired = 'EXPIRED',
  /** Access requests that have been created by an Internal HB User and need review. */
  NeedsReview = 'NEEDS_REVIEW',
  /**
   * Access requests that have been rejected by a HB Customer and are no longer valid.
   *           This means the request was never approved.
   */
  Rejected = 'REJECTED',
  /**
   * Access requests that have been revoked by a HB Customer or Internal HB User
   *           and are no longer valid.
   */
  Revoked = 'REVOKED'
}

/** A user account */
export type Account = {
  __typename?: 'Account';
  accountType: Scalars['String']['output'];
  asActor: Scalars['String']['output'];
  avatarColor: ColorNameEnum;
  avatarVariant: Scalars['Int']['output'];
  badges: Array<Badge>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  mfaEnabled: Scalars['Boolean']['output'];
  mfaMethod?: Maybe<MfaMethodEnum>;
  savedSearchRequests: SavedSearchRequestConnection;
  savedSearchRequestsCaseDashboard: SavedSearchRequestConnection;
  shortName: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};


/** A user account */
export type AccountSavedSearchRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dashboardType: DashboardTypeEnum;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A user account */
export type AccountSavedSearchRequestsCaseDashboardArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

export type ActionError = {
  __typename?: 'ActionError';
  errors: Array<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  token?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ActionParams = {
  assignReviewToQueueParams?: InputMaybe<AssignReviewToQueueParams>;
  cancelReviewParams?: InputMaybe<CancelReviewParams>;
  commentParams?: InputMaybe<CommentParams>;
  createInvestigationParams?: InputMaybe<CreateInvestigationParams>;
  createReviewParams?: InputMaybe<CreateReviewParams>;
  notificationParams?: InputMaybe<NotificationParams>;
  reviewDigestNotificationParams?: InputMaybe<ReviewDigestNotificationParams>;
  snoozeReviewParams?: InputMaybe<SnoozeReviewParams>;
  tagParams?: InputMaybe<TagParams>;
  updateReviewDueDateParams?: InputMaybe<UpdateReviewDueDateParams>;
  workflowActionReviewParams?: InputMaybe<WorkflowActionReviewParams>;
  writeOtherInfoParams?: InputMaybe<WriteOtherInfoParams>;
};

export type ActionWarning = {
  __typename?: 'ActionWarning';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  warnings: Array<Scalars['String']['output']>;
};

/** A model encapsulating an Activity Tag. */
export type ActivityTag = {
  __typename?: 'ActivityTag';
  name: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** A pairing of an Activity Tag and a FinCEN advisory. */
export type ActivityTagFincenAdvisory = {
  __typename?: 'ActivityTagFincenAdvisory';
  activityTag: ActivityTag;
  fincenAdvisory: FincenAdvisory;
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of AddAdvisoryToReview */
export type AddAdvisoryToReviewInput = {
  advisoryToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
  suggested?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of AddAdvisoryToReview. */
export type AddAdvisoryToReviewPayload = {
  __typename?: 'AddAdvisoryToReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** an address with no extra fields */
export type Address = BaseAddress & {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  administrativeDistrictLevel1?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geopoint?: Maybe<Geopoint>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  administrativeDistrictLevel1?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
};

export type AddressMetadata = {
  __typename?: 'AddressMetadata';
  active: Scalars['Boolean']['output'];
  value: Address;
};

export type AddressMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: AddressInput;
};

/** Autogenerated input type of AdjustReviewDueDate */
export type AdjustReviewDueDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New due date for review. */
  dueAt: Scalars['ISO8601DateTime']['input'];
  /** New due date for review. */
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of AdjustReviewDueDate. */
export type AdjustReviewDueDatePayload = {
  __typename?: 'AdjustReviewDueDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The review to update due date for. */
  review: Review;
};

/** Autogenerated input type of AdjustReviewTimeline */
export type AdjustReviewTimelineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New timeline start date for review. */
  timelineStartedAt: Scalars['ISO8601DateTime']['input'];
  /** Review token to update timeline for. */
  token: Scalars['String']['input'];
};

/** Autogenerated return type of AdjustReviewTimeline. */
export type AdjustReviewTimelinePayload = {
  __typename?: 'AdjustReviewTimelinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The review after timeline adjustment. */
  review: Review;
};

export type AlertData = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  rule: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  triggeredAt?: InputMaybe<Scalars['String']['input']>;
};

/** A named alert belonging to an organization. */
export type AlertRule = {
  __typename?: 'AlertRule';
  name: Scalars['String']['output'];
  suppressionRules?: Maybe<Array<AlertSuppressionRule>>;
  token: Scalars['ID']['output'];
};

/** A library object which can be the subject of an alert. */
export type AlertSubject = {
  alertSuppressionRules: Array<AlertSuppressionRule>;
  alertSuppressionRulesCount: Scalars['Int']['output'];
  organizationAlertCount: Scalars['Int']['output'];
  organizationAlerts: OrganizationAlertConnection;
};


/** A library object which can be the subject of an alert. */
export type AlertSubjectOrganizationAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A rule to suppress a named alert for a specific entity */
export type AlertSuppressionRule = {
  __typename?: 'AlertSuppressionRule';
  alertRule: AlertRule;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledBy?: Maybe<Account>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  expiresAt: Scalars['ISO8601DateTime']['output'];
  /** @deprecated Prefer using AlertSuppressionRule#state. */
  isExpired: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
  state: AlertSuppressionRuleState;
  subject: LibraryEntity;
  suppressedOrganizationAlerts?: Maybe<OrganizationAlertConnection>;
  token: Scalars['ID']['output'];
};


/** A rule to suppress a named alert for a specific entity */
export type AlertSuppressionRuleSuppressedOrganizationAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum AlertSuppressionRuleState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

/** Status and possible result of an alerts export job. */
export type AlertsExportJob = Job & {
  __typename?: 'AlertsExportJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

export type ApiCoreV2Status = {
  __typename?: 'ApiCoreV2Status';
  message?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  subStatusCode?: Maybe<Scalars['Int']['output']>;
};

/** A Hummingbird API key */
export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  whitelistedIps: Array<Scalars['Cidr']['output']>;
};

export type ApiSearchV2BusinessResultsPage = {
  __typename?: 'ApiSearchV2BusinessResultsPage';
  endIndex: Scalars['Int']['output'];
  resultGroup: Array<ApiSearchV2BusinessSearchResultsGroup>;
  startIndex: Scalars['Int']['output'];
  status: ApiCoreV2Status;
};

export type ApiSearchV2BusinessSearchResultsGroup = {
  __typename?: 'ApiSearchV2BusinessSearchResultsGroup';
  dominantValues?: Maybe<ComThomsonreutersSchemasSearchBusinessDominantValuesContainer>;
  groupId: Scalars['String']['output'];
  recordCount?: Maybe<Scalars['Int']['output']>;
  recordDetails: ComThomsonreutersSchemasSearchBusinessRecordDetailsContainer;
  relevance?: Maybe<Scalars['String']['output']>;
};

export type ApiSearchV2PersonResultsPageV3 = {
  __typename?: 'ApiSearchV2PersonResultsPageV3';
  endIndex: Scalars['Int']['output'];
  resultGroup: Array<ApiSearchV2PersonSearchResultsGroup>;
  startIndex: Scalars['Int']['output'];
  status: ApiCoreV2Status;
};

export type ApiSearchV2PersonSearchResultsGroup = {
  __typename?: 'ApiSearchV2PersonSearchResultsGroup';
  dominantValues?: Maybe<ComThomsonreutersSchemasSearchPersonDominantValuesContainer>;
  groupId: Scalars['String']['output'];
  recordCount?: Maybe<Scalars['Int']['output']>;
  recordDetails: ComThomsonreutersSchemasSearchPersonRecordDetailsContainer;
  relevance?: Maybe<Scalars['String']['output']>;
};

/** A HB app */
export type App = {
  __typename?: 'App';
  categories: Array<AppCategoryEnum>;
  slug: Scalars['String']['output'];
};

export enum AppCategoryEnum {
  Aml = 'AML',
  Blockchain = 'BLOCKCHAIN',
  Cases = 'CASES',
  Collaboration = 'COLLABORATION',
  ConsumerProtection = 'CONSUMER_PROTECTION',
  Crm = 'CRM',
  CustomerDiligence = 'CUSTOMER_DILIGENCE',
  Data = 'DATA',
  Email = 'EMAIL',
  FileManagement = 'FILE_MANAGEMENT',
  Fraud = 'FRAUD',
  Intelligence = 'INTELLIGENCE',
  KycKyb = 'KYC_KYB',
  LegalProcess = 'LEGAL_PROCESS',
  Reporting = 'REPORTING',
  Sanctions = 'SANCTIONS',
  Spreadsheets = 'SPREADSHEETS',
  TeamCommunication = 'TEAM_COMMUNICATION',
  Ticketing = 'TICKETING'
}

/** The connection type for App. */
export type AppConnection = {
  __typename?: 'AppConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<App>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppEdge = {
  __typename?: 'AppEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<App>;
};

export enum AppStatusEnum {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE',
  Requested = 'REQUESTED'
}

/** An approval assignment */
export type ApprovalAssignment = {
  __typename?: 'ApprovalAssignment';
  assignee?: Maybe<Account>;
  decision?: Maybe<Scalars['String']['output']>;
  decisionMadeAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  queue?: Maybe<Queue>;
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of ApproveAccessRequest */
export type ApproveAccessRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ApproveAccessRequest. */
export type ApproveAccessRequestPayload = {
  __typename?: 'ApproveAccessRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignReviewToQueueParams = {
  queueToken?: InputMaybe<Scalars['ID']['input']>;
  unassignAccount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A file attached to an investigation */
export type Attachment = {
  __typename?: 'Attachment';
  contentType: Scalars['String']['output'];
  dataSource?: Maybe<Scalars['String']['output']>;
  fileSizeBytes: Scalars['Int']['output'];
  filename: Scalars['String']['output'];
  investigation: Investigation;
  path?: Maybe<Scalars['String']['output']>;
  summarizable?: Maybe<Scalars['Boolean']['output']>;
  summary?: Maybe<AttachmentSummary>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uploadedAt: Scalars['ISO8601DateTime']['output'];
  uploadedBy: Account;
};

export type AttachmentAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  uploadToken?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for Attachment. */
export type AttachmentConnection = {
  __typename?: 'AttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Attachment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AttachmentEdge = {
  __typename?: 'AttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Attachment>;
};

export type AttachmentInput = {
  contentType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  uploadToken: Scalars['ID']['input'];
};

export type AttachmentSummary = {
  __typename?: 'AttachmentSummary';
  createdAt: Scalars['ISO8601DateTime']['output'];
  inProgress: Scalars['Boolean']['output'];
  statements?: Maybe<Array<Scalars['String']['output']>>;
  status: AttachmentSummaryStatus;
  token: Scalars['ID']['output'];
  tooBig: Scalars['Boolean']['output'];
  userFeedback?: Maybe<AttachmentSummaryFeedback>;
};

export type AttachmentSummaryFeedback = {
  __typename?: 'AttachmentSummaryFeedback';
  createdAt: Scalars['ISO8601DateTime']['output'];
  rating: AttachmentSummaryFeedbackRatingEnum;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum AttachmentSummaryFeedbackRatingEnum {
  Nay = 'Nay',
  Yay = 'Yay'
}

export enum AttachmentSummaryStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type AttachmentTxnEntry = {
  attributes: AttachmentAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
  owner?: InputMaybe<LibraryObjectRelation>;
};

/** Representation of authentication name type with a domain. */
export type Authentication = {
  __typename?: 'Authentication';
  authenticationType: AuthenticationNameTypeEnum;
  domain: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export enum AuthenticationNameTypeEnum {
  EmailPassword = 'EMAIL_PASSWORD',
  Oauth2 = 'OAUTH2',
  SamlSso = 'SAML_SSO',
  WorkosMagicLink = 'WORKOS_MAGIC_LINK',
  WorkosSso = 'WORKOS_SSO'
}

/** A model encapsulating an Information Request Autocomplete Recipient. */
export type AutocompleteRecipient = {
  __typename?: 'AutocompleteRecipient';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum AutomatedTaskCategory {
  Ai = 'ai',
  Apps = 'apps',
  Automations = 'automations',
  Filings = 'filings',
  Reviews = 'reviews'
}

/** Used to describe task progression over the current month */
export type AutomatedTaskCurrentCreditStats = {
  __typename?: 'AutomatedTaskCurrentCreditStats';
  creditsUsed: Scalars['Int']['output'];
  data: Array<AutomatedTaskUsageStatsDatum>;
  daysLeft: Scalars['Int']['output'];
  month: Scalars['String']['output'];
  nextMonth: Scalars['String']['output'];
  range: Scalars['String']['output'];
  totalCredit: Scalars['Int']['output'];
};

export enum AutomatedTaskStatsGranularity {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum AutomatedTaskStatsRange {
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS'
}

/** Automated task usage statistics */
export type AutomatedTaskUsageStats = {
  __typename?: 'AutomatedTaskUsageStats';
  data: Array<AutomatedTaskUsageStatsDatum>;
  timeSaved: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  totalTasks: Scalars['Int']['output'];
};

/** An individual datum in AutomatedTask usage statistics */
export type AutomatedTaskUsageStatsDatum = {
  __typename?: 'AutomatedTaskUsageStatsDatum';
  label: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  taskAttributes?: Maybe<Scalars['JSON']['output']>;
  timeSaved: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  totalTasks: Scalars['Int']['output'];
};

/** Overall automated task usage statistics */
export type AutomatedTasksUsage = {
  __typename?: 'AutomatedTasksUsage';
  categoryData: AutomatedTaskUsageStats;
  currentCreditData: AutomatedTaskCurrentCreditStats;
  dateData: AutomatedTaskUsageStats;
  performanceDataByCategory: AutomatedTaskUsageStats;
};


/** Overall automated task usage statistics */
export type AutomatedTasksUsageCategoryDataArgs = {
  range: AutomatedTaskStatsRange;
};


/** Overall automated task usage statistics */
export type AutomatedTasksUsageDateDataArgs = {
  granularity: AutomatedTaskStatsGranularity;
  range: AutomatedTaskStatsRange;
};


/** Overall automated task usage statistics */
export type AutomatedTasksUsagePerformanceDataByCategoryArgs = {
  category: AutomatedTaskCategory;
  range: AutomatedTaskStatsRange;
};

export enum AutomationActionType {
  AssignReviewToQueue = 'assign_review_to_queue',
  CancelReview = 'cancel_review',
  Comment = 'comment',
  CompleteReview = 'complete_review',
  CreateInvestigation = 'create_investigation',
  CreateReview = 'create_review',
  LockInvestigation = 'lock_investigation',
  NoOp = 'no_op',
  Notification = 'notification',
  OpenReview = 'open_review',
  ReviewDigestNotification = 'review_digest_notification',
  SnoozeReview = 'snooze_review',
  Tag = 'tag',
  UnlockInvestigation = 'unlock_investigation',
  UpdateReviewDueDate = 'update_review_due_date',
  WorkflowActionReview = 'workflow_action_review',
  WriteOtherInfo = 'write_other_info'
}

/** An automation event representing a case. */
export type AutomationCaseEvent = BaseAutomationEvent & {
  __typename?: 'AutomationCaseEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

/** An automation event representing a Datasource. */
export type AutomationDatasourceEvent = BaseAutomationEvent & {
  __typename?: 'AutomationDatasourceEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

export enum AutomationDateUnitEnum {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Months = 'months'
}

export enum AutomationDomainType {
  Business = 'business',
  Case = 'case',
  Datasource = 'datasource',
  Filing = 'filing',
  MiddeskBusiness = 'middesk_business',
  Person = 'person',
  Review = 'review'
}

export type AutomationEvent = AutomationCaseEvent | AutomationDatasourceEvent | AutomationFilingEvent | AutomationLibraryEvent | AutomationMiddeskBusinessEvent | AutomationReviewEvent;

export enum AutomationFailureNotificationSetting {
  AfterEveryFailure = 'after_every_failure',
  AfterPreviousSuccess = 'after_previous_success',
  NoNotifications = 'no_notifications'
}

/** An automation event representing a filing. */
export type AutomationFilingEvent = BaseAutomationEvent & {
  __typename?: 'AutomationFilingEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  reviewToken?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
};

/** An automation event representing a library record. */
export type AutomationLibraryEvent = BaseAutomationEvent & {
  __typename?: 'AutomationLibraryEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

/** An automation event representing a Middesk business. */
export type AutomationMiddeskBusinessEvent = BaseAutomationEvent & {
  __typename?: 'AutomationMiddeskBusinessEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

export type AutomationOtherInfoLabel = {
  __typename?: 'AutomationOtherInfoLabel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** An automation event representing a review. */
export type AutomationReviewEvent = BaseAutomationEvent & {
  __typename?: 'AutomationReviewEvent';
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

/** The action params for an automation rule. */
export type AutomationRuleActionParams = {
  __typename?: 'AutomationRuleActionParams';
  assignReviewToQueueParams?: Maybe<AutomationRuleAssignReviewToQueueParams>;
  cancelReviewParams?: Maybe<AutomationRuleCancelReviewParams>;
  commentParams?: Maybe<AutomationRuleCommentParams>;
  createInvestigationParams?: Maybe<AutomationRuleCreateInvestigationParams>;
  createReviewParams?: Maybe<AutomationRuleCreateReviewParams>;
  notificationParams?: Maybe<AutomationRuleNotificationParams>;
  reviewDigestNotificationParams?: Maybe<AutomationRuleReviewDigestNotificationParams>;
  snoozeReviewParams?: Maybe<AutomationRuleSnoozeReviewParams>;
  tagParams?: Maybe<AutomationRuleTagParams>;
  updateReviewDueDateParams?: Maybe<AutomationRuleUpdateReviewDueDateParams>;
  workflowActionReviewParams?: Maybe<AutomationWorkflowActionReviewParams>;
  writeOtherInfoParams?: Maybe<AutomationWriteOtherInfoParams>;
};

/** The assign review to queue params for an automation rule. */
export type AutomationRuleAssignReviewToQueueParams = {
  __typename?: 'AutomationRuleAssignReviewToQueueParams';
  queueToken?: Maybe<Scalars['ID']['output']>;
  unassignAccount?: Maybe<Scalars['Boolean']['output']>;
};

/** The cancel review params for an automation rule. */
export type AutomationRuleCancelReviewParams = {
  __typename?: 'AutomationRuleCancelReviewParams';
  cancellationReason: Scalars['String']['output'];
};

/** The comment params for an automation rule. */
export type AutomationRuleCommentParams = {
  __typename?: 'AutomationRuleCommentParams';
  smartValuesEnabled: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type AutomationRuleCreateInvestigationParams = {
  __typename?: 'AutomationRuleCreateInvestigationParams';
  activityTags?: Maybe<Array<Scalars['ID']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  narrative?: Maybe<Scalars['String']['output']>;
  reviewTypeCanonicalId?: Maybe<Scalars['ID']['output']>;
  smartValuesEnabled: Scalars['Boolean']['output'];
};

/** The create review params for an automation rule. */
export type AutomationRuleCreateReviewParams = {
  __typename?: 'AutomationRuleCreateReviewParams';
  actionOtherInfoLabels: Array<AutomationOtherInfoLabel>;
  activityTags?: Maybe<Array<Scalars['ID']['output']>>;
  assigneeToken?: Maybe<Scalars['ID']['output']>;
  dueDateRelativeIncrementUnit?: Maybe<AutomationDateUnitEnum>;
  dueDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
  narrative?: Maybe<Scalars['String']['output']>;
  queueToken?: Maybe<Scalars['ID']['output']>;
  reviewTypeCanonicalId?: Maybe<Scalars['ID']['output']>;
  smartValuesEnabled: Scalars['Boolean']['output'];
  transferReviewAssignee: Scalars['Boolean']['output'];
};

/** An automation rule execution. */
export type AutomationRuleExecution = {
  __typename?: 'AutomationRuleExecution';
  createdAt: Scalars['ISO8601DateTime']['output'];
  details?: Maybe<Scalars['String']['output']>;
  event?: Maybe<AutomationEvent>;
  ruleName: Scalars['String']['output'];
  status: AutomationRuleExecutionStatus;
  token: Scalars['ID']['output'];
};

/** The connection type for AutomationRuleExecution. */
export type AutomationRuleExecutionConnection = {
  __typename?: 'AutomationRuleExecutionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AutomationRuleExecutionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AutomationRuleExecution>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AutomationRuleExecutionEdge = {
  __typename?: 'AutomationRuleExecutionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AutomationRuleExecution>;
};

export enum AutomationRuleExecutionMode {
  Automatic = 'automatic',
  Preview = 'preview'
}

export enum AutomationRuleExecutionStatus {
  Completed = 'completed',
  Error = 'error',
  NotSampled = 'not_sampled',
  Pending = 'pending',
  Started = 'started'
}

/** Summarizes the rule executions for a given rule on a specific day. */
export type AutomationRuleExecutionSummaryByDay = {
  __typename?: 'AutomationRuleExecutionSummaryByDay';
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
};

/** The notification params for an automation rule. */
export type AutomationRuleNotificationParams = {
  __typename?: 'AutomationRuleNotificationParams';
  accountTokens?: Maybe<Array<Scalars['ID']['output']>>;
  badgeTokens?: Maybe<Array<Scalars['ID']['output']>>;
  body: Scalars['String']['output'];
  recipientEmails?: Maybe<Array<EmailRecipient>>;
  sendToReviewAssignee?: Maybe<Scalars['Boolean']['output']>;
  smartValuesEnabled: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
};

/** An automation rule pending execution. */
export type AutomationRulePendingExecution = {
  __typename?: 'AutomationRulePendingExecution';
  createdAt: Scalars['ISO8601DateTime']['output'];
  domainType: AutomationDomainType;
  event: AutomationEvent;
  token: Scalars['ID']['output'];
};

/** The connection type for AutomationRulePendingExecution. */
export type AutomationRulePendingExecutionConnection = {
  __typename?: 'AutomationRulePendingExecutionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AutomationRulePendingExecutionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AutomationRulePendingExecution>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AutomationRulePendingExecutionEdge = {
  __typename?: 'AutomationRulePendingExecutionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AutomationRulePendingExecution>;
};

/** Summarizes a preview run */
export type AutomationRulePreviewRun = {
  __typename?: 'AutomationRulePreviewRun';
  domainType?: Maybe<AutomationDomainType>;
  ranAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  startingAtDate: Scalars['ISO8601DateTime']['output'];
  totalEventsActionedCount: Scalars['Int']['output'];
  totalEventsMatchedCount: Scalars['Int']['output'];
};

/** Summarizes the rules recent activity */
export type AutomationRuleRecentActivity = {
  __typename?: 'AutomationRuleRecentActivity';
  executionsByDay: Array<AutomationRuleExecutionSummaryByDay>;
};

export type AutomationRuleReviewDigestNotificationParams = {
  __typename?: 'AutomationRuleReviewDigestNotificationParams';
  accountTokens?: Maybe<Array<Scalars['ID']['output']>>;
  badgeTokens?: Maybe<Array<Scalars['ID']['output']>>;
  recipientEmails?: Maybe<Array<EmailRecipient>>;
};

/** The snooze review params for an automation rule */
export type AutomationRuleSnoozeReviewParams = {
  __typename?: 'AutomationRuleSnoozeReviewParams';
  description?: Maybe<Scalars['String']['output']>;
  snoozeTimingPolicy?: Maybe<SnoozeTimingPolicyEnum>;
  snoozeUntilDateRelativeIncrementUnit?: Maybe<AutomationDateUnitEnum>;
  snoozeUntilDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
};

/** The tag params for an automation rule. */
export type AutomationRuleTagParams = {
  __typename?: 'AutomationRuleTagParams';
  tagDefinitionToken?: Maybe<Scalars['ID']['output']>;
};

/** An automation rule template. */
export type AutomationRuleTemplate = EmptyRuleTemplate | ScheduledRuleTemplate | ScheduledWithTriggerRuleTemplate | TriggeredRuleTemplate;

/** The action params for an automation rule. */
export type AutomationRuleTemplateActionParams = {
  __typename?: 'AutomationRuleTemplateActionParams';
  assignReviewToQueueParams?: Maybe<AutomationRuleTemplateAssignReviewToQueueParams>;
  cancelReviewParams?: Maybe<AutomationRuleTemplateCancelReviewParams>;
  commentParams?: Maybe<AutomationRuleTemplateCommentParams>;
  createInvestigationParams?: Maybe<AutomationRuleTemplateCreateInvestigationParams>;
  createReviewParams?: Maybe<AutomationRuleTemplateCreateReviewParams>;
  notificationParams?: Maybe<AutomationRuleTemplateNotificationParams>;
  reviewDigestNotificationParams?: Maybe<AutomationRuleTemplateReviewDigestNotificationParams>;
  snoozeReviewParams?: Maybe<AutomationRuleTemplateSnoozeReviewParams>;
  tagParams?: Maybe<AutomationRuleTemplateTagParams>;
  updateReviewDueDateParams?: Maybe<AutomationRuleTemplateUpdateReviewDueDateParams>;
  writeOtherInfoParams?: Maybe<AutomationRuleTemplateWriteOtherInfoParams>;
};

/** The assign review to queue params for an automation rule. */
export type AutomationRuleTemplateAssignReviewToQueueParams = {
  __typename?: 'AutomationRuleTemplateAssignReviewToQueueParams';
  queueToken?: Maybe<Scalars['ID']['output']>;
  unassignAccount?: Maybe<Scalars['Boolean']['output']>;
};

/** A base automation rule template */
export type AutomationRuleTemplateBase = {
  actionParams?: Maybe<AutomationRuleTemplateActionParams>;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  automationTemplateCategory?: Maybe<AutomationRuleTemplateCategory>;
  automationType?: Maybe<AutomationRuleType>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  humanReadableSchedule?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  setup?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  triggerDescription?: Maybe<Scalars['String']['output']>;
  triggerName?: Maybe<Scalars['String']['output']>;
};

/** The cancel review params for an automation rule. */
export type AutomationRuleTemplateCancelReviewParams = {
  __typename?: 'AutomationRuleTemplateCancelReviewParams';
  cancellationReason: Scalars['String']['output'];
};

/** An automation template category. */
export type AutomationRuleTemplateCategory = {
  __typename?: 'AutomationRuleTemplateCategory';
  name: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  style?: Maybe<AutomationTemplateCategoryStyle>;
  token: Scalars['ID']['output'];
};

/** The comment params for an automation rule. */
export type AutomationRuleTemplateCommentParams = {
  __typename?: 'AutomationRuleTemplateCommentParams';
  smartValuesEnabled: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export type AutomationRuleTemplateCreateInvestigationParams = {
  __typename?: 'AutomationRuleTemplateCreateInvestigationParams';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  narrative?: Maybe<Scalars['String']['output']>;
  reviewTypeCanonicalId?: Maybe<Scalars['ID']['output']>;
  smartValuesEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** The create review params for an automation rule. */
export type AutomationRuleTemplateCreateReviewParams = {
  __typename?: 'AutomationRuleTemplateCreateReviewParams';
  actionOtherInfoLabels: Array<AutomationRuleTemplateOtherInfoLabel>;
  assigneeToken?: Maybe<Scalars['ID']['output']>;
  dueDateRelativeIncrementUnit?: Maybe<AutomationRuleTemplateDateUnitEnum>;
  dueDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
  narrative?: Maybe<Scalars['String']['output']>;
  queueToken?: Maybe<Scalars['ID']['output']>;
  smartValuesEnabled: Scalars['Boolean']['output'];
};

export enum AutomationRuleTemplateDateUnitEnum {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Months = 'months'
}

/** The notification params for an automation rule. */
export type AutomationRuleTemplateNotificationParams = {
  __typename?: 'AutomationRuleTemplateNotificationParams';
  body: Scalars['String']['output'];
  sendToReviewAssignee?: Maybe<Scalars['Boolean']['output']>;
  smartValuesEnabled: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
};

export type AutomationRuleTemplateOtherInfoLabel = {
  __typename?: 'AutomationRuleTemplateOtherInfoLabel';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AutomationRuleTemplateReviewDigestNotificationParams = {
  __typename?: 'AutomationRuleTemplateReviewDigestNotificationParams';
  smartValuesEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** The snooze review params for an automation rule */
export type AutomationRuleTemplateSnoozeReviewParams = {
  __typename?: 'AutomationRuleTemplateSnoozeReviewParams';
  description?: Maybe<Scalars['String']['output']>;
  snoozeTimingPolicy?: Maybe<SnoozeTimingPolicyEnum>;
  snoozeUntilDateRelativeIncrementUnit?: Maybe<AutomationRuleTemplateDateUnitEnum>;
  snoozeUntilDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
};

/** The tag params for an automation rule. */
export type AutomationRuleTemplateTagParams = {
  __typename?: 'AutomationRuleTemplateTagParams';
  tagDefinitionToken?: Maybe<Scalars['ID']['output']>;
};

/** The update review due date params for an automation rule */
export type AutomationRuleTemplateUpdateReviewDueDateParams = {
  __typename?: 'AutomationRuleTemplateUpdateReviewDueDateParams';
  relativeToCreatedDate?: Maybe<Scalars['Boolean']['output']>;
  relativeToDueDate?: Maybe<Scalars['Boolean']['output']>;
  updateToDateRelativeIncrementUnit?: Maybe<AutomationRuleTemplateDateUnitEnum>;
  updateToDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
};

/** The update other info params for an automation rule */
export type AutomationRuleTemplateWriteOtherInfoParams = {
  __typename?: 'AutomationRuleTemplateWriteOtherInfoParams';
  actionOtherInfoLabels: Array<AutomationRuleTemplateOtherInfoLabel>;
  overwrite: Scalars['Boolean']['output'];
};

/** An automation rule trigger. */
export type AutomationRuleTrigger = {
  __typename?: 'AutomationRuleTrigger';
  datasource?: Maybe<Datasource>;
  description?: Maybe<Scalars['String']['output']>;
  domainType: AutomationDomainType;
  triggerFilters?: Maybe<Scalars['AutomationFiltersNode']['output']>;
};

/** The result of an automation rule trigger. */
export type AutomationRuleTriggerResult = {
  __typename?: 'AutomationRuleTriggerResult';
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: AutomationEvent;
  eventableToken: Scalars['ID']['output'];
  result: Scalars['Boolean']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for AutomationRuleTriggerResult. */
export type AutomationRuleTriggerResultConnection = {
  __typename?: 'AutomationRuleTriggerResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AutomationRuleTriggerResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AutomationRuleTriggerResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AutomationRuleTriggerResultEdge = {
  __typename?: 'AutomationRuleTriggerResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AutomationRuleTriggerResult>;
};

/** An automation trigger template. */
export type AutomationRuleTriggerTemplate = {
  __typename?: 'AutomationRuleTriggerTemplate';
  description: Scalars['String']['output'];
  domainType: AutomationDomainType;
  name: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  snapshotFilters?: Maybe<Scalars['AutomationFiltersNode']['output']>;
  token: Scalars['ID']['output'];
};

export enum AutomationRuleType {
  Schedule = 'schedule',
  ScheduleWithTrigger = 'schedule_with_trigger',
  Trigger = 'trigger'
}

/** Summarizes the rules upcoming run */
export type AutomationRuleUpcomingRunSummary = {
  __typename?: 'AutomationRuleUpcomingRunSummary';
  nextRunDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  totalEventsMatchedCount: Scalars['Int']['output'];
};

/** The update review due date params for an automation rule */
export type AutomationRuleUpdateReviewDueDateParams = {
  __typename?: 'AutomationRuleUpdateReviewDueDateParams';
  relativeToCreatedDate?: Maybe<Scalars['Boolean']['output']>;
  relativeToDueDate?: Maybe<Scalars['Boolean']['output']>;
  updateToDateRelativeIncrementUnit?: Maybe<AutomationDateUnitEnum>;
  updateToDateRelativeIncrementValue?: Maybe<Scalars['Int']['output']>;
};

export enum AutomationStatsGranularity {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum AutomationStatsRange {
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS'
}

export enum AutomationStatsTaxonomy {
  Actions = 'ACTIONS',
  Domains = 'DOMAINS'
}

export enum AutomationTemplateCategoryStyle {
  Default = 'default',
  Digest = 'digest',
  Diligence = 'diligence',
  Management = 'management',
  Notification = 'notification',
  Preparation = 'preparation',
  Quality = 'quality'
}

/** Automation usage statistics */
export type AutomationUsageStats = {
  __typename?: 'AutomationUsageStats';
  data: Array<AutomationUsageStatsDatum>;
  sum: Scalars['Int']['output'];
};

/** An individual datum in automation usage statistics */
export type AutomationUsageStatsDatum = {
  __typename?: 'AutomationUsageStatsDatum';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

/** The workflow action review params for an automation rule */
export type AutomationWorkflowActionReviewParams = {
  __typename?: 'AutomationWorkflowActionReviewParams';
  actionSlug?: Maybe<Scalars['ID']['output']>;
  canonicalReviewTypeId?: Maybe<Scalars['ID']['output']>;
  choiceKey?: Maybe<Scalars['ID']['output']>;
};

/** The update other info params for an automation rule */
export type AutomationWriteOtherInfoParams = {
  __typename?: 'AutomationWriteOtherInfoParams';
  actionOtherInfoLabels: Array<AutomationOtherInfoLabel>;
  overwrite: Scalars['Boolean']['output'];
};

/** A bundle of permissions. */
export type Badge = {
  __typename?: 'Badge';
  accounts: AccountConnection;
  color: ColorNameEnum;
  description?: Maybe<Scalars['String']['output']>;
  /** The human-readable name of this badge. */
  displayName: Scalars['String']['output'];
  /** The id of this badge, for machine consumption. */
  name: Scalars['String']['output'];
  organization: Organization;
  permissions: Array<Permission>;
  queue?: Maybe<Queue>;
  /** Indicates if the badge is editable by a user */
  restricted: Scalars['Boolean']['output'];
  /** Configurations for automatic assignment of reviews to holders of this badge. */
  reviewTypeAssignmentSettings: Array<ReviewTypeAssignmentSettings>;
  token: Scalars['ID']['output'];
  /** Reviews of these types can be viewed in addition to other permissions. */
  viewableReviewTypes: Array<CanonicalReview>;
};


/** A bundle of permissions. */
export type BadgeAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Badge. */
export type BadgeConnection = {
  __typename?: 'BadgeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BadgeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Badge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BadgeEdge = {
  __typename?: 'BadgeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Badge>;
};

/** Custom parameters for badge display name, color, description, viewable review types, and permissions */
export type BadgeInput = {
  color?: InputMaybe<ColorNameEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<BadgePermissionsEnum>>;
  queue?: InputMaybe<Scalars['Boolean']['input']>;
  viewableReviewTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum BadgePermissionsEnum {
  /**
   * Create, edit and view automations for all organizations as an internal
   * Hummingbird user. THIS IS A HIGHLY SENSITIVE PERMISSION AND ANY QUESTIONS
   * SHOULD BE DIRECTED TO THE LOGIC TEAM. Feature Flag Required:/ Enable
   * Organization Automations.
   */
  AdminManageAutomations = 'ADMIN_MANAGE_AUTOMATIONS',
  /**
   * Allows a user to view/request an app and edit settings for installed apps.
   * Feature Flag Required:/ Enable Information Requests.
   */
  AppLauncherAllAccess = 'APP_LAUNCHER_ALL_ACCESS',
  /** Allows the user to mark requests cancelled. */
  CancelInformationRequests = 'CANCEL_INFORMATION_REQUESTS',
  /** Allows the user to mark requests completed. Feature Flag Required:/ Enable Information Requests. */
  CompleteInformationRequests = 'COMPLETE_INFORMATION_REQUESTS',
  /** Create user accounts. */
  CreateAccounts = 'CREATE_ACCOUNTS',
  /** Create API keys. */
  CreateApiKeys = 'CREATE_API_KEYS',
  /** Create CTR filing (NOT LIVE YET). */
  CreateCtrFiling = 'CREATE_CTR_FILING',
  /** Create financial institutions. Does not affect imports. */
  CreateFinancialInstitutions = 'CREATE_FINANCIAL_INSTITUTIONS',
  /** Allows a user to create a new request. Feature Flag Required:/ Enable Information Requests. */
  CreateInformationRequests = 'CREATE_INFORMATION_REQUESTS',
  /** Create notification subscriptions. */
  CreateNotificationSubscription = 'CREATE_NOTIFICATION_SUBSCRIPTION',
  /** Create SFTP public keys. */
  CreatePublicKey = 'CREATE_PUBLIC_KEY',
  /** Allows a user to create a Library Relationship Definition entry. Feature Flag Required:/ Enable CRM Relationships */
  CreateRelationshipDefinitions = 'CREATE_RELATIONSHIP_DEFINITIONS',
  /** Create SAR filing. */
  CreateSarFiling = 'CREATE_SAR_FILING',
  /** Create STR filing. */
  CreateStrFiling = 'CREATE_STR_FILING',
  /** Create tag definitions. */
  CreateTagDefinitions = 'CREATE_TAG_DEFINITIONS',
  /** Can delete some or all transactions in a case. */
  DeleteAllTransactions = 'DELETE_ALL_TRANSACTIONS',
  /** Delete SFTP public keys. */
  DeletePublicKey = 'DELETE_PUBLIC_KEY',
  /** Unassign users from a review. */
  DeleteReviewAssignee = 'DELETE_REVIEW_ASSIGNEE',
  /** Unassign queues from a review. */
  DeleteReviewQueue = 'DELETE_REVIEW_QUEUE',
  /** Destroy user accounts. */
  DestroyAccounts = 'DESTROY_ACCOUNTS',
  /** Destroy narrative templates. */
  DestroyTemplate = 'DESTROY_TEMPLATE',
  /** Can download case documents. */
  DownloadCaseDocuments = 'DOWNLOAD_CASE_DOCUMENTS',
  /** Edit all badges and permissions. */
  EditBadges = 'EDIT_BADGES',
  /** Can edit, create, and delete case alerts. */
  EditCaseAlerts = 'EDIT_CASE_ALERTS',
  /** Can edit case other information. */
  EditCaseOtherInfo = 'EDIT_CASE_OTHER_INFO',
  /** Can edit Review types. */
  EditReviewTypes = 'EDIT_REVIEW_TYPES',
  /** Create, update, and delete Thomson Reuters CLEAR credentials. */
  EditThomsonReutersClearCredentials = 'EDIT_THOMSON_REUTERS_CLEAR_CREDENTIALS',
  /** Download an export of all cases within a timeframe. */
  ExportCases = 'EXPORT_CASES',
  /** Use AI to generate narratives. */
  GenerateAiNarratives = 'GENERATE_AI_NARRATIVES',
  /** Lock case information. */
  LockCaseInformation = 'LOCK_CASE_INFORMATION',
  /** Read all analytics charts. */
  ManageAnalytics = 'MANAGE_ANALYTICS',
  /** Create, update, and delete an automation rule. Feature Flag Required:/ Enable Organization Automations. */
  ManageAutomations = 'MANAGE_AUTOMATIONS',
  /** Manage Chainalysis integration. */
  ManageChainalysis = 'MANAGE_CHAINALYSIS',
  /** Manage directory sync. */
  ManageDirectorySync = 'MANAGE_DIRECTORY_SYNC',
  /** Create, update, and delete Elliptic credentials. */
  ManageEllipticCredentials = 'MANAGE_ELLIPTIC_CREDENTIALS',
  /** Create, update, and delete Middesk credentials. */
  ManageMiddeskCredentials = 'MANAGE_MIDDESK_CREDENTIALS',
  /** Create, update, and delete Sigma credentials. */
  ManageSigmaCredentials = 'MANAGE_SIGMA_CREDENTIALS',
  /** Allows a user to approve, reject and revoke a support access request. */
  ManageSupportAccess = 'MANAGE_SUPPORT_ACCESS',
  /** Manage webhook endpoints. */
  ManageWebhooks = 'MANAGE_WEBHOOKS',
  /** Enable a user to merge cases. */
  MergeCases = 'MERGE_CASES',
  /** Enable a user to merge CRM (Library) subjects. */
  MergeLibrarySubjects = 'MERGE_LIBRARY_SUBJECTS',
  /** Modify (update or delete) library relationship definitions. */
  ModifyRelationshipDefinitions = 'MODIFY_RELATIONSHIP_DEFINITIONS',
  /** Modify tag definitions. */
  ModifyTagDefinitions = 'MODIFY_TAG_DEFINITIONS',
  /** Read all organization alerts. */
  ReadAllAlerts = 'READ_ALL_ALERTS',
  /** Read the audit logs. */
  ReadAllAuditLogs = 'READ_ALL_AUDIT_LOGS',
  /** Read the organization imports. */
  ReadAllImports = 'READ_ALL_IMPORTS',
  /** Allows a user to view the request dashboard. */
  ReadAllInformationRequests = 'READ_ALL_INFORMATION_REQUESTS',
  /** Read reviews that are cancelled, or cases where all reviews are cancelled. */
  ReadCancelledCases = 'READ_CANCELLED_CASES',
  /** Allows a user to view the filings dashboard. Feature Flag Required:/ Enable Filing Reporting Dashboard. */
  ReadFilingsDashboard = 'READ_FILINGS_DASHBOARD',
  /** Internal administrative panel (This should not be enabled for customers). */
  ReadHummingbirdAdmin = 'READ_HUMMINGBIRD_ADMIN',
  /**
   * Read all organization cases where not all reviews are cancelled, and reviews
   * that are not cancelled. (If this permission and ‘Read User Cases’ are not
   * selected, the system will default this permission to on.)
   */
  ReadNotCancelledCases = 'READ_NOT_CANCELLED_CASES',
  /** Read SFTP public keys. */
  ReadOrganizationPublicKeys = 'READ_ORGANIZATION_PUBLIC_KEYS',
  /** Allows a user to view the profile dashboard. Feature Flag Required:/ Enable Read Profile Dashboard Permission. */
  ReadProfileDashboard = 'READ_PROFILE_DASHBOARD',
  /** Internal sidekiq administrative panel (This should not be enabled for customers). */
  ReadSidekiq = 'READ_SIDEKIQ',
  /**
   * Read cases that are assigned to the user. (If this permission and ‘Read All
   * Cases’ are not selected, ‘Read All Cases’ permission is defaulted to on.)
   */
  ReadUserCases = 'READ_USER_CASES',
  /** Reset organization for product demos. */
  ResetDemo = 'RESET_DEMO',
  /** Search CRM Subjects. */
  SearchLibrarySubjects = 'SEARCH_LIBRARY_SUBJECTS',
  /** Internal use only. */
  TestPermissionDoNotUse = 'TEST_PERMISSION_DO_NOT_USE',
  /** Unlock case information. */
  UnlockCaseInformation = 'UNLOCK_CASE_INFORMATION',
  /** Cancel an accidental review. */
  UpdateCancelReview = 'UPDATE_CANCEL_REVIEW',
  /** Mark reviews completed. */
  UpdateCompleteReview = 'UPDATE_COMPLETE_REVIEW',
  /** Update the due date of a review. */
  UpdateDueDate = 'UPDATE_DUE_DATE',
  /** Change review internal control number. */
  UpdateInternalControlNumber = 'UPDATE_INTERNAL_CONTROL_NUMBER',
  /** Open a pending review early. */
  UpdateOpenReviewNow = 'UPDATE_OPEN_REVIEW_NOW',
  /** Update organization configuration. */
  UpdateOrganization = 'UPDATE_ORGANIZATION',
  /** Assign or reassign users to a review. */
  UpdateReviewAssignee = 'UPDATE_REVIEW_ASSIGNEE',
  /** Assign or reassign queues to a review. */
  UpdateReviewQueue = 'UPDATE_REVIEW_QUEUE',
  /** Snooze an open review. */
  UpdateSnoozeReview = 'UPDATE_SNOOZE_REVIEW',
  /** Update narrative templates. */
  UpdateTemplate = 'UPDATE_TEMPLATE',
  /** Re-open reviews. */
  UpdateUncompleteReview = 'UPDATE_UNCOMPLETE_REVIEW',
  /** Run Bulk table decryption for a given organization. */
  UseBulkTableDecryption = 'USE_BULK_TABLE_DECRYPTION',
  /** Run Middesk business searches. */
  UseMiddesk = 'USE_MIDDESK',
  /** Run Thomson Reuters CLEAR person/business searches and create reports. */
  UseThomsonReutersClear = 'USE_THOMSON_REUTERS_CLEAR',
  /** Use AI to validate narratives. */
  ValidateAiNarratives = 'VALIDATE_AI_NARRATIVES',
  /** View all automation rules. Feature Flag Required:/ Enable Organization Automations. */
  ViewAutomations = 'VIEW_AUTOMATIONS',
  /** View all badges and permissions. */
  ViewBadges = 'VIEW_BADGES',
  /** View all Review types. */
  ViewReviewTypes = 'VIEW_REVIEW_TYPES',
  /** Allow user to write data (removing makes user read-only). */
  WriteAny = 'WRITE_ANY'
}

export type BankAccountAttributes = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  classification?: InputMaybe<BankAccountClassificationAttributes>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  closedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  countryAndState?: InputMaybe<IsoCountryInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  foreign?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  openedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type BankAccountBalanceAttributes = {
  amount?: InputMaybe<HbMoneyInput>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  recordedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
};

export type BankAccountBalanceTxnEntry = {
  attributes: BankAccountBalanceAttributes;
  bankAccount?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type BankAccountClassification = {
  __typename?: 'BankAccountClassification';
  subtype?: Maybe<Scalars['String']['output']>;
  subtypeOther?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type BankAccountClassificationAttributes = {
  subtype?: InputMaybe<Scalars['String']['input']>;
  subtypeOther?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export enum BankAccountSignatoryRoleEnum {
  Joint = 'JOINT',
  NonAccountHolder = 'NON_ACCOUNT_HOLDER',
  Primary = 'PRIMARY',
  Unknown = 'UNKNOWN'
}

export type BankAccountTxnEntry = {
  accountHolder?: InputMaybe<LibraryObjectRelation>;
  attributes: BankAccountAttributes;
  institution?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

/** interface for implementing common fields on an address */
export type BaseAddress = {
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  administrativeDistrictLevel1?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geopoint?: Maybe<Geopoint>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
};

export type BaseAutomationEvent = {
  domainType: AutomationDomainType;
  eventableHref: Scalars['String']['output'];
  eventableToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

/** Custom parameters for review batch actions */
export type BatchActionParameters = {
  assigneeToken?: InputMaybe<Scalars['String']['input']>;
  caseName?: InputMaybe<Scalars['String']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unassignStrategy?: InputMaybe<UnassignStrategyEnum>;
};

export type BatchActionStatus = {
  __typename?: 'BatchActionStatus';
  complete: Scalars['Boolean']['output'];
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy?: Maybe<Account>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  failures: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  pending: Scalars['Int']['output'];
  sidekiqBid: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
};

/** Types of batch actions that can be taken from the dashboard */
export enum BatchActionTypeEnum {
  /** Assigns reviews to specific user. */
  Assign = 'assign',
  /** Cancels reviews. */
  Cancel = 'cancel',
  /** Marks reviews as completed. */
  Complete = 'complete',
  /** Deletes transactions. */
  Delete = 'delete',
  /** Marks transactions as flagged. */
  Flag = 'flag',
  /** Merges reviews. */
  Merge = 'merge',
  /** Merges library profiles. */
  MergeLibraryProfiles = 'merge_library_profiles',
  /** Removes assignee from reviews. */
  Unassign = 'unassign',
  /** Marks reviews as uncompleted. */
  Uncomplete = 'uncomplete',
  /** Removes flag from transactions. */
  Unflag = 'unflag'
}

/** Autogenerated input type of BatchUpsertLibraryLinks */
export type BatchUpsertLibraryLinksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  informationRequestToken: Scalars['ID']['input'];
  libraryLinkInputs: Array<InformationRequestLibraryLinkInput>;
};

/** Autogenerated return type of BatchUpsertLibraryLinks. */
export type BatchUpsertLibraryLinksPayload = {
  __typename?: 'BatchUpsertLibraryLinksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  informationRequest: InformationRequest;
};

/** Autogenerated input type of BatchUpsertRecipients */
export type BatchUpsertRecipientsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  informationRequestToken: Scalars['ID']['input'];
  recipientInputs: Array<InformationRequestRecipientInput>;
};

/** Autogenerated return type of BatchUpsertRecipients. */
export type BatchUpsertRecipientsPayload = {
  __typename?: 'BatchUpsertRecipientsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  informationRequestRecipients?: Maybe<Array<InformationRequestRecipient>>;
};

export type BirthdateMetadata = {
  __typename?: 'BirthdateMetadata';
  active: Scalars['Boolean']['output'];
  value: Scalars['DateParts']['output'];
};

export type BirthdateMetadataInput = {
  active: Scalars['Boolean']['input'];
  value: Scalars['DateParts']['input'];
};

export type BlankAutomationRule = CanonicalAutomationRuleBase & {
  __typename?: 'BlankAutomationRule';
  actionParams: AutomationRuleActionParams;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  executionMode: AutomationRuleExecutionMode;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  latestExecution?: Maybe<AutomationRuleExecution>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationToken: Scalars['ID']['output'];
  recentActivity: AutomationRuleRecentActivity;
  ruleExecutions: AutomationRuleExecutionConnection;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  token: Scalars['ID']['output'];
  unsampledCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usageCount: Scalars['Int']['output'];
  usagePercentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};


export type BlankAutomationRuleRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlockchainTypeEnum {
  Bitcoin = 'BITCOIN',
  BitcoinCash = 'BITCOIN_CASH',
  Cardano = 'CARDANO',
  Eos = 'EOS',
  Ethereum = 'ETHEREUM',
  Litecoin = 'LITECOIN',
  Monero = 'MONERO',
  Other = 'OTHER',
  Ripple = 'RIPPLE',
  Unknown = 'UNKNOWN'
}

export type Browser = {
  __typename?: 'Browser';
  type: Scalars['String']['output'];
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type BrowserAttributes = {
  type: Scalars['String']['input'];
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export type Business = {
  __typename?: 'Business';
  label?: Maybe<Scalars['String']['output']>;
  naicsCode?: Maybe<Scalars['String']['output']>;
};

/** an address with types specific to businesses */
export type BusinessAddress = BaseAddress & {
  __typename?: 'BusinessAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressType?: Maybe<BusinessAddressTypeEnum>;
  addressTypeOther?: Maybe<Scalars['String']['output']>;
  administrativeDistrictLevel1?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geopoint?: Maybe<Geopoint>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
};

export type BusinessAddressAttributes = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<BusinessAddressTypeEnum>;
  addressTypeOther?: InputMaybe<Scalars['String']['input']>;
  administrativeDistrictLevel1?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
};

export enum BusinessAddressTypeEnum {
  Branch = 'BRANCH',
  Home = 'HOME',
  MailingAddress = 'MAILING_ADDRESS',
  Office = 'OFFICE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  UsBillingAddress = 'US_BILLING_ADDRESS',
  UsShippingAddress = 'US_SHIPPING_ADDRESS',
  Work = 'WORK'
}

export type BusinessAttributes = {
  addresses?: InputMaybe<Array<BusinessAddressAttributes>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  businessType?: InputMaybe<BusinessTypeAttributes>;
  businessTypes?: InputMaybe<Array<BusinessTypeMetadataAttributes>>;
  dbaNames?: InputMaybe<Array<Scalars['String']['input']>>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  identityDocuments?: InputMaybe<Array<IdentityDocumentAttributes>>;
  legalNames?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNamesMetadata?: InputMaybe<Array<LegalNameMetadataAttributes>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberAttributes>>;
  tins?: InputMaybe<Array<TinAttributes>>;
  tinsMetadata?: InputMaybe<Array<TinsMetadataAttributes>>;
  usernames?: InputMaybe<Array<Scalars['String']['input']>>;
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BusinessOwnerAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipPercent?: InputMaybe<Scalars['Decimal']['input']>;
};

export type BusinessOwnerTxnEntry = {
  attributes: BusinessOwnerAttributes;
  business?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
  owner?: InputMaybe<LibraryObjectRelation>;
};

export type BusinessTxnEntry = {
  attributes: BusinessAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type BusinessTypeAttributes = {
  label?: InputMaybe<Scalars['String']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessTypeMetadata = {
  __typename?: 'BusinessTypeMetadata';
  active: Scalars['Boolean']['output'];
  value: Business;
};

export type BusinessTypeMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: BusinessTypeAttributes;
};

/** Autogenerated input type of CancelAlertSuppressionRule */
export type CancelAlertSuppressionRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of CancelAlertSuppressionRule. */
export type CancelAlertSuppressionRulePayload = {
  __typename?: 'CancelAlertSuppressionRulePayload';
  alertSuppressionRule: AlertSuppressionRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CancelCaseReview */
export type CancelCaseReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of CancelCaseReview. */
export type CancelCaseReviewPayload = {
  __typename?: 'CancelCaseReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Autogenerated input type of CancelInformationRequest */
export type CancelInformationRequestInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  informationRequestToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelInformationRequest. */
export type CancelInformationRequestPayload = {
  __typename?: 'CancelInformationRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  informationRequest?: Maybe<InformationRequest>;
};

export type CancelReviewParams = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
};

/** An automation rule. */
export type CanonicalAutomationRule = BlankAutomationRule | ScheduleAutomationRule | ScheduleWithTriggerAutomationRule | TriggerAutomationRule;

/** An automation rule with fields applicable to all automation rule types */
export type CanonicalAutomationRuleBase = {
  actionParams: AutomationRuleActionParams;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  executionMode: AutomationRuleExecutionMode;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  latestExecution?: Maybe<AutomationRuleExecution>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationToken: Scalars['ID']['output'];
  recentActivity: AutomationRuleRecentActivity;
  ruleExecutions: AutomationRuleExecutionConnection;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  token: Scalars['ID']['output'];
  unsampledCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usageCount: Scalars['Int']['output'];
  usagePercentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};


/** An automation rule with fields applicable to all automation rule types */
export type CanonicalAutomationRuleBaseRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CanonicalAutomationRule. */
export type CanonicalAutomationRuleConnection = {
  __typename?: 'CanonicalAutomationRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CanonicalAutomationRuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CanonicalAutomationRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CanonicalAutomationRuleEdge = {
  __typename?: 'CanonicalAutomationRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CanonicalAutomationRule>;
};

export type CanonicalReview = {
  __typename?: 'CanonicalReview';
  badges?: Maybe<Array<Badge>>;
  billingType?: Maybe<ReviewTypeBillingTypeEnum>;
  canonicalId: Scalars['ID']['output'];
  currentReviewType: TypeOfReview;
  /** An HTML hex color. */
  displayColor: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedBy?: Maybe<Account>;
};

/** The connection type for CanonicalReview. */
export type CanonicalReviewConnection = {
  __typename?: 'CanonicalReviewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CanonicalReviewEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CanonicalReview>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CanonicalReviewEdge = {
  __typename?: 'CanonicalReviewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CanonicalReview>;
};

export type CaseAlert = {
  __typename?: 'CaseAlert';
  amount?: Maybe<HbMoney>;
  comments?: Maybe<Scalars['String']['output']>;
  dateRange?: Maybe<Array<TimeWithParts>>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  links: Array<WebLink>;
  otherInfo: Scalars['JSON']['output'];
  recentTransactions?: Maybe<Array<Transaction>>;
  review?: Maybe<Review>;
  rule?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
  transactions?: Maybe<Array<Transaction>>;
  transactionsCount: Scalars['Int']['output'];
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for CaseAlert. */
export type CaseAlertConnection = {
  __typename?: 'CaseAlertConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CaseAlertEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CaseAlert>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CaseAlertEdge = {
  __typename?: 'CaseAlertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CaseAlert>;
};

export type CaseAlertGroup = {
  __typename?: 'CaseAlertGroup';
  alerts: CaseAlertConnection;
  comments?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  otherInfo: Scalars['JSON']['output'];
  rule?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};


export type CaseAlertGroupAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CaseAlertGroup. */
export type CaseAlertGroupConnection = {
  __typename?: 'CaseAlertGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CaseAlertGroupEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CaseAlertGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CaseAlertGroupEdge = {
  __typename?: 'CaseAlertGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CaseAlertGroup>;
};

export type CaseImport = {
  __typename?: 'CaseImport';
  category?: Maybe<CaseImportCategory>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Path from which the raw import file can be retrieved. */
  downloadPath?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  importedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  importer: Account;
  state: CaseImportState;
  token: Scalars['ID']['output'];
};

/** Generated from TextParser::Constants::ALL_CATEGORIES */
export enum CaseImportCategory {
  AlertImport = 'ALERT_IMPORT',
  BankAccounts = 'BANK_ACCOUNTS',
  Businesses = 'BUSINESSES',
  CaseImport = 'CASE_IMPORT',
  CryptoAddresses = 'CRYPTO_ADDRESSES',
  CustomImports = 'CUSTOM_IMPORTS',
  Devices = 'DEVICES',
  Individuals = 'INDIVIDUALS',
  Institutions = 'INSTITUTIONS',
  LibraryData = 'LIBRARY_DATA',
  PaymentCards = 'PAYMENT_CARDS',
  Products = 'PRODUCTS',
  Transactions = 'TRANSACTIONS'
}

/** The connection type for CaseImport. */
export type CaseImportConnection = {
  __typename?: 'CaseImportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CaseImportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CaseImport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CaseImportEdge = {
  __typename?: 'CaseImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CaseImport>;
};

export enum CaseImportState {
  Failure = 'FAILURE',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

/** The position of Chainalysis integration through the alerts */
export type ChainalysisAlertsCursor = {
  __typename?: 'ChainalysisAlertsCursor';
  lastAlertCreatedAt: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The configuration for the Chainalysis integration. */
export type ChainalysisConfiguration = {
  __typename?: 'ChainalysisConfiguration';
  alertsCursor?: Maybe<ChainalysisAlertsCursor>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  groupAlertsBy: ChainalysisGroupByTypeEnum;
  groupingDenyListUserIds?: Maybe<Array<Scalars['String']['output']>>;
  processAlertsCreatedAfter: Scalars['ISO8601DateTime']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ChainalysisGroupByTypeEnum {
  Default = 'default',
  NoGrouping = 'no_grouping',
  WalletId = 'wallet_id'
}

export type Classification = {
  __typename?: 'Classification';
  subtype?: Maybe<Scalars['String']['output']>;
  subtypeOther?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type ClassificationAttributes = {
  subtype?: InputMaybe<FinancialInstitutionSubtypeEnum>;
  subtypeOther?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FinancialInstitutionTypeEnum>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export type ClassificationMetadata = {
  __typename?: 'ClassificationMetadata';
  active: Scalars['Boolean']['output'];
  value: Classification;
};

export type ClassificationMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: ClassificationAttributes;
};

export enum ColorNameEnum {
  Aqua = 'aqua',
  Blue = 'blue',
  Brown = 'brown',
  DarkBlue = 'dark_blue',
  DarkGreen = 'dark_green',
  DarkPurple = 'dark_purple',
  Green = 'green',
  LightBlue = 'light_blue',
  Magenta = 'magenta',
  Orange = 'orange',
  Pink = 'pink',
  Red = 'red',
  Tan = 'tan',
  Teal = 'teal',
  Yellow = 'yellow'
}

export type ComThomsonreutersSchemasBusinessDataBusinessContacts = {
  __typename?: 'ComThomsonreutersSchemasBusinessDataBusinessContacts';
  name?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasCommonDataCommonDataAddress = {
  __typename?: 'ComThomsonreutersSchemasCommonDataCommonDataAddress';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  careOf?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  deliverableAddress?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  lastVerifiedDate?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  matchToSubjectAddress?: Maybe<Scalars['Boolean']['output']>;
  matchToSubjectsCity?: Maybe<Scalars['String']['output']>;
  matchToSubjectsState?: Maybe<Scalars['String']['output']>;
  matchToSubjectsZipCode?: Maybe<Scalars['String']['output']>;
  matchToUserSuppliedCity?: Maybe<Scalars['String']['output']>;
  matchToUserSuppliedState?: Maybe<Scalars['String']['output']>;
  matchToUserSuppliedZipCode?: Maybe<Scalars['String']['output']>;
  poBox?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  reportedDate?: Maybe<Scalars['String']['output']>;
  residenceType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateAbbreviation?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetLine?: Maybe<Array<Scalars['String']['output']>>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  streetLine3?: Maybe<Scalars['String']['output']>;
  typeOfAddress?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
  zipCodeExtension?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasCommonDataDriverLicenseInfo = {
  __typename?: 'ComThomsonreutersSchemasCommonDataDriverLicenseInfo';
  driverLicenseCounty?: Maybe<Array<Scalars['String']['output']>>;
  driverLicenseNumber?: Maybe<Array<Scalars['String']['output']>>;
  driverLicenseState?: Maybe<Array<Scalars['String']['output']>>;
  matchToSubjectsDlNumber?: Maybe<Array<Scalars['String']['output']>>;
  matchToUserSuppliedDlNumber?: Maybe<Array<Scalars['String']['output']>>;
};

export type ComThomsonreutersSchemasCommonDataPersonBirthDate = {
  __typename?: 'ComThomsonreutersSchemasCommonDataPersonBirthDate';
  personBirthDate?: Maybe<Array<Scalars['String']['output']>>;
  sourceInfo?: Maybe<Array<ComThomsonreutersSchemasCommonDataSearchSources>>;
};

export type ComThomsonreutersSchemasCommonDataPersonName = {
  __typename?: 'ComThomsonreutersSchemasCommonDataPersonName';
  entityId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  secondaryLastName?: Maybe<Scalars['String']['output']>;
  sourceInfo?: Maybe<Array<ComThomsonreutersSchemasCommonDataSearchSources>>;
  suffix?: Maybe<Scalars['String']['output']>;
  trEntityId?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasCommonDataPersonProfile = {
  __typename?: 'ComThomsonreutersSchemasCommonDataPersonProfile';
  dayOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  employeeOccupation?: Maybe<Array<Scalars['String']['output']>>;
  matchToSubjectsDob?: Maybe<Array<Scalars['String']['output']>>;
  matchToUserSuppliedDob?: Maybe<Array<Scalars['String']['output']>>;
  monthOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  numberofDependents?: Maybe<Array<Scalars['String']['output']>>;
  personAge?: Maybe<Array<Scalars['String']['output']>>;
  personAgeFrom?: Maybe<Array<Scalars['String']['output']>>;
  personAgeTo?: Maybe<Array<Scalars['String']['output']>>;
  personBirthDate?: Maybe<Array<Scalars['String']['output']>>;
  personBirthDates?: Maybe<Array<ComThomsonreutersSchemasCommonDataPersonBirthDate>>;
  personBirthPlace?: Maybe<Array<Scalars['String']['output']>>;
  personBirthState?: Maybe<Array<Scalars['String']['output']>>;
  personBuild?: Maybe<Array<Scalars['String']['output']>>;
  personCitizenship?: Maybe<Array<Scalars['String']['output']>>;
  personContactEmail?: Maybe<Array<Scalars['String']['output']>>;
  personDeathDate?: Maybe<Array<Scalars['String']['output']>>;
  personDeathIndicator?: Maybe<Array<Scalars['String']['output']>>;
  personDeathPlace?: Maybe<Array<Scalars['String']['output']>>;
  personEducationLevel?: Maybe<Array<Scalars['String']['output']>>;
  personEthnicity?: Maybe<Array<Scalars['String']['output']>>;
  personEyeColor?: Maybe<Array<Scalars['String']['output']>>;
  personHairColor?: Maybe<Array<Scalars['String']['output']>>;
  personHeight?: Maybe<Array<Scalars['String']['output']>>;
  personMaritalStatus?: Maybe<Array<Scalars['String']['output']>>;
  personMarkings?: Maybe<Array<Scalars['String']['output']>>;
  personMarkingsLocation?: Maybe<Array<Scalars['String']['output']>>;
  personMarkingsType?: Maybe<Array<Scalars['String']['output']>>;
  personRace?: Maybe<Array<Scalars['String']['output']>>;
  personSex?: Maybe<Array<Scalars['String']['output']>>;
  personSkinTone?: Maybe<Array<Scalars['String']['output']>>;
  personSpouseName?: Maybe<Array<Scalars['String']['output']>>;
  personWeight?: Maybe<Array<Scalars['String']['output']>>;
  professionalTitle?: Maybe<Array<Scalars['String']['output']>>;
  yearOfBirth?: Maybe<Array<Scalars['String']['output']>>;
};

export type ComThomsonreutersSchemasCommonDataPhoneInfo = {
  __typename?: 'ComThomsonreutersSchemasCommonDataPhoneInfo';
  additionalPhoneList?: Maybe<Array<Scalars['String']['output']>>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  matchToSubjectsPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  otherPhoneCarrier?: Maybe<Scalars['String']['output']>;
  otherPhoneNumber?: Maybe<Scalars['String']['output']>;
  otherPhoneNumberType?: Maybe<Scalars['String']['output']>;
  phoneCarrier?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberSuffix?: Maybe<Scalars['String']['output']>;
  phoneNumberType?: Maybe<Scalars['String']['output']>;
  tollFreePhoneNumber?: Maybe<Scalars['String']['output']>;
  workPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasCommonDataSearchSources = {
  __typename?: 'ComThomsonreutersSchemasCommonDataSearchSources';
  sourceDocumentGuid?: Maybe<Scalars['String']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasCommonDataSsnInfo = {
  __typename?: 'ComThomsonreutersSchemasCommonDataSsnInfo';
  matchToSubjectsSsn?: Maybe<Array<Scalars['String']['output']>>;
  matchToUserSuppliedSsn?: Maybe<Array<Scalars['String']['output']>>;
  partialSsn?: Maybe<Array<Scalars['String']['output']>>;
  ssn?: Maybe<Array<Scalars['String']['output']>>;
  ssnExpirationDate?: Maybe<Scalars['String']['output']>;
  ssnIssuanceText?: Maybe<Scalars['String']['output']>;
  ssnIssueDate?: Maybe<Scalars['String']['output']>;
  ssnIssueState?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasPeopleDataAddressesPhoneInfo = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataAddressesPhoneInfo';
  address?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  normalizedAddress?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  phones?: Maybe<Array<ComThomsonreutersSchemasCommonDataPhoneInfo>>;
};

export type ComThomsonreutersSchemasPeopleDataDateOfBirth = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataDateOfBirth';
  dayOfBirth?: Maybe<Scalars['String']['output']>;
  fullDateOfBirth?: Maybe<Scalars['String']['output']>;
  monthOfBirth?: Maybe<Scalars['String']['output']>;
  yearOfBirth?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasPeopleDataExperianGatewayRecord = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataExperianGatewayRecord';
  addressesPhoneInfo?: Maybe<Array<ComThomsonreutersSchemasPeopleDataAddressesPhoneInfo>>;
  dateOfBirth?: Maybe<Array<ComThomsonreutersSchemasPeopleDataDateOfBirth>>;
  fraudServicesInfo?: Maybe<ComThomsonreutersSchemasPeopleDataFraudServicesInfo>;
  matchesPersonEntity?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<ComThomsonreutersSchemasCommonDataPersonName>>;
  phones?: Maybe<Array<ComThomsonreutersSchemasCommonDataPhoneInfo>>;
  reportDate?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  spouseInfo?: Maybe<Array<ComThomsonreutersSchemasPeopleDataSpouseInfo>>;
  ssn?: Maybe<Array<ComThomsonreutersSchemasCommonDataSsnInfo>>;
};

export type ComThomsonreutersSchemasPeopleDataFraudServicesCode = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataFraudServicesCode';
  code?: Maybe<Scalars['String']['output']>;
  indicator?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasPeopleDataFraudServicesInfo = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataFraudServicesInfo';
  dateOfBirth?: Maybe<ComThomsonreutersSchemasPeopleDataDateOfBirth>;
  dateOfDeath?: Maybe<Scalars['String']['output']>;
  fraudServicesCode?: Maybe<ComThomsonreutersSchemasPeopleDataFraudServicesCode>;
  sicCode?: Maybe<Scalars['String']['output']>;
  ssnFirstIssueYear?: Maybe<Scalars['String']['output']>;
  ssnLastIssueYear?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasPeopleDataSpouseInfo = {
  __typename?: 'ComThomsonreutersSchemasPeopleDataSpouseInfo';
  name?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchBusinessDominantValues = {
  __typename?: 'ComThomsonreutersSchemasSearchBusinessDominantValues';
  address?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  fein?: Maybe<Scalars['String']['output']>;
  fileState?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchBusinessDominantValuesContainer = {
  __typename?: 'ComThomsonreutersSchemasSearchBusinessDominantValuesContainer';
  businessDominantValues: ComThomsonreutersSchemasSearchBusinessDominantValues;
};

export type ComThomsonreutersSchemasSearchBusinessExecutives = {
  __typename?: 'ComThomsonreutersSchemasSearchBusinessExecutives';
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchBusinessRecordDetailsContainer = {
  __typename?: 'ComThomsonreutersSchemasSearchBusinessRecordDetailsContainer';
  businessResponseDetail: ComThomsonreutersSchemasSearchBusinessResponseDetail;
};

export type ComThomsonreutersSchemasSearchBusinessResponseDetail = {
  __typename?: 'ComThomsonreutersSchemasSearchBusinessResponseDetail';
  address?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  allSourceDocuments?: Maybe<Array<ComThomsonreutersSchemasSearchSearchSources>>;
  alternateAddresses?: Maybe<Array<ComThomsonreutersSchemasCommonDataCommonDataAddress>>;
  alternateBusinessNames?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  businessContacts?: Maybe<Array<ComThomsonreutersSchemasBusinessDataBusinessContacts>>;
  businessExecutives?: Maybe<Array<ComThomsonreutersSchemasSearchBusinessExecutives>>;
  businessName?: Maybe<Scalars['String']['output']>;
  companyEntityId?: Maybe<Scalars['String']['output']>;
  corporationInfo?: Maybe<Array<ComThomsonreutersSchemasSearchCorporationInfo>>;
  legalImmediateParent?: Maybe<Scalars['String']['output']>;
  legalUltimateParent?: Maybe<Scalars['String']['output']>;
  listOfDunsNumbers?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  listOfFeiNs?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  listOfFileStates?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  listOfNpiNumbers?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  locationType?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<ComThomsonreutersSchemasSearchListInfo>;
  uccInfo?: Maybe<Array<ComThomsonreutersSchemasSearchUccInfo>>;
};

export type ComThomsonreutersSchemasSearchCorporationInfo = {
  __typename?: 'ComThomsonreutersSchemasSearchCorporationInfo';
  corporationNumber?: Maybe<Scalars['String']['output']>;
  dateIncorporated?: Maybe<Scalars['String']['output']>;
  stateOfIncorporation?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchImages = {
  __typename?: 'ComThomsonreutersSchemasSearchImages';
  binaryImage?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  imageDate?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchListInfo = {
  __typename?: 'ComThomsonreutersSchemasSearchListInfo';
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  dunsNumber?: Maybe<Array<Scalars['String']['output']>>;
  fein?: Maybe<Array<Scalars['String']['output']>>;
  fileState?: Maybe<Array<Scalars['String']['output']>>;
  npiNumber?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumber?: Maybe<Array<Scalars['String']['output']>>;
};

export type ComThomsonreutersSchemasSearchPersonDominantValues = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonDominantValues';
  address?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  ageInfo?: Maybe<ComThomsonreutersSchemasCommonDataPersonProfile>;
  name?: Maybe<ComThomsonreutersSchemasCommonDataPersonName>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchPersonDominantValuesContainer = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonDominantValuesContainer';
  personDominantValues: ComThomsonreutersSchemasSearchPersonDominantValues;
};

export type ComThomsonreutersSchemasSearchPersonRecordDetailsContainer = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonRecordDetailsContainer';
  personResponseDetail: ComThomsonreutersSchemasSearchPersonResponseDetail;
};

export type ComThomsonreutersSchemasSearchPersonResponseDetail = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonResponseDetail';
  additionalPhoneNumbers?: Maybe<Array<ComThomsonreutersSchemasSearchPersonSearchAdditionalPhoneNumbers>>;
  akaNames?: Maybe<Array<ComThomsonreutersSchemasCommonDataPersonName>>;
  allSourceDocuments?: Maybe<Array<ComThomsonreutersSchemasSearchPersonSearchSources>>;
  driverLicenseInfo?: Maybe<Array<ComThomsonreutersSchemasCommonDataDriverLicenseInfo>>;
  emailAddress?: Maybe<Array<Scalars['String']['output']>>;
  employer?: Maybe<Array<Scalars['String']['output']>>;
  experianGatewayRecord?: Maybe<ComThomsonreutersSchemasPeopleDataExperianGatewayRecord>;
  knownAddresses?: Maybe<Array<ComThomsonreutersSchemasSearchPersonSearchAddressInfo>>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<ComThomsonreutersSchemasCommonDataPersonName>;
  npiNumber?: Maybe<Scalars['String']['output']>;
  personEntityId?: Maybe<Scalars['String']['output']>;
  personProfile?: Maybe<ComThomsonreutersSchemasCommonDataPersonProfile>;
  photoImages?: Maybe<ComThomsonreutersSchemasSearchPersonSearchPhotoImages>;
  ssn?: Maybe<Array<Scalars['String']['output']>>;
};

export type ComThomsonreutersSchemasSearchPersonSearchAdditionalPhoneNumbers = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonSearchAdditionalPhoneNumbers';
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sourceInfo?: Maybe<Array<ComThomsonreutersSchemasSearchPersonSearchSources>>;
};

export type ComThomsonreutersSchemasSearchPersonSearchAddressInfo = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonSearchAddressInfo';
  address?: Maybe<ComThomsonreutersSchemasCommonDataCommonDataAddress>;
  phones?: Maybe<ComThomsonreutersSchemasCommonDataPhoneInfo>;
  sourceInfo?: Maybe<Array<ComThomsonreutersSchemasSearchPersonSearchSources>>;
};

export type ComThomsonreutersSchemasSearchPersonSearchPhotoImages = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonSearchPhotoImages';
  images?: Maybe<Array<ComThomsonreutersSchemasSearchImages>>;
  photos?: Maybe<Array<ComThomsonreutersSchemasSearchPhotos>>;
};

export type ComThomsonreutersSchemasSearchPersonSearchSources = {
  __typename?: 'ComThomsonreutersSchemasSearchPersonSearchSources';
  sourceDocumentGuid?: Maybe<Scalars['String']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchPhotos = {
  __typename?: 'ComThomsonreutersSchemasSearchPhotos';
  photoDate?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchSearchSources = {
  __typename?: 'ComThomsonreutersSchemasSearchSearchSources';
  sourceDocumentGuid?: Maybe<Scalars['String']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
};

export type ComThomsonreutersSchemasSearchUccInfo = {
  __typename?: 'ComThomsonreutersSchemasSearchUccInfo';
  uccFilingDate?: Maybe<Scalars['String']['output']>;
  uccFilingNumber?: Maybe<Scalars['String']['output']>;
};

/** Types of combinators that can be used in GroupInput */
export enum CombinatorEnum {
  /** All filters in the group must all match */
  And = 'and',
  /** Any filter in the group must match */
  Or = 'or'
}

export type Comment = {
  __typename?: 'Comment';
  author: Account;
  commentThread: CommentThread;
  createdAt: Scalars['ISO8601DateTime']['output'];
  quoteText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CommentParams = {
  smartValuesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CommentThread = {
  __typename?: 'CommentThread';
  comments: Array<Comment>;
  resolvedAt: Scalars['ISO8601DateTime']['output'];
  resolvedBy: Account;
  reviewTypeTask?: Maybe<ReviewTypeTask>;
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of CompleteInformationRequest */
export type CompleteInformationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  informationRequestToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteInformationRequest. */
export type CompleteInformationRequestPayload = {
  __typename?: 'CompleteInformationRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  informationRequest?: Maybe<InformationRequest>;
};

/** A completed RFI revealing fewer fields to public endpoint. */
export type CompletedRequestForInformation = {
  __typename?: 'CompletedRequestForInformation';
  externalToken: Scalars['ID']['output'];
  form: RequestForInformationForm;
  organization: Organization;
};

export type Contact = {
  __typename?: 'Contact';
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ContactAttributes = {
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactMetadata = {
  __typename?: 'ContactMetadata';
  active: Scalars['Boolean']['output'];
  value: Contact;
};

export type ContactMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: ContactAttributes;
};

/** Autogenerated input type of CreateAccount */
export type CreateAccountInput = {
  badgeToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/** Autogenerated return type of CreateAccount. */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of CreateApiKey */
export type CreateApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationEmail: Scalars['String']['input'];
};

/** Autogenerated return type of CreateApiKey. */
export type CreateApiKeyPayload = {
  __typename?: 'CreateApiKeyPayload';
  apiKey: ApiKey;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** returned as a separate field on mutation to avoid repeat display or retrieval in ApiKey */
  clientSecret: Scalars['String']['output'];
};

/** Autogenerated input type of CreateAttachmentSummaryFeedback */
export type CreateAttachmentSummaryFeedbackInput = {
  attachmentToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  rating: AttachmentSummaryFeedbackRatingEnum;
  summaryToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAttachmentSummaryFeedback. */
export type CreateAttachmentSummaryFeedbackPayload = {
  __typename?: 'CreateAttachmentSummaryFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  summaryFeedback?: Maybe<AttachmentSummaryFeedback>;
};

/** Autogenerated input type of CreateAttachments */
export type CreateAttachmentsInput = {
  attachments: Array<AttachmentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of CreateAttachments. */
export type CreateAttachmentsPayload = {
  __typename?: 'CreateAttachmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

/** Autogenerated input type of CreateAutomationRuleAsAdmin */
export type CreateAutomationRuleAsAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationToken?: InputMaybe<Scalars['ID']['input']>;
  templateSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAutomationRuleAsAdmin. */
export type CreateAutomationRuleAsAdminPayload = {
  __typename?: 'CreateAutomationRuleAsAdminPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAutomationRule */
export type CreateAutomationRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAutomationRule. */
export type CreateAutomationRulePayload = {
  __typename?: 'CreateAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateAutomationTemplate */
export type CreateAutomationTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAutomationTemplate. */
export type CreateAutomationTemplatePayload = {
  __typename?: 'CreateAutomationTemplatePayload';
  automationTemplate: AutomationRuleTemplate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateChainalysisConfiguration */
export type CreateChainalysisConfigurationInput = {
  apiKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  directExposureReviewTypeCanonicalId: Scalars['String']['input'];
  groupAlertsBy: ChainalysisGroupByTypeEnum;
  groupingDenyListUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  indirectExposureReviewTypeCanonicalId: Scalars['String']['input'];
  processAlertsCreatedAfter: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of CreateChainalysisConfiguration. */
export type CreateChainalysisConfigurationPayload = {
  __typename?: 'CreateChainalysisConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of CreateDatasource */
export type CreateDatasourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  query: Scalars['String']['input'];
  warehouseToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateDatasource. */
export type CreateDatasourcePayload = {
  __typename?: 'CreateDatasourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  datasource: Datasource;
  datasourceWarehouse: DatasourceWarehouse;
};

/** Autogenerated input type of CreateInboundRequestResponse */
export type CreateInboundRequestResponseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inboundRequestToken: Scalars['ID']['input'];
  surveyResponseEntries: Array<SurveyResponseEntryInput>;
};

/** Autogenerated return type of CreateInboundRequestResponse. */
export type CreateInboundRequestResponsePayload = {
  __typename?: 'CreateInboundRequestResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  responseErrors?: Maybe<Array<SurveyResponseEntryError>>;
  token?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of CreateInformationRequest */
export type CreateInformationRequestInput = {
  caseToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dueAt: Scalars['ISO8601DateTime']['input'];
  formToken: Scalars['ID']['input'];
  recipients: Array<InformationRequestRecipientInput>;
  reminderDelayDays?: InputMaybe<Scalars['Int']['input']>;
  reminderFrequencyDays?: InputMaybe<Scalars['Int']['input']>;
  senderSurveyResponseEntries: Array<SurveyResponseEntryInput>;
  subscriberEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateInformationRequest. */
export type CreateInformationRequestPayload = {
  __typename?: 'CreateInformationRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  responseErrors?: Maybe<Array<SurveyResponseEntryError>>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of CreateInformationRequestResponse */
export type CreateInformationRequestResponseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalToken: Scalars['ID']['input'];
  recipientSurveyResponseEntries: Array<SurveyResponseEntryInput>;
};

/** Autogenerated return type of CreateInformationRequestResponse. */
export type CreateInformationRequestResponsePayload = {
  __typename?: 'CreateInformationRequestResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmationCode?: Maybe<Scalars['ID']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  responseErrors?: Maybe<Array<SurveyResponseEntryError>>;
};

export type CreateInvestigationAlertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  rule?: InputMaybe<Scalars['String']['input']>;
  triggeredAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated input type of CreateInvestigationDocumentDownload */
export type CreateInvestigationDocumentDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<InvestigationDownloadDocumentInput>;
  investigationToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInvestigationDocumentDownload. */
export type CreateInvestigationDocumentDownloadPayload = {
  __typename?: 'CreateInvestigationDocumentDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  downloadToken?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of CreateInvestigation */
export type CreateInvestigationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  review: CreateInvestigationReviewInput;
};

export type CreateInvestigationParams = {
  activityTags?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  narrative?: InputMaybe<Scalars['String']['input']>;
  reviewTypeCanonicalId?: InputMaybe<Scalars['ID']['input']>;
  smartValuesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateInvestigation. */
export type CreateInvestigationPayload = {
  __typename?: 'CreateInvestigationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

export type CreateInvestigationReviewInput = {
  alerts?: InputMaybe<Array<CreateInvestigationAlertInput>>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  queueToken?: InputMaybe<Scalars['ID']['input']>;
  reviewTypeCanonicalId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateOtherInfoLabel */
export type CreateOtherInfoLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dashboardVisible?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayAs: OtherInfoLabelDisplayAsEnum;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  managed?: InputMaybe<Scalars['Boolean']['input']>;
  type: OtherInfoLabelTypeEnum;
};

/** Autogenerated return type of CreateOtherInfoLabel. */
export type CreateOtherInfoLabelPayload = {
  __typename?: 'CreateOtherInfoLabelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  otherInfoLabel: OtherInfoLabel;
};

/** Autogenerated input type of CreatePublicKey */
export type CreatePublicKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['String']['input'];
};

/** Autogenerated return type of CreatePublicKey. */
export type CreatePublicKeyPayload = {
  __typename?: 'CreatePublicKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  publicKey: PublicKey;
};

/** Autogenerated input type of CreateReviewNarrativeValidation */
export type CreateReviewNarrativeValidationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  narrative: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of CreateReviewNarrativeValidation. */
export type CreateReviewNarrativeValidationPayload = {
  __typename?: 'CreateReviewNarrativeValidationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  narrativeValidation: NarrativeValidation;
};

export type CreateReviewParams = {
  activityTags?: InputMaybe<Array<Scalars['String']['input']>>;
  assigneeToken?: InputMaybe<Scalars['ID']['input']>;
  dueDateRelativeIncrementUnit?: InputMaybe<AutomationDateUnitEnum>;
  dueDateRelativeIncrementValue?: InputMaybe<Scalars['Int']['input']>;
  narrative?: InputMaybe<Scalars['String']['input']>;
  otherInfoEntries?: InputMaybe<Array<OtherInfoEntryParams>>;
  queueToken?: InputMaybe<Scalars['ID']['input']>;
  reviewTypeCanonicalId?: InputMaybe<Scalars['ID']['input']>;
  smartValuesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  transferReviewAssignee?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateTag */
export type CreateTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objectToken: Scalars['ID']['input'];
  objectType: TagDefinitionAllowedTypeEnum;
  tagDefinitionToken: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateTag. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taggedObject: TaggedObject;
};

/** Autogenerated input type of CreateTransientUploadAuthorization */
export type CreateTransientUploadAuthorizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uploadCategory: Scalars['String']['input'];
};

/** Autogenerated return type of CreateTransientUploadAuthorization. */
export type CreateTransientUploadAuthorizationPayload = {
  __typename?: 'CreateTransientUploadAuthorizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transientUploadAuthorization: TransientUploadAuthorization;
};

/** Autogenerated input type of CreateWebhookEndpoint */
export type CreateWebhookEndpointInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enableMtls?: InputMaybe<Scalars['Boolean']['input']>;
  trustedCaCertPem?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** Autogenerated return type of CreateWebhookEndpoint. */
export type CreateWebhookEndpointPayload = {
  __typename?: 'CreateWebhookEndpointPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  webhookEndpoint: WebhookEndpoint;
  webhookEndpointSecretKey: Scalars['String']['output'];
};

export type CryptoAddressAttributes = {
  address?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  blockchain?: InputMaybe<CryptoAddressBlockchain>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
};

export type CryptoAddressBlockchain = {
  type?: InputMaybe<BlockchainTypeEnum>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export type CryptoAddressBlockchainAttributes = {
  __typename?: 'CryptoAddressBlockchainAttributes';
  type?: Maybe<Scalars['String']['output']>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type CryptoAddressTxnEntry = {
  accountHolder?: InputMaybe<LibraryObjectRelation>;
  attributes: CryptoAddressAttributes;
  institution?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export enum CyberEventIndicatorTypeEnum {
  CommandAndControlIp = 'command_and_control_ip',
  CommandAndControlUrl = 'command_and_control_url',
  MacAddress = 'mac_address',
  MalwareDigest = 'malware_digest',
  Other = 'other',
  Port = 'port',
  SuspiciousEmail = 'suspicious_email',
  SuspiciousFilename = 'suspicious_filename',
  SuspiciousIp = 'suspicious_ip',
  SuspiciousUrl = 'suspicious_url',
  TargetedSystem = 'targeted_system'
}

/** Status and possible result of a dashboard export job. */
export type DashboardExportJob = Job & {
  __typename?: 'DashboardExportJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

export enum DashboardTypeEnum {
  AutomationExecutions = 'automation_executions',
  CtrFilings = 'ctr_filings',
  Filings = 'filings',
  InformationRequests = 'information_requests',
  Profiles = 'profiles',
  Reviews = 'reviews',
  Transactions = 'transactions'
}

export type Datasource = {
  __typename?: 'Datasource';
  columns: Array<DatasourceColumn>;
  name: Scalars['String']['output'];
  query: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  warehouse: DatasourceWarehouse;
};

export type DatasourceColumn = {
  __typename?: 'DatasourceColumn';
  name: Scalars['String']['output'];
  type: DatasourceColumnEnum;
};

export enum DatasourceColumnEnum {
  Decimal = 'decimal',
  Integer = 'integer',
  String = 'string'
}

export type DatasourceDatatable = {
  __typename?: 'DatasourceDatatable';
  datasource: Datasource;
  filterClauseColumnName: Scalars['String']['output'];
  location: DatasourceDatatableLocationEnum;
  records: UntypedConnection;
  token: Scalars['ID']['output'];
};


export type DatasourceDatatableRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum DatasourceDatatableLocationEnum {
  People = 'people'
}

export type DatasourceWarehouse = {
  __typename?: 'DatasourceWarehouse';
  datasources: Array<Datasource>;
  informationSchema: InformationSchema;
  name: Scalars['String']['output'];
  records: UntypedConnection;
  token: Scalars['ID']['output'];
  type: DatasourceWarehouseTypeEnum;
};


export type DatasourceWarehouseRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

/** The state of an access request */
export enum DatasourceWarehouseTypeEnum {
  Demo = 'demo',
  Starburst = 'starburst'
}

export type DecisionInformationSummaryCounts = {
  __typename?: 'DecisionInformationSummaryCounts';
  bankAccount: Scalars['Int']['output'];
  business: Scalars['Int']['output'];
  cryptoAddress: Scalars['Int']['output'];
  device: Scalars['Int']['output'];
  individual: Scalars['Int']['output'];
  institution: Scalars['Int']['output'];
  paymentCard: Scalars['Int']['output'];
  product: Scalars['Int']['output'];
  transactionImports: Scalars['Int']['output'];
};

/** Autogenerated input type of DeclineInboundRequest */
export type DeclineInboundRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  declinedReason: Scalars['String']['input'];
  inboundRequestToken: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineInboundRequest. */
export type DeclineInboundRequestPayload = {
  __typename?: 'DeclineInboundRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  inboundRequest?: Maybe<InboundRequest>;
};

/** Autogenerated input type of DeleteApiKey */
export type DeleteApiKeyInput = {
  apiKeyUid: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteApiKey. */
export type DeleteApiKeyPayload = {
  __typename?: 'DeleteApiKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteAttachment */
export type DeleteAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteAttachment. */
export type DeleteAttachmentPayload = {
  __typename?: 'DeleteAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

/** Autogenerated input type of DeleteAutomationRuleAsAdmin */
export type DeleteAutomationRuleAsAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationToken: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAutomationRuleAsAdmin. */
export type DeleteAutomationRuleAsAdminPayload = {
  __typename?: 'DeleteAutomationRuleAsAdminPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAutomationRule */
export type DeleteAutomationRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAutomationRule. */
export type DeleteAutomationRulePayload = {
  __typename?: 'DeleteAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteAutomationTemplate */
export type DeleteAutomationTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAutomationTemplate. */
export type DeleteAutomationTemplatePayload = {
  __typename?: 'DeleteAutomationTemplatePayload';
  automationTemplate: AutomationRuleTemplate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteCaseAlert */
export type DeleteCaseAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteCaseAlert. */
export type DeleteCaseAlertPayload = {
  __typename?: 'DeleteCaseAlertPayload';
  caseAlert: CaseAlert;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Autogenerated input type of DeleteChainalysisConfiguration */
export type DeleteChainalysisConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteChainalysisConfiguration. */
export type DeleteChainalysisConfigurationPayload = {
  __typename?: 'DeleteChainalysisConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of DeleteComment */
export type DeleteCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentToken: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteComment. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteFINTRACApiSettings */
export type DeleteFintracApiSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteFINTRACApiSettings. */
export type DeleteFintracApiSettingsPayload = {
  __typename?: 'DeleteFINTRACApiSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteIndicator */
export type DeleteIndicatorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteIndicator. */
export type DeleteIndicatorPayload = {
  __typename?: 'DeleteIndicatorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  indicator: Indicator;
};

/** Autogenerated input type of DeleteMiddeskCredential */
export type DeleteMiddeskCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteMiddeskCredential. */
export type DeleteMiddeskCredentialPayload = {
  __typename?: 'DeleteMiddeskCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteNarrativeTemplate */
export type DeleteNarrativeTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteNarrativeTemplate. */
export type DeleteNarrativeTemplatePayload = {
  __typename?: 'DeleteNarrativeTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  narrativeTemplate: NarrativeTemplate;
};

/** Autogenerated input type of DeletePublicKey */
export type DeletePublicKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePublicKey. */
export type DeletePublicKeyPayload = {
  __typename?: 'DeletePublicKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  publicKey: PublicKey;
};

/** Autogenerated input type of DeleteSavedSearch */
export type DeleteSavedSearchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteSavedSearch. */
export type DeleteSavedSearchPayload = {
  __typename?: 'DeleteSavedSearchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedSearchRequest: SavedSearchRequest;
};

/** Autogenerated input type of DeleteSigmaConfiguration */
export type DeleteSigmaConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteSigmaConfiguration. */
export type DeleteSigmaConfigurationPayload = {
  __typename?: 'DeleteSigmaConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteTag */
export type DeleteTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objectToken: Scalars['ID']['input'];
  objectType: TagDefinitionAllowedTypeEnum;
  tagToken: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteTag. */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taggedObject: TaggedObject;
};

/** Autogenerated input type of DeleteThomsonReutersClearCredential */
export type DeleteThomsonReutersClearCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteThomsonReutersClearCredential. */
export type DeleteThomsonReutersClearCredentialPayload = {
  __typename?: 'DeleteThomsonReutersClearCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of DeleteTransactionEndpoint */
export type DeleteTransactionEndpointInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteTransactionEndpoint. */
export type DeleteTransactionEndpointPayload = {
  __typename?: 'DeleteTransactionEndpointPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transaction: Transaction;
};

/** Autogenerated input type of DeleteWebhookEndpoint */
export type DeleteWebhookEndpointInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DeleteWebhookEndpoint. */
export type DeleteWebhookEndpointPayload = {
  __typename?: 'DeleteWebhookEndpointPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  webhookEndpoint: WebhookEndpoint;
};

export type Deployment = {
  __typename?: 'Deployment';
  curatedNotes?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  rawNotes?: Maybe<Array<Scalars['String']['output']>>;
  sha?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

/** The connection type for Deployment. */
export type DeploymentConnection = {
  __typename?: 'DeploymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeploymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Deployment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DeploymentEdge = {
  __typename?: 'DeploymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Deployment>;
};

export type DeviceAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  browser?: InputMaybe<BrowserAttributes>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fingerprint?: InputMaybe<Scalars['String']['input']>;
  firstSeenAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastSeenAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  operatingSystem?: InputMaybe<OperatingSystemAttributes>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceTxnEntry = {
  attributes: DeviceAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
  owner?: InputMaybe<LibraryObjectRelation>;
};

export type DirectorySyncAccountChange = {
  __typename?: 'DirectorySyncAccountChange';
  action: DirectorySyncAccountChangeAction;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export enum DirectorySyncAccountChangeAction {
  Activate = 'activate',
  Create = 'create',
  Deactivate = 'deactivate',
  Delete = 'delete'
}

export type DirectorySyncBadgeChange = {
  __typename?: 'DirectorySyncBadgeChange';
  action: DirectorySyncBadgeChangeAction;
  badge: Badge;
  email: Scalars['String']['output'];
};

export enum DirectorySyncBadgeChangeAction {
  Add = 'add',
  Remove = 'remove'
}

/** Directory sync configuration */
export type DirectorySyncConfig = {
  __typename?: 'DirectorySyncConfig';
  directoryLinked: Scalars['Boolean']['output'];
  directorySyncEnabled: Scalars['Boolean']['output'];
};

export type DirectorySyncDiff = {
  __typename?: 'DirectorySyncDiff';
  accountChanges: Array<DirectorySyncAccountChange>;
  badgeChanges: Array<DirectorySyncBadgeChange>;
};

export type DirectorySyncGroup = {
  __typename?: 'DirectorySyncGroup';
  badges: Array<Badge>;
  name: Scalars['String']['output'];
  organization: Organization;
  token: Scalars['ID']['output'];
};

/** Maps a directory sync group to a list of badges */
export type DirectorySyncGroupBadgeAssignment = {
  badgeTokens: Array<Scalars['ID']['input']>;
  directorySyncGroupToken: Scalars['ID']['input'];
};

/** Autogenerated input type of DisableAutomationRule */
export type DisableAutomationRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of DisableAutomationRule. */
export type DisableAutomationRulePayload = {
  __typename?: 'DisableAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DisableEllipticIntegration */
export type DisableEllipticIntegrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableEllipticIntegration. */
export type DisableEllipticIntegrationPayload = {
  __typename?: 'DisableEllipticIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  ellipticConfiguration?: Maybe<EllipticConfiguration>;
};

/** Autogenerated input type of DismissSuggestedFincenAdvisory */
export type DismissSuggestedFincenAdvisoryInput = {
  advisoryToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
};

/** Autogenerated return type of DismissSuggestedFincenAdvisory. */
export type DismissSuggestedFincenAdvisoryPayload = {
  __typename?: 'DismissSuggestedFincenAdvisoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Union representing two download objects */
export type Download = EmailDownload | FileDownloadAuthorization;

/** Autogenerated input type of DownloadOrganizationAlert */
export type DownloadOrganizationAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DownloadOrganizationAlert. */
export type DownloadOrganizationAlertPayload = {
  __typename?: 'DownloadOrganizationAlertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** The configuration for the Elliptic integration. */
export type EllipticConfiguration = {
  __typename?: 'EllipticConfiguration';
  groupTxnAnalysesBy: EllipticGroupByTypeEnum;
  minRiskScore?: Maybe<Scalars['Decimal']['output']>;
  processTxnAnalysesAnalysedAtAfter: Scalars['ISO8601DateTime']['output'];
  token: Scalars['ID']['output'];
  txnAnalysesCursor?: Maybe<EllipticTxnAnalysesCursor>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum EllipticGroupByTypeEnum {
  Default = 'default',
  NoGrouping = 'no_grouping',
  WalletId = 'wallet_id'
}

/** The position of Elliptic integration through the transaction analyses */
export type EllipticTxnAnalysesCursor = {
  __typename?: 'EllipticTxnAnalysesCursor';
  lastTxnAnalysesAnalysedAtAfter: Scalars['ISO8601DateTime']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EmailDownload = {
  __typename?: 'EmailDownload';
  filename: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

/** Type for capturing basic email recipient information */
export type EmailRecipient = {
  __typename?: 'EmailRecipient';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type EmailRecipientParams = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EmbeddedAnalyticsDashboard = OmniEmbeddedAnalyticsDashboard | SupersetEmbeddedAnalyticsDashboard;

export type EmptyRuleTemplate = AutomationRuleTemplateBase & {
  __typename?: 'EmptyRuleTemplate';
  actionParams?: Maybe<AutomationRuleTemplateActionParams>;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  automationTemplateCategory?: Maybe<AutomationRuleTemplateCategory>;
  automationType?: Maybe<AutomationRuleType>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  humanReadableSchedule?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  setup?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  triggerDescription?: Maybe<Scalars['String']['output']>;
  triggerName?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EnableAutomationRule */
export type EnableAutomationRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  executionMode: AutomationRuleExecutionMode;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableAutomationRule. */
export type EnableAutomationRulePayload = {
  __typename?: 'EnableAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EnableDisableAutomationTemplate */
export type EnableDisableAutomationTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableDisableAutomationTemplate. */
export type EnableDisableAutomationTemplatePayload = {
  __typename?: 'EnableDisableAutomationTemplatePayload';
  automationTemplate: AutomationRuleTemplate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EnableEllipticIntegration */
export type EnableEllipticIntegrationInput = {
  apiKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupTxnAnalysesBy: EllipticGroupByTypeEnum;
  minRiskScore?: InputMaybe<Scalars['Decimal']['input']>;
  processTxnAnalysesAnalysedAtAfter: Scalars['ISO8601DateTime']['input'];
  secret: Scalars['String']['input'];
};

/** Autogenerated return type of EnableEllipticIntegration. */
export type EnableEllipticIntegrationPayload = {
  __typename?: 'EnableEllipticIntegrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  ellipticConfiguration: EllipticConfiguration;
};

/** Autogenerated input type of EnableOrDisableWebhookEndpoint */
export type EnableOrDisableWebhookEndpointInput = {
  action: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of EnableOrDisableWebhookEndpoint. */
export type EnableOrDisableWebhookEndpointPayload = {
  __typename?: 'EnableOrDisableWebhookEndpointPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  webhookEndpoint: WebhookEndpoint;
};

export enum EnabledFormField {
  Address = 'address',
  Alias = 'alias',
  BirthDate = 'birth_date',
  MinScore = 'min_score',
  Name = 'name'
}

/** Autogenerated input type of EnqueueAlertsExportJob */
export type EnqueueAlertsExportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
};

/** Autogenerated return type of EnqueueAlertsExportJob. */
export type EnqueueAlertsExportJobPayload = {
  __typename?: 'EnqueueAlertsExportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: AlertsExportJob;
};

/** Autogenerated input type of EnqueueDashboardExportJob */
export type EnqueueDashboardExportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  search: SearchRequestInput;
  type: DashboardTypeEnum;
};

/** Autogenerated return type of EnqueueDashboardExportJob. */
export type EnqueueDashboardExportJobPayload = {
  __typename?: 'EnqueueDashboardExportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: DashboardExportJob;
};

/** Autogenerated input type of EnqueueFINTRACValidationJob */
export type EnqueueFintracValidationJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
};

/** Autogenerated return type of EnqueueFINTRACValidationJob. */
export type EnqueueFintracValidationJobPayload = {
  __typename?: 'EnqueueFINTRACValidationJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  validationAttempt: ValidationAttempt;
};

/** Autogenerated input type of EnqueueHistoryExportJob */
export type EnqueueHistoryExportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
};

/** Autogenerated return type of EnqueueHistoryExportJob. */
export type EnqueueHistoryExportJobPayload = {
  __typename?: 'EnqueueHistoryExportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: HistoryExportJob;
};

/** Autogenerated input type of EnqueueMiddeskBusinessJob */
export type EnqueueMiddeskBusinessJobInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dbaNames?: InputMaybe<Array<Scalars['String']['input']>>;
  investigationToken: Scalars['ID']['input'];
  libraryBusinessToken: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  personNames?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  tin?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnqueueMiddeskBusinessJob. */
export type EnqueueMiddeskBusinessJobPayload = {
  __typename?: 'EnqueueMiddeskBusinessJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: MiddeskBusinessJob;
};

/** Autogenerated input type of EnqueueReviewNarrativeGenerationJob */
export type EnqueueReviewNarrativeGenerationJobInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filingDecisionIntent: Scalars['String']['input'];
  filingType: Scalars['String']['input'];
  reviewToken: Scalars['ID']['input'];
};

/** Autogenerated return type of EnqueueReviewNarrativeGenerationJob. */
export type EnqueueReviewNarrativeGenerationJobPayload = {
  __typename?: 'EnqueueReviewNarrativeGenerationJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ReviewNarrativeGenerationJob;
};

/** Autogenerated input type of EnqueueScreeningSearchReportJob */
export type EnqueueScreeningSearchReportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['ID']['input'];
  libraryToken: Scalars['ID']['input'];
  libraryType: LibraryTypeEnum;
  provider: ScreeningSearchProvider;
  searchQuery: ScreeningSearchQueryParameters;
};

/** Autogenerated return type of EnqueueScreeningSearchReportJob. */
export type EnqueueScreeningSearchReportJobPayload = {
  __typename?: 'EnqueueScreeningSearchReportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ScreeningSearchJob;
};

/** Autogenerated input type of EnqueueTestWebhookEndpointJob */
export type EnqueueTestWebhookEndpointJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  webhookEndpointToken: Scalars['ID']['input'];
  webhookEventType: WebhookEventTypeEnum;
};

/** Autogenerated return type of EnqueueTestWebhookEndpointJob. */
export type EnqueueTestWebhookEndpointJobPayload = {
  __typename?: 'EnqueueTestWebhookEndpointJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: WebhookEndpointTestJob;
};

/** Autogenerated input type of EnqueueThomsonReutersBusinessReportJob */
export type EnqueueThomsonReutersBusinessReportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  investigationToken: Scalars['ID']['input'];
  libraryToken?: InputMaybe<Scalars['ID']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnqueueThomsonReutersBusinessReportJob. */
export type EnqueueThomsonReutersBusinessReportJobPayload = {
  __typename?: 'EnqueueThomsonReutersBusinessReportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ThomsonReutersBusinessReportJob;
};

/** Autogenerated input type of EnqueueThomsonReutersBusinessSearchJob */
export type EnqueueThomsonReutersBusinessSearchJobInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  corporationId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  fein?: InputMaybe<Scalars['String']['input']>;
  filingDate?: InputMaybe<Scalars['DateParts']['input']>;
  filingNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  secondaryLastName?: InputMaybe<Scalars['String']['input']>;
  stateTerritory?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnqueueThomsonReutersBusinessSearchJob. */
export type EnqueueThomsonReutersBusinessSearchJobPayload = {
  __typename?: 'EnqueueThomsonReutersBusinessSearchJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ThomsonReutersBusinessSearchJob;
};

/** Autogenerated input type of EnqueueThomsonReutersPersonReportJob */
export type EnqueueThomsonReutersPersonReportJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  investigationToken: Scalars['ID']['input'];
  libraryToken?: InputMaybe<Scalars['ID']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnqueueThomsonReutersPersonReportJob. */
export type EnqueueThomsonReutersPersonReportJobPayload = {
  __typename?: 'EnqueueThomsonReutersPersonReportJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ThomsonReutersPersonReportJob;
};

/** Autogenerated input type of EnqueueThomsonReutersPersonSearchJob */
export type EnqueueThomsonReutersPersonSearchJobInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateParts']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  driversLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  secondaryLastName?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  stateTerritory?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnqueueThomsonReutersPersonSearchJob. */
export type EnqueueThomsonReutersPersonSearchJobPayload = {
  __typename?: 'EnqueueThomsonReutersPersonSearchJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  job: ThomsonReutersPersonSearchJob;
};

/** Contact ID FINTRAC API mapping to Hummingbird user */
export type FintracApiContactIdMapping = {
  __typename?: 'FINTRACApiContactIdMapping';
  account: Account;
  fintracContactId: Scalars['Int']['output'];
  token: Scalars['ID']['output'];
};

/** The connection type for FINTRACApiContactIdMapping. */
export type FintracApiContactIdMappingConnection = {
  __typename?: 'FINTRACApiContactIdMappingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FintracApiContactIdMappingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FintracApiContactIdMapping>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FintracApiContactIdMappingEdge = {
  __typename?: 'FINTRACApiContactIdMappingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FintracApiContactIdMapping>;
};

/** Input schema for mapping a user to FINTRAC contact ID */
export type FintracApiContactIdMappingInput = {
  accountToken: Scalars['ID']['input'];
  fintracContactId: Scalars['Int']['input'];
};

export enum FintracApiCredentialState {
  Invalid = 'invalid',
  Untested = 'untested',
  Valid = 'valid'
}

/** Settings used for FINTRAC API integration */
export type FintracApiSettings = {
  __typename?: 'FINTRACApiSettings';
  clientId: Scalars['String']['output'];
  contactMappings: FintracApiContactIdMappingConnection;
  credentialState: FintracApiCredentialState;
  defaultFintracContactId?: Maybe<Scalars['Int']['output']>;
  defaultLocationId: Scalars['ID']['output'];
  filingInstitutionToken: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Settings used for FINTRAC API integration */
export type FintracApiSettingsContactMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** FINTRAC Public Private Partnership project */
export enum FintracPppEnum {
  Anton = 'anton',
  Athena = 'athena',
  Chameleon = 'chameleon',
  Guardian = 'guardian',
  Legion = 'legion',
  Protect = 'protect',
  Shadow = 'shadow'
}

/** FINTRAC suspicion types */
export enum FintracSuspicionTypeEnum {
  MoneyLaundering = 'money_laundering',
  MoneyLaunderingAndTerroristFinancing = 'money_laundering_and_terrorist_financing',
  TerroristFinancing = 'terrorist_financing'
}

export enum FeatureFlag {
  AutomationsMatches = 'AUTOMATIONS_MATCHES',
  AutoRelationshipIds = 'AUTO_RELATIONSHIP_IDS',
  ClickAwayToCloseCaseDrawer = 'CLICK_AWAY_TO_CLOSE_CASE_DRAWER',
  CrmSearchPermission = 'CRM_SEARCH_PERMISSION',
  DeleteDuplicateExclusionsDuringCaseMerge = 'DELETE_DUPLICATE_EXCLUSIONS_DURING_CASE_MERGE',
  DirectorySync = 'DIRECTORY_SYNC',
  DisableRemovalOfFilingResponsesFromSftp = 'DISABLE_REMOVAL_OF_FILING_RESPONSES_FROM_SFTP',
  EnableActivityDateApiFormatting = 'ENABLE_ACTIVITY_DATE_API_FORMATTING',
  EnableAiGeneratedAutomationRules = 'ENABLE_AI_GENERATED_AUTOMATION_RULES',
  EnableAiNarrativeValidation = 'ENABLE_AI_NARRATIVE_VALIDATION',
  EnableAlertIngestion = 'ENABLE_ALERT_INGESTION',
  EnableAlertOtherInfoDesignRefinements = 'ENABLE_ALERT_OTHER_INFO_DESIGN_REFINEMENTS',
  EnableAlertProcessing = 'ENABLE_ALERT_PROCESSING',
  EnableAlertStuckWebhook = 'ENABLE_ALERT_STUCK_WEBHOOK',
  EnableAlertSuppression = 'ENABLE_ALERT_SUPPRESSION',
  EnableAlwaysReturnFilingDataApi = 'ENABLE_ALWAYS_RETURN_FILING_DATA_API',
  EnableAttachmentGptVisionTranscription = 'ENABLE_ATTACHMENT_GPT_VISION_TRANSCRIPTION',
  EnableAttachmentIngestionApi = 'ENABLE_ATTACHMENT_INGESTION_API',
  EnableAttachmentSummarization = 'ENABLE_ATTACHMENT_SUMMARIZATION',
  EnableAutomationsPreviewModeUi = 'ENABLE_AUTOMATIONS_PREVIEW_MODE_UI',
  EnableAutomationEventProcessing = 'ENABLE_AUTOMATION_EVENT_PROCESSING',
  EnableAutomationPreviewModeBackend = 'ENABLE_AUTOMATION_PREVIEW_MODE_BACKEND',
  EnableAutomationRecipeNotes = 'ENABLE_AUTOMATION_RECIPE_NOTES',
  EnableAutomationRuleSettings = 'ENABLE_AUTOMATION_RULE_SETTINGS',
  EnableAutomationScheduledPreviewModeUi = 'ENABLE_AUTOMATION_SCHEDULED_PREVIEW_MODE_UI',
  EnableAutoUnlockOfReferredCases = 'ENABLE_AUTO_UNLOCK_OF_REFERRED_CASES',
  EnableBlockConnectionsAndLocationsForLargeCases = 'ENABLE_BLOCK_CONNECTIONS_AND_LOCATIONS_FOR_LARGE_CASES',
  EnableBulkCaseImport = 'ENABLE_BULK_CASE_IMPORT',
  EnableBulkCrmImport = 'ENABLE_BULK_CRM_IMPORT',
  EnableBulkTableDecryption = 'ENABLE_BULK_TABLE_DECRYPTION',
  EnableCancelingReviewsWithPendingFintracFilings = 'ENABLE_CANCELING_REVIEWS_WITH_PENDING_FINTRAC_FILINGS',
  EnableCaseAlertCountColumn = 'ENABLE_CASE_ALERT_COUNT_COLUMN',
  EnableCaseEntityAddRemoveApi = 'ENABLE_CASE_ENTITY_ADD_REMOVE_API',
  EnableCaseMerging = 'ENABLE_CASE_MERGING',
  EnableCaseTags = 'ENABLE_CASE_TAGS',
  EnableClaudeTesting = 'ENABLE_CLAUDE_TESTING',
  EnableCorrectReviewEscalationTimelineHandling = 'ENABLE_CORRECT_REVIEW_ESCALATION_TIMELINE_HANDLING',
  EnableCreditUsageEmails = 'ENABLE_CREDIT_USAGE_EMAILS',
  EnableCrmHistory = 'ENABLE_CRM_HISTORY',
  EnableCsvExport = 'ENABLE_CSV_EXPORT',
  EnableCtrFiling = 'ENABLE_CTR_FILING',
  EnableDashboardSearch = 'ENABLE_DASHBOARD_SEARCH',
  EnableDatasources = 'ENABLE_DATASOURCES',
  EnableDataCacheUnarchivedScopeRemoval = 'ENABLE_DATA_CACHE_UNARCHIVED_SCOPE_REMOVAL',
  EnableDemoMode = 'ENABLE_DEMO_MODE',
  EnableDeselectVictimsByDefault = 'ENABLE_DESELECT_VICTIMS_BY_DEFAULT',
  EnableDocumentValidationTestReport = 'ENABLE_DOCUMENT_VALIDATION_TEST_REPORT',
  EnableDownloadsPage = 'ENABLE_DOWNLOADS_PAGE',
  EnableDownloadFilings = 'ENABLE_DOWNLOAD_FILINGS',
  EnableDuplicateAlertDetection = 'ENABLE_DUPLICATE_ALERT_DETECTION',
  EnableDuplicateCaseImportDetection = 'ENABLE_DUPLICATE_CASE_IMPORT_DETECTION',
  EnableEllipticIntegration = 'ENABLE_ELLIPTIC_INTEGRATION',
  EnableEnforceReadUserCasesPermission = 'ENABLE_ENFORCE_READ_USER_CASES_PERMISSION',
  EnableErrorsForAlertsWithIdenticalExternalId = 'ENABLE_ERRORS_FOR_ALERTS_WITH_IDENTICAL_EXTERNAL_ID',
  EnableErrorOnMissingInstitutionRelationshipReference = 'ENABLE_ERROR_ON_MISSING_INSTITUTION_RELATIONSHIP_REFERENCE',
  EnableFannieMaeSftpImport = 'ENABLE_FANNIE_MAE_SFTP_IMPORT',
  EnableFastOpsMetricsCaseTriggersAndOutcomesReport = 'ENABLE_FAST_OPS_METRICS_CASE_TRIGGERS_AND_OUTCOMES_REPORT',
  EnableFilingsDashboardUsStates = 'ENABLE_FILINGS_DASHBOARD_US_STATES',
  EnableFilingInstitutionRelationshipInSarIngestion = 'ENABLE_FILING_INSTITUTION_RELATIONSHIP_IN_SAR_INGESTION',
  EnableFilingReportingDashboard = 'ENABLE_FILING_REPORTING_DASHBOARD',
  EnableFilingResponseSftpUpload = 'ENABLE_FILING_RESPONSE_SFTP_UPLOAD',
  EnableFincenRetryOnSendTimeout = 'ENABLE_FINCEN_RETRY_ON_SEND_TIMEOUT',
  EnableFintracApi = 'ENABLE_FINTRAC_API',
  EnableFintracApiAsyncFlow = 'ENABLE_FINTRAC_API_ASYNC_FLOW',
  EnableFintracApiSettingsUi = 'ENABLE_FINTRAC_API_SETTINGS_UI',
  EnableFintracClientCredentials = 'ENABLE_FINTRAC_CLIENT_CREDENTIALS',
  EnableFintracIndividualStrPopulation = 'ENABLE_FINTRAC_INDIVIDUAL_STR_POPULATION',
  EnableForceReviewTypesOnBadges = 'ENABLE_FORCE_REVIEW_TYPES_ON_BADGES',
  EnableGoAmlLinkedFilingValidations = 'ENABLE_GO_AML_LINKED_FILING_VALIDATIONS',
  EnableGraphQlAdminControls = 'ENABLE_GRAPH_QL_ADMIN_CONTROLS',
  EnableInformationCustomImport = 'ENABLE_INFORMATION_CUSTOM_IMPORT',
  EnableInformationRequests = 'ENABLE_INFORMATION_REQUESTS',
  EnableInformationRequestsReminderDigests = 'ENABLE_INFORMATION_REQUESTS_REMINDER_DIGESTS',
  EnableIngestSigmaSanctionsResults = 'ENABLE_INGEST_SIGMA_SANCTIONS_RESULTS',
  EnableInlineSubjectExternalId = 'ENABLE_INLINE_SUBJECT_EXTERNAL_ID',
  EnableIntercomTestWorkspace = 'ENABLE_INTERCOM_TEST_WORKSPACE',
  EnableInternalControlNumberGenerator = 'ENABLE_INTERNAL_CONTROL_NUMBER_GENERATOR',
  EnableKybDemo = 'ENABLE_KYB_DEMO',
  EnableLibraryFinanciaInstitutionAutoPopulate = 'ENABLE_LIBRARY_FINANCIA_INSTITUTION_AUTO_POPULATE',
  EnableLibraryMerging = 'ENABLE_LIBRARY_MERGING',
  EnableLibraryMergingBusinesses = 'ENABLE_LIBRARY_MERGING_BUSINESSES',
  EnableLibraryMergingFinancialInstitutions = 'ENABLE_LIBRARY_MERGING_FINANCIAL_INSTITUTIONS',
  EnableLinkedFilingValidationErrors = 'ENABLE_LINKED_FILING_VALIDATION_ERRORS',
  EnableMaskedPanFilingsForLibrary = 'ENABLE_MASKED_PAN_FILINGS_FOR_LIBRARY',
  EnableMiddeskIntegration = 'ENABLE_MIDDESK_INTEGRATION',
  EnableMultipleChainalysisConfigurations = 'ENABLE_MULTIPLE_CHAINALYSIS_CONFIGURATIONS',
  EnableNarrativeGenerationTemplates = 'ENABLE_NARRATIVE_GENERATION_TEMPLATES',
  EnableNarrativeStreaming = 'ENABLE_NARRATIVE_STREAMING',
  EnableNewAutomationsActivityLog = 'ENABLE_NEW_AUTOMATIONS_ACTIVITY_LOG',
  EnableNewAutomationAction = 'ENABLE_NEW_AUTOMATION_ACTION',
  EnableNewOrgAlertGroupingLogic = 'ENABLE_NEW_ORG_ALERT_GROUPING_LOGIC',
  EnableNewReviewTags = 'ENABLE_NEW_REVIEW_TAGS',
  EnableOmniAsSubprocessor = 'ENABLE_OMNI_AS_SUBPROCESSOR',
  EnableOnlyGroupAlertsIntoCasesWithoutADecision = 'ENABLE_ONLY_GROUP_ALERTS_INTO_CASES_WITHOUT_A_DECISION',
  EnableOpenAiNarrativeGeneratorDemo = 'ENABLE_OPEN_AI_NARRATIVE_GENERATOR_DEMO',
  EnableOpenAiVision = 'ENABLE_OPEN_AI_VISION',
  EnableOrganizationAutomationsBackend = 'ENABLE_ORGANIZATION_AUTOMATIONS_BACKEND',
  EnableOrganizationAutomationsFrontend = 'ENABLE_ORGANIZATION_AUTOMATIONS_FRONTEND',
  EnableOtherInfoApiUpdates = 'ENABLE_OTHER_INFO_API_UPDATES',
  EnableOtherInfoContainsFiltering = 'ENABLE_OTHER_INFO_CONTAINS_FILTERING',
  EnablePauseAutomationEventPruning = 'ENABLE_PAUSE_AUTOMATION_EVENT_PRUNING',
  EnablePeggyTransactionEditingFields = 'ENABLE_PEGGY_TRANSACTION_EDITING_FIELDS',
  EnablePerformanceMonitoring = 'ENABLE_PERFORMANCE_MONITORING',
  EnableQueueAssignmentApi = 'ENABLE_QUEUE_ASSIGNMENT_API',
  EnableReadProfileDashboardPermission = 'ENABLE_READ_PROFILE_DASHBOARD_PERMISSION',
  EnableReceiveFilings = 'ENABLE_RECEIVE_FILINGS',
  EnableRemoveDataOnApiUpdate = 'ENABLE_REMOVE_DATA_ON_API_UPDATE',
  EnableReviewAssignmentPermissions = 'ENABLE_REVIEW_ASSIGNMENT_PERMISSIONS',
  EnableReviewAssignmentWebhook = 'ENABLE_REVIEW_ASSIGNMENT_WEBHOOK',
  EnableReviewDueDateSelection = 'ENABLE_REVIEW_DUE_DATE_SELECTION',
  EnableRfiCrmRecipients = 'ENABLE_RFI_CRM_RECIPIENTS',
  EnableRiskScoreApp = 'ENABLE_RISK_SCORE_APP',
  EnableS3DownloadViaCloudfront = 'ENABLE_S3_DOWNLOAD_VIA_CLOUDFRONT',
  EnableS3UploadViaCloudfront = 'ENABLE_S3_UPLOAD_VIA_CLOUDFRONT',
  EnableSalesforceSync = 'ENABLE_SALESFORCE_SYNC',
  EnableSanctionsScreeningTab = 'ENABLE_SANCTIONS_SCREENING_TAB',
  EnableSarAdornments = 'ENABLE_SAR_ADORNMENTS',
  EnableSarRequiringCumulativeAmountForContinuingReports = 'ENABLE_SAR_REQUIRING_CUMULATIVE_AMOUNT_FOR_CONTINUING_REPORTS',
  EnableSendFilings = 'ENABLE_SEND_FILINGS',
  EnableSharingFilingErrors = 'ENABLE_SHARING_FILING_ERRORS',
  EnableShowPeerApprovalComments = 'ENABLE_SHOW_PEER_APPROVAL_COMMENTS',
  EnableSigmaIntegration = 'ENABLE_SIGMA_INTEGRATION',
  EnableSkipAndRemoveEmptyHttpImportFiles = 'ENABLE_SKIP_AND_REMOVE_EMPTY_HTTP_IMPORT_FILES',
  EnableSmartValuesInNarrativeTemplates = 'ENABLE_SMART_VALUES_IN_NARRATIVE_TEMPLATES',
  EnableSubjectReferencesInFilingsCaseResponseApi = 'ENABLE_SUBJECT_REFERENCES_IN_FILINGS_CASE_RESPONSE_API',
  EnableSuggestedKeyTerms = 'ENABLE_SUGGESTED_KEY_TERMS',
  EnableSupersetEmbeddedAnalytics = 'ENABLE_SUPERSET_EMBEDDED_ANALYTICS',
  EnableSupportAccess = 'ENABLE_SUPPORT_ACCESS',
  EnableSurveyIdInApiResponse = 'ENABLE_SURVEY_ID_IN_API_RESPONSE',
  EnableSyntheticDataGeneration = 'ENABLE_SYNTHETIC_DATA_GENERATION',
  EnableTaskPricing = 'ENABLE_TASK_PRICING',
  EnableTaskPricingAnalytics = 'ENABLE_TASK_PRICING_ANALYTICS',
  EnableTextEntryInlineComments = 'ENABLE_TEXT_ENTRY_INLINE_COMMENTS',
  EnableThomsonReutersIntegration = 'ENABLE_THOMSON_REUTERS_INTEGRATION',
  EnableTransactionEndpointColumns = 'ENABLE_TRANSACTION_ENDPOINT_COLUMNS',
  EnableUnversionedApiCalls = 'ENABLE_UNVERSIONED_API_CALLS',
  EnableWootricSurveys = 'ENABLE_WOOTRIC_SURVEYS',
  EnforceDashboardAssigneeViewPermission = 'ENFORCE_DASHBOARD_ASSIGNEE_VIEW_PERMISSION',
  FixExcessiveFintracValidationRuns = 'FIX_EXCESSIVE_FINTRAC_VALIDATION_RUNS',
  ForceCoinbaseCleOptions = 'FORCE_COINBASE_CLE_OPTIONS',
  GermanyCryptoSubjectMapping = 'GERMANY_CRYPTO_SUBJECT_MAPPING',
  GermanySignatoryRetailMapping = 'GERMANY_SIGNATORY_RETAIL_MAPPING',
  InterpreterCsvTemplates = 'INTERPRETER_CSV_TEMPLATES',
  ReadTransactionsNewIndex = 'READ_TRANSACTIONS_NEW_INDEX',
  ReferMinimalInformation = 'REFER_MINIMAL_INFORMATION',
  ReviewsToQueues = 'REVIEWS_TO_QUEUES',
  RichTextForMultilineQuestions = 'RICH_TEXT_FOR_MULTILINE_QUESTIONS',
  SnoozeReviewTimingPolicy = 'SNOOZE_REVIEW_TIMING_POLICY',
  StopIndexingTransactionsOldIndex = 'STOP_INDEXING_TRANSACTIONS_OLD_INDEX',
  StrReportSummaryPdf = 'STR_REPORT_SUMMARY_PDF',
  SuperInfoDataTypes = 'SUPER_INFO_DATA_TYPES',
  SuperInfoTransactionsEditModal = 'SUPER_INFO_TRANSACTIONS_EDIT_MODAL',
  TurnOffRfiReminders = 'TURN_OFF_RFI_REMINDERS',
  UpsertLibraryLinkedInvestigationObjects = 'UPSERT_LIBRARY_LINKED_INVESTIGATION_OBJECTS',
  UseHbFintracReferenceNumber = 'USE_HB_FINTRAC_REFERENCE_NUMBER',
  UseSpecialHandlingForGermanFiling = 'USE_SPECIAL_HANDLING_FOR_GERMAN_FILING'
}

export type FeatureFlagState = {
  __typename?: 'FeatureFlagState';
  enabled: Scalars['Boolean']['output'];
  flag: FeatureFlag;
};

export enum FederalRegulatorEnum {
  Bafin = 'BAFIN',
  Cftc = 'CFTC',
  Fdic = 'FDIC',
  Fhfa = 'FHFA',
  Fintrac = 'FINTRAC',
  Frb = 'FRB',
  Fspk = 'FSPK',
  Irs = 'IRS',
  Ncua = 'NCUA',
  NotApplicable = 'NOT_APPLICABLE',
  Occ = 'OCC',
  Sec = 'SEC'
}

export type FederalRegulatorMetadata = {
  __typename?: 'FederalRegulatorMetadata';
  active: Scalars['Boolean']['output'];
  value: FederalRegulatorEnum;
};

export type FederalRegulatorMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: FederalRegulatorEnum;
};

/** Autogenerated input type of FetchDirectorySyncGroups */
export type FetchDirectorySyncGroupsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of FetchDirectorySyncGroups. */
export type FetchDirectorySyncGroupsPayload = {
  __typename?: 'FetchDirectorySyncGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directorySyncGroups: Array<DirectorySyncGroup>;
};

export type FileDownloadAuthorization = {
  __typename?: 'FileDownloadAuthorization';
  filename: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

/** A model encapsulating a Filing. */
export type Filing = {
  __typename?: 'Filing';
  activityEndDate: Scalars['ISO8601DateTime']['output'];
  activityStartDate: Scalars['ISO8601DateTime']['output'];
  assignee: Account;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentState: FilingStateEnum;
  currentStateString: Scalars['String']['output'];
  filingInstitution: FilingInstitution;
  investigationReview: Review;
  response?: Maybe<FilingResponse>;
  subdivisions?: Maybe<Array<Scalars['String']['output']>>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** An event in the lifecycle of a filing. */
export type FilingEvent = {
  __typename?: 'FilingEvent';
  details: Array<FilingEventDetail>;
  identifiers: Array<FilingEventIdentifier>;
  status: FilingEventStatusEnum;
  timestamp: Scalars['ISO8601DateTime']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Detailed description of the filing event and other relevant data. */
export type FilingEventDetail = FilingEventInfoTable | FilingEventParagraph;

/** Filing event detail types. */
export enum FilingEventDetailTypeEnum {
  InfoTable = 'info_table',
  Paragraph = 'paragraph'
}

/** A key piece of information identifying a filing. */
export type FilingEventIdentifier = {
  __typename?: 'FilingEventIdentifier';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** A row in a filing events info table. */
export type FilingEventInfoRow = {
  __typename?: 'FilingEventInfoRow';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** A set of attributes describing a filing event */
export type FilingEventInfoTable = {
  __typename?: 'FilingEventInfoTable';
  name: Scalars['String']['output'];
  rows: Array<FilingEventInfoRow>;
  type: FilingEventDetailTypeEnum;
};

/** Text description providing extra details on the filing event. */
export type FilingEventParagraph = {
  __typename?: 'FilingEventParagraph';
  type: FilingEventDetailTypeEnum;
  value: Scalars['String']['output'];
};

/** Filing event statuses. */
export enum FilingEventStatusEnum {
  Completed = 'completed',
  Pending = 'pending',
  Rejected = 'rejected',
  Warning = 'warning'
}

/** A model encapsulating a FilingInstitution. */
export type FilingInstitution = {
  __typename?: 'FilingInstitution';
  fintracApiSettings?: Maybe<FintracApiSettings>;
  name: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export enum FilingJurisdictionEnum {
  Bermuda = 'BERMUDA',
  FicGhana = 'FIC_GHANA',
  Fincen = 'FINCEN',
  Fintrac = 'FINTRAC',
  Germany = 'GERMANY',
  Gnecb = 'GNECB',
  Nca = 'NCA',
  Netherlands = 'NETHERLANDS',
  Nfiu = 'NFIU',
  None = 'NONE',
  Sweden = 'SWEDEN'
}

/** Jurisdiction-specific configurations. */
export type FilingJurisdictionRules = {
  token: Scalars['ID']['output'];
};

/** A model encapsulating a Filing Response. */
export type FilingResponse = {
  __typename?: 'FilingResponse';
  bsaId: Scalars['String']['output'];
};

/** FinCEN filing states */
export enum FilingStateEnum {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Fatal = 'fatal',
  Filed = 'filed',
  ManuallyRejected = 'manually_rejected',
  Paused = 'paused',
  Pending = 'pending',
  PrimaryWarning = 'primary_warning',
  Rejected = 'rejected',
  RejectedAndRetried = 'rejected_and_retried',
  SendTimeout = 'send_timeout',
  Warning = 'warning'
}

/** Filing types. */
export enum FilingTypeEnum {
  Continuing = 'CONTINUING',
  CorrectOrAmend = 'CORRECT_OR_AMEND',
  CorrectOrAmendContinuing = 'CORRECT_OR_AMEND_CONTINUING',
  FincenDirectedBackfiling = 'FINCEN_DIRECTED_BACKFILING',
  Initial = 'INITIAL',
  Poca = 'POCA',
  Terrorism = 'TERRORISM'
}

/** A normalized filter with group reference */
export type FilterInput = {
  field: Scalars['String']['input'];
  group: Scalars['Int']['input'];
  predicate: PredicateInput;
};

/** Filter option for a column */
export type FilterOptionV2 = {
  __typename?: 'FilterOptionV2';
  column: Scalars['String']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
};

/** A filter entry */
export type FilterOutput = {
  __typename?: 'FilterOutput';
  field: Scalars['String']['output'];
  group: Scalars['Int']['output'];
  predicate: PredicateOutput;
};

/** A column that can be filtered upon */
export type FilterableColumn = {
  __typename?: 'FilterableColumn';
  /** Specifies key for searching aggregations values */
  aggsKey?: Maybe<Scalars['String']['output']>;
  apiName: Scalars['String']['output'];
  /** Path to retrieve the value used for filtering */
  filterPath?: Maybe<Array<Scalars['String']['output']>>;
  /** Specifies whether a column is hidden by default */
  hidden: Scalars['Boolean']['output'];
  /** Specifies whether a column can be hidden */
  hideable?: Maybe<Scalars['Boolean']['output']>;
  /** Specifies whether a column is sortable */
  sortable: Scalars['Boolean']['output'];
  /** Column Title */
  title: Scalars['String']['output'];
  /** Field value type */
  type?: Maybe<TermsTypeEnum>;
  /** Path to retrieve the value of a cell */
  valuePath: Array<Scalars['String']['output']>;
};

export type FinancialInstitutionAttributes = {
  address?: InputMaybe<AddressInput>;
  addressesMetadata?: InputMaybe<Array<AddressMetadataAttributes>>;
  alternateNames?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  classification?: InputMaybe<ClassificationAttributes>;
  classifications?: InputMaybe<Array<ClassificationMetadataAttributes>>;
  contact?: InputMaybe<ContactAttributes>;
  contacts?: InputMaybe<Array<ContactMetadataAttributes>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  identification?: InputMaybe<IdentificationAttributes>;
  identifications?: InputMaybe<Array<IdentificationMetadataAttributes>>;
  name?: InputMaybe<Scalars['String']['input']>;
  namesMetadata?: InputMaybe<Array<NameStringMetadataAttributes>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  primaryFederalRegulator?: InputMaybe<FederalRegulatorEnum>;
  primaryFederalRegulators?: InputMaybe<Array<FederalRegulatorMetadataAttributes>>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  swiftCodes?: InputMaybe<Array<SwiftCodeMetadataAttributes>>;
  tin?: InputMaybe<TinAttributes>;
  tinsMetadata?: InputMaybe<Array<TinsMetadataAttributes>>;
};

export enum FinancialInstitutionSubtypeEnum {
  CardClub = 'CARD_CLUB',
  ClearingBrokerSecurities = 'CLEARING_BROKER_SECURITIES',
  ExecutionOnlyBrokerSecurities = 'EXECUTION_ONLY_BROKER_SECURITIES',
  FuturesCommissionMerchant = 'FUTURES_COMMISSION_MERCHANT',
  HoldingCompany = 'HOLDING_COMPANY',
  IntroducingBrokerCommodities = 'INTRODUCING_BROKER_COMMODITIES',
  IntroducingBrokerSecurities = 'INTRODUCING_BROKER_SECURITIES',
  InvestmentAdviser = 'INVESTMENT_ADVISER',
  InvestmentCompany = 'INVESTMENT_COMPANY',
  Other = 'OTHER',
  RetailForeignExchangeDealer = 'RETAIL_FOREIGN_EXCHANGE_DEALER',
  SelfClearingBrokerSecurities = 'SELF_CLEARING_BROKER_SECURITIES',
  StateLicensedCasino = 'STATE_LICENSED_CASINO',
  SubsidiaryOfBank = 'SUBSIDIARY_OF_BANK',
  TribalAuthorizedCasino = 'TRIBAL_AUTHORIZED_CASINO'
}

export type FinancialInstitutionTxnEntry = {
  attributes: FinancialInstitutionAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export enum FinancialInstitutionTypeEnum {
  CasinoOrCardClub = 'CASINO_OR_CARD_CLUB',
  DepositoryInstitution = 'DEPOSITORY_INSTITUTION',
  HousingGse = 'HOUSING_GSE',
  InsuranceCompany = 'INSURANCE_COMPANY',
  LoanOrFinanceCompany = 'LOAN_OR_FINANCE_COMPANY',
  Msb = 'MSB',
  Other = 'OTHER',
  SecuritiesOrFutures = 'SECURITIES_OR_FUTURES'
}

/** A global FinCEN advisory which is used to inject key terms into narratives. */
export type FincenAdvisory = {
  __typename?: 'FincenAdvisory';
  advisoryCode: Scalars['String']['output'];
  advisoryUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  keyTerm: Scalars['String']['output'];
  publishedAt: Scalars['ISO8601DateTime']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for FincenAdvisory. */
export type FincenAdvisoryConnection = {
  __typename?: 'FincenAdvisoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FincenAdvisoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FincenAdvisory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FincenAdvisoryEdge = {
  __typename?: 'FincenAdvisoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FincenAdvisory>;
};

/** An Information Request Recipient and the associated organization. */
export type FindInformationRequestRecipientByExternalTokenQueryResult = {
  __typename?: 'FindInformationRequestRecipientByExternalTokenQueryResult';
  errorMessages?: Maybe<Array<Scalars['String']['output']>>;
  inboundRequestCaseToken?: Maybe<Scalars['ID']['output']>;
  inboundRequestOrganizationToken?: Maybe<Scalars['ID']['output']>;
  inboundRequestReviewToken?: Maybe<Scalars['ID']['output']>;
  inboundRequestToken?: Maybe<Scalars['ID']['output']>;
  informationRequestRecipient?: Maybe<InformationRequestRecipient>;
  organization?: Maybe<Organization>;
};

/** Detailed description of the filing event and other relevant data. */
export type FormConfigurationByPathUnion = InfoRequestPath | TipIntakeConfiguration;

export type FrequentCaseAlertRule = {
  __typename?: 'FrequentCaseAlertRule';
  count: Scalars['Int']['output'];
  rule: Scalars['String']['output'];
};

export type GenderMetadata = {
  __typename?: 'GenderMetadata';
  active: Scalars['Boolean']['output'];
  value: LibraryGenderEnum;
};

export type GenderMetadataInput = {
  active: Scalars['Boolean']['input'];
  value: LibraryGenderEnum;
};

/** Autogenerated input type of GenerateScreeningSearchReport */
export type GenerateScreeningSearchReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investigationToken?: InputMaybe<Scalars['ID']['input']>;
  libraryToken: Scalars['ID']['input'];
  provider: ScreeningSearchProvider;
  sourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenerateScreeningSearchReport. */
export type GenerateScreeningSearchReportPayload = {
  __typename?: 'GenerateScreeningSearchReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigationAttachment?: Maybe<Attachment>;
  libraryAttachment: LibraryAttachment;
};

export enum GeneratedDocumentType {
  CtrResponse = 'CTR_RESPONSE',
  CtrSummary = 'CTR_SUMMARY',
  CtrXml = 'CTR_XML',
  FintracStrJson = 'FINTRAC_STR_JSON',
  FintracStrJsonPreview = 'FINTRAC_STR_JSON_PREVIEW',
  FintracStrSummary = 'FINTRAC_STR_SUMMARY',
  FintracStrSummaryPreview = 'FINTRAC_STR_SUMMARY_PREVIEW',
  GoamlXml = 'GOAML_XML',
  InformationRequestResponse = 'INFORMATION_REQUEST_RESPONSE',
  InvestigationAttachment = 'INVESTIGATION_ATTACHMENT',
  InvestigationDocuments = 'INVESTIGATION_DOCUMENTS',
  RequestForInformationResponse = 'REQUEST_FOR_INFORMATION_RESPONSE',
  SarPdf = 'SAR_PDF',
  SarResponse = 'SAR_RESPONSE',
  SarXml = 'SAR_XML',
  Summary = 'SUMMARY',
  SurveyFile = 'SURVEY_FILE',
  TipSubmission = 'TIP_SUBMISSION',
  TransactionsCsv = 'TRANSACTIONS_CSV',
  TransactionsCsvAll = 'TRANSACTIONS_CSV_ALL'
}

/** Generic jurisdiction-specific configurations. */
export type GenericFilingJurisdictionRules = FilingJurisdictionRules & {
  __typename?: 'GenericFilingJurisdictionRules';
  token: Scalars['ID']['output'];
};

export type Geopoint = {
  __typename?: 'Geopoint';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

/** GoAML jurisdiction-specific configurations. */
export type GoAmlFilingJurisdictionRules = FilingJurisdictionRules & {
  __typename?: 'GoAMLFilingJurisdictionRules';
  currencyCodes: Array<Scalars['String']['output']>;
  reportCodes: Array<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

/** A normalized combinator and parent reference */
export type GroupInput = {
  combinator: CombinatorEnum;
  parent?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter group */
export type GroupOutput = {
  __typename?: 'GroupOutput';
  combinator: CombinatorEnum;
  parent?: Maybe<Scalars['Int']['output']>;
};

export type HbMoney = {
  __typename?: 'HbMoney';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
  formatted: Scalars['String']['output'];
  isoCurrency: Scalars['Boolean']['output'];
};

export type HbMoneyInput = {
  amount: Scalars['Decimal']['input'];
  currency: Scalars['String']['input'];
  isoCurrency?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Status and possible result of a history export job. */
export type HistoryExportJob = Job & {
  __typename?: 'HistoryExportJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  presignedUrl?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

export type Identification = {
  __typename?: 'Identification';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<InstitutionIdTypeEnum>;
};

export type IdentificationAttributes = {
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type IdentificationMetadata = {
  __typename?: 'IdentificationMetadata';
  active: Scalars['Boolean']['output'];
  value: Identification;
};

export type IdentificationMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: IdentificationAttributes;
};

export type IdentityDocumentAttributes = {
  expiresOn?: InputMaybe<Scalars['DateParts']['input']>;
  issuingCountryCode?: InputMaybe<Scalars['String']['input']>;
  issuingStateCode?: InputMaybe<Scalars['String']['input']>;
  nationalityCountryCode?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IdentityDocumentTypeEnum>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export enum IdentityDocumentTypeEnum {
  DriversLicense = 'DRIVERS_LICENSE',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  UkCarRegistration = 'UK_CAR_REGISTRATION',
  UkNationalHealthServiceNumber = 'UK_NATIONAL_HEALTH_SERVICE_NUMBER',
  UkNationalInsuranceNumber = 'UK_NATIONAL_INSURANCE_NUMBER',
  UkProofOfAgeStandardSchemeNumber = 'UK_PROOF_OF_AGE_STANDARD_SCHEME_NUMBER',
  Unknown = 'UNKNOWN',
  UsAlienRegistration = 'US_ALIEN_REGISTRATION'
}

/** A model encapsulating an Inbound Request sent from one organization to another. */
export type InboundRequest = {
  __typename?: 'InboundRequest';
  informationRequest?: Maybe<InformationRequest>;
  informationRequestResponse?: Maybe<InformationRequestResponse>;
  organization: Organization;
  review?: Maybe<Review>;
  sendingOrganizationName?: Maybe<Scalars['String']['output']>;
  status: InboundRequestStatusEnum;
  token: Scalars['ID']['output'];
};

/** The status of an inbound request. */
export enum InboundRequestStatusEnum {
  /** An inbound request was cancelled. */
  CancelledBySender = 'cancelled_by_sender',
  /** An inbound request that has been declined by the recipient. */
  Declined = 'declined',
  /** An inbound request that is overdue. */
  Overdue = 'overdue',
  /** An inbound request is awaiting a response. */
  Pending = 'pending',
  /** A recipient responded to an inbound request. */
  Responded = 'responded'
}

/** For populating Included N of Total Count */
export type IncludedAndTotalSubjectCounts = {
  __typename?: 'IncludedAndTotalSubjectCounts';
  includedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** An incomplete RFI */
export type IncompleteRequestForInformation = {
  __typename?: 'IncompleteRequestForInformation';
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  externalToken: Scalars['ID']['output'];
  form: RequestForInformationForm;
  organization: Organization;
  response?: Maybe<SurveyResponse>;
  sendDecisionedAt: Scalars['ISO8601DateTime']['output'];
  senderSurvey?: Maybe<Survey>;
  senderSurveyResponse?: Maybe<SurveyResponse>;
  token: Scalars['ID']['output'];
};

/** A cyber event indicator for FinCEN reporting. */
export type Indicator = {
  __typename?: 'Indicator';
  device?: Maybe<LibraryDevice>;
  eventType: CyberEventIndicatorTypeEnum;
  eventTypeOther?: Maybe<Scalars['String']['output']>;
  review: Review;
  token: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** The base path of the information request form */
export type InfoRequestPath = {
  __typename?: 'InfoRequestPath';
  path: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** A model encapsulating an Information Request. */
export type InformationRequest = {
  __typename?: 'InformationRequest';
  allowResponses?: Maybe<Scalars['Boolean']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelledBy?: Maybe<Account>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy?: Maybe<Account>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  daysOverdue?: Maybe<Scalars['Int']['output']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  declinedBy?: Maybe<Account>;
  declinedReason?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  dueAt: Scalars['ISO8601DateTime']['output'];
  externalToken: Scalars['ID']['output'];
  hasInboundRequest: Scalars['Boolean']['output'];
  informationRequestForm: InformationRequestForm;
  informationRequestRecipients: Array<InformationRequestRecipient>;
  informationRequestResponses: Array<InformationRequestResponse>;
  informationRequestSubscribers: Array<InformationRequestSubscriber>;
  investigations: Array<Investigation>;
  lastReminderSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastRespondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  nextReminderDueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  recipientSurvey: Survey;
  relatedLibraryEntries?: Maybe<Array<LibraryEntry>>;
  reminderConfigurationLabel: Scalars['String']['output'];
  reminderTimeline: Array<Scalars['ISO8601DateTime']['output']>;
  remindersLastToggledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  remindersLastToggledBy?: Maybe<Account>;
  remindersPaused: Scalars['Boolean']['output'];
  senderSurvey: Survey;
  senderSurveyResponse: SurveyResponse;
  status: InformationRequestStatusEnum;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for InformationRequest. */
export type InformationRequestConnection = {
  __typename?: 'InformationRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InformationRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InformationRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InformationRequestEdge = {
  __typename?: 'InformationRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InformationRequest>;
};

/** A model encapsulating configuration details for generating Information Request Forms. */
export type InformationRequestForm = {
  __typename?: 'InformationRequestForm';
  defaultDueInDays?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  organization: Organization;
  recipientSurvey: Survey;
  reminderConfigurations: Array<InformationRequestFormReminderConfiguration>;
  senderSurvey: Survey;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** A model encapsulating parameters that can be set when creating an Information Request. */
export type InformationRequestFormReminderConfiguration = {
  __typename?: 'InformationRequestFormReminderConfiguration';
  delayDays?: Maybe<Scalars['Int']['output']>;
  frequencyDays?: Maybe<Scalars['Int']['output']>;
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

/** Library link input */
export type InformationRequestLibraryLinkInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  libraryToken: Scalars['ID']['input'];
  libraryType: InformationRequestLinkedObject;
};

/** A type of Library Object that an Information Request can be linked to. */
export enum InformationRequestLinkedObject {
  BankAccounts = 'bank_accounts',
  Businesses = 'businesses',
  CryptoAddresses = 'crypto_addresses',
  Devices = 'devices',
  FinancialInstitutions = 'financial_institutions',
  PaymentCards = 'payment_cards',
  People = 'people',
  Products = 'products'
}

/** A model encapsulating an Information Request Recipient. */
export type InformationRequestRecipient = {
  __typename?: 'InformationRequestRecipient';
  createdAt: Scalars['ISO8601DateTime']['output'];
  customUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  externalUrl: Scalars['String']['output'];
  informationRequest: InformationRequest;
  informationRequestResponses: Array<InformationRequestResponse>;
  name: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Recipient input */
export type InformationRequestRecipientInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  token?: InputMaybe<Scalars['ID']['input']>;
};

/** A model encapsulating an Information Request Response. */
export type InformationRequestResponse = {
  __typename?: 'InformationRequestResponse';
  createdAt: Scalars['ISO8601DateTime']['output'];
  informationRequestRecipient: InformationRequestRecipient;
  surveyResponse: SurveyResponse;
  token: Scalars['ID']['output'];
};

/** A model encapsulating a link to download a copy of an Information Request Response. */
export type InformationRequestResponseCopy = {
  __typename?: 'InformationRequestResponseCopy';
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

/** An information request search result */
export type InformationRequestSearchResult = {
  __typename?: 'InformationRequestSearchResult';
  investigation: Investigation;
  title?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

/** The status of an information request */
export enum InformationRequestStatusEnum {
  /** An information request was cancelled. */
  Cancelled = 'cancelled',
  /** An information request was marked completed. */
  Completed = 'completed',
  /** An information request was declined. */
  Declined = 'declined',
  /** An information request that is overdue. */
  Overdue = 'overdue',
  /** An information request was sent to recipients. */
  RequestSent = 'request_sent',
  /** A recipient responded to an information request. */
  ResponseReceived = 'response_received'
}

/** A model encapsulating an Information Request Subscriber. */
export type InformationRequestSubscriber = {
  __typename?: 'InformationRequestSubscriber';
  email: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type InformationSchema = {
  __typename?: 'InformationSchema';
  columns: Array<InformationSchemaColumn>;
};

export type InformationSchemaColumn = {
  __typename?: 'InformationSchemaColumn';
  columnName: Scalars['String']['output'];
  tableCatalog: Scalars['String']['output'];
  tableName: Scalars['String']['output'];
  tableSchema: Scalars['String']['output'];
};

export enum InstitutionIdTypeEnum {
  Crd = 'CRD',
  Fintrac = 'FINTRAC',
  Gcr = 'GCR',
  Iard = 'IARD',
  Naic = 'NAIC',
  Nmls = 'NMLS',
  Rssd = 'RSSD',
  Sec = 'SEC'
}

export type InstitutionRelationshipAttributes = {
  actionedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  relationship?: InputMaybe<InstitutionRelationshipTypeAttributes>;
  status?: InputMaybe<InstitutionRelationshipStatusEnum>;
};

export enum InstitutionRelationshipStatusEnum {
  RelationshipContinues = 'RELATIONSHIP_CONTINUES',
  Resigned = 'RESIGNED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

export type InstitutionRelationshipTxnEntry = {
  attributes: InstitutionRelationshipAttributes;
  business?: InputMaybe<LibraryObjectRelation>;
  institution?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
  person?: InputMaybe<LibraryObjectRelation>;
};

export type InstitutionRelationshipTypeAttributes = {
  type?: InputMaybe<InstitutionRelationshipTypeEnum>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export enum InstitutionRelationshipTypeEnum {
  Accountant = 'ACCOUNTANT',
  Agent = 'AGENT',
  Appraiser = 'APPRAISER',
  Attorney = 'ATTORNEY',
  Borrower = 'BORROWER',
  Customer = 'CUSTOMER',
  Director = 'DIRECTOR',
  Employee = 'EMPLOYEE',
  None = 'NONE',
  Officer = 'OFFICER',
  Other = 'OTHER',
  Owner = 'OWNER'
}

export type Investigation = {
  __typename?: 'Investigation';
  activeLock?: Maybe<InvestigationLock>;
  alertGroup: CaseAlertGroup;
  alertGroups: CaseAlertGroupConnection;
  /** Alerts from Associated Reviews */
  alerts: Array<CaseAlert>;
  attachment: Attachment;
  attachmentSummary: AttachmentSummary;
  attachments: AttachmentConnection;
  blocked: Scalars['Boolean']['output'];
  businesses: InvestigationSubjectBusinessConnection;
  caseAlertCount: Scalars['Int']['output'];
  commentCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  cryptoAddresses: InvestigationSubjectCryptoAddressConnection;
  downloadFilesDisabled: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  financialInstitutions: InvestigationSubjectFinancialInstitutionConnection;
  frequentCaseAlertRules?: Maybe<Array<FrequentCaseAlertRule>>;
  inboundRequests: Array<InboundRequest>;
  informationRequests: InformationRequestConnection;
  maxCaseAlertTriggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  merging: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  narrativeAutoCompleteDisabled: Scalars['Boolean']['output'];
  organizationAlerts: Array<OrganizationAlert>;
  otherInfo: Array<OtherInfoEntry>;
  people: InvestigationSubjectPersonConnection;
  products: InvestigationSubjectProductConnection;
  reviewCount: Scalars['Int']['output'];
  reviews: Array<Review>;
  search?: Maybe<Array<InvestigationSearchResult>>;
  searchLibrarySubjects: LibraryEntryConnection;
  /** Secondary subjects listed under "Other Accounts & Devices" */
  secondarySubjects: InvestigationSecondarySubjectConnection;
  subject: InvestigationSubject;
  subjects: Array<InvestigationSubject>;
  taggedWith: Array<Tag>;
  timelineEvents: TimelineEventConnection;
  timelineEventsUpToDate: Scalars['Boolean']['output'];
  token: Scalars['ID']['output'];
  transaction: Transaction;
  transactionStatistics: TransactionStatistics;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  version: Scalars['String']['output'];
};


export type InvestigationAlertGroupArgs = {
  token: Scalars['String']['input'];
};


export type InvestigationAlertGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvestigationAttachmentArgs = {
  attachmentToken: Scalars['String']['input'];
};


export type InvestigationAttachmentSummaryArgs = {
  attachmentSummaryToken: Scalars['String']['input'];
};


export type InvestigationAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvestigationBusinessesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type InvestigationCryptoAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type InvestigationFinancialInstitutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type InvestigationInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  respondedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type InvestigationPeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type InvestigationProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type InvestigationSearchArgs = {
  query: Scalars['String']['input'];
};


export type InvestigationSearchLibrarySubjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeOwned?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  subjectTypes?: InputMaybe<Array<LibraryEntryTypeEnum>>;
};


export type InvestigationSecondarySubjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  subjectTypes?: InputMaybe<Array<SecondarySubjectTypeEnum>>;
};


export type InvestigationSubjectArgs = {
  token: Scalars['ID']['input'];
  type: LibraryTypeEnum;
};


export type InvestigationTimelineEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvestigationTransactionArgs = {
  transactionToken: Scalars['String']['input'];
};

/** The connection type for Investigation. */
export type InvestigationConnection = {
  __typename?: 'InvestigationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Investigation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

export type InvestigationDocumentDownload = {
  __typename?: 'InvestigationDocumentDownload';
  filename: Scalars['String']['output'];
  presignedGetUrl: Scalars['String']['output'];
  presignedHeadUrl: Scalars['String']['output'];
};

/** An investigation document */
export type InvestigationDownloadDocumentInput = {
  documentType: GeneratedDocumentType;
  token: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type InvestigationEdge = {
  __typename?: 'InvestigationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Investigation>;
};

/** Updated data */
export type InvestigationInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InvestigationLibraryBankAccountSearchResult = {
  __typename?: 'InvestigationLibraryBankAccountSearchResult';
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryBusinessSearchResult = {
  __typename?: 'InvestigationLibraryBusinessSearchResult';
  dbaNames?: Maybe<Array<Scalars['String']['output']>>;
  legalNames?: Maybe<Array<Scalars['String']['output']>>;
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryCryptoAddressSearchResult = {
  __typename?: 'InvestigationLibraryCryptoAddressSearchResult';
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryDeviceSearchResult = {
  __typename?: 'InvestigationLibraryDeviceSearchResult';
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryFinancialInstitutionSearchResult = {
  __typename?: 'InvestigationLibraryFinancialInstitutionSearchResult';
  address?: Maybe<Address>;
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryPaymentCardSearchResult = {
  __typename?: 'InvestigationLibraryPaymentCardSearchResult';
  externalId?: Maybe<Scalars['String']['output']>;
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryPersonSearchResult = {
  __typename?: 'InvestigationLibraryPersonSearchResult';
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<PersonName>;
  token: Scalars['ID']['output'];
};

export type InvestigationLibraryProductSearchResult = {
  __typename?: 'InvestigationLibraryProductSearchResult';
  linkedObjectToken?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationLock = {
  __typename?: 'InvestigationLock';
  createdAt: Scalars['ISO8601DateTime']['output'];
  txn?: Maybe<LibraryTxn>;
};

/** The enum of the approval decision */
export enum InvestigationReviewApprovalDecisionEnum {
  Approve = 'APPROVE',
  RequestChanges = 'REQUEST_CHANGES'
}

/** The enum of the approval status */
export enum InvestigationReviewApprovalStatusEnum {
  Approved = 'APPROVED',
  ChangesRequested = 'CHANGES_REQUESTED'
}

/** The enum of a reviews origin relationship type */
export enum InvestigationReviewRelationshipTypeEnum {
  Escalation = 'escalation',
  Monitoring = 'monitoring'
}

/** The enum of the reviews dashboard value for the status column */
export enum InvestigationReviewStatusEnum {
  Approved = 'approved',
  Cancelled = 'cancelled',
  ChangesRequested = 'changes_requested',
  Completed = 'completed',
  Created = 'created',
  Opened = 'opened',
  Pending = 'pending',
  ReadyForReview = 'ready_for_review',
  WorkStarted = 'work_started'
}

export type InvestigationSearchResult = InvestigationLibraryBankAccountSearchResult | InvestigationLibraryBusinessSearchResult | InvestigationLibraryCryptoAddressSearchResult | InvestigationLibraryDeviceSearchResult | InvestigationLibraryFinancialInstitutionSearchResult | InvestigationLibraryPaymentCardSearchResult | InvestigationLibraryPersonSearchResult | InvestigationLibraryProductSearchResult;

export type InvestigationSecondarySubject = {
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSecondarySubjectIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSecondarySubject. */
export type InvestigationSecondarySubjectConnection = {
  __typename?: 'InvestigationSecondarySubjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSecondarySubjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSecondarySubject>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSecondarySubjectEdge = {
  __typename?: 'InvestigationSecondarySubjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSecondarySubject>;
};

export type InvestigationSubject = {
  errors: Array<InvestigationSubjectError>;
  token: Scalars['String']['output'];
};

export type InvestigationSubjectBankAccount = InvestigationSecondarySubject & InvestigationSubject & {
  __typename?: 'InvestigationSubjectBankAccount';
  bankAccount?: Maybe<LibraryBankAccount>;
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectBankAccountIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

export type InvestigationSubjectBranch = {
  __typename?: 'InvestigationSubjectBranch';
  address: Address;
  createdAt: Scalars['ISO8601DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  roleInTransaction?: Maybe<RoleInTransaction>;
  rssdNumber?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

export type InvestigationSubjectBusiness = InvestigationSubject & InvestigationSubjectEntityShared & {
  __typename?: 'InvestigationSubjectBusiness';
  associatedBankAccountCount: IncludedAndTotalSubjectCounts;
  associatedCryptoAddressCount: IncludedAndTotalSubjectCounts;
  associatedDeviceCount: IncludedAndTotalSubjectCounts;
  associatedPaymentCardCount: IncludedAndTotalSubjectCounts;
  business?: Maybe<LibraryBusiness>;
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  roleInActivity?: Maybe<RoleInActivity>;
  roleInActivityOther?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectBusinessAssociatedBankAccountCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectBusinessAssociatedCryptoAddressCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectBusinessAssociatedDeviceCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectBusinessAssociatedPaymentCardCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectBusinessIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSubjectBusiness. */
export type InvestigationSubjectBusinessConnection = {
  __typename?: 'InvestigationSubjectBusinessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSubjectBusinessEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSubjectBusiness>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSubjectBusinessEdge = {
  __typename?: 'InvestigationSubjectBusinessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSubjectBusiness>;
};

export type InvestigationSubjectCryptoAddress = InvestigationSecondarySubject & InvestigationSubject & {
  __typename?: 'InvestigationSubjectCryptoAddress';
  cryptoAddress?: Maybe<LibraryCryptoAddress>;
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectCryptoAddressIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSubjectCryptoAddress. */
export type InvestigationSubjectCryptoAddressConnection = {
  __typename?: 'InvestigationSubjectCryptoAddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSubjectCryptoAddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSubjectCryptoAddress>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSubjectCryptoAddressEdge = {
  __typename?: 'InvestigationSubjectCryptoAddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSubjectCryptoAddress>;
};

export type InvestigationSubjectDevice = InvestigationSecondarySubject & InvestigationSubject & {
  __typename?: 'InvestigationSubjectDevice';
  device?: Maybe<LibraryDevice>;
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectDeviceIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

export type InvestigationSubjectEntityShared = {
  associatedBankAccountCount: IncludedAndTotalSubjectCounts;
  associatedCryptoAddressCount: IncludedAndTotalSubjectCounts;
  associatedDeviceCount: IncludedAndTotalSubjectCounts;
  associatedPaymentCardCount: IncludedAndTotalSubjectCounts;
};


export type InvestigationSubjectEntitySharedAssociatedBankAccountCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectEntitySharedAssociatedCryptoAddressCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectEntitySharedAssociatedDeviceCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectEntitySharedAssociatedPaymentCardCountArgs = {
  reviewToken: Scalars['String']['input'];
};

export type InvestigationSubjectError = {
  __typename?: 'InvestigationSubjectError';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type InvestigationSubjectFinancialInstitution = InvestigationSubject & {
  __typename?: 'InvestigationSubjectFinancialInstitution';
  branches: Array<InvestigationSubjectBranch>;
  errors: Array<InvestigationSubjectError>;
  financialInstitution?: Maybe<LibraryFinancialInstitution>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  internalControlNumber?: Maybe<Scalars['String']['output']>;
  lossToInstitution?: Maybe<Scalars['String']['output']>;
  roleInTransaction?: Maybe<RoleInTransaction>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectFinancialInstitutionIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSubjectFinancialInstitution. */
export type InvestigationSubjectFinancialInstitutionConnection = {
  __typename?: 'InvestigationSubjectFinancialInstitutionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSubjectFinancialInstitutionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSubjectFinancialInstitution>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSubjectFinancialInstitutionEdge = {
  __typename?: 'InvestigationSubjectFinancialInstitutionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSubjectFinancialInstitution>;
};

export type InvestigationSubjectPaymentCard = InvestigationSecondarySubject & InvestigationSubject & {
  __typename?: 'InvestigationSubjectPaymentCard';
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  paymentCard?: Maybe<LibraryPaymentCard>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectPaymentCardIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

export type InvestigationSubjectPerson = InvestigationSubject & InvestigationSubjectEntityShared & {
  __typename?: 'InvestigationSubjectPerson';
  associatedBankAccountCount: IncludedAndTotalSubjectCounts;
  associatedCryptoAddressCount: IncludedAndTotalSubjectCounts;
  associatedDeviceCount: IncludedAndTotalSubjectCounts;
  associatedPaymentCardCount: IncludedAndTotalSubjectCounts;
  corroborativeStatement: Scalars['Boolean']['output'];
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  person?: Maybe<LibraryPerson>;
  roleInActivity?: Maybe<RoleInActivity>;
  roleInActivityOther?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectPersonAssociatedBankAccountCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectPersonAssociatedCryptoAddressCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectPersonAssociatedDeviceCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectPersonAssociatedPaymentCardCountArgs = {
  reviewToken: Scalars['String']['input'];
};


export type InvestigationSubjectPersonIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSubjectPerson. */
export type InvestigationSubjectPersonConnection = {
  __typename?: 'InvestigationSubjectPersonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSubjectPersonEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSubjectPerson>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSubjectPersonEdge = {
  __typename?: 'InvestigationSubjectPersonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSubjectPerson>;
};

export type InvestigationSubjectProduct = InvestigationSubject & {
  __typename?: 'InvestigationSubjectProduct';
  errors: Array<InvestigationSubjectError>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<LibraryProduct>;
  token: Scalars['String']['output'];
};


export type InvestigationSubjectProductIncludedInReviewArgs = {
  reviewToken: Scalars['String']['input'];
};

/** The connection type for InvestigationSubjectProduct. */
export type InvestigationSubjectProductConnection = {
  __typename?: 'InvestigationSubjectProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvestigationSubjectProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvestigationSubjectProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvestigationSubjectProductEdge = {
  __typename?: 'InvestigationSubjectProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvestigationSubjectProduct>;
};

/** A country and state represented as ISO codes */
export type IsoCountry = {
  __typename?: 'IsoCountry';
  isoCountryCode: Scalars['String']['output'];
  isoStateCode?: Maybe<Scalars['String']['output']>;
};

export type IsoCountryInput = {
  isoCountryCode?: InputMaybe<Scalars['String']['input']>;
  isoStateCode?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for getting status information about asynchronous jobs */
export type Job = {
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** Status of the job */
export enum JobStatus {
  Enqueued = 'ENQUEUED',
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Started = 'STARTED'
}

export type LegalNameMetadata = {
  __typename?: 'LegalNameMetadata';
  active: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type LegalNameMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

/** A financial account (bank account, payment card, crypto address). */
export type LibraryAccount = LibraryBankAccount | LibraryCryptoAddress | LibraryPaymentCard;

export type LibraryAccountsAndDevicesFields = {
  bankAccounts: Array<LibraryBankAccount>;
  cryptoAddresses: Array<LibraryCryptoAddress>;
  devices: Array<LibraryDevice>;
  paginatedBankAccounts: LibraryBankAccountConnection;
  paginatedCryptoAddresses: LibraryCryptoAddressConnection;
  paginatedDevices: LibraryDeviceConnection;
  paginatedPaymentCards: LibraryPaymentCardConnection;
  paymentCards: Array<LibraryPaymentCard>;
};


export type LibraryAccountsAndDevicesFieldsPaginatedBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryAccountsAndDevicesFieldsPaginatedCryptoAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryAccountsAndDevicesFieldsPaginatedDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryAccountsAndDevicesFieldsPaginatedPaymentCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LibraryAttachment = {
  __typename?: 'LibraryAttachment';
  archived: Scalars['Boolean']['output'];
  contentType: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  fileSizeBytes: Scalars['Int']['output'];
  filename: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  owner: LibraryEntry;
  tags: Array<LibraryTag>;
  token: Scalars['ID']['output'];
  txnToken: Scalars['ID']['output'];
  uploadedAt: Scalars['String']['output'];
  uploadedBy: Account;
  url: Scalars['String']['output'];
};

/** The connection type for LibraryAttachment. */
export type LibraryAttachmentConnection = {
  __typename?: 'LibraryAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryAttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryAttachment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryAttachmentEdge = {
  __typename?: 'LibraryAttachmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryAttachment>;
};

export type LibraryBankAccount = LibraryObject & {
  __typename?: 'LibraryBankAccount';
  accountHolder?: Maybe<LibraryEntity>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  bankAccountBalances?: Maybe<Array<LibraryBankAccountBalance>>;
  classification?: Maybe<BankAccountClassification>;
  closed?: Maybe<Scalars['Boolean']['output']>;
  closedAt?: Maybe<Scalars['DateOrTime']['output']>;
  countryAndState?: Maybe<IsoCountry>;
  createdAt: Scalars['String']['output'];
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  foreign?: Maybe<Scalars['Boolean']['output']>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  informationRequests: InformationRequestConnection;
  institution?: Maybe<LibraryFinancialInstitution>;
  notes?: Maybe<Scalars['String']['output']>;
  openedAt?: Maybe<Scalars['DateOrTime']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  revisions: LibraryRevisionConnection;
  routingNumber?: Maybe<Scalars['String']['output']>;
  signatories?: Maybe<Array<LibrarySignatory>>;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryBankAccountAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBankAccountIncludedInReviewArgs = {
  reviewToken?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryBankAccountInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBankAccountRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryBankAccountRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBankAccountRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export type LibraryBankAccountBalance = LibraryNestedObject & {
  __typename?: 'LibraryBankAccountBalance';
  amount?: Maybe<HbMoney>;
  archived: Scalars['Boolean']['output'];
  bankAccount?: Maybe<LibraryBankAccount>;
  externalId?: Maybe<Scalars['String']['output']>;
  recordedAt?: Maybe<Scalars['DateOrTime']['output']>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** The connection type for LibraryBankAccount. */
export type LibraryBankAccountConnection = {
  __typename?: 'LibraryBankAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryBankAccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryBankAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryBankAccountEdge = {
  __typename?: 'LibraryBankAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryBankAccount>;
};

export type LibraryBusiness = AlertSubject & LibraryAccountsAndDevicesFields & LibraryBusinessOwnerRelationshipMemberFields & LibraryInstitutionRelationshipMemberFields & LibraryObject & LibraryRelationshipMemberFields & {
  __typename?: 'LibraryBusiness';
  addresses?: Maybe<Array<BusinessAddress>>;
  alertSuppressionRules: Array<AlertSuppressionRule>;
  alertSuppressionRulesCount: Scalars['Int']['output'];
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  bankAccounts: Array<LibraryBankAccount>;
  businessOwnerRelationships: LibraryBusinessOwnerConnection;
  businessRelationships: LibraryBusinessOwnerConnection;
  businessType?: Maybe<Business>;
  businessTypes?: Maybe<Array<BusinessTypeMetadata>>;
  createdAt: Scalars['String']['output'];
  cryptoAddresses: Array<LibraryCryptoAddress>;
  dbaNames?: Maybe<Array<Scalars['String']['output']>>;
  devices: Array<LibraryDevice>;
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  externalId?: Maybe<Scalars['String']['output']>;
  identityDocuments?: Maybe<Array<LibraryIdentityDocument>>;
  informationRequests: InformationRequestConnection;
  institutionRelationships: Array<LibraryInstitutionRelationship>;
  institutionRelationshipsCount: Scalars['Int']['output'];
  legalNames?: Maybe<Array<Scalars['String']['output']>>;
  legalNamesMetadata?: Maybe<Array<LegalNameMetadata>>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationAlertCount: Scalars['Int']['output'];
  organizationAlerts: OrganizationAlertConnection;
  otherInfo: Array<OtherInfoEntry>;
  ownedByRelationships: LibraryBusinessOwnerConnection;
  paginatedBankAccounts: LibraryBankAccountConnection;
  paginatedCryptoAddresses: LibraryCryptoAddressConnection;
  paginatedDevices: LibraryDeviceConnection;
  paginatedPaymentCards: LibraryPaymentCardConnection;
  paymentCards: Array<LibraryPaymentCard>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  relationships: LibraryRelationshipConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  tins?: Maybe<Array<Tin>>;
  tinsMetadata?: Maybe<Array<TinMetadata>>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  usernames?: Maybe<Array<Scalars['String']['output']>>;
  version: Scalars['Int']['output'];
  websites?: Maybe<Array<Scalars['String']['output']>>;
};


export type LibraryBusinessAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessBusinessOwnerRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessBusinessRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessOrganizationAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessOwnedByRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessPaginatedBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessPaginatedCryptoAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessPaginatedDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessPaginatedPaymentCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryBusinessRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryBusinessRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export type LibraryBusinessOwner = LibraryNestedObject & {
  __typename?: 'LibraryBusinessOwner';
  archived: Scalars['Boolean']['output'];
  business?: Maybe<LibraryBusiness>;
  externalId?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<LibraryEntity>;
  ownershipPercent?: Maybe<Scalars['Decimal']['output']>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** The connection type for LibraryBusinessOwner. */
export type LibraryBusinessOwnerConnection = {
  __typename?: 'LibraryBusinessOwnerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryBusinessOwnerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryBusinessOwner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryBusinessOwnerEdge = {
  __typename?: 'LibraryBusinessOwnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryBusinessOwner>;
};

export type LibraryBusinessOwnerRelationshipMemberFields = {
  businessOwnerRelationships: LibraryBusinessOwnerConnection;
};


export type LibraryBusinessOwnerRelationshipMemberFieldsBusinessOwnerRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LibraryCryptoAddress = LibraryObject & {
  __typename?: 'LibraryCryptoAddress';
  accountHolder?: Maybe<LibraryEntity>;
  address?: Maybe<Scalars['String']['output']>;
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  blockchain?: Maybe<CryptoAddressBlockchainAttributes>;
  createdAt: Scalars['String']['output'];
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  informationRequests: InformationRequestConnection;
  institution?: Maybe<LibraryFinancialInstitution>;
  notes?: Maybe<Scalars['String']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryCryptoAddressAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryCryptoAddressIncludedInReviewArgs = {
  reviewToken?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryCryptoAddressInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryCryptoAddressRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryCryptoAddressRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryCryptoAddressRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for LibraryCryptoAddress. */
export type LibraryCryptoAddressConnection = {
  __typename?: 'LibraryCryptoAddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryCryptoAddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryCryptoAddress>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryCryptoAddressEdge = {
  __typename?: 'LibraryCryptoAddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryCryptoAddress>;
};

export type LibraryDevice = LibraryObject & {
  __typename?: 'LibraryDevice';
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  browser?: Maybe<Browser>;
  createdAt: Scalars['String']['output'];
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  fingerprint?: Maybe<Scalars['String']['output']>;
  firstSeenAt?: Maybe<Scalars['DateOrTime']['output']>;
  geopoint?: Maybe<Geopoint>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  informationRequests: InformationRequestConnection;
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastSeenAt?: Maybe<Scalars['DateOrTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  operatingSystem?: Maybe<OperatingSystem>;
  otherInfo: Array<OtherInfoEntry>;
  owner?: Maybe<LibraryEntity>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};


export type LibraryDeviceAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryDeviceIncludedInReviewArgs = {
  reviewToken?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryDeviceInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryDeviceRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryDeviceRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryDeviceRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for LibraryDevice. */
export type LibraryDeviceConnection = {
  __typename?: 'LibraryDeviceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryDeviceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryDevice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryDeviceEdge = {
  __typename?: 'LibraryDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryDevice>;
};

/** Entity in the legal sense. Natural person, corporation, or similar. */
export type LibraryEntity = LibraryBusiness | LibraryPerson;

export type LibraryEntry = LibraryBankAccount | LibraryBusiness | LibraryCryptoAddress | LibraryDevice | LibraryFinancialInstitution | LibraryPaymentCard | LibraryPerson | LibraryProduct;

/** The connection type for LibraryEntry. */
export type LibraryEntryConnection = {
  __typename?: 'LibraryEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LibraryEntryEdge = {
  __typename?: 'LibraryEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryEntry>;
};

export enum LibraryEntryTypeEnum {
  BankAccounts = 'bank_accounts',
  Businesses = 'businesses',
  CryptoAddresses = 'crypto_addresses',
  Devices = 'devices',
  FinancialInstitutions = 'financial_institutions',
  PaymentCards = 'payment_cards',
  People = 'people',
  Products = 'products'
}

export type LibraryFinancialInstitution = LibraryObject & LibraryRelationshipMemberFields & {
  __typename?: 'LibraryFinancialInstitution';
  address?: Maybe<Address>;
  addressesMetadata?: Maybe<Array<AddressMetadata>>;
  alertsCount: Scalars['Int']['output'];
  alternateNames?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  classification?: Maybe<Classification>;
  classifications?: Maybe<Array<ClassificationMetadata>>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<Array<ContactMetadata>>;
  createdAt: Scalars['String']['output'];
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  identification?: Maybe<Identification>;
  identifications?: Maybe<Array<IdentificationMetadata>>;
  informationRequests: InformationRequestConnection;
  name?: Maybe<Scalars['String']['output']>;
  namesMetadata?: Maybe<Array<NameMetadata>>;
  notes?: Maybe<Scalars['String']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  primaryFederalRegulator?: Maybe<FederalRegulatorEnum>;
  primaryFederalRegulators?: Maybe<Array<FederalRegulatorMetadata>>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  relationships: LibraryRelationshipConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  swiftCode?: Maybe<Scalars['String']['output']>;
  swiftCodes?: Maybe<Array<SwiftCodeMetadata>>;
  tags: Array<LibraryTag>;
  tin?: Maybe<Tin>;
  tinsMetadata?: Maybe<Array<TinMetadata>>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryFinancialInstitutionAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryFinancialInstitutionInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryFinancialInstitutionRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryFinancialInstitutionRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryFinancialInstitutionRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryFinancialInstitutionRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export enum LibraryGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export type LibraryIdentityDocument = {
  __typename?: 'LibraryIdentityDocument';
  expiresOn?: Maybe<Scalars['DateParts']['output']>;
  issuingCountryCode?: Maybe<Scalars['String']['output']>;
  issuingStateCode?: Maybe<Scalars['String']['output']>;
  nationalityCountryCode?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<IdentityDocumentTypeEnum>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type LibraryInstitutionRelationship = LibraryNestedObject & {
  __typename?: 'LibraryInstitutionRelationship';
  actionedAt?: Maybe<Scalars['DateOrTime']['output']>;
  archived: Scalars['Boolean']['output'];
  business?: Maybe<LibraryBusiness>;
  externalId?: Maybe<Scalars['String']['output']>;
  institution?: Maybe<LibraryFinancialInstitution>;
  person?: Maybe<LibraryPerson>;
  relationship?: Maybe<Relationship>;
  status?: Maybe<InstitutionRelationshipStatusEnum>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LibraryInstitutionRelationshipMemberFields = {
  institutionRelationships: Array<LibraryInstitutionRelationship>;
  institutionRelationshipsCount: Scalars['Int']['output'];
};

export type LibraryNestedObject = {
  archived: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LibraryObject = {
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  informationRequests: InformationRequestConnection;
  notes?: Maybe<Scalars['String']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryObjectAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryObjectInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryObjectRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryObjectRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryObjectRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export type LibraryObjectRelation = {
  key: LibraryObjectRelationKey;
  type: LibraryTypeEnum;
};

export type LibraryObjectRelationKey = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type LibraryPaymentCard = LibraryObject & {
  __typename?: 'LibraryPaymentCard';
  accountHolder?: Maybe<LibraryEntity>;
  activatedAt?: Maybe<Scalars['DateOrTime']['output']>;
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  cardType?: Maybe<PaymentCardTypeAttributes>;
  closed?: Maybe<Scalars['Boolean']['output']>;
  closedAt?: Maybe<Scalars['DateOrTime']['output']>;
  createdAt: Scalars['String']['output'];
  creditLimit?: Maybe<HbMoney>;
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  expiration?: Maybe<Scalars['YearMonth']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  foreign?: Maybe<Scalars['Boolean']['output']>;
  includedInReview?: Maybe<Scalars['Boolean']['output']>;
  informationRequests: InformationRequestConnection;
  institution?: Maybe<LibraryFinancialInstitution>;
  maskedPan?: Maybe<Scalars['String']['output']>;
  network?: Maybe<PaymentCardNetworkAttributes>;
  notes?: Maybe<Scalars['String']['output']>;
  openedAt?: Maybe<Scalars['DateOrTime']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  revisions: LibraryRevisionConnection;
  status?: Maybe<Scalars['String']['output']>;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryPaymentCardAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPaymentCardIncludedInReviewArgs = {
  reviewToken?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryPaymentCardInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPaymentCardRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryPaymentCardRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPaymentCardRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for LibraryPaymentCard. */
export type LibraryPaymentCardConnection = {
  __typename?: 'LibraryPaymentCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryPaymentCardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryPaymentCard>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryPaymentCardEdge = {
  __typename?: 'LibraryPaymentCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryPaymentCard>;
};

export type LibraryPerson = AlertSubject & LibraryAccountsAndDevicesFields & LibraryBusinessOwnerRelationshipMemberFields & LibraryInstitutionRelationshipMemberFields & LibraryObject & LibraryRelationshipMemberFields & {
  __typename?: 'LibraryPerson';
  addresses?: Maybe<Array<PersonAddress>>;
  alertSuppressionRules: Array<AlertSuppressionRule>;
  alertSuppressionRulesCount: Scalars['Int']['output'];
  alertsCount: Scalars['Int']['output'];
  alternateNames?: Maybe<Array<Scalars['String']['output']>>;
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  bankAccounts: Array<LibraryBankAccount>;
  birthdate?: Maybe<Scalars['DateParts']['output']>;
  birthdates?: Maybe<Array<BirthdateMetadata>>;
  businessOwnerRelationships: LibraryBusinessOwnerConnection;
  createdAt: Scalars['String']['output'];
  cryptoAddresses: Array<LibraryCryptoAddress>;
  devices: Array<LibraryDevice>;
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  externalId?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<LibraryGenderEnum>;
  genders?: Maybe<Array<GenderMetadata>>;
  identityDocuments?: Maybe<Array<LibraryIdentityDocument>>;
  informationRequests: InformationRequestConnection;
  institutionRelationships: Array<LibraryInstitutionRelationship>;
  institutionRelationshipsCount: Scalars['Int']['output'];
  name?: Maybe<PersonName>;
  names?: Maybe<Array<PersonNameMetadata>>;
  notes?: Maybe<Scalars['String']['output']>;
  occupations?: Maybe<Array<Occupation>>;
  organizationAlertCount: Scalars['Int']['output'];
  organizationAlerts: OrganizationAlertConnection;
  otherInfo: Array<OtherInfoEntry>;
  paginatedBankAccounts: LibraryBankAccountConnection;
  paginatedCryptoAddresses: LibraryCryptoAddressConnection;
  paginatedDevices: LibraryDeviceConnection;
  paginatedPaymentCards: LibraryPaymentCardConnection;
  paymentCards: Array<LibraryPaymentCard>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  relationships: LibraryRelationshipConnection;
  revisions: LibraryRevisionConnection;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  tins?: Maybe<Array<Tin>>;
  tinsMetadata?: Maybe<Array<TinMetadata>>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  usernames?: Maybe<Array<Scalars['String']['output']>>;
  version: Scalars['Int']['output'];
  websites?: Maybe<Array<Scalars['String']['output']>>;
};


export type LibraryPersonAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonBusinessOwnerRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonOrganizationAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonPaginatedBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonPaginatedCryptoAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonPaginatedDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonPaginatedPaymentCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryPersonRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryPersonRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export type LibraryProduct = LibraryObject & LibraryRelationshipMemberFields & {
  __typename?: 'LibraryProduct';
  alertsCount: Scalars['Int']['output'];
  archived: Scalars['Boolean']['output'];
  attachments?: Maybe<LibraryAttachmentConnection>;
  attachmentsCount: Scalars['Int']['output'];
  commodityType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  cusip?: Maybe<Scalars['String']['output']>;
  devicesCount: Scalars['Int']['output'];
  /** A short textual description of this object. Typically under 100 characters. A full name, account number, etc */
  displayName: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  informationRequests: InformationRequestConnection;
  marketTraded?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  previousVersion?: Maybe<LibraryEntry>;
  quickLinks?: Maybe<Array<QuickLink>>;
  relatedCaseSubject?: Maybe<InvestigationSubject>;
  relatedCases: InvestigationConnection;
  relationships: LibraryRelationshipConnection;
  revisions: LibraryRevisionConnection;
  sku?: Maybe<Scalars['String']['output']>;
  subAccountsCount: Scalars['Int']['output'];
  tags: Array<LibraryTag>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};


export type LibraryProductAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryProductInformationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryProductRelatedCaseSubjectArgs = {
  caseLinkToken?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['String']['input'];
};


export type LibraryProductRelatedCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryProductRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LibraryProductRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};

export type LibraryRelationship = LibraryNestedObject & {
  __typename?: 'LibraryRelationship';
  archived: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  origin: LibraryRelationshipMember;
  relationshipDefinition: LibraryRelationshipDefinition;
  target: LibraryRelationshipMember;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** The connection type for LibraryRelationship. */
export type LibraryRelationshipConnection = {
  __typename?: 'LibraryRelationshipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryRelationshipEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryRelationship>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

export type LibraryRelationshipDefinition = LibraryNestedObject & {
  __typename?: 'LibraryRelationshipDefinition';
  archived: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  relationshipsCount: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** The connection type for LibraryRelationshipDefinition. */
export type LibraryRelationshipDefinitionConnection = {
  __typename?: 'LibraryRelationshipDefinitionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryRelationshipDefinitionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryRelationshipDefinition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryRelationshipDefinitionEdge = {
  __typename?: 'LibraryRelationshipDefinitionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryRelationshipDefinition>;
};

/** An edge in a connection. */
export type LibraryRelationshipEdge = {
  __typename?: 'LibraryRelationshipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryRelationship>;
};

/** Library object participating in a relationship with another library object. */
export type LibraryRelationshipMember = LibraryBusiness | LibraryFinancialInstitution | LibraryPerson | LibraryProduct;

export type LibraryRelationshipMemberFields = {
  relationships: LibraryRelationshipConnection;
};


export type LibraryRelationshipMemberFieldsRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LibraryRevision = {
  __typename?: 'LibraryRevision';
  belongsToMerge: Scalars['Boolean']['output'];
  latest: Scalars['Boolean']['output'];
  startTxn: LibraryTxn;
  token: Scalars['ID']['output'];
  txnToken: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
};

/** The connection type for LibraryRevision. */
export type LibraryRevisionConnection = {
  __typename?: 'LibraryRevisionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryRevisionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LibraryRevision>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LibraryRevisionEdge = {
  __typename?: 'LibraryRevisionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LibraryRevision>;
};

export type LibrarySignatory = LibraryNestedObject & {
  __typename?: 'LibrarySignatory';
  archived: Scalars['Boolean']['output'];
  bankAccount: LibraryBankAccount;
  externalId?: Maybe<Scalars['String']['output']>;
  person?: Maybe<LibraryPerson>;
  role?: Maybe<BankAccountSignatoryRoleEnum>;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LibraryTag = LibraryNestedObject & {
  __typename?: 'LibraryTag';
  archived: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  tagDefinition: LibraryTagDefinition;
  taggedObject: LibraryEntry;
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LibraryTagDefinition = LibraryNestedObject & {
  __typename?: 'LibraryTagDefinition';
  archived: Scalars['Boolean']['output'];
  color: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  token: Scalars['String']['output'];
  txnToken: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LibraryTxn = {
  __typename?: 'LibraryTxn';
  account: Account;
  createdAt: Scalars['ISO8601DateTime']['output'];
  token: Scalars['ID']['output'];
};

export enum LibraryTypeEnum {
  Attachments = 'attachments',
  BankAccountBalances = 'bank_account_balances',
  BankAccounts = 'bank_accounts',
  BusinessOwners = 'business_owners',
  Businesses = 'businesses',
  CryptoAddresses = 'crypto_addresses',
  Devices = 'devices',
  FinancialInstitutions = 'financial_institutions',
  InstitutionRelationships = 'institution_relationships',
  PaymentCards = 'payment_cards',
  People = 'people',
  Products = 'products',
  RelationshipDefinitions = 'relationship_definitions',
  Relationships = 'relationships',
  ScreeningSearchResults = 'screening_search_results',
  Signatories = 'signatories',
  TagDefinitions = 'tag_definitions',
  Tags = 'tags'
}

/** Autogenerated input type of LockInvestigation */
export type LockInvestigationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of LockInvestigation. */
export type LockInvestigationPayload = {
  __typename?: 'LockInvestigationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

export type MergeCreateActionChoicesInput = {
  accountType?: InputMaybe<Scalars['String']['input']>;
  buttonType?: InputMaybe<Scalars['String']['input']>;
  confirmationTitle?: InputMaybe<Scalars['String']['input']>;
  decisionModalButtonTitle?: InputMaybe<Scalars['String']['input']>;
  fromState?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  pastParticiple?: InputMaybe<Scalars['String']['input']>;
  proceedTo?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  toState?: InputMaybe<Scalars['String']['input']>;
};

export type MergeCreateActionEffectDefaultsInput = {
  assigneeToken?: InputMaybe<Scalars['String']['input']>;
  assignmentStrategy?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  daysToSnooze?: InputMaybe<Scalars['Int']['input']>;
  decision?: InputMaybe<InvestigationReviewApprovalDecisionEnum>;
  defaultQueueToken?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  dueInDays?: InputMaybe<Scalars['Int']['input']>;
  filingType?: InputMaybe<Scalars['String']['input']>;
  minimumPeerReviewers?: InputMaybe<Scalars['Int']['input']>;
  newReviewTypeCanonicalId?: InputMaybe<Scalars['String']['input']>;
  newReviewTypeToken?: InputMaybe<Scalars['String']['input']>;
  openInDays?: InputMaybe<Scalars['Int']['input']>;
  originReviewRelationship?: InputMaybe<InvestigationReviewRelationshipTypeEnum>;
  queueRequired?: InputMaybe<Scalars['Boolean']['input']>;
  queueToken?: InputMaybe<Scalars['String']['input']>;
  queueTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientOrganizationId?: InputMaybe<Scalars['ID']['input']>;
  recipientReviewTypeCanonicalId?: InputMaybe<Scalars['ID']['input']>;
  relationship?: InputMaybe<InvestigationReviewRelationshipTypeEnum>;
  reviewerTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timelineStrategy?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MergeCreateActionEffectsInput = {
  defaults?: InputMaybe<MergeCreateActionEffectDefaultsInput>;
  key?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ReviewTypeActionEffectTypeEnum>;
};

export type MergeCreateActionInput = {
  choices?: InputMaybe<Array<MergeCreateActionChoicesInput>>;
  effects?: InputMaybe<Array<MergeCreateActionEffectsInput>>;
  headline?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<WorkflowIconEnum>;
  params?: InputMaybe<MergeCreateActionParamsInput>;
  requiredBadge?: InputMaybe<Scalars['String']['input']>;
  requiredPermissions?: InputMaybe<Array<BadgePermissionsEnum>>;
  slug: Scalars['String']['input'];
  stateMachine?: InputMaybe<MergeCreateActionStateMachineInput>;
  taskRequirements?: InputMaybe<Array<MergeCreateTaskRequirementsInput>>;
  tasks?: InputMaybe<Array<MergeCreateTaskInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ReviewActionTypeEnum>;
};

export type MergeCreateActionParamsInput = {
  decisionTitle?: InputMaybe<Scalars['String']['input']>;
  headlineSubtext?: InputMaybe<Scalars['String']['input']>;
  jurisdiction?: InputMaybe<Scalars['String']['input']>;
  reportableLabel?: InputMaybe<Scalars['String']['input']>;
  requireFilingValidationFincen?: InputMaybe<Scalars['Boolean']['input']>;
  requireFilingValidationFincenCtr?: InputMaybe<Scalars['Boolean']['input']>;
  requireFilingValidationFintrac?: InputMaybe<Scalars['Boolean']['input']>;
  requireFilingValidationGnecb?: InputMaybe<Scalars['Boolean']['input']>;
  requireReviewValidationToComplete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MergeCreateActionRequirementsInput = {
  actionSlug: Scalars['String']['input'];
  requirements: Array<Scalars['String']['input']>;
};

export type MergeCreateActionStateMachineInput = {
  name: Scalars['String']['input'];
  states: Array<MergeCreateActionStateMachineStateInput>;
};

export type MergeCreateActionStateMachineStateInput = {
  accept?: InputMaybe<Scalars['Boolean']['input']>;
  initial?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of MergeCreateReview */
export type MergeCreateReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: MergeCreateReviewTypeInput;
  token?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of MergeCreateReview. */
export type MergeCreateReviewPayload = {
  __typename?: 'MergeCreateReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  reviewType: TypeOfReview;
};

export type MergeCreateReviewTypeInput = {
  actionRequirements?: InputMaybe<Array<MergeCreateActionRequirementsInput>>;
  actions?: InputMaybe<Array<MergeCreateActionInput>>;
  billingType?: InputMaybe<ReviewTypeBillingTypeEnum>;
  canonicalId?: InputMaybe<Scalars['String']['input']>;
  displayColor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timelineParams?: InputMaybe<MergeCreateTimelineParamsInput>;
  timelineType?: InputMaybe<ReviewTypeTimelineTypeEnum>;
};

export type MergeCreateSurveyParams = {
  sections: Array<MergeCreateSurveySectionParams>;
};

export type MergeCreateSurveyQuestionFileUploadParams = {
  allowedMimeTypes: Array<Scalars['String']['input']>;
};

export type MergeCreateSurveyQuestionNumberParams = {
  allowLeadingZeros?: InputMaybe<Scalars['Boolean']['input']>;
  allowNegative?: InputMaybe<Scalars['Boolean']['input']>;
  decimalScale?: InputMaybe<Scalars['Int']['input']>;
  fixedDecimalScale?: InputMaybe<Scalars['Boolean']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

export type MergeCreateSurveyQuestionOptionParams = {
  dependentQuestions?: InputMaybe<Array<MergeCreateSurveyQuestionParams>>;
  label: Scalars['String']['input'];
};

export type MergeCreateSurveyQuestionParams = {
  fileUploadConfiguration?: InputMaybe<MergeCreateSurveyQuestionFileUploadParams>;
  multiline?: InputMaybe<Scalars['Boolean']['input']>;
  multivalued?: InputMaybe<Scalars['Boolean']['input']>;
  numberProps?: InputMaybe<MergeCreateSurveyQuestionNumberParams>;
  options?: InputMaybe<Array<MergeCreateSurveyQuestionOptionParams>>;
  reportAs?: InputMaybe<MergeCreateSurveyQuestionReportAsParams>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<SurveyQuestionStyleEnum>;
  tag?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: SurveyQuestionTypeEnum;
};

export type MergeCreateSurveyQuestionReportAsParams = {
  label?: InputMaybe<Scalars['String']['input']>;
};

export type MergeCreateSurveySectionParams = {
  questions?: InputMaybe<Array<MergeCreateSurveyQuestionParams>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MergeCreateTaskFilingDetailsParams = {
  enableBatchingIfAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MergeCreateTaskInput = {
  headline?: InputMaybe<Scalars['String']['input']>;
  headlineSubtext?: InputMaybe<Scalars['String']['input']>;
  icon: WorkflowIconEnum;
  params?: InputMaybe<MergeCreateTaskInputParams>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ReviewTaskTypeEnum>;
};

export type MergeCreateTaskInputParams =
  { filingDetails: MergeCreateTaskFilingDetailsParams; research?: never; survey?: never; tagging?: never; textEntry?: never; }
  |  { filingDetails?: never; research: MergeCreateTaskResearchParams; survey?: never; tagging?: never; textEntry?: never; }
  |  { filingDetails?: never; research?: never; survey: MergeCreateSurveyParams; tagging?: never; textEntry?: never; }
  |  { filingDetails?: never; research?: never; survey?: never; tagging: MergeCreateTaskTaggingParams; textEntry?: never; }
  |  { filingDetails?: never; research?: never; survey?: never; tagging?: never; textEntry: MergeCreateTaskTextEntryParams; };

export type MergeCreateTaskRequirementsInput = {
  key: Scalars['String']['input'];
  tasks: Array<Scalars['String']['input']>;
};

export type MergeCreateTaskResearchParams = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MergeCreateTaskTaggingParams = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MergeCreateTaskTextEntryParams = {
  helpText?: InputMaybe<Array<Scalars['String']['input']>>;
  sarNarrative?: InputMaybe<Scalars['Boolean']['input']>;
  skipValidateText?: InputMaybe<Scalars['Boolean']['input']>;
  templates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MergeCreateTimelineParamsInput = {
  timeline?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of MergeLibraryObjects */
export type MergeLibraryObjectsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mergeContextInvestigationToken?: InputMaybe<Scalars['ID']['input']>;
  sourceTokens: Array<Scalars['ID']['input']>;
  targetToken: Scalars['ID']['input'];
  type: LibraryTypeEnum;
};

/** Autogenerated return type of MergeLibraryObjects. */
export type MergeLibraryObjectsPayload = {
  __typename?: 'MergeLibraryObjectsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sources: Array<LibraryObject>;
  target: LibraryObject;
};

export enum MfaMethodEnum {
  Authenticator = 'AUTHENTICATOR',
  Email = 'EMAIL',
  None = 'NONE'
}

/** A Middesk Business API address. */
export type MiddeskAddress = {
  __typename?: 'MiddeskAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business record. */
export type MiddeskBusiness = {
  __typename?: 'MiddeskBusiness';
  account: Account;
  createdAt: Scalars['ISO8601DateTime']['output'];
  libraryToken: Scalars['ID']['output'];
  middeskBusinessId: Scalars['ID']['output'];
  status: MiddeskBusinessStatus;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  wasInAudit: Scalars['Boolean']['output'];
};

/** A Middesk Business API response. */
export type MiddeskBusinessApiResponse = {
  __typename?: 'MiddeskBusinessApiResponse';
  addresses?: Maybe<Array<MiddeskAddress>>;
  createdAt: Scalars['String']['output'];
  externalId?: Maybe<Scalars['ID']['output']>;
  formation?: Maybe<MiddeskFormation>;
  id: Scalars['ID']['output'];
  industryClassification?: Maybe<MiddeskIndustryClassification>;
  name: Scalars['String']['output'];
  names?: Maybe<Array<MiddeskName>>;
  people?: Maybe<Array<MiddeskPerson>>;
  phoneNumbers?: Maybe<Array<MiddeskPhoneNumber>>;
  registrations?: Maybe<Array<MiddeskRegistration>>;
  status: MiddeskBusinessStatus;
  tin?: Maybe<MiddeskTin>;
  updatedAt: Scalars['String']['output'];
  watchlist?: Maybe<MiddeskWatchlist>;
  website?: Maybe<MiddeskWebsite>;
};

/** The connection type for MiddeskBusiness. */
export type MiddeskBusinessConnection = {
  __typename?: 'MiddeskBusinessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MiddeskBusinessEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MiddeskBusiness>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MiddeskBusinessEdge = {
  __typename?: 'MiddeskBusinessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MiddeskBusiness>;
};

/** Status and possible results of a Middesk Business request. */
export type MiddeskBusinessJob = Job & {
  __typename?: 'MiddeskBusinessJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  middeskBusinessStatus?: Maybe<MiddeskBusinessStatus>;
  result?: Maybe<MiddeskBusinessApiResponse>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** Status of a MiddeskBusiness */
export enum MiddeskBusinessStatus {
  Approved = 'APPROVED',
  InAudit = 'IN_AUDIT',
  InReview = 'IN_REVIEW',
  Open = 'OPEN',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Credential used for Middesk integration */
export type MiddeskCredential = {
  __typename?: 'MiddeskCredential';
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A Middesk Business API formation object. */
export type MiddeskFormation = {
  __typename?: 'MiddeskFormation';
  formationDate?: Maybe<Scalars['String']['output']>;
  formationState?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API industry classification object. */
export type MiddeskIndustryClassification = {
  __typename?: 'MiddeskIndustryClassification';
  categories?: Maybe<Array<MiddeskIndustryClassificationCategory>>;
};

/** A Middesk Business API industry classification category object. */
export type MiddeskIndustryClassificationCategory = {
  __typename?: 'MiddeskIndustryClassificationCategory';
  category?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API name. */
export type MiddeskName = {
  __typename?: 'MiddeskName';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API person. */
export type MiddeskPerson = {
  __typename?: 'MiddeskPerson';
  name?: Maybe<Scalars['String']['output']>;
  titles?: Maybe<Array<MiddeskPersonTitle>>;
};

/** A Middesk Business API person title. */
export type MiddeskPersonTitle = {
  __typename?: 'MiddeskPersonTitle';
  title?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API phone number object. */
export type MiddeskPhoneNumber = {
  __typename?: 'MiddeskPhoneNumber';
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API registration object. */
export type MiddeskRegistration = {
  __typename?: 'MiddeskRegistration';
  entityType?: Maybe<Scalars['String']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API Tax Identification Number object. */
export type MiddeskTin = {
  __typename?: 'MiddeskTin';
  tin?: Maybe<Scalars['String']['output']>;
};

/** A Middesk Business API watchlist object. */
export type MiddeskWatchlist = {
  __typename?: 'MiddeskWatchlist';
  hitCount?: Maybe<Scalars['Int']['output']>;
};

/** A Middesk Business API website object. */
export type MiddeskWebsite = {
  __typename?: 'MiddeskWebsite';
  domain?: Maybe<MiddeskWebsiteDomain>;
};

/** A Middesk Business API website domain object. */
export type MiddeskWebsiteDomain = {
  __typename?: 'MiddeskWebsiteDomain';
  domain?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a Fincen advisory to an investigation review */
  addFincenAdvisoryToReview?: Maybe<AddAdvisoryToReviewPayload>;
  /** Adjust due date of a review directly. */
  adjustReviewDueDate?: Maybe<AdjustReviewDueDatePayload>;
  /** Adjust new timeline for review with auto-calculation of due date. */
  adjustReviewTimeline?: Maybe<AdjustReviewTimelinePayload>;
  /** Update an access request to be approved */
  approveAccessRequest?: Maybe<ApproveAccessRequestPayload>;
  /** A mutation to create or delete Information Request library links. */
  batchUpsertLibraryLinks?: Maybe<BatchUpsertLibraryLinksPayload>;
  /** A mutation to create or update Information Request Recipients. */
  batchUpsertRecipients?: Maybe<BatchUpsertRecipientsPayload>;
  cancelAlertSuppressionRule?: Maybe<CancelAlertSuppressionRulePayload>;
  cancelCaseReview?: Maybe<CancelCaseReviewPayload>;
  /** Cancels an Information Request. */
  cancelInformationRequest?: Maybe<CancelInformationRequestPayload>;
  /** Completes an Information Request. */
  completeInformationRequest?: Maybe<CompleteInformationRequestPayload>;
  /** Create a new account. */
  createAccount?: Maybe<CreateAccountPayload>;
  createApiKey?: Maybe<CreateApiKeyPayload>;
  createAttachmentSummaryFeedback?: Maybe<CreateAttachmentSummaryFeedbackPayload>;
  createAttachments?: Maybe<CreateAttachmentsPayload>;
  /** Create an automation rule. Pass in a slug to create a rule from a template. */
  createAutomationRule?: Maybe<CreateAutomationRulePayload>;
  /** Create an automation rule. Pass in a slug to create a rule from a template. */
  createAutomationRuleAsAdmin?: Maybe<CreateAutomationRuleAsAdminPayload>;
  /** Create an automation template. */
  createAutomationTemplate?: Maybe<CreateAutomationTemplatePayload>;
  createChainalysisConfiguration?: Maybe<CreateChainalysisConfigurationPayload>;
  createDatasource?: Maybe<CreateDatasourcePayload>;
  /** Creates an Information Request Response FOR an Inbound Request. */
  createInboundRequestResponse?: Maybe<CreateInboundRequestResponsePayload>;
  /** Creates an Information Request. */
  createInformationRequest?: Maybe<CreateInformationRequestPayload>;
  /** Creates an Information Request Response. */
  createInformationRequestResponse?: Maybe<CreateInformationRequestResponsePayload>;
  createInvestigation?: Maybe<CreateInvestigationPayload>;
  createInvestigationDocumentDownload?: Maybe<CreateInvestigationDocumentDownloadPayload>;
  createOtherInfoLabel?: Maybe<CreateOtherInfoLabelPayload>;
  createPublicKey?: Maybe<CreatePublicKeyPayload>;
  createReviewNarrativeValidation?: Maybe<CreateReviewNarrativeValidationPayload>;
  createTag?: Maybe<CreateTagPayload>;
  createTransientUploadAuthorization?: Maybe<CreateTransientUploadAuthorizationPayload>;
  createWebhookEndpoint?: Maybe<CreateWebhookEndpointPayload>;
  /** Declines the Information Request that an Inbound Request belongs to. */
  declineInboundRequest?: Maybe<DeclineInboundRequestPayload>;
  deleteApiKey?: Maybe<DeleteApiKeyPayload>;
  deleteAttachment?: Maybe<DeleteAttachmentPayload>;
  /** Delete an automation rule. */
  deleteAutomationRule?: Maybe<DeleteAutomationRulePayload>;
  /** Delete an automation rule as an automations administrator (INTERNAL ONLY) */
  deleteAutomationRuleAsAdmin?: Maybe<DeleteAutomationRuleAsAdminPayload>;
  /** Delete an automation template. */
  deleteAutomationTemplate?: Maybe<DeleteAutomationTemplatePayload>;
  deleteCaseAlert?: Maybe<DeleteCaseAlertPayload>;
  deleteChainalysisConfiguration?: Maybe<DeleteChainalysisConfigurationPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** Delete a cyber event indicator. */
  deleteCyberEventIndicator?: Maybe<DeleteIndicatorPayload>;
  /** Delete FINTRAC API settings. */
  deleteFintracApiSettings?: Maybe<DeleteFintracApiSettingsPayload>;
  /** Delete Middesk credential. */
  deleteMiddeskCredential?: Maybe<DeleteMiddeskCredentialPayload>;
  deleteNarrativeTemplate?: Maybe<DeleteNarrativeTemplatePayload>;
  deletePublicKey?: Maybe<DeletePublicKeyPayload>;
  /** Delete a saved search */
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  /** Delete Sigma configuration. */
  deleteSigmaConfiguration?: Maybe<DeleteSigmaConfigurationPayload>;
  deleteTag?: Maybe<DeleteTagPayload>;
  /** Delete TR CLEAR credential. */
  deleteThomsonReutersClearCredential?: Maybe<DeleteThomsonReutersClearCredentialPayload>;
  deleteTransactionEndpoint?: Maybe<DeleteTransactionEndpointPayload>;
  deleteWebhookEndpoint?: Maybe<DeleteWebhookEndpointPayload>;
  /** Disable an automation rule. */
  disableAutomationRule?: Maybe<DisableAutomationRulePayload>;
  disableEllipticIntegration?: Maybe<DisableEllipticIntegrationPayload>;
  /** Dismiss a suggested Fincen advisory on an investigation review */
  dismissSuggestedFincenAdvisory?: Maybe<DismissSuggestedFincenAdvisoryPayload>;
  /** Download alert JSON and send an email. */
  downloadOrganizationAlert?: Maybe<DownloadOrganizationAlertPayload>;
  /** Enable an automation rule. */
  enableAutomationRule?: Maybe<EnableAutomationRulePayload>;
  /** Enable an automation template. */
  enableDisableAutomationTemplate?: Maybe<EnableDisableAutomationTemplatePayload>;
  enableEllipticIntegration?: Maybe<EnableEllipticIntegrationPayload>;
  enableOrDisableWebhookEndpoint?: Maybe<EnableOrDisableWebhookEndpointPayload>;
  /** Enqueues an alerts export job. */
  enqueueAlertsExportJob?: Maybe<EnqueueAlertsExportJobPayload>;
  /** Export dashboard data to CSV */
  enqueueDashboardExportJob?: Maybe<EnqueueDashboardExportJobPayload>;
  /** Enqueues a FINTRAC validation job. */
  enqueueFintracValidationJob?: Maybe<EnqueueFintracValidationJobPayload>;
  /** Enqueues an audit log export job. */
  enqueueHistoryExportJob?: Maybe<EnqueueHistoryExportJobPayload>;
  /** Enqueues a MiddeskBusiness job. */
  enqueueMiddeskBusinessJob?: Maybe<EnqueueMiddeskBusinessJobPayload>;
  /** Enqueues a review narrative generation job. */
  enqueueReviewNarrativeGenerationJob?: Maybe<EnqueueReviewNarrativeGenerationJobPayload>;
  /** Enqueue a job to generate a report for a screening search */
  enqueueScreeningSearchReportJob?: Maybe<EnqueueScreeningSearchReportJobPayload>;
  /** Enqueues a TR CLEAR business report job. */
  enqueueThomsonReutersBusinessReportJob?: Maybe<EnqueueThomsonReutersBusinessReportJobPayload>;
  /** Enqueues a TR CLEAR business search job. */
  enqueueThomsonReutersBusinessSearchJob?: Maybe<EnqueueThomsonReutersBusinessSearchJobPayload>;
  /** Enqueues a TR CLEAR person report job. */
  enqueueThomsonReutersPersonReportJob?: Maybe<EnqueueThomsonReutersPersonReportJobPayload>;
  /** Enqueues a TR CLEAR person search job. */
  enqueueThomsonReutersPersonSearchJob?: Maybe<EnqueueThomsonReutersPersonSearchJobPayload>;
  /** Fetch directory sync groups from WorkOS and save them to the database */
  fetchDirectorySyncGroups?: Maybe<FetchDirectorySyncGroupsPayload>;
  /** Generates a ScreeningSearch report for a provider and attaches it to the Investigation and Entity. */
  generateScreeningSearchReport?: Maybe<GenerateScreeningSearchReportPayload>;
  lockInvestigation?: Maybe<LockInvestigationPayload>;
  /** Creates a new Review Type from an existing one while merging values */
  mergeCreateReviewType?: Maybe<MergeCreateReviewPayload>;
  /** Merge one or many source library objects into the target */
  mergeLibraryObjects: MergeLibraryObjectsPayload;
  openCaseReviewNow?: Maybe<OpenCaseReviewNowPayload>;
  overrideAlertSuppression?: Maybe<OverrideAlertSuppressionPayload>;
  /** Perform a single batch action against several cases */
  performInvestigationBatchAction?: Maybe<PerformInvestigationBatchActionPayload>;
  /** Perform a single batch action against several library profiles */
  performLibraryProfilesBatchAction?: Maybe<PerformLibraryProfilesBatchActionPayload>;
  /** Perform a single batch action against several reviews */
  performReviewBatchAction?: Maybe<PerformReviewBatchActionPayload>;
  /** Perform a single batch action against several transactions */
  performTransactionBatchAction?: Maybe<PerformTransactionBatchActionPayload>;
  recordLibraryTransaction?: Maybe<RecordLibraryTransactionPayload>;
  /** Update an access request to be rejected */
  rejectAccessRequest?: Maybe<RejectAccessRequestPayload>;
  /** Remove a Fincen advisory to an investigation review */
  removeFincenAdvisoryFromReview?: Maybe<RemoveAdvisoryFromReviewPayload>;
  renameAttachment?: Maybe<RenameAttachmentPayload>;
  /** Render a narrative template. */
  renderNarrativeTemplate?: Maybe<RenderNarrativeTemplatePayload>;
  /** Initiates a request to install an app for the current organization. */
  requestInstallApp?: Maybe<RequestInstallPayload>;
  /** Re-send confirmation email for a new account. */
  resendAccountConfirmation?: Maybe<ResendAccountConfirmationPayload>;
  /** Reset the current organization and populate it with fresh demo data. */
  resetDemo?: Maybe<ResetDemoPayload>;
  resolveCommentThread?: Maybe<ResolveCommentThreadPayload>;
  /** Update an access request to be revoked */
  revokeAccessRequest?: Maybe<RevokeAccessRequestPayload>;
  snoozeCaseReview?: Maybe<SnoozeCaseReviewPayload>;
  /** Submits a Request for Information response */
  submitRequestForInformation?: Maybe<SubmitRequestForInformationPayload>;
  /** Submits a Tip response */
  submitTipIntake?: Maybe<SubmitTipIntakePayload>;
  summarizeAttachment?: Maybe<SummarizeAttachmentPayload>;
  /** Enqueues a job for testing a webhook endpoint. */
  testWebhookEndpoint?: Maybe<EnqueueTestWebhookEndpointJobPayload>;
  /** Updates an Information Request. */
  toggleRequestReminders?: Maybe<ToggleRequestRemindersPayload>;
  /** Triggers a rules action against its preview-mode matches */
  triggerAutomationRulePreviewModeRun?: Maybe<TriggerPreviewModeRunPayload>;
  /** Undelete an automation rule. */
  undeleteAutomationRule?: Maybe<UndeleteAutomationRulePayload>;
  /** Unlinks an account from the current organization. */
  unlinkAccount?: Maybe<UnlinkAccountPayload>;
  unlockInvestigation?: Maybe<UnlockInvestigationPayload>;
  /** Update the badges assigned to an account. */
  updateAccountBadges?: Maybe<UpdateAccountBadgesPayload>;
  updateApiKey?: Maybe<UpdateApiKeyPayload>;
  /** Update an automation rule. */
  updateAutomationRule?: Maybe<UpdateAutomationRulePayload>;
  /** Update an automation rule as an automation admin user (INTERNAL ONLY) */
  updateAutomationRuleAsAdmin?: Maybe<UpdateAutomationRuleAsAdminPayload>;
  /** Updates notes of an automation rule. */
  updateAutomationRuleNotes?: Maybe<UpdateAutomationRuleNotesPayload>;
  /** Updates setting of an automation rule. */
  updateAutomationRuleSettings?: Maybe<UpdateAutomationRuleSettingsPayload>;
  /** Update an automation template. */
  updateAutomationTemplate?: Maybe<UpdateAutomationTemplatePayload>;
  /** Updates a badge's name, description, color, permissions, and/or viewable review types */
  updateBadge?: Maybe<UpdateBadgePayload>;
  /** Bulk update the exclusion statuses for all case subjects of a specified section. */
  updateBulkReviewDataExclusions?: Maybe<UpdateBulkReviewDataExclusionsPayload>;
  updateDatasource?: Maybe<UpdateDatasourcePayload>;
  /** Update the badges assigned to a directory sync group. */
  updateDirectorySyncBadgeAssignments?: Maybe<UpdateDirectorySyncBadgeAssignmentsPayload>;
  /** Enable or disable directory sync for an organization */
  updateDirectorySyncEnabled?: Maybe<UpdateDirectorySyncEnabledPayload>;
  updateInvestigation?: Maybe<UpdateInvestigationPayload>;
  updateOtherInfoLabel?: Maybe<UpdateOtherInfoLabelPayload>;
  /** Update a review */
  updateReview?: Maybe<UpdateReviewPayload>;
  /** Update the exclusion status for a subject. */
  updateSingleReviewDataExclusion?: Maybe<UpdateSingleReviewDataExclusionPayload>;
  /** Flag or unflag a transaction. */
  updateTransactionFlagged?: Maybe<UpdateTransactionFlaggedPayload>;
  /** Create or update a case alert. */
  upsertCaseAlert?: Maybe<UpsertCaseAlertPayload>;
  upsertComment?: Maybe<UpsertCommentPayload>;
  /** Create or update a cyber event. */
  upsertCyberEventIndicator?: Maybe<UpsertIndicatorPayload>;
  /** Upsert FINTRAC API settings. */
  upsertFintracApiSettings?: Maybe<UpsertFintracApiSettingsPayload>;
  /** Create or update Middesk credential. */
  upsertMiddeskCredential?: Maybe<UpsertMiddeskCredentialPayload>;
  /** Create or update a narrative template. */
  upsertNarrativeTemplate?: Maybe<UpsertNarrativeTemplatePayload>;
  /** Create or update a saved search */
  upsertSavedSearch?: Maybe<UpsertSavedSearchPayload>;
  upsertScreeningSearchResult?: Maybe<UpsertScreeningSearchResultPayload>;
  /** Create or update Sigma configuration. */
  upsertSigmaConfiguration?: Maybe<UpsertSigmaConfigurationPayload>;
  /** Create or update TR CLEAR credential. */
  upsertThomsonReutersClearCredential?: Maybe<UpsertThomsonReutersClearCredentialPayload>;
  /** Create or update a transaction */
  upsertTransaction?: Maybe<UpsertTransactionPayload>;
  upsertTransactionEndpoint?: Maybe<UpsertTransactionEndpointPayload>;
};


export type MutationAddFincenAdvisoryToReviewArgs = {
  input: AddAdvisoryToReviewInput;
};


export type MutationAdjustReviewDueDateArgs = {
  input: AdjustReviewDueDateInput;
};


export type MutationAdjustReviewTimelineArgs = {
  input: AdjustReviewTimelineInput;
};


export type MutationApproveAccessRequestArgs = {
  input: ApproveAccessRequestInput;
};


export type MutationBatchUpsertLibraryLinksArgs = {
  input: BatchUpsertLibraryLinksInput;
};


export type MutationBatchUpsertRecipientsArgs = {
  input: BatchUpsertRecipientsInput;
};


export type MutationCancelAlertSuppressionRuleArgs = {
  input: CancelAlertSuppressionRuleInput;
};


export type MutationCancelCaseReviewArgs = {
  input: CancelCaseReviewInput;
};


export type MutationCancelInformationRequestArgs = {
  input: CancelInformationRequestInput;
};


export type MutationCompleteInformationRequestArgs = {
  input: CompleteInformationRequestInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreateAttachmentSummaryFeedbackArgs = {
  input: CreateAttachmentSummaryFeedbackInput;
};


export type MutationCreateAttachmentsArgs = {
  input: CreateAttachmentsInput;
};


export type MutationCreateAutomationRuleArgs = {
  input: CreateAutomationRuleInput;
};


export type MutationCreateAutomationRuleAsAdminArgs = {
  input: CreateAutomationRuleAsAdminInput;
};


export type MutationCreateAutomationTemplateArgs = {
  input: CreateAutomationTemplateInput;
};


export type MutationCreateChainalysisConfigurationArgs = {
  input: CreateChainalysisConfigurationInput;
};


export type MutationCreateDatasourceArgs = {
  input: CreateDatasourceInput;
};


export type MutationCreateInboundRequestResponseArgs = {
  input: CreateInboundRequestResponseInput;
};


export type MutationCreateInformationRequestArgs = {
  input: CreateInformationRequestInput;
};


export type MutationCreateInformationRequestResponseArgs = {
  input: CreateInformationRequestResponseInput;
};


export type MutationCreateInvestigationArgs = {
  input: CreateInvestigationInput;
};


export type MutationCreateInvestigationDocumentDownloadArgs = {
  input: CreateInvestigationDocumentDownloadInput;
};


export type MutationCreateOtherInfoLabelArgs = {
  input: CreateOtherInfoLabelInput;
};


export type MutationCreatePublicKeyArgs = {
  input: CreatePublicKeyInput;
};


export type MutationCreateReviewNarrativeValidationArgs = {
  input: CreateReviewNarrativeValidationInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTransientUploadAuthorizationArgs = {
  input: CreateTransientUploadAuthorizationInput;
};


export type MutationCreateWebhookEndpointArgs = {
  input: CreateWebhookEndpointInput;
};


export type MutationDeclineInboundRequestArgs = {
  input: DeclineInboundRequestInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};


export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};


export type MutationDeleteAutomationRuleArgs = {
  input: DeleteAutomationRuleInput;
};


export type MutationDeleteAutomationRuleAsAdminArgs = {
  input: DeleteAutomationRuleAsAdminInput;
};


export type MutationDeleteAutomationTemplateArgs = {
  input: DeleteAutomationTemplateInput;
};


export type MutationDeleteCaseAlertArgs = {
  input: DeleteCaseAlertInput;
};


export type MutationDeleteChainalysisConfigurationArgs = {
  input: DeleteChainalysisConfigurationInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationDeleteCyberEventIndicatorArgs = {
  input: DeleteIndicatorInput;
};


export type MutationDeleteFintracApiSettingsArgs = {
  input: DeleteFintracApiSettingsInput;
};


export type MutationDeleteMiddeskCredentialArgs = {
  input: DeleteMiddeskCredentialInput;
};


export type MutationDeleteNarrativeTemplateArgs = {
  input: DeleteNarrativeTemplateInput;
};


export type MutationDeletePublicKeyArgs = {
  input: DeletePublicKeyInput;
};


export type MutationDeleteSavedSearchArgs = {
  input: DeleteSavedSearchInput;
};


export type MutationDeleteSigmaConfigurationArgs = {
  input: DeleteSigmaConfigurationInput;
};


export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


export type MutationDeleteThomsonReutersClearCredentialArgs = {
  input: DeleteThomsonReutersClearCredentialInput;
};


export type MutationDeleteTransactionEndpointArgs = {
  input: DeleteTransactionEndpointInput;
};


export type MutationDeleteWebhookEndpointArgs = {
  input: DeleteWebhookEndpointInput;
};


export type MutationDisableAutomationRuleArgs = {
  input: DisableAutomationRuleInput;
};


export type MutationDisableEllipticIntegrationArgs = {
  input: DisableEllipticIntegrationInput;
};


export type MutationDismissSuggestedFincenAdvisoryArgs = {
  input: DismissSuggestedFincenAdvisoryInput;
};


export type MutationDownloadOrganizationAlertArgs = {
  input: DownloadOrganizationAlertInput;
};


export type MutationEnableAutomationRuleArgs = {
  input: EnableAutomationRuleInput;
};


export type MutationEnableDisableAutomationTemplateArgs = {
  input: EnableDisableAutomationTemplateInput;
};


export type MutationEnableEllipticIntegrationArgs = {
  input: EnableEllipticIntegrationInput;
};


export type MutationEnableOrDisableWebhookEndpointArgs = {
  input: EnableOrDisableWebhookEndpointInput;
};


export type MutationEnqueueAlertsExportJobArgs = {
  input: EnqueueAlertsExportJobInput;
};


export type MutationEnqueueDashboardExportJobArgs = {
  input: EnqueueDashboardExportJobInput;
};


export type MutationEnqueueFintracValidationJobArgs = {
  input: EnqueueFintracValidationJobInput;
};


export type MutationEnqueueHistoryExportJobArgs = {
  input: EnqueueHistoryExportJobInput;
};


export type MutationEnqueueMiddeskBusinessJobArgs = {
  input: EnqueueMiddeskBusinessJobInput;
};


export type MutationEnqueueReviewNarrativeGenerationJobArgs = {
  input: EnqueueReviewNarrativeGenerationJobInput;
};


export type MutationEnqueueScreeningSearchReportJobArgs = {
  input: EnqueueScreeningSearchReportJobInput;
};


export type MutationEnqueueThomsonReutersBusinessReportJobArgs = {
  input: EnqueueThomsonReutersBusinessReportJobInput;
};


export type MutationEnqueueThomsonReutersBusinessSearchJobArgs = {
  input: EnqueueThomsonReutersBusinessSearchJobInput;
};


export type MutationEnqueueThomsonReutersPersonReportJobArgs = {
  input: EnqueueThomsonReutersPersonReportJobInput;
};


export type MutationEnqueueThomsonReutersPersonSearchJobArgs = {
  input: EnqueueThomsonReutersPersonSearchJobInput;
};


export type MutationFetchDirectorySyncGroupsArgs = {
  input: FetchDirectorySyncGroupsInput;
};


export type MutationGenerateScreeningSearchReportArgs = {
  input: GenerateScreeningSearchReportInput;
};


export type MutationLockInvestigationArgs = {
  input: LockInvestigationInput;
};


export type MutationMergeCreateReviewTypeArgs = {
  input: MergeCreateReviewInput;
};


export type MutationMergeLibraryObjectsArgs = {
  input: MergeLibraryObjectsInput;
};


export type MutationOpenCaseReviewNowArgs = {
  input: OpenCaseReviewNowInput;
};


export type MutationOverrideAlertSuppressionArgs = {
  input: OverrideAlertSuppressionInput;
};


export type MutationPerformInvestigationBatchActionArgs = {
  input: PerformInvestigationBatchActionInput;
};


export type MutationPerformLibraryProfilesBatchActionArgs = {
  input: PerformLibraryProfilesBatchActionInput;
};


export type MutationPerformReviewBatchActionArgs = {
  input: PerformReviewBatchActionInput;
};


export type MutationPerformTransactionBatchActionArgs = {
  input: PerformTransactionBatchActionInput;
};


export type MutationRecordLibraryTransactionArgs = {
  input: RecordLibraryTransactionInput;
};


export type MutationRejectAccessRequestArgs = {
  input: RejectAccessRequestInput;
};


export type MutationRemoveFincenAdvisoryFromReviewArgs = {
  input: RemoveAdvisoryFromReviewInput;
};


export type MutationRenameAttachmentArgs = {
  input: RenameAttachmentInput;
};


export type MutationRenderNarrativeTemplateArgs = {
  input: RenderNarrativeTemplateInput;
};


export type MutationRequestInstallAppArgs = {
  input: RequestInstallInput;
};


export type MutationResendAccountConfirmationArgs = {
  input: ResendAccountConfirmationInput;
};


export type MutationResetDemoArgs = {
  input: ResetDemoInput;
};


export type MutationResolveCommentThreadArgs = {
  input: ResolveCommentThreadInput;
};


export type MutationRevokeAccessRequestArgs = {
  input: RevokeAccessRequestInput;
};


export type MutationSnoozeCaseReviewArgs = {
  input: SnoozeCaseReviewInput;
};


export type MutationSubmitRequestForInformationArgs = {
  input: SubmitRequestForInformationInput;
};


export type MutationSubmitTipIntakeArgs = {
  input: SubmitTipIntakeInput;
};


export type MutationSummarizeAttachmentArgs = {
  input: SummarizeAttachmentInput;
};


export type MutationTestWebhookEndpointArgs = {
  input: EnqueueTestWebhookEndpointJobInput;
};


export type MutationToggleRequestRemindersArgs = {
  input: ToggleRequestRemindersInput;
};


export type MutationTriggerAutomationRulePreviewModeRunArgs = {
  input: TriggerPreviewModeRunInput;
};


export type MutationUndeleteAutomationRuleArgs = {
  input: UndeleteAutomationRuleInput;
};


export type MutationUnlinkAccountArgs = {
  input: UnlinkAccountInput;
};


export type MutationUnlockInvestigationArgs = {
  input: UnlockInvestigationInput;
};


export type MutationUpdateAccountBadgesArgs = {
  input: UpdateAccountBadgesInput;
};


export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};


export type MutationUpdateAutomationRuleArgs = {
  input: UpdateAutomationRuleInput;
};


export type MutationUpdateAutomationRuleAsAdminArgs = {
  input: UpdateAutomationRuleAsAdminInput;
};


export type MutationUpdateAutomationRuleNotesArgs = {
  input: UpdateAutomationRuleNotesInput;
};


export type MutationUpdateAutomationRuleSettingsArgs = {
  input: UpdateAutomationRuleSettingsInput;
};


export type MutationUpdateAutomationTemplateArgs = {
  input: UpdateAutomationTemplateInput;
};


export type MutationUpdateBadgeArgs = {
  input: UpdateBadgeInput;
};


export type MutationUpdateBulkReviewDataExclusionsArgs = {
  input: UpdateBulkReviewDataExclusionsInput;
};


export type MutationUpdateDatasourceArgs = {
  input: UpdateDatasourceInput;
};


export type MutationUpdateDirectorySyncBadgeAssignmentsArgs = {
  input: UpdateDirectorySyncBadgeAssignmentsInput;
};


export type MutationUpdateDirectorySyncEnabledArgs = {
  input: UpdateDirectorySyncEnabledInput;
};


export type MutationUpdateInvestigationArgs = {
  input: UpdateInvestigationInput;
};


export type MutationUpdateOtherInfoLabelArgs = {
  input: UpdateOtherInfoLabelInput;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


export type MutationUpdateSingleReviewDataExclusionArgs = {
  input: UpdateSingleReviewDataExclusionInput;
};


export type MutationUpdateTransactionFlaggedArgs = {
  input: UpdateTransactionFlaggedInput;
};


export type MutationUpsertCaseAlertArgs = {
  input: UpsertCaseAlertInput;
};


export type MutationUpsertCommentArgs = {
  input: UpsertCommentInput;
};


export type MutationUpsertCyberEventIndicatorArgs = {
  input: UpsertIndicatorInput;
};


export type MutationUpsertFintracApiSettingsArgs = {
  input: UpsertFintracApiSettingsInput;
};


export type MutationUpsertMiddeskCredentialArgs = {
  input: UpsertMiddeskCredentialInput;
};


export type MutationUpsertNarrativeTemplateArgs = {
  input: UpsertNarrativeTemplateInput;
};


export type MutationUpsertSavedSearchArgs = {
  input: UpsertSavedSearchInput;
};


export type MutationUpsertScreeningSearchResultArgs = {
  input: UpsertScreeningSearchResultInput;
};


export type MutationUpsertSigmaConfigurationArgs = {
  input: UpsertSigmaConfigurationInput;
};


export type MutationUpsertThomsonReutersClearCredentialArgs = {
  input: UpsertThomsonReutersClearCredentialInput;
};


export type MutationUpsertTransactionArgs = {
  input: UpsertTransactionInput;
};


export type MutationUpsertTransactionEndpointArgs = {
  input: UpsertTransactionEndpointInput;
};

export type NameAttributes = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

export type NameMetadata = {
  __typename?: 'NameMetadata';
  active: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type NameMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: NameAttributes;
};

export type NameStringMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type NarrativeTemplate = {
  __typename?: 'NarrativeTemplate';
  /**
   * This NarrativeTemplate is shown on TextEntry tasks for the applicable review
   * type. If null, it will be shown on all review types
   */
  applicableReviewType?: Maybe<TypeOfReview>;
  content: Scalars['String']['output'];
  creator?: Maybe<Account>;
  name: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type NarrativeValidation = {
  __typename?: 'NarrativeValidation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  status: NarrativeValidationStatusEnum;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validations?: Maybe<Array<NarrativeValidationResult>>;
};

export type NarrativeValidationCriterion = {
  __typename?: 'NarrativeValidationCriterion';
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type NarrativeValidationResult = {
  __typename?: 'NarrativeValidationResult';
  criterion: NarrativeValidationCriterion;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  validations?: Maybe<Array<NarrativeValidationResult>>;
};

/** The status of the narrative validation */
export enum NarrativeValidationStatusEnum {
  Completed = 'completed',
  Enqueued = 'enqueued',
  Failed = 'failed',
  Processing = 'processing'
}

export type NotificationParams = {
  accountTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  badgeTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  body?: InputMaybe<Scalars['String']['input']>;
  recipientEmails?: InputMaybe<Array<EmailRecipientParams>>;
  sendToReviewAssignee?: InputMaybe<Scalars['Boolean']['input']>;
  smartValuesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type Occupation = {
  __typename?: 'Occupation';
  company?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  naicsCode?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
};

export type OccupationAttributes = {
  company?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<Scalars['String']['input']>;
};

export type OmniEmbeddedAnalyticsDashboard = {
  __typename?: 'OmniEmbeddedAnalyticsDashboard';
  name: Scalars['String']['output'];
  omniDashboardIds: Array<Scalars['String']['output']>;
  signedUrls: Array<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of OpenCaseReviewNow */
export type OpenCaseReviewNowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of OpenCaseReviewNow. */
export type OpenCaseReviewNowPayload = {
  __typename?: 'OpenCaseReviewNowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

export type OperatingSystem = {
  __typename?: 'OperatingSystem';
  type: Scalars['String']['output'];
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type OperatingSystemAttributes = {
  type: Scalars['String']['input'];
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

/** Types of filter predicate operators that can be used in PredicateInput */
export enum OperatorEnum {
  /** The field must contain all of the value, but may also contain other data */
  Contains = 'contains',
  /** The field must be greater than value */
  Gt = 'gt',
  /** The field must be greater than or equal to value */
  Gte = 'gte',
  /** The field must exactly match at least one value in list */
  In = 'in',
  /** The field must exactly match value */
  Is = 'is',
  /** The field must be less than value */
  Lt = 'lt',
  /** The field must be less than or equal to value */
  Lte = 'lte',
  /** The field must not exactly match value */
  Not = 'not',
  /** The field must not exactly match any value in list */
  NotIn = 'not_in'
}

/** An organization. */
export type Organization = {
  __typename?: 'Organization';
  accounts: AccountConnection;
  authenticationTypes: Array<Authentication>;
  automationRules: CanonicalAutomationRuleConnection;
  automationRulesLatestCheckForEventable?: Maybe<Scalars['ISO8601DateTime']['output']>;
  automationRulesWithResultsForEventable: CanonicalAutomationRuleConnection;
  /** Permissions available for users to enable/disable. */
  availablePermissions: Array<Permission>;
  /** All badges which can be given to organization members. */
  badges: BadgeConnection;
  chainalysisConfiguration?: Maybe<ChainalysisConfiguration>;
  customerId: Scalars['ID']['output'];
  externalFormLogoImage?: Maybe<Scalars['String']['output']>;
  featureFlags: Array<FeatureFlagState>;
  loginBannerMessage?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationApps: OrganizationAppConnection;
  relationshipDefinitions: LibraryRelationshipDefinitionConnection;
  shortCode: Scalars['String']['output'];
  tagDefinitions: Array<LibraryTagDefinition>;
  thomsonReutersClearCredential?: Maybe<ThomsonReutersClearCredential>;
  timezone: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  webhookEndpoints: Array<WebhookEndpoint>;
};


/** An organization. */
export type OrganizationAccountsArgs = {
  accountSearchQuery?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization. */
export type OrganizationAutomationRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization. */
export type OrganizationAutomationRulesLatestCheckForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};


/** An organization. */
export type OrganizationAutomationRulesWithResultsForEventableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization. */
export type OrganizationBadgesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  asQueues?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization. */
export type OrganizationOrganizationAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization. */
export type OrganizationRelationshipDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  relationshipDefinitionsSearchQuery?: InputMaybe<Scalars['String']['input']>;
};


/** An organization. */
export type OrganizationTagDefinitionsArgs = {
  allowedTypes?: InputMaybe<Array<TagDefinitionAllowedTypeEnum>>;
};

export type OrganizationAlert = {
  __typename?: 'OrganizationAlert';
  alertRule: AlertRule;
  appliedAlertSuppressionRules: Array<AlertSuppressionRule>;
  /** @deprecated Feature was never fully implemented, no real data uses this. */
  comments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dismissedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalPageLinks?: Maybe<Array<WebLink>>;
  processedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  review?: Maybe<Review>;
  state: OrganizationAlertState;
  suppressedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  token: Scalars['ID']['output'];
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for OrganizationAlert. */
export type OrganizationAlertConnection = {
  __typename?: 'OrganizationAlertConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationAlertEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganizationAlert>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationAlertEdge = {
  __typename?: 'OrganizationAlertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationAlert>;
};

export enum OrganizationAlertState {
  Dismissed = 'DISMISSED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Stuck = 'STUCK',
  Suppressed = 'SUPPRESSED'
}

/** A HB app's association to an organization */
export type OrganizationApp = {
  __typename?: 'OrganizationApp';
  app: App;
  requestedAt?: Maybe<Scalars['DateOrTime']['output']>;
  status: AppStatusEnum;
};

/** The connection type for OrganizationApp. */
export type OrganizationAppConnection = {
  __typename?: 'OrganizationAppConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationAppEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganizationApp>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationAppEdge = {
  __typename?: 'OrganizationAppEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationApp>;
};

/** Organization review decision */
export type OrganizationReviewDecision = {
  __typename?: 'OrganizationReviewDecision';
  label: Scalars['String']['output'];
};

export type OtherInfoEntry = {
  __typename?: 'OtherInfoEntry';
  displayAs: OtherInfoLabelDisplayAsEnum;
  label: Scalars['String']['output'];
  managed?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type OtherInfoEntryInput = {
  displayAs?: InputMaybe<OtherInfoLabelDisplayAsEnum>;
  label: Scalars['String']['input'];
  managed?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OtherInfoEntryParams = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OtherInfoLabel = {
  __typename?: 'OtherInfoLabel';
  dashboardVisible?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayAs: OtherInfoLabelDisplayAsEnum;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  managed?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['ID']['output'];
  type: OtherInfoLabelTypeEnum;
};

/** The connection type for OtherInfoLabel. */
export type OtherInfoLabelConnection = {
  __typename?: 'OtherInfoLabelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OtherInfoLabelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OtherInfoLabel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

export enum OtherInfoLabelDisplayAsEnum {
  CustomInfo = 'custom_info',
  OtherInfo = 'other_info'
}

/** An edge in a connection. */
export type OtherInfoLabelEdge = {
  __typename?: 'OtherInfoLabelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OtherInfoLabel>;
};

export enum OtherInfoLabelTypeEnum {
  BankAccount = 'bank_account',
  Business = 'business',
  CryptoAddress = 'crypto_address',
  Device = 'device',
  FinancialInstitution = 'financial_institution',
  PaymentCard = 'payment_card',
  Person = 'person',
  Product = 'product',
  Review = 'review',
  ReviewData = 'review_data',
  Transaction = 'transaction'
}

/** Autogenerated input type of OverrideAlertSuppression */
export type OverrideAlertSuppressionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of OverrideAlertSuppression. */
export type OverrideAlertSuppressionPayload = {
  __typename?: 'OverrideAlertSuppressionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organizationAlert: OrganizationAlert;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Participant = {
  __typename?: 'Participant';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  institutionName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PaymentCard = {
  type?: InputMaybe<Scalars['String']['input']>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentCardAttributes = {
  activatedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  cardType?: InputMaybe<PaymentCard>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  closedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  creditLimit?: InputMaybe<HbMoneyInput>;
  expiration?: InputMaybe<Scalars['YearMonth']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  foreign?: InputMaybe<Scalars['Boolean']['input']>;
  maskedPan?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<PaymentCardNetwork>;
  notes?: InputMaybe<Scalars['String']['input']>;
  openedAt?: InputMaybe<Scalars['DateOrTime']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentCardNetwork = {
  type?: InputMaybe<Scalars['String']['input']>;
  typeOther?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentCardNetworkAttributes = {
  __typename?: 'PaymentCardNetworkAttributes';
  type?: Maybe<Scalars['String']['output']>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type PaymentCardTxnEntry = {
  accountHolder?: InputMaybe<LibraryObjectRelation>;
  attributes: PaymentCardAttributes;
  institution?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type PaymentCardTypeAttributes = {
  __typename?: 'PaymentCardTypeAttributes';
  type?: Maybe<Scalars['String']['output']>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PerformInvestigationBatchAction */
export type PerformInvestigationBatchActionInput = {
  /** The name of the batch action */
  batchActionName: BatchActionTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Custom parameters for a batch action */
  parameters?: InputMaybe<BatchActionParameters>;
  /** tokens we want to apply the action against. */
  tokens: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PerformInvestigationBatchAction. */
export type PerformInvestigationBatchActionPayload = {
  __typename?: 'PerformInvestigationBatchActionPayload';
  /** The unique bid of the SideKiq worker batch */
  bid: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PerformLibraryProfilesBatchAction */
export type PerformLibraryProfilesBatchActionInput = {
  /** The name of the batch action */
  batchActionName: BatchActionTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Custom parameters for a batch action */
  parameters?: InputMaybe<BatchActionParameters>;
  /** tokens we want to apply the action against. */
  tokens: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PerformLibraryProfilesBatchAction. */
export type PerformLibraryProfilesBatchActionPayload = {
  __typename?: 'PerformLibraryProfilesBatchActionPayload';
  /** The unique bid of the SideKiq worker batch */
  bid: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PerformReviewBatchAction */
export type PerformReviewBatchActionInput = {
  /** The name of the batch action */
  batchActionName: BatchActionTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Custom parameters for a batch action */
  parameters?: InputMaybe<BatchActionParameters>;
  /** tokens we want to apply the action against. */
  tokens: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PerformReviewBatchAction. */
export type PerformReviewBatchActionPayload = {
  __typename?: 'PerformReviewBatchActionPayload';
  /** The unique bid of the SideKiq worker batch */
  bid: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PerformTransactionBatchAction */
export type PerformTransactionBatchActionInput = {
  /** The name of the batch action */
  batchActionName: BatchActionTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Custom parameters for a batch action */
  parameters?: InputMaybe<BatchActionParameters>;
  /** tokens we want to apply the action against. */
  tokens: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PerformTransactionBatchAction. */
export type PerformTransactionBatchActionPayload = {
  __typename?: 'PerformTransactionBatchActionPayload';
  /** The unique bid of the SideKiq worker batch */
  bid: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  category?: Maybe<PermissionCategoryEnum>;
  description: Scalars['String']['output'];
  externalDescription: Scalars['String']['output'];
  externalName: Scalars['String']['output'];
  name: BadgePermissionsEnum;
  token: Scalars['ID']['output'];
};

export enum PermissionCategoryEnum {
  Accounts = 'ACCOUNTS',
  Analytics = 'ANALYTICS',
  Cases = 'CASES',
  Crm = 'CRM',
  HummingbirdAi = 'HUMMINGBIRD_AI',
  OrganizationSettings = 'ORGANIZATION_SETTINGS',
  RequestsForInformation = 'REQUESTS_FOR_INFORMATION',
  Reviews = 'REVIEWS'
}

/** an address with types specific to people */
export type PersonAddress = BaseAddress & {
  __typename?: 'PersonAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressType?: Maybe<PersonAddressTypeEnum>;
  addressTypeOther?: Maybe<Scalars['String']['output']>;
  administrativeDistrictLevel1?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  geopoint?: Maybe<Geopoint>;
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
};

export type PersonAddressAttributes = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<PersonAddressTypeEnum>;
  addressTypeOther?: InputMaybe<Scalars['String']['input']>;
  administrativeDistrictLevel1?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
};

export enum PersonAddressTypeEnum {
  Birthplace = 'BIRTHPLACE',
  Home = 'HOME',
  MailingAddress = 'MAILING_ADDRESS',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  UsBillingAddress = 'US_BILLING_ADDRESS',
  UsShippingAddress = 'US_SHIPPING_ADDRESS',
  Work = 'WORK'
}

export type PersonAttributes = {
  addresses?: InputMaybe<Array<PersonAddressAttributes>>;
  alternateNames?: InputMaybe<Array<Scalars['String']['input']>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  birthdate?: InputMaybe<Scalars['DateParts']['input']>;
  birthdates?: InputMaybe<Array<BirthdateMetadataInput>>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<LibraryGenderEnum>;
  genders?: InputMaybe<Array<GenderMetadataInput>>;
  identityDocuments?: InputMaybe<Array<IdentityDocumentAttributes>>;
  name?: InputMaybe<NameAttributes>;
  names?: InputMaybe<Array<NameMetadataAttributes>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  occupations?: InputMaybe<Array<OccupationAttributes>>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberAttributes>>;
  tins?: InputMaybe<Array<TinAttributes>>;
  tinsMetadata?: InputMaybe<Array<TinsMetadataAttributes>>;
  usernames?: InputMaybe<Array<Scalars['String']['input']>>;
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PersonName = {
  __typename?: 'PersonName';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type PersonNameMetadata = {
  __typename?: 'PersonNameMetadata';
  active: Scalars['Boolean']['output'];
  value: PersonName;
};

export type PersonTxnEntry = {
  attributes: PersonAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  extension?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PhoneNumberEnum>;
};

export type PhoneNumberAttributes = {
  extension?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PhoneNumberEnum>;
};

export enum PhoneNumberEnum {
  Fax = 'FAX',
  Home = 'HOME',
  Mobile = 'MOBILE',
  Work = 'WORK'
}

export type PhoneNumberInput = {
  extension?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** A simple filter predicate */
export type PredicateInput = {
  operator: OperatorEnum;
  values: Array<Scalars['String']['input']>;
};

/** Filter predicate */
export type PredicateOutput = {
  __typename?: 'PredicateOutput';
  operator: OperatorEnum;
  values: Array<Scalars['String']['output']>;
};

export type ProductAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  commodityType?: InputMaybe<Scalars['String']['input']>;
  cusip?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  marketTraded?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  sku?: InputMaybe<Scalars['String']['input']>;
};

export type ProductKey = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ProductTxnEntry = {
  attributes: ProductAttributes;
  key?: InputMaybe<ProductKey>;
};

export type ProviderContext = {
  __typename?: 'ProviderContext';
  asyncSearchable: Scalars['Boolean']['output'];
  enabledFormFields: Array<EnabledFormField>;
  expand: SearchHit;
  expandable: Scalars['Boolean']['output'];
  screeningSearchAsyncJobResults: SearchHitConnection;
  screeningSearchAsyncJobStatus: ScreeningSearchJob;
  search: SearchHitConnection;
  tableHeaders: Array<TableHeader>;
};


export type ProviderContextExpandArgs = {
  providerId: Scalars['ID']['input'];
};


export type ProviderContextScreeningSearchAsyncJobResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  investigationToken: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  libraryToken: Scalars['ID']['input'];
  libraryType: Scalars['String']['input'];
  searchQuery: ScreeningSearchQueryParameters;
};


export type ProviderContextScreeningSearchAsyncJobStatusArgs = {
  investigationToken: Scalars['ID']['input'];
  libraryToken: Scalars['ID']['input'];
  libraryType: Scalars['String']['input'];
  searchQuery: ScreeningSearchQueryParameters;
};


export type ProviderContextSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchQuery: ScreeningSearchQueryParameters;
};

export type PublicKey = {
  __typename?: 'PublicKey';
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  _debugElasticsearch?: Maybe<Scalars['Boolean']['output']>;
  _debugSql?: Maybe<Scalars['Boolean']['output']>;
  adminAutomationRuleOrganization: Organization;
  adminAutomationRuleOrganizations: Array<Organization>;
  alertSuppressionRule?: Maybe<AlertSuppressionRule>;
  alertsExportJob?: Maybe<AlertsExportJob>;
  apiKeys: Array<ApiKey>;
  /** All undeleted apps */
  apps: AppConnection;
  automatedTasksUsage: AutomatedTasksUsage;
  automationRecipeTaskUsage: AutomatedTaskUsageStats;
  automationRule: CanonicalAutomationRule;
  automationRuleTemplate: AutomationRuleTemplate;
  automationRuleTemplateCategories: Array<AutomationRuleTemplateCategory>;
  automationRuleTemplates: Array<AutomationRuleTemplate>;
  automationTriggerTemplates: Array<AutomationRuleTriggerTemplate>;
  automationsUsageCategories: AutomationUsageStats;
  automationsUsageStats: AutomationUsageStats;
  /** Fetch a specific badge. */
  badge?: Maybe<Badge>;
  batchActionStatus?: Maybe<BatchActionStatus>;
  canonicalReviewType?: Maybe<CanonicalReview>;
  canonicalReviewTypes?: Maybe<CanonicalReviewConnection>;
  caseImport?: Maybe<CaseImport>;
  caseImports: CaseImportConnection;
  chainalysisConfigurations: Array<ChainalysisConfiguration>;
  commentThreads: Array<CommentThread>;
  currentAccount: Account;
  currentOrganization: Organization;
  customFieldLabelSearch: Array<OtherInfoLabel>;
  dashboardExportJob?: Maybe<DashboardExportJob>;
  datasourceDatatable: DatasourceDatatable;
  datasourceDatatables: Array<DatasourceDatatable>;
  datasourceWarehouse: DatasourceWarehouse;
  datasourceWarehouses: Array<DatasourceWarehouse>;
  datasources: Array<Datasource>;
  deployments?: Maybe<DeploymentConnection>;
  directorySyncConfig?: Maybe<DirectorySyncConfig>;
  directorySyncDiff?: Maybe<DirectorySyncDiff>;
  directorySyncGroups?: Maybe<Array<DirectorySyncGroup>>;
  ellipticConfiguration?: Maybe<EllipticConfiguration>;
  emailDownload: Download;
  embeddedAnalyticsDashboard?: Maybe<EmbeddedAnalyticsDashboard>;
  embeddedAnalyticsDashboards?: Maybe<Array<EmbeddedAnalyticsDashboard>>;
  filingInstitution?: Maybe<FilingInstitution>;
  fincenAdvisories: FincenAdvisoryConnection;
  fintracValidationAttempt?: Maybe<ValidationAttempt>;
  formConfigurationByPath?: Maybe<FormConfigurationByPathUnion>;
  historyExportJob?: Maybe<HistoryExportJob>;
  inboundRequest?: Maybe<InboundRequest>;
  informationRequest?: Maybe<InformationRequest>;
  informationRequestAutocompleteRecipients: Array<AutocompleteRecipient>;
  informationRequestForms: Array<InformationRequestForm>;
  informationRequestRecipientByExternalToken?: Maybe<FindInformationRequestRecipientByExternalTokenQueryResult>;
  informationRequestResponseCopy?: Maybe<InformationRequestResponseCopy>;
  informationRequestSearch: Array<InformationRequestSearchResult>;
  investigation?: Maybe<Investigation>;
  investigationDocumentDownload: InvestigationDocumentDownload;
  investigations?: Maybe<InvestigationConnection>;
  library?: Maybe<Array<LibraryEntry>>;
  libraryAttachmentByToken: LibraryAttachment;
  libraryBusinessOwnerByOwnerAndBusiness?: Maybe<LibraryBusinessOwner>;
  libraryByToken?: Maybe<LibraryEntry>;
  libraryByTokens?: Maybe<Array<LibraryEntry>>;
  middeskBusinessJob?: Maybe<MiddeskBusinessJob>;
  middeskBusinessesByLibraryToken: MiddeskBusinessConnection;
  middeskCredential?: Maybe<MiddeskCredential>;
  mtlsCaCertificate?: Maybe<X509Certificate>;
  narrativeTemplates: Array<NarrativeTemplate>;
  narrativeValidation: NarrativeValidation;
  organizationAlert?: Maybe<OrganizationAlert>;
  organizationAlerts: OrganizationAlertConnection;
  organizationReviewDecisions: Array<OrganizationReviewDecision>;
  otherInfoLabelExists: Scalars['Boolean']['output'];
  otherInfoLabelSearch: Array<OtherInfoLabel>;
  otherInfoLabels: OtherInfoLabelConnection;
  participantSearch: Array<Participant>;
  promptAi?: Maybe<Response>;
  publicKey?: Maybe<PublicKey>;
  publicKeys?: Maybe<Array<PublicKey>>;
  /** Queues are groups of users which can be used for assignment */
  queues: QueueConnection;
  quickSearch: Array<QuickSearchResult>;
  requestForInformation?: Maybe<RequestForInformation>;
  requestForInformationRecipientCopy?: Maybe<RequestForInformationRecipientCopy>;
  review?: Maybe<Review>;
  reviewNarrativeGenerationJob: ReviewNarrativeGenerationJob;
  reviewType?: Maybe<TypeOfReview>;
  screeningSearchProvider: ProviderContext;
  searchAutomationRuleExecutions: SearchAutomationRuleExecutionsResult;
  searchAutomationRuleExecutionsMetadataV2: SearchMetadataResult;
  searchCtrFilingsMetadata: SearchMetadataResult;
  searchFilings: SearchFilingsResult;
  searchFilingsMetadataV2: SearchMetadataResult;
  searchFilingsV2: SearchFilingsResult;
  searchInformationRequests: SearchInformationRequestsResult;
  searchInformationRequestsMetadataV2: SearchMetadataResult;
  searchProfiles: SearchProfilesResult;
  searchProfilesMetadataV2: SearchMetadataResult;
  searchReviewMetadataV2: SearchMetadataResult;
  searchReviewsV3: SearchReviewsResultV2;
  searchTransactions: SearchTransactionsResult;
  searchTransactionsMetadataV2: SearchMetadataResult;
  searchTransactionsStats: SearchTransactionsStatsResult;
  sigmaConfiguration?: Maybe<SigmaConfiguration>;
  /** Fetch a specific support access request. */
  supportAccessRequest?: Maybe<AccessRequest>;
  supportAccessRequests: AccessRequestConnection;
  /** Retrieve a survey file upload. */
  surveyFileUpload?: Maybe<ShrineUploadedFile>;
  thomsonReutersBusinessReportJob?: Maybe<ThomsonReutersBusinessReportJob>;
  thomsonReutersBusinessSearchJob?: Maybe<ThomsonReutersBusinessSearchJob>;
  thomsonReutersPersonReportJob?: Maybe<ThomsonReutersPersonReportJob>;
  thomsonReutersPersonSearchJob?: Maybe<ThomsonReutersPersonSearchJob>;
  tipIntakeConfiguration?: Maybe<TipIntakeConfiguration>;
  tipIntakeConfigurations: Array<TipIntakeConfiguration>;
  tipSubmissionCopy?: Maybe<TipSubmissionCopy>;
  /** A dictionary of webhook endpoint test data keyed by event type */
  webhookEndpointTestData: Scalars['JSON']['output'];
  webhookEndpointTestJob?: Maybe<WebhookEndpointTestJob>;
};


export type QueryAdminAutomationRuleOrganizationArgs = {
  organizationToken: Scalars['ID']['input'];
};


export type QueryAlertSuppressionRuleArgs = {
  token: Scalars['String']['input'];
};


export type QueryAlertsExportJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAutomationRecipeTaskUsageArgs = {
  range: AutomatedTaskStatsRange;
};


export type QueryAutomationRuleArgs = {
  organizationToken?: InputMaybe<Scalars['ID']['input']>;
  token: Scalars['ID']['input'];
};


export type QueryAutomationRuleTemplateArgs = {
  token: Scalars['ID']['input'];
};


export type QueryAutomationRuleTemplatesArgs = {
  enabledOnly: Scalars['Boolean']['input'];
};


export type QueryAutomationsUsageCategoriesArgs = {
  range: AutomationStatsRange;
  taxonomy: AutomationStatsTaxonomy;
};


export type QueryAutomationsUsageStatsArgs = {
  granularity: AutomationStatsGranularity;
  range: AutomationStatsRange;
};


export type QueryBadgeArgs = {
  token: Scalars['ID']['input'];
};


export type QueryBatchActionStatusArgs = {
  bid: Scalars['ID']['input'];
};


export type QueryCanonicalReviewTypeArgs = {
  canonicalId: Scalars['ID']['input'];
};


export type QueryCanonicalReviewTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCaseImportArgs = {
  token: Scalars['String']['input'];
};


export type QueryCaseImportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCommentThreadsArgs = {
  includeResolved?: InputMaybe<Scalars['Boolean']['input']>;
  investigationReviewToken?: InputMaybe<Scalars['ID']['input']>;
  investigationToken: Scalars['ID']['input'];
  reviewTypeTaskToken?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCustomFieldLabelSearchArgs = {
  labelType: OtherInfoLabelTypeEnum;
};


export type QueryDashboardExportJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryDatasourceDatatableArgs = {
  token: Scalars['ID']['input'];
};


export type QueryDatasourceDatatablesArgs = {
  location?: InputMaybe<DatasourceDatatableLocationEnum>;
};


export type QueryDatasourceWarehouseArgs = {
  token: Scalars['ID']['input'];
};


export type QueryDeploymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEmailDownloadArgs = {
  token: Scalars['ID']['input'];
};


export type QueryEmbeddedAnalyticsDashboardArgs = {
  token: Scalars['ID']['input'];
};


export type QueryFilingInstitutionArgs = {
  token: Scalars['ID']['input'];
};


export type QueryFincenAdvisoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFintracValidationAttemptArgs = {
  reviewToken: Scalars['ID']['input'];
};


export type QueryFormConfigurationByPathArgs = {
  path: Scalars['String']['input'];
};


export type QueryHistoryExportJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryInboundRequestArgs = {
  token: Scalars['ID']['input'];
};


export type QueryInformationRequestArgs = {
  token: Scalars['ID']['input'];
};


export type QueryInformationRequestAutocompleteRecipientsArgs = {
  investigationToken?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInformationRequestRecipientByExternalTokenArgs = {
  externalToken: Scalars['ID']['input'];
};


export type QueryInformationRequestResponseCopyArgs = {
  informationRequestResponseToken: Scalars['ID']['input'];
};


export type QueryInformationRequestSearchArgs = {
  query: Scalars['String']['input'];
};


export type QueryInvestigationArgs = {
  token: Scalars['String']['input'];
};


export type QueryInvestigationDocumentDownloadArgs = {
  token: Scalars['ID']['input'];
};


export type QueryInvestigationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdBetween?: InputMaybe<TimeRange>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  updatedBetween?: InputMaybe<TimeRange>;
  workflow?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLibraryArgs = {
  query: Scalars['String']['input'];
  types?: InputMaybe<Array<LibraryTypeEnum>>;
};


export type QueryLibraryAttachmentByTokenArgs = {
  token: Scalars['ID']['input'];
  txnToken?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLibraryBusinessOwnerByOwnerAndBusinessArgs = {
  businessToken: Scalars['ID']['input'];
  ownerToken: Scalars['ID']['input'];
};


export type QueryLibraryByTokenArgs = {
  token: Scalars['String']['input'];
  txnToken?: InputMaybe<Scalars['String']['input']>;
  type: LibraryTypeEnum;
};


export type QueryLibraryByTokensArgs = {
  tokens: Array<Scalars['String']['input']>;
  txnToken?: InputMaybe<Scalars['String']['input']>;
  type: LibraryTypeEnum;
};


export type QueryMiddeskBusinessJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryMiddeskBusinessesByLibraryTokenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  libraryToken: Scalars['ID']['input'];
};


export type QueryNarrativeValidationArgs = {
  token: Scalars['ID']['input'];
};


export type QueryOrganizationAlertArgs = {
  token: Scalars['String']['input'];
};


export type QueryOrganizationAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<OrganizationAlertState>;
};


export type QueryOrganizationReviewDecisionsArgs = {
  labelFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOtherInfoLabelExistsArgs = {
  label: Scalars['String']['input'];
  labelType: OtherInfoLabelTypeEnum;
};


export type QueryOtherInfoLabelSearchArgs = {
  labelType: OtherInfoLabelTypeEnum;
  query?: InputMaybe<Scalars['String']['input']>;
  useVisibilitySettings?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOtherInfoLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantSearchArgs = {
  query: Scalars['String']['input'];
};


export type QueryPromptAiArgs = {
  maxSample?: InputMaybe<Scalars['Int']['input']>;
  prompt: Scalars['String']['input'];
};


export type QueryPublicKeyArgs = {
  token: Scalars['ID']['input'];
};


export type QueryQueuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuickSearchArgs = {
  query: Scalars['String']['input'];
};


export type QueryRequestForInformationArgs = {
  externalToken: Scalars['String']['input'];
};


export type QueryRequestForInformationRecipientCopyArgs = {
  externalToken: Scalars['String']['input'];
};


export type QueryReviewArgs = {
  token: Scalars['String']['input'];
};


export type QueryReviewNarrativeGenerationJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryReviewTypeArgs = {
  token: Scalars['ID']['input'];
};


export type QueryScreeningSearchProviderArgs = {
  provider: ScreeningSearchProvider;
};


export type QuerySearchAutomationRuleExecutionsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchAutomationRuleExecutionsMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchCtrFilingsMetadataArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchFilingsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchFilingsMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchFilingsV2Args = {
  dashboardType: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchInformationRequestsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchInformationRequestsMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchProfilesArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchProfilesMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchReviewMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchReviewsV3Args = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchTransactionsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: SearchRequestInput;
};


export type QuerySearchTransactionsMetadataV2Args = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<SearchRequestInput>;
};


export type QuerySearchTransactionsStatsArgs = {
  search?: InputMaybe<SearchRequestInput>;
};


export type QuerySupportAccessRequestArgs = {
  token: Scalars['ID']['input'];
};


export type QuerySupportAccessRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySurveyFileUploadArgs = {
  clientId: Scalars['ID']['input'];
  questionToken: Scalars['ID']['input'];
};


export type QueryThomsonReutersBusinessReportJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryThomsonReutersBusinessSearchJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryThomsonReutersPersonReportJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryThomsonReutersPersonSearchJobArgs = {
  token: Scalars['ID']['input'];
};


export type QueryTipIntakeConfigurationArgs = {
  organizationUrlSlug?: InputMaybe<Scalars['String']['input']>;
  tipIntakeSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTipSubmissionCopyArgs = {
  tipToken: Scalars['String']['input'];
};


export type QueryWebhookEndpointTestJobArgs = {
  token: Scalars['ID']['input'];
};

/** A queue is a group of users */
export type Queue = {
  __typename?: 'Queue';
  color: ColorNameEnum;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The human-readable name of this queue. */
  name: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** The connection type for Queue. */
export type QueueConnection = {
  __typename?: 'QueueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QueueEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Queue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QueueEdge = {
  __typename?: 'QueueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Queue>;
};

export type QuickLink = {
  __typename?: 'QuickLink';
  category?: Maybe<Scalars['String']['output']>;
  href: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type QuickSearchResult = {
  __typename?: 'QuickSearchResult';
  caseName?: Maybe<Scalars['String']['output']>;
  members: Array<QuickSearchResultMember>;
  reviews: Array<QuickSearchResultReview>;
  token: Scalars['ID']['output'];
};

export type QuickSearchResultMember = {
  __typename?: 'QuickSearchResultMember';
  color: Scalars['String']['output'];
  initials: Scalars['String']['output'];
};

export type QuickSearchResultReview = {
  __typename?: 'QuickSearchResultReview';
  displayState?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  typeColor: Scalars['String']['output'];
};

/** Autogenerated input type of RecordLibraryTransaction */
export type RecordLibraryTransactionInput = {
  allowCustomFieldUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<Array<AttachmentTxnEntry>>;
  bankAccountBalances?: InputMaybe<Array<BankAccountBalanceTxnEntry>>;
  bankAccounts?: InputMaybe<Array<BankAccountTxnEntry>>;
  businessOwners?: InputMaybe<Array<BusinessOwnerTxnEntry>>;
  businesses?: InputMaybe<Array<BusinessTxnEntry>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cryptoAddresses?: InputMaybe<Array<CryptoAddressTxnEntry>>;
  devices?: InputMaybe<Array<DeviceTxnEntry>>;
  financialInstitutions?: InputMaybe<Array<FinancialInstitutionTxnEntry>>;
  institutionRelationships?: InputMaybe<Array<InstitutionRelationshipTxnEntry>>;
  paymentCards?: InputMaybe<Array<PaymentCardTxnEntry>>;
  people?: InputMaybe<Array<PersonTxnEntry>>;
  products?: InputMaybe<Array<ProductTxnEntry>>;
  relationshipDefinitions?: InputMaybe<Array<RelationshipDefinitionTxnEntry>>;
  relationships?: InputMaybe<Array<RelationshipTxnEntry>>;
  signatories?: InputMaybe<Array<SignatoryTxnEntry>>;
  tagDefinitions?: InputMaybe<Array<TagDefinitionTxnEntry>>;
  tags?: InputMaybe<Array<TagTxnEntry>>;
};

/** Autogenerated return type of RecordLibraryTransaction. */
export type RecordLibraryTransactionPayload = {
  __typename?: 'RecordLibraryTransactionPayload';
  attachments: Array<LibraryAttachment>;
  bankAccountBalances: Array<LibraryBankAccountBalance>;
  bankAccounts: Array<LibraryBankAccount>;
  businessOwners: Array<LibraryBusinessOwner>;
  businesses: Array<LibraryBusiness>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  cryptoAddresses: Array<LibraryCryptoAddress>;
  devices: Array<LibraryDevice>;
  financialInstitutions: Array<LibraryFinancialInstitution>;
  institutionRelationships: Array<LibraryInstitutionRelationship>;
  paymentCards: Array<LibraryPaymentCard>;
  people: Array<LibraryPerson>;
  products: Array<LibraryProduct>;
  relationshipDefinitions: Array<LibraryRelationshipDefinition>;
  relationships: Array<LibraryRelationship>;
  sequenceNumber: Scalars['Int']['output'];
  signatories: Array<LibrarySignatory>;
  tagDefinitions: Array<LibraryTagDefinition>;
  tags: Array<LibraryTag>;
};

/** Autogenerated input type of RejectAccessRequest */
export type RejectAccessRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rejectedReason: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of RejectAccessRequest. */
export type RejectAccessRequestPayload = {
  __typename?: 'RejectAccessRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Relationship = {
  __typename?: 'Relationship';
  type?: Maybe<InstitutionRelationshipTypeEnum>;
  typeOther?: Maybe<Scalars['String']['output']>;
};

export type RelationshipAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RelationshipDefinitionAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type RelationshipDefinitionTxnEntry = {
  attributes: RelationshipDefinitionAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type RelationshipTxnEntry = {
  attributes?: InputMaybe<RelationshipAttributes>;
  key?: InputMaybe<LibraryObjectRelationKey>;
  origin?: InputMaybe<LibraryObjectRelation>;
  relationshipDefinition?: InputMaybe<LibraryObjectRelation>;
  target?: InputMaybe<LibraryObjectRelation>;
};

/** Autogenerated input type of RemoveAdvisoryFromReview */
export type RemoveAdvisoryFromReviewInput = {
  advisoryToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAdvisoryFromReview. */
export type RemoveAdvisoryFromReviewPayload = {
  __typename?: 'RemoveAdvisoryFromReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Autogenerated input type of RenameAttachment */
export type RenameAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
  investigationToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of RenameAttachment. */
export type RenameAttachmentPayload = {
  __typename?: 'RenameAttachmentPayload';
  attachment: Attachment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

/** Autogenerated input type of RenderNarrativeTemplate */
export type RenderNarrativeTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of RenderNarrativeTemplate. */
export type RenderNarrativeTemplatePayload = {
  __typename?: 'RenderNarrativeTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The rendered narrative */
  renderedText: Scalars['String']['output'];
};

/** An RFI that is either complete or incomplete. */
export type RequestForInformation = CompletedRequestForInformation | IncompleteRequestForInformation;

/** Recipient form for an RFI. */
export type RequestForInformationForm = {
  __typename?: 'RequestForInformationForm';
  headerHtml: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  survey: Survey;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type RequestForInformationRecipientCopy = {
  __typename?: 'RequestForInformationRecipientCopy';
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

/** An RFI summary model that can be used to identify a specific RFI */
export type RequestForInformationSummary = {
  __typename?: 'RequestForInformationSummary';
  externalToken: Scalars['ID']['output'];
  form: RequestForInformationForm;
  recipientEmail?: Maybe<Scalars['String']['output']>;
  response?: Maybe<SurveyResponse>;
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of RequestInstall */
export type RequestInstallInput = {
  appSlug: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestInstall. */
export type RequestInstallPayload = {
  __typename?: 'RequestInstallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ResendAccountConfirmation */
export type ResendAccountConfirmationInput = {
  accountToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResendAccountConfirmation. */
export type ResendAccountConfirmationPayload = {
  __typename?: 'ResendAccountConfirmationPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmationSent: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ResetDemo */
export type ResetDemoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResetDemo. */
export type ResetDemoPayload = {
  __typename?: 'ResetDemoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of ResolveCommentThread */
export type ResolveCommentThreadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  threadToken: Scalars['ID']['input'];
};

/** Autogenerated return type of ResolveCommentThread. */
export type ResolveCommentThreadPayload = {
  __typename?: 'ResolveCommentThreadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  thread: CommentThread;
};

/** A generative AI response */
export type Response = {
  __typename?: 'Response';
  body: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
};

export type Review = {
  __typename?: 'Review';
  actionDecisions: Array<ReviewActionDecision>;
  activityEndedOnFromAlgorithm?: Maybe<Scalars['ISO8601DateTime']['output']>;
  activityStartedOnFromAlgorithm?: Maybe<Scalars['ISO8601DateTime']['output']>;
  activityTags?: Maybe<Array<ActivityTag>>;
  alertCount: Scalars['Int']['output'];
  alertGroup: CaseAlertGroup;
  alertGroups: CaseAlertGroupConnection;
  alertSummary: Scalars['String']['output'];
  alerts: Array<CaseAlert>;
  amountInvolvedFromAlgorithmString?: Maybe<Scalars['String']['output']>;
  approvalAssignments: Array<ApprovalAssignment>;
  approvalStatus?: Maybe<InvestigationReviewApprovalStatusEnum>;
  assignee?: Maybe<Account>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  decisionInformationSummaryCounts: DecisionInformationSummaryCounts;
  displayState: Scalars['String']['output'];
  displayStatus: Scalars['String']['output'];
  documents: Array<ReviewDocument>;
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  filingEvents?: Maybe<Array<FilingEvent>>;
  filingName: Scalars['String']['output'];
  filingStatus?: Maybe<Scalars['String']['output']>;
  filingStatusString?: Maybe<Scalars['String']['output']>;
  filingType?: Maybe<FilingTypeEnum>;
  fincenAdvisories: Array<FincenAdvisory>;
  fincenAdvisorySuggestions: Array<ActivityTagFincenAdvisory>;
  firstActivityAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  inboundRequest?: Maybe<InboundRequest>;
  includedBusinessCountScoped: IncludedAndTotalSubjectCounts;
  includedFinancialInstitutionCountScoped: IncludedAndTotalSubjectCounts;
  includedIndividualCountScoped: IncludedAndTotalSubjectCounts;
  includedProductCountScoped: IncludedAndTotalSubjectCounts;
  includedSecondarySubjectCountScoped: SecondarySubjectCounts;
  internalControlNumber?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
  isCompleted: Scalars['Boolean']['output'];
  jurisdiction: FilingJurisdictionEnum;
  jurisdictionRules: FilingJurisdictionRules;
  narrativeTemplate: NarrativeTemplate;
  narrativeTemplates: Array<NarrativeTemplate>;
  netActivity?: Maybe<HbMoney>;
  openAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  openedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  pendingAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  queue?: Maybe<Queue>;
  recentAlertExternalId?: Maybe<Scalars['String']['output']>;
  requestForInformation?: Maybe<RequestForInformationSummary>;
  reviewType: TypeOfReview;
  sarFilingDecision?: Maybe<ReviewActionDecision>;
  stage?: Maybe<Scalars['String']['output']>;
  state: ReviewStatesEnum;
  status: InvestigationReviewStatusEnum;
  surveyResponseFileUploads: Array<SurveyResponseFileUpload>;
  tags: Array<Scalars['String']['output']>;
  timelineStartedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  token: Scalars['ID']['output'];
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ReviewAlertGroupArgs = {
  token: Scalars['String']['input'];
};


export type ReviewAlertGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReviewIncludedBusinessCountScopedArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ReviewIncludedFinancialInstitutionCountScopedArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ReviewIncludedIndividualCountScopedArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ReviewIncludedProductCountScopedArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type ReviewIncludedSecondarySubjectCountScopedArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  subjectTypes?: InputMaybe<Array<SecondarySubjectTypeEnum>>;
};


export type ReviewNarrativeTemplateArgs = {
  token: Scalars['String']['input'];
};

/** A configuration for auto-assignment of reviews. */
export type ReviewActionDecision = {
  __typename?: 'ReviewActionDecision';
  arbiter: Account;
  choice?: Maybe<ReviewTypeActionChoice>;
  review: Review;
  token: Scalars['ID']['output'];
};

/** The type of the review action */
export enum ReviewActionTypeEnum {
  Decision = 'decision',
  FintracFiling = 'fintrac_filing',
  GoamlFiling = 'goaml_filing',
  UnitedStatesCtrFiling = 'united_states_ctr_filing',
  UnitedStatesFiling = 'united_states_filing'
}

export enum ReviewDataExclusionSubjectTypes {
  LibraryBankAccount = 'LibraryBankAccount',
  LibraryCryptoAddress = 'LibraryCryptoAddress',
  LibraryDevice = 'LibraryDevice',
  LibraryPaymentCard = 'LibraryPaymentCard',
  BankAccount = 'bank_account',
  Business = 'business',
  CryptoAddress = 'crypto_address',
  Device = 'device',
  Individual = 'individual',
  Institution = 'institution',
  PaymentCard = 'payment_card',
  Product = 'product'
}

export type ReviewDigestNotificationParams = {
  accountTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  badgeTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  recipientEmails?: InputMaybe<Array<EmailRecipientParams>>;
  sendToReviewAssignee?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewDocument = {
  __typename?: 'ReviewDocument';
  description?: Maybe<Scalars['String']['output']>;
  format: ReviewDocumentFormat;
  path: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GeneratedDocumentType;
};

export enum ReviewDocumentFormat {
  Csv = 'CSV',
  Json = 'JSON',
  Pdf = 'PDF',
  Xml = 'XML',
  Zip = 'ZIP'
}

/** Updated data */
export type ReviewInput = {
  internalControlNumber?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
};

/** Status and possible results of a review narrative generation job. */
export type ReviewNarrativeGenerationJob = Job & {
  __typename?: 'ReviewNarrativeGenerationJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  generatedNarrative?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** The state of a review */
export enum ReviewStatesEnum {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Created = 'created',
  Opened = 'opened',
  Pending = 'pending',
  ReadyForReview = 'ready_for_review'
}

/** The type of the review task */
export enum ReviewTaskTypeEnum {
  AlertReview = 'alert_review',
  CaseInformation = 'case_information',
  CreateReviewSettings = 'create_review_settings',
  CtrFilingDetails = 'ctr_filing_details',
  CyberEventIndicators = 'cyber_event_indicators',
  DisputeSurvey = 'dispute_survey',
  FilingDetails = 'filing_details',
  FintracFilingDetails = 'fintrac_filing_details',
  FiuFilingReportIdentifier = 'fiu_filing_report_identifier',
  GoamlFilingDetails = 'goaml_filing_details',
  GoamlFilingReportId = 'goaml_filing_report_id',
  InformationSharing = 'information_sharing',
  NetActivity = 'net_activity',
  RequestForInformationDetails = 'request_for_information_details',
  RequestForInformationPrepopulation = 'request_for_information_prepopulation',
  RequestForInformationResponseView = 'request_for_information_response_view',
  Research = 'research',
  Survey = 'survey',
  Tagging = 'tagging',
  TextEntry = 'text_entry',
  TipView = 'tip_view'
}

export type ReviewTypeAction = {
  __typename?: 'ReviewTypeAction';
  actionType: ReviewActionTypeEnum;
  choices: Array<ReviewTypeActionChoice>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  decisionTitle: Scalars['String']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  jurisdiction?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  reportableLabel?: Maybe<Scalars['String']['output']>;
  requireFilingValidationFincen?: Maybe<Scalars['Boolean']['output']>;
  requireFilingValidationFincenCtr?: Maybe<Scalars['Boolean']['output']>;
  requireFilingValidationFintrac?: Maybe<Scalars['Boolean']['output']>;
  requireFilingValidationGnecb?: Maybe<Scalars['Boolean']['output']>;
  requireReviewValidationToComplete?: Maybe<Scalars['Boolean']['output']>;
  requiredBadgeName?: Maybe<Scalars['String']['output']>;
  requiredPermissions?: Maybe<Array<Permission>>;
  slug: Scalars['String']['output'];
  stateMachine: ReviewTypeActionStateMachine;
  taskRequirements: Array<ReviewTypeTaskRequirement>;
  tasks: Array<ReviewTypeTask>;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A configuration for auto-assignment of reviews. */
export type ReviewTypeActionChoice = {
  __typename?: 'ReviewTypeActionChoice';
  accountType?: Maybe<Scalars['String']['output']>;
  arbiterType: Scalars['String']['output'];
  buttonType: ReviewTypeActionChoiceButtonTypeEnum;
  confirmationTitle: Scalars['String']['output'];
  decisionModalButtonTitle: Scalars['String']['output'];
  effects: Array<ReviewTypeActionEffect>;
  fromState: Scalars['String']['output'];
  icon: WorkflowIconEnum;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  pastParticiple: Scalars['String']['output'];
  proceedTo?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  toState: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export enum ReviewTypeActionChoiceButtonTypeEnum {
  Dismiss = 'dismiss',
  Primary = 'primary',
  Secondary = 'secondary'
}

export type ReviewTypeActionEffect = {
  __typename?: 'ReviewTypeActionEffect';
  defaults?: Maybe<ReviewTypeActionEffectDefaults>;
  settingsTask?: Maybe<ReviewTypeTask>;
  token: Scalars['ID']['output'];
  type: ReviewTypeActionEffectTypeEnum;
};

export type ReviewTypeActionEffectDefaults = {
  __typename?: 'ReviewTypeActionEffectDefaults';
  assigneeToken?: Maybe<Scalars['ID']['output']>;
  assignmentStrategy?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  daysToSnooze?: Maybe<Scalars['Int']['output']>;
  decision?: Maybe<InvestigationReviewApprovalDecisionEnum>;
  defaultQueueToken?: Maybe<Scalars['ID']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  dueInDays?: Maybe<Scalars['Int']['output']>;
  filingType?: Maybe<Scalars['String']['output']>;
  minimumPeerReviewers?: Maybe<Scalars['Int']['output']>;
  newReviewTypeCanonicalId?: Maybe<Scalars['ID']['output']>;
  newReviewTypeToken?: Maybe<Scalars['ID']['output']>;
  openInDays?: Maybe<Scalars['Int']['output']>;
  originReviewRelationship?: Maybe<InvestigationReviewRelationshipTypeEnum>;
  queueRequired?: Maybe<Scalars['Boolean']['output']>;
  queueToken?: Maybe<Scalars['ID']['output']>;
  queueTokens?: Maybe<Array<Scalars['ID']['output']>>;
  recipientOrganizationId?: Maybe<Scalars['ID']['output']>;
  recipientReviewTypeCanonicalId?: Maybe<Scalars['ID']['output']>;
  relationship?: Maybe<InvestigationReviewRelationshipTypeEnum>;
  reviewerTokens?: Maybe<Array<Scalars['ID']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timelineStrategy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ReviewTypeActionEffectTypeEnum {
  AssignReview = 'assign_review',
  ChainalysisPostAlertStatusAndComment = 'chainalysis_post_alert_status_and_comment',
  CompleteInformationRequest = 'complete_information_request',
  CopyFilingData = 'copy_filing_data',
  CreateAlertSuppressionRule = 'create_alert_suppression_rule',
  CreateAutomatedReport = 'create_automated_report',
  CreateComment = 'create_comment',
  CreateFakeFilingResponse = 'create_fake_filing_response',
  CreateFiling = 'create_filing',
  CreatePeerApproval = 'create_peer_approval',
  CreatePeerApprovalDecision = 'create_peer_approval_decision',
  CreateReferral = 'create_referral',
  CreateReview = 'create_review',
  EllipticUpdateTransactionAnalysisStatus = 'elliptic_update_transaction_analysis_status',
  FileWithFintrac = 'file_with_fintrac',
  GoAmlRecordManualFiling = 'go_aml_record_manual_filing',
  LockCase = 'lock_case',
  Notification = 'notification',
  SendRequestForInformation = 'send_request_for_information',
  SetDueDate = 'set_due_date',
  SnapshotDocument = 'snapshot_document',
  SnoozeReview = 'snooze_review',
  SubmitAutomatedReport = 'submit_automated_report',
  UnlockCase = 'unlock_case',
  UpdateDisputeInvestigationTimeline = 'update_dispute_investigation_timeline',
  UpdateGoAmlFilingState = 'update_go_aml_filing_state'
}

export type ReviewTypeActionRequirement = {
  __typename?: 'ReviewTypeActionRequirement';
  action?: Maybe<ReviewTypeAction>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  requiredAction?: Maybe<ReviewTypeAction>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ReviewTypeActionStateMachine = {
  __typename?: 'ReviewTypeActionStateMachine';
  name: Scalars['String']['output'];
  states: Array<ReviewTypeActionStateMachineState>;
};

export type ReviewTypeActionStateMachineState = {
  __typename?: 'ReviewTypeActionStateMachineState';
  accept: Scalars['Boolean']['output'];
  initial: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** A configuration for auto-assignment of reviews. */
export type ReviewTypeAssignmentSettings = {
  __typename?: 'ReviewTypeAssignmentSettings';
  badge: Badge;
  canonicalReviewType: CanonicalReview;
  organization: Organization;
  token: Scalars['ID']['output'];
};

export enum ReviewTypeBillingTypeEnum {
  CustomerDiligence = 'CUSTOMER_DILIGENCE',
  CustomInvestigations = 'CUSTOM_INVESTIGATIONS'
}

export type ReviewTypeTask = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ReviewTypeTaskRequirement = {
  __typename?: 'ReviewTypeTaskRequirement';
  choice: ReviewTypeActionChoice;
  createdAt: Scalars['ISO8601DateTime']['output'];
  task: ReviewTypeTask;
  token: Scalars['ID']['output'];
};

export type ReviewTypeTimelineParams = {
  __typename?: 'ReviewTypeTimelineParams';
  timeline?: Maybe<Scalars['Int']['output']>;
};

export enum ReviewTypeTimelineTypeEnum {
  AutoStart = 'auto_start',
  Default = 'default',
  DisputeInvestigation = 'dispute_investigation'
}

/** Autogenerated input type of RevokeAccessRequest */
export type RevokeAccessRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of RevokeAccessRequest. */
export type RevokeAccessRequestPayload = {
  __typename?: 'RevokeAccessRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum RoleInActivity {
  Both = 'BOTH',
  Other = 'OTHER',
  PayeeOrReceiver = 'PAYEE_OR_RECEIVER',
  PurchaserOrSender = 'PURCHASER_OR_SENDER',
  Suspect = 'SUSPECT',
  Victim = 'VICTIM'
}

export enum RoleInTransaction {
  Both = 'BOTH',
  PayingLocation = 'PAYING_LOCATION',
  SellingLocation = 'SELLING_LOCATION'
}

/** Saved search request, which can contain filters, sorts and/or a query/ */
export type SavedSearchRequest = {
  __typename?: 'SavedSearchRequest';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dashboardType?: Maybe<DashboardTypeEnum>;
  filters: Array<FilterOutput>;
  groups: Array<GroupOutput>;
  name: Scalars['String']['output'];
  query?: Maybe<Scalars['String']['output']>;
  sorts?: Maybe<Array<SortOutput>>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for SavedSearchRequest. */
export type SavedSearchRequestConnection = {
  __typename?: 'SavedSearchRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SavedSearchRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SavedSearchRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SavedSearchRequestEdge = {
  __typename?: 'SavedSearchRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SavedSearchRequest>;
};

export type ScheduleAutomationRule = CanonicalAutomationRuleBase & {
  __typename?: 'ScheduleAutomationRule';
  actionParams: AutomationRuleActionParams;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  executionMode: AutomationRuleExecutionMode;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  latestExecution?: Maybe<AutomationRuleExecution>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationToken: Scalars['ID']['output'];
  recentActivity: AutomationRuleRecentActivity;
  ruleExecutions: AutomationRuleExecutionConnection;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  scheduleConfig: Scalars['String']['output'];
  scheduleStartOn: Scalars['ISO8601DateTime']['output'];
  token: Scalars['ID']['output'];
  unsampledCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usageCount: Scalars['Int']['output'];
  usagePercentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};


export type ScheduleAutomationRuleRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRule = CanonicalAutomationRuleBase & TriggerableAutomationRule & {
  __typename?: 'ScheduleWithTriggerAutomationRule';
  actionParams: AutomationRuleActionParams;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  childRuleTriggers?: Maybe<Array<AutomationRuleTrigger>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  executionMode: AutomationRuleExecutionMode;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  latestExecution?: Maybe<AutomationRuleExecution>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationToken: Scalars['ID']['output'];
  pendingRuleExecutions?: Maybe<AutomationRulePendingExecutionConnection>;
  previewModeManualRuns: Array<AutomationRulePreviewRun>;
  previewModePendingRun?: Maybe<AutomationRulePreviewRun>;
  /** The result for a given eventable. */
  primaryResultForEventable?: Maybe<AutomationRuleTriggerResult>;
  primaryRuleTrigger?: Maybe<AutomationRuleTrigger>;
  recentActivity: AutomationRuleRecentActivity;
  ruleExecutions: AutomationRuleExecutionConnection;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  scheduleConfig: Scalars['String']['output'];
  scheduleStartOn: Scalars['ISO8601DateTime']['output'];
  /** The result for a given eventable. */
  secondaryResultsForEventable?: Maybe<Array<AutomationRuleTriggerResult>>;
  successfulTriggerResultsIncludedInNextRun?: Maybe<AutomationRuleTriggerResultConnection>;
  token: Scalars['ID']['output'];
  unsampledCount: Scalars['Int']['output'];
  upcomingScheduledRunSummary: AutomationRuleUpcomingRunSummary;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usageCount: Scalars['Int']['output'];
  usagePercentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};


/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRulePendingRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRulePrimaryResultForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};


/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRuleRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRuleSecondaryResultsForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};


/** A scheduled-with-trigger automation rule. */
export type ScheduleWithTriggerAutomationRuleSuccessfulTriggerResultsIncludedInNextRunArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledRuleTemplate = AutomationRuleTemplateBase & {
  __typename?: 'ScheduledRuleTemplate';
  actionParams?: Maybe<AutomationRuleTemplateActionParams>;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  automationTemplateCategory?: Maybe<AutomationRuleTemplateCategory>;
  automationType?: Maybe<AutomationRuleType>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  humanReadableSchedule?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  scheduleConfig: Scalars['String']['output'];
  setup?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  triggerDescription?: Maybe<Scalars['String']['output']>;
  triggerName?: Maybe<Scalars['String']['output']>;
};

export type ScheduledWithTriggerRuleTemplate = AutomationRuleTemplateBase & {
  __typename?: 'ScheduledWithTriggerRuleTemplate';
  actionParams?: Maybe<AutomationRuleTemplateActionParams>;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  automationTemplateCategory?: Maybe<AutomationRuleTemplateCategory>;
  automationTriggerTemplate?: Maybe<AutomationRuleTriggerTemplate>;
  automationType?: Maybe<AutomationRuleType>;
  description?: Maybe<Scalars['String']['output']>;
  domainType?: Maybe<AutomationDomainType>;
  enabled: Scalars['Boolean']['output'];
  humanReadableSchedule?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  scheduleConfig: Scalars['String']['output'];
  setup?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  triggerDescription?: Maybe<Scalars['String']['output']>;
  triggerName?: Maybe<Scalars['String']['output']>;
};

/** Status and possible results of an asynchronous screening search. */
export type ScreeningSearchJob = Job & {
  __typename?: 'ScreeningSearchJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

export enum ScreeningSearchProvider {
  Aleph = 'aleph',
  Brokercheck = 'brokercheck',
  Castellum = 'castellum',
  Minerva = 'minerva',
  OpenSanctions = 'open_sanctions'
}

/** Parameters for a screening search. */
export type ScreeningSearchQueryParameters = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  administrativeDistrictLevel1?: InputMaybe<Scalars['String']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  minScore?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

/** AutomationRuleExecutions search results */
export type SearchAutomationRuleExecutionsResult = {
  __typename?: 'SearchAutomationRuleExecutionsResult';
  entries: Array<AutomationRuleExecution>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Filings search results */
export type SearchFilingsResult = {
  __typename?: 'SearchFilingsResult';
  entries: Array<Filing>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SearchHit = {
  __typename?: 'SearchHit';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  resultEntries?: Maybe<Scalars['JSON']['output']>;
  results: Scalars['JSON']['output'];
  tableValues: Scalars['JSON']['output'];
};

/** The connection type for SearchHit. */
export type SearchHitConnection = {
  __typename?: 'SearchHitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchHitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchHit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SearchHitEdge = {
  __typename?: 'SearchHitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchHit>;
};

/** Information Requests search results */
export type SearchInformationRequestsResult = {
  __typename?: 'SearchInformationRequestsResult';
  entries: Array<InformationRequest>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SearchMetadataResult = {
  __typename?: 'SearchMetadataResult';
  columns: Array<FilterableColumn>;
  filterOptions: Array<FilterOptionV2>;
  staticColumns: Array<FilterableColumn>;
  token: Scalars['ID']['output'];
};

export type SearchProfilesResult = {
  __typename?: 'SearchProfilesResult';
  entries: Array<LibraryEntry>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Normalized query input for review search */
export type SearchRequestInput = {
  /** Filter predicates */
  filters?: InputMaybe<Array<FilterInput>>;
  /** Group of filters */
  groups?: InputMaybe<Array<GroupInput>>;
  /** Query to filter upon */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Sorting information */
  sorts?: InputMaybe<Array<SortValueInput>>;
};

export type SearchReviewsResultV2 = {
  __typename?: 'SearchReviewsResultV2';
  entries: Array<Review>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SearchTransactionsResult = {
  __typename?: 'SearchTransactionsResult';
  entries: Array<Transaction>;
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** A bucket containing stats, and possibly more buckets containing more stats */
export type SearchTransactionsStatsBucket = {
  __typename?: 'SearchTransactionsStatsBucket';
  buckets?: Maybe<Array<SearchTransactionsStatsBucket>>;
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type SearchTransactionsStatsResult = {
  __typename?: 'SearchTransactionsStatsResult';
  byDate: Array<SearchTransactionsStatsBucket>;
  byDirection: Array<SearchTransactionsStatsBucket>;
  currency?: Maybe<Scalars['String']['output']>;
  isoCurrency?: Maybe<Scalars['Boolean']['output']>;
  sum?: Maybe<Scalars['JSON']['output']>;
};

export type SecondarySubjectCounts = {
  __typename?: 'SecondarySubjectCounts';
  bankAccounts?: Maybe<IncludedAndTotalSubjectCounts>;
  cryptoAddresses?: Maybe<IncludedAndTotalSubjectCounts>;
  devices?: Maybe<IncludedAndTotalSubjectCounts>;
  paymentCards?: Maybe<IncludedAndTotalSubjectCounts>;
};

export enum SecondarySubjectTypeEnum {
  BankAccounts = 'bank_accounts',
  CryptoAddresses = 'crypto_addresses',
  Devices = 'devices',
  PaymentCards = 'payment_cards'
}

export enum SectionTypeEnum {
  Businesses = 'businesses',
  Institutions = 'institutions',
  Other = 'other',
  People = 'people',
  Products = 'products'
}

/** A Shrine::UploadedFile. */
export type ShrineUploadedFile = {
  __typename?: 'ShrineUploadedFile';
  /** The location where the file was uploaded to the storage. */
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  /** Size in bytes. */
  size?: Maybe<Scalars['Int']['output']>;
  /** A URL to access this file. Typically a pre-signed S3 url with short expiry. */
  url: Scalars['String']['output'];
};

/** Configuration used for Sigma integration. */
export type SigmaConfiguration = {
  __typename?: 'SigmaConfiguration';
  apiKey: Scalars['ID']['output'];
  indicators: Array<Scalars['String']['output']>;
  monitored: Scalars['Boolean']['output'];
  monitoringFiltersetId?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SignatoryAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<BankAccountSignatoryRoleEnum>;
};

export type SignatoryTxnEntry = {
  attributes: SignatoryAttributes;
  bankAccount?: InputMaybe<LibraryObjectRelation>;
  key?: InputMaybe<LibraryObjectRelationKey>;
  person?: InputMaybe<LibraryObjectRelation>;
};

/** Autogenerated input type of SnoozeCaseReview */
export type SnoozeCaseReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  snoozeUntil: Scalars['ISO8601Date']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of SnoozeCaseReview. */
export type SnoozeCaseReviewPayload = {
  __typename?: 'SnoozeCaseReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

export type SnoozeReviewParams = {
  description?: InputMaybe<Scalars['String']['input']>;
  snoozeTimingPolicy?: InputMaybe<SnoozeTimingPolicyEnum>;
  snoozeUntilDateRelativeIncrementUnit?: InputMaybe<AutomationDateUnitEnum>;
  snoozeUntilDateRelativeIncrementValue?: InputMaybe<Scalars['Int']['input']>;
};

export enum SnoozeTimingPolicyEnum {
  KeepExisting = 'keep_existing',
  Reset = 'reset'
}

export enum SortEnum {
  /** Sort ascending */
  Asc = 'asc',
  /** Sort descending */
  Desc = 'desc'
}

/** Sort direction */
export type SortOutput = {
  __typename?: 'SortOutput';
  sortDir: SortEnum;
  sortField: Scalars['String']['output'];
};

/** Specific sort value for a column */
export type SortValueInput = {
  sortDir: SortEnum;
  sortField: Scalars['String']['input'];
};

/** Autogenerated input type of SubmitRequestForInformation */
export type SubmitRequestForInformationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entries: Array<SurveyResponseEntryInput>;
  externalToken: Scalars['String']['input'];
};

/** Autogenerated return type of SubmitRequestForInformation. */
export type SubmitRequestForInformationPayload = {
  __typename?: 'SubmitRequestForInformationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmationCode?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  responseErrors?: Maybe<Array<SurveyResponseEntryError>>;
};

/** Autogenerated input type of SubmitTipIntake */
export type SubmitTipIntakeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entries: Array<SurveyResponseEntryInput>;
  externalToken: Scalars['String']['input'];
};

/** Autogenerated return type of SubmitTipIntake. */
export type SubmitTipIntakePayload = {
  __typename?: 'SubmitTipIntakePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmationCode?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  responseErrors?: Maybe<Array<SurveyResponseEntryError>>;
  tipToken?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of SummarizeAttachment */
export type SummarizeAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['ID']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of SummarizeAttachment. */
export type SummarizeAttachmentPayload = {
  __typename?: 'SummarizeAttachmentPayload';
  attachment: Attachment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

export type SupersetEmbeddedAnalyticsDashboard = {
  __typename?: 'SupersetEmbeddedAnalyticsDashboard';
  dashboardId: Scalars['String']['output'];
  guestToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  supersetDomain: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** A Survey that can contain sections and questions */
export type Survey = {
  __typename?: 'Survey';
  createdAt: Scalars['ISO8601DateTime']['output'];
  sections: Array<SurveySection>;
  tag: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Survey File Upload Configuration */
export type SurveyFileUploadConfiguration = {
  __typename?: 'SurveyFileUploadConfiguration';
  allowedMimeTypes?: Maybe<Array<Scalars['String']['output']>>;
  questionType?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['ID']['output']>;
};

/** A Survey Question */
export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  fileUploadConfiguration?: Maybe<SurveyFileUploadConfiguration>;
  isDependentQuestion: Scalars['Boolean']['output'];
  multiline?: Maybe<Scalars['Boolean']['output']>;
  multivalued?: Maybe<Scalars['Boolean']['output']>;
  numberProps?: Maybe<SurveyQuestionNumberProps>;
  options?: Maybe<Array<SurveyQuestionOption>>;
  parentQuestion?: Maybe<SurveyQuestion>;
  parentSelectOption?: Maybe<SurveyQuestionSelectOption>;
  reportAs?: Maybe<SurveyQuestionReportAs>;
  required?: Maybe<Scalars['Boolean']['output']>;
  style?: Maybe<SurveyQuestionStyleEnum>;
  tag?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  type: SurveyQuestionTypeEnum;
};

/** Survey Number Question props */
export type SurveyQuestionNumberProps = {
  __typename?: 'SurveyQuestionNumberProps';
  allowLeadingZeros?: Maybe<Scalars['Boolean']['output']>;
  allowNegative?: Maybe<Scalars['Boolean']['output']>;
  decimalScale?: Maybe<Scalars['Int']['output']>;
  decimalSeparator?: Maybe<Scalars['String']['output']>;
  fixedDecimalScale?: Maybe<Scalars['Boolean']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  thousandSeparator?: Maybe<Scalars['String']['output']>;
  thousandsGroupStyle?: Maybe<Scalars['String']['output']>;
};

/** A Survey Question option */
export type SurveyQuestionOption = {
  __typename?: 'SurveyQuestionOption';
  label: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Survey question's report as obj */
export type SurveyQuestionReportAs = {
  __typename?: 'SurveyQuestionReportAs';
  label?: Maybe<Scalars['String']['output']>;
};

/** Survey question */
export type SurveyQuestionSelectOption = {
  __typename?: 'SurveyQuestionSelectOption';
  label: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export enum SurveyQuestionStyleEnum {
  Default = 'default',
  Flat = 'flat'
}

export enum SurveyQuestionTypeEnum {
  Boolean = 'boolean',
  Checkbox = 'checkbox',
  Date = 'date',
  Datetime = 'datetime',
  FileUpload = 'file_upload',
  Number = 'number',
  Select = 'select',
  Text = 'text'
}

/** A Survey response that can contain response entries */
export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  createdAt: Scalars['ISO8601DateTime']['output'];
  entries?: Maybe<Array<SurveyResponseEntry>>;
  id: Scalars['ID']['output'];
  surveyTag: Scalars['String']['output'];
  tag: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Entry to a single question for a survey */
export type SurveyResponseEntry = SurveyResponseEntryArray | SurveyResponseEntryBoolean | SurveyResponseEntryString;

/** A Survey Response entry that is an array value */
export type SurveyResponseEntryArray = {
  __typename?: 'SurveyResponseEntryArray';
  token: Scalars['ID']['output'];
  value: Array<Scalars['String']['output']>;
};

/** A Survey Response entry that is a boolean value */
export type SurveyResponseEntryBoolean = {
  __typename?: 'SurveyResponseEntryBoolean';
  token: Scalars['ID']['output'];
  value: Scalars['Boolean']['output'];
};

/** Error for a single question for a survey */
export type SurveyResponseEntryError = {
  __typename?: 'SurveyResponseEntryError';
  error: Scalars['String']['output'];
  metadata?: Maybe<SurveyResponseEntryErrorMetadata>;
  token: Scalars['ID']['output'];
};

/** Metadata on an error for a single question for a survey */
export type SurveyResponseEntryErrorMetadata = {
  __typename?: 'SurveyResponseEntryErrorMetadata';
  validMimeTypes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Survey Response entry input with single or multiple values */
export type SurveyResponseEntryInput = {
  token: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A Survey Response entry that is an string value */
export type SurveyResponseEntryString = {
  __typename?: 'SurveyResponseEntryString';
  token: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Survey response file upload */
export type SurveyResponseFileUpload = {
  __typename?: 'SurveyResponseFileUpload';
  clientId: Scalars['ID']['output'];
  contentType: Scalars['String']['output'];
  question: SurveyQuestion;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

/** Survey section */
export type SurveySection = {
  __typename?: 'SurveySection';
  questions: Array<SurveyQuestion>;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type SwiftCodeMetadata = {
  __typename?: 'SwiftCodeMetadata';
  active: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type SwiftCodeMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export enum TrcPermissiblePurposeDppaEnum {
  /** No permitted use. */
  Code_0 = 'CODE_0',
  /** For official use by a Court, Law Enforcement Agency or other Government agency. */
  Code_1 = 'CODE_1',
  /**
   * To verify or correct information provided to you by a person in order to
   * prevent fraud, pursue legal remedies or recover a debt; skip tracing.
   */
  Code_3 = 'CODE_3',
  /** For use in connection with a civil, criminal or arbitral legal proceeding or legal research. */
  Code_4 = 'CODE_4',
  /** For use in connection with an insurance claims investigation or insurance antifraud activities. */
  Code_6 = 'CODE_6'
}

export enum TrcPermissiblePurposeGlbEnum {
  /** For use in complying with federal, state, or local laws, rules, and other applicable legal requirements. */
  CodeA = 'CODE_A',
  /** For use to protect against or prevent actual or potential fraud, unauthorized transactions, claims or other liability. */
  CodeB = 'CODE_B',
  /** For use by a person holding a legal or beneficial interest relating to the consumer. */
  CodeC = 'CODE_C',
  /** To persons acting in a fiduciary or representative capacity on behalf of the consumer. */
  CodeH = 'CODE_H',
  /** For use as necessary to effect, administer or enforce a transaction that a consumer requests or authorizes. */
  CodeI = 'CODE_I',
  /**
   * For use in complying with a properly authorized civil, criminal or regulatory
   * investigation, or subpoena or summons by Federal, State or local authority.
   */
  CodeJ = 'CODE_J',
  /** For required institutional risk control or for resolving consumer disputes or inquiries. */
  CodeK = 'CODE_K',
  /**
   * For use by a Law Enforcement Agency, self-regulatory organizations or for an
   * investigation on a matter related to public safety.
   */
  CodeL = 'CODE_L',
  /** With the consent or at the direction of the consumer. */
  CodeQ = 'CODE_Q'
}

export enum TrcPermissiblePurposeVoterEnum {
  /** Use in connection with a permissible election-related purpose. */
  Code_2 = 'CODE_2',
  /** Use in connection with a non-commercial purpose. */
  Code_5 = 'CODE_5',
  /** No permitted use. */
  Code_7 = 'CODE_7'
}

export type TableHeader = {
  __typename?: 'TableHeader';
  field: Scalars['String']['output'];
  header: Scalars['String']['output'];
};

/** Tags an object with a LibraryTagDefinition */
export type Tag = {
  __typename?: 'Tag';
  tagDefinition: LibraryTagDefinition;
  token: Scalars['ID']['output'];
};

export type TagAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TagDefinitionAllowedTypeEnum {
  Attachments = 'attachments',
  Investigations = 'investigations',
  Reviews = 'reviews'
}

export type TagDefinitionAttributes = {
  allowedTypes?: InputMaybe<Array<TagDefinitionAllowedTypeEnum>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type TagDefinitionTxnEntry = {
  attributes: TagDefinitionAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
};

export type TagParams = {
  tagDefinitionToken?: InputMaybe<Scalars['ID']['input']>;
};

export type TagTxnEntry = {
  attributes: TagAttributes;
  key?: InputMaybe<LibraryObjectRelationKey>;
  tagDefinition: LibraryObjectRelation;
  taggedObject: LibraryObjectRelation;
};

export type TaggedObject = Investigation | Review;

export type TaskTypeAlertReview = ReviewTypeTask & {
  __typename?: 'TaskTypeAlertReview';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeAlertReviewParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeAlertReviewParams = {
  __typename?: 'TaskTypeAlertReviewParams';
  editable: Scalars['Boolean']['output'];
};

export type TaskTypeCtrFilingDetails = ReviewTypeTask & {
  __typename?: 'TaskTypeCTRFilingDetails';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeCtrFilingDetailsParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeCtrFilingDetailsParams = {
  __typename?: 'TaskTypeCTRFilingDetailsParams';
  sdtm: Scalars['Boolean']['output'];
};

export type TaskTypeCaseInformation = ReviewTypeTask & {
  __typename?: 'TaskTypeCaseInformation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeCaseInformationParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeCaseInformationParams = {
  __typename?: 'TaskTypeCaseInformationParams';
  editable: Scalars['Boolean']['output'];
  onlyTypes: Array<Scalars['String']['output']>;
  /** Display case errors as warnings in a review */
  treatValidationErrorsAsWarnings: Scalars['Boolean']['output'];
};

export type TaskTypeCreateReviewSettings = ReviewTypeTask & {
  __typename?: 'TaskTypeCreateReviewSettings';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeCyberEventIndicators = ReviewTypeTask & {
  __typename?: 'TaskTypeCyberEventIndicators';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeDisputeSurvey = ReviewTypeTask & {
  __typename?: 'TaskTypeDisputeSurvey';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  survey?: Maybe<Survey>;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeFintracFilingDetails = ReviewTypeTask & {
  __typename?: 'TaskTypeFINTRACFilingDetails';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeFintracFilingDetailsParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeFintracFilingDetailsParams = {
  __typename?: 'TaskTypeFINTRACFilingDetailsParams';
  iranMinisterialDirective: Scalars['String']['output'];
  politicallyExposedPerson: Scalars['String']['output'];
  /** Public private partnership project names */
  publicPrivatePartnerships: Array<FintracPppEnum>;
  suspicionType?: Maybe<FintracSuspicionTypeEnum>;
};

export type TaskTypeFilingDetails = ReviewTypeTask & {
  __typename?: 'TaskTypeFilingDetails';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeFilingDetailsParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeFilingDetailsParams = {
  __typename?: 'TaskTypeFilingDetailsParams';
  batchFilingEnabled: Scalars['Boolean']['output'];
  fiu: Scalars['String']['output'];
  sdtm: Scalars['Boolean']['output'];
};

export type TaskTypeFiuFilingReportIdentifier = ReviewTypeTask & {
  __typename?: 'TaskTypeFiuFilingReportIdentifier';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeGoAmlFilingDetails = ReviewTypeTask & {
  __typename?: 'TaskTypeGoAMLFilingDetails';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeGoAmlFilingReportId = ReviewTypeTask & {
  __typename?: 'TaskTypeGoAMLFilingReportId';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeInformationSharing = ReviewTypeTask & {
  __typename?: 'TaskTypeInformationSharing';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeNetActivity = ReviewTypeTask & {
  __typename?: 'TaskTypeNetActivity';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeRequestForInformationDetails = ReviewTypeTask & {
  __typename?: 'TaskTypeRequestForInformationDetails';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeRequestForInformationDetailsParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeRequestForInformationDetailsParams = {
  __typename?: 'TaskTypeRequestForInformationDetailsParams';
  enableSubscribers: Scalars['Boolean']['output'];
  requireDueAt: Scalars['Boolean']['output'];
};

export type TaskTypeRequestForInformationPrepopulation = ReviewTypeTask & {
  __typename?: 'TaskTypeRequestForInformationPrepopulation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  survey?: Maybe<Survey>;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeRequestForInformationResponseView = ReviewTypeTask & {
  __typename?: 'TaskTypeRequestForInformationResponseView';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeResearch = ReviewTypeTask & {
  __typename?: 'TaskTypeResearch';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeResearchParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeResearchParams = {
  __typename?: 'TaskTypeResearchParams';
  instructions: Scalars['String']['output'];
  required: Scalars['String']['output'];
  sources: Array<Scalars['String']['output']>;
};

export type TaskTypeSurvey = ReviewTypeTask & {
  __typename?: 'TaskTypeSurvey';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  survey?: Maybe<Survey>;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeTagging = ReviewTypeTask & {
  __typename?: 'TaskTypeTagging';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeTaggingParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeTaggingParams = {
  __typename?: 'TaskTypeTaggingParams';
  categories: Array<Scalars['String']['output']>;
  excludeGroups: Array<Scalars['String']['output']>;
  groups: Array<Scalars['String']['output']>;
  includeOnly: Array<Scalars['String']['output']>;
};

export type TaskTypeTextEntry = ReviewTypeTask & {
  __typename?: 'TaskTypeTextEntry';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  params: TaskTypeTextEntryParams;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TaskTypeTextEntryParams = {
  __typename?: 'TaskTypeTextEntryParams';
  helpText: Array<Scalars['String']['output']>;
  sarNarrative: Scalars['Boolean']['output'];
  skipValidateText: Scalars['Boolean']['output'];
  templates: Scalars['Boolean']['output'];
  validateForFiling: Scalars['Boolean']['output'];
};

export type TaskTypeTipView = ReviewTypeTask & {
  __typename?: 'TaskTypeTipView';
  createdAt: Scalars['ISO8601DateTime']['output'];
  headline: Scalars['String']['output'];
  headlineSubtext?: Maybe<Scalars['String']['output']>;
  icon: WorkflowIconEnum;
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Types of combinators that can be used in GroupInput */
export enum TermsTypeEnum {
  /** A field which allows for filtering based on true or false */
  Boolean = 'boolean',
  /** A date field, which allows for range filtering */
  Date = 'date',
  /** A number field which allows for comparison filtering */
  Number = 'number',
  /** A special field which allows for filtering based on a relationship */
  Relation = 'relation',
  /** A string field, which allows for simple filtering */
  String = 'string'
}

/** Status and possible result of a Thomson Reuters CLEAR Business Report request. */
export type ThomsonReutersBusinessReportJob = Job & {
  __typename?: 'ThomsonReutersBusinessReportJob';
  attachment?: Maybe<Attachment>;
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** Status and possible result of a Thomson Reuters CLEAR Business Search request. */
export type ThomsonReutersBusinessSearchJob = Job & {
  __typename?: 'ThomsonReutersBusinessSearchJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ApiSearchV2BusinessResultsPage>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** Credentials used for Thomson Reuters CLEAR integration */
export type ThomsonReutersClearCredential = {
  __typename?: 'ThomsonReutersClearCredential';
  permissiblePurposeDppa: TrcPermissiblePurposeDppaEnum;
  permissiblePurposeGlb: TrcPermissiblePurposeGlbEnum;
  permissiblePurposeVoter: TrcPermissiblePurposeVoterEnum;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Status and possible result of a Thomson Reuters CLEAR Person Report request. */
export type ThomsonReutersPersonReportJob = Job & {
  __typename?: 'ThomsonReutersPersonReportJob';
  attachment?: Maybe<Attachment>;
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** Status and possible result of a Thomson Reuters CLEAR Person Search request. */
export type ThomsonReutersPersonSearchJob = Job & {
  __typename?: 'ThomsonReutersPersonSearchJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ApiSearchV2PersonResultsPageV3>;
  /** The current state of the async request. */
  status: JobStatus;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/**
 * A time range [beginning, end). Inclusive of begin and exclusive of end. Either
 * begin or end can be omitted to make the range open-ended.
 */
export type TimeRange = {
  begin?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  end?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type TimeWithParts = {
  __typename?: 'TimeWithParts';
  date: Scalars['String']['output'];
  localize: Scalars['Boolean']['output'];
  string: Scalars['String']['output'];
  time?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

/** A time with parts. Provide either date, date & time of day, or a timestamp. */
export type TimeWithPartsInput = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  time?: InputMaybe<Scalars['TimeOfDay']['input']>;
  timestamp?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  actor?: Maybe<Account>;
  content?: Maybe<Scalars['String']['output']>;
  icon: TimelineEventIconEnum;
  review?: Maybe<Review>;
  timestamp: Scalars['ISO8601DateTime']['output'];
  title: Scalars['String']['output'];
};

/** The connection type for TimelineEvent. */
export type TimelineEventConnection = {
  __typename?: 'TimelineEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TimelineEventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TimelineEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TimelineEventEdge = {
  __typename?: 'TimelineEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TimelineEvent>;
};

export enum TimelineEventIconEnum {
  /** Timeline Event icon: #<AuditLog::Icon::AssignApproval> */
  AssignApproval = 'assign_approval',
  /** Timeline Event icon: #<AuditLog::Icon::AssignedReview> */
  AssignedReview = 'assigned_review',
  /** Timeline Event icon: #<AuditLog::Icon::Author> */
  Author = 'author',
  /** Timeline Event icon: #<AuditLog::Icon::CancelRequest> */
  CancelRequest = 'cancel_request',
  /** Timeline Event icon: #<AuditLog::Icon::Cancelled> */
  Cancelled = 'cancelled',
  /** Timeline Event icon: #<AuditLog::Icon::Completed> */
  Completed = 'completed',
  /** Timeline Event icon: #<AuditLog::Icon::Creation> */
  Creation = 'creation',
  /** Timeline Event icon: #<AuditLog::Icon::Information> */
  Information = 'information',
  /** Timeline Event icon: #<AuditLog::Icon::Lock> */
  Lock = 'lock',
  /** Timeline Event icon: #<AuditLog::Icon::MadeDecision> */
  MadeDecision = 'made_decision',
  /** Timeline Event icon: #<AuditLog::Icon::MergeData> */
  MergeData = 'merge_data',
  /** Timeline Event icon: #<AuditLog::Icon::MessageSent> */
  MessageSent = 'message_sent',
  /** Timeline Event icon: #<AuditLog::Icon::Middesk> */
  Middesk = 'middesk',
  /** Timeline Event icon: #<AuditLog::Icon::OpenNow> */
  OpenNow = 'open_now',
  /** Timeline Event icon: #<AuditLog::Icon::Quote> */
  Quote = 'quote',
  /** Timeline Event icon: #<AuditLog::Icon::ReassignedReview> */
  ReassignedReview = 'reassigned_review',
  /** Timeline Event icon: #<AuditLog::Icon::RemoveInformation> */
  RemoveInformation = 'remove_information',
  /** Timeline Event icon: #<AuditLog::Icon::RemoveTag> */
  RemoveTag = 'remove_tag',
  /** Timeline Event icon: #<AuditLog::Icon::RequestApproval> */
  RequestApproval = 'request_approval',
  /** Timeline Event icon: #<AuditLog::Icon::Send> */
  Send = 'send',
  /** Timeline Event icon: #<AuditLog::Icon::SendAccepted> */
  SendAccepted = 'send_accepted',
  /** Timeline Event icon: #<AuditLog::Icon::SendRejected> */
  SendRejected = 'send_rejected',
  /** Timeline Event icon: #<AuditLog::Icon::SendWarning> */
  SendWarning = 'send_warning',
  /** Timeline Event icon: #<AuditLog::Icon::Snoozed> */
  Snoozed = 'snoozed',
  /** Timeline Event icon: #<AuditLog::Icon::StartReview> */
  StartReview = 'start_review',
  /** Timeline Event icon: #<AuditLog::Icon::Tag> */
  Tag = 'tag',
  /** Timeline Event icon: #<AuditLog::Icon::TextEntry> */
  TextEntry = 'text_entry',
  /** Timeline Event icon: #<AuditLog::Icon::Timeline> */
  Timeline = 'timeline',
  /** Timeline Event icon: #<AuditLog::Icon::TrClearPersonSearchPerformed> */
  TrClear = 'tr_clear',
  /** Timeline Event icon: #<AuditLog::Icon::Transactions> */
  Transactions = 'transactions',
  /** Timeline Event icon: #<AuditLog::Icon::UnassignedReview> */
  UnassignedReview = 'unassigned_review',
  /** Timeline Event icon: #<AuditLog::Icon::Uncompleted> */
  Uncompleted = 'uncompleted',
  /** Timeline Event icon: #<AuditLog::Icon::Unlock> */
  Unlock = 'unlock'
}

export type Tin = {
  __typename?: 'Tin';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TinTypeEnum>;
};

export type TinAttributes = {
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TinTypeEnum>;
};

export type TinMetadata = {
  __typename?: 'TinMetadata';
  active: Scalars['Boolean']['output'];
  value: Tin;
};

export enum TinTypeEnum {
  Ein = 'EIN',
  Foreign = 'FOREIGN',
  Ssn = 'SSN'
}

export type TinsMetadataAttributes = {
  active: Scalars['Boolean']['input'];
  value: TinAttributes;
};

/** A model encapsulating configuration details for generating Tips. */
export type TipIntakeConfiguration = {
  __typename?: 'TipIntakeConfiguration';
  canonicalReviewType: CanonicalReview;
  customUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  externalToken: Scalars['ID']['output'];
  organization: Organization;
  survey: Survey;
  title: Scalars['String']['output'];
  token: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type TipSubmissionCopy = {
  __typename?: 'TipSubmissionCopy';
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ToggleRequestReminders */
export type ToggleRequestRemindersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  disableReminders: Scalars['Boolean']['input'];
  informationRequestToken: Scalars['ID']['input'];
};

/** Autogenerated return type of ToggleRequestReminders. */
export type ToggleRequestRemindersPayload = {
  __typename?: 'ToggleRequestRemindersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  informationRequest?: Maybe<InformationRequest>;
};

/** A transaction */
export type Transaction = {
  __typename?: 'Transaction';
  alertExternalIds: Array<Scalars['String']['output']>;
  alertRules: Array<Scalars['String']['output']>;
  amount: HbMoney;
  amountLocal: HbMoney;
  completedAt?: Maybe<TimeWithParts>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currencyIssuingCountryCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  direction: TransactionDirection;
  endpoints: Array<TransactionEndpoint>;
  events: Array<TransactionEvent>;
  externalId?: Maybe<Scalars['String']['output']>;
  fincenCtrTransactionType?: Maybe<Scalars['String']['output']>;
  fincenCtrTransactionTypeOther?: Maybe<Scalars['String']['output']>;
  flaggedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  import?: Maybe<CaseImport>;
  initiatedAt?: Maybe<TimeWithParts>;
  instrumentType: TransactionInstrument;
  instrumentTypeDescription?: Maybe<Scalars['String']['output']>;
  iso8583MessageType?: Maybe<Scalars['String']['output']>;
  iso8583MessageTypeDescription?: Maybe<Scalars['String']['output']>;
  iso18245MerchantCategoryCode?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  otherInfo: Array<OtherInfoEntry>;
  responseCode?: Maybe<Scalars['String']['output']>;
  reviewInternalControlNumbers: Array<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  timestamp: TimeWithParts;
  token: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Transaction Direction */
export enum TransactionDirection {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Transfer = 'TRANSFER'
}

export type TransactionEndpoint = {
  __typename?: 'TransactionEndpoint';
  account?: Maybe<LibraryAccount>;
  accountToken?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Address>;
  amount?: Maybe<HbMoney>;
  description?: Maybe<Scalars['String']['output']>;
  endpointType: TransactionEndpointTypeEnum;
  entity?: Maybe<LibraryEntity>;
  /** @deprecated Use `entity` field instead */
  entityName?: Maybe<Scalars['String']['output']>;
  entityToken?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Confusing mix of things */
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<PhoneNumber>;
  /** @deprecated Use `entity` and `account` fields instead */
  summary?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
};

export enum TransactionEndpointTypeEnum {
  Destination = 'DESTINATION',
  Source = 'SOURCE'
}

/** A transaction event */
export type TransactionEvent = {
  __typename?: 'TransactionEvent';
  description: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  occurredAt: TimeWithParts;
  otherInfo: Scalars['JSON']['output'];
  token: Scalars['ID']['output'];
};

/** A transaction event */
export type TransactionEventInput = {
  description: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  occurredAt?: InputMaybe<TimeWithPartsInput>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
};

/** A transaction */
export type TransactionInput = {
  amount: HbMoneyInput;
  amountLocal: HbMoneyInput;
  completedAt?: InputMaybe<TimeWithPartsInput>;
  currencyIssuingCountryCode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  direction: TransactionDirection;
  events?: InputMaybe<Array<TransactionEventInput>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fincenCtrTransactionType?: InputMaybe<Scalars['String']['input']>;
  fincenCtrTransactionTypeOther?: InputMaybe<Scalars['String']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  initiatedAt?: InputMaybe<TimeWithPartsInput>;
  instrumentType: TransactionInstrument;
  instrumentTypeDetail?: InputMaybe<Scalars['String']['input']>;
  iso8583MessageType?: InputMaybe<Scalars['String']['input']>;
  iso18245MerchantCategoryCode?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<TransactionLocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  otherInfo?: InputMaybe<Array<OtherInfoEntryInput>>;
  responseCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  token?: InputMaybe<Scalars['ID']['input']>;
};

/** Transaction Instrument Types */
export enum TransactionInstrument {
  Ach = 'ACH',
  Atm = 'ATM',
  Cash = 'CASH',
  Check = 'CHECK',
  CreditCard = 'CREDIT_CARD',
  Crypto = 'CRYPTO',
  Currency = 'CURRENCY',
  DebitCard = 'DEBIT_CARD',
  Dividend = 'DIVIDEND',
  ElectronicTransfer = 'ELECTRONIC_TRANSFER',
  Loan = 'LOAN',
  Mixed = 'MIXED',
  MoneyOrder = 'MONEY_ORDER',
  Mortgage = 'MORTGAGE',
  Online = 'ONLINE',
  Other = 'OTHER',
  Policy = 'POLICY',
  Purchase = 'PURCHASE',
  ShareTransfer = 'SHARE_TRANSFER',
  SmartCard = 'SMART_CARD',
  Unknown = 'UNKNOWN',
  Wire = 'WIRE'
}

/** A transaction location */
export type TransactionLocationInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  administrativeDistrictLevel1?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sublocality?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionStatistics = {
  __typename?: 'TransactionStatistics';
  countsByBucket: Array<Scalars['Int']['output']>;
  earliestTransactionDate?: Maybe<Scalars['ISO8601Date']['output']>;
  latestTransactionDate?: Maybe<Scalars['ISO8601Date']['output']>;
  netActivity?: Maybe<HbMoney>;
  sumFlaggedTransactions?: Maybe<HbMoney>;
  totalCount: Scalars['Int']['output'];
};

export type TransientUploadAuthorization = {
  __typename?: 'TransientUploadAuthorization';
  expireTime: Scalars['ISO8601DateTime']['output'];
  maxContentLength: Scalars['Int']['output'];
  presignedPostFields: Scalars['JSON']['output'];
  presignedPostUrl: Scalars['String']['output'];
  uploadCategory: Scalars['String']['output'];
  uploadToken: Scalars['String']['output'];
};

/** A triggered automation rule. */
export type TriggerAutomationRule = CanonicalAutomationRuleBase & TriggerableAutomationRule & {
  __typename?: 'TriggerAutomationRule';
  actionParams: AutomationRuleActionParams;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  childRuleTriggers?: Maybe<Array<AutomationRuleTrigger>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  executionMode: AutomationRuleExecutionMode;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  latestExecution?: Maybe<AutomationRuleExecution>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationToken: Scalars['ID']['output'];
  pendingRuleExecutions?: Maybe<AutomationRulePendingExecutionConnection>;
  previewModeManualRuns: Array<AutomationRulePreviewRun>;
  previewModePendingRun?: Maybe<AutomationRulePreviewRun>;
  /** The result for a given eventable. */
  primaryResultForEventable?: Maybe<AutomationRuleTriggerResult>;
  primaryRuleTrigger?: Maybe<AutomationRuleTrigger>;
  recentActivity: AutomationRuleRecentActivity;
  ruleExecutions: AutomationRuleExecutionConnection;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  /** The result for a given eventable. */
  secondaryResultsForEventable?: Maybe<Array<AutomationRuleTriggerResult>>;
  token: Scalars['ID']['output'];
  unsampledCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usageCount: Scalars['Int']['output'];
  usagePercentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};


/** A triggered automation rule. */
export type TriggerAutomationRulePendingRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A triggered automation rule. */
export type TriggerAutomationRulePrimaryResultForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};


/** A triggered automation rule. */
export type TriggerAutomationRuleRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A triggered automation rule. */
export type TriggerAutomationRuleSecondaryResultsForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};

/** Autogenerated input type of TriggerPreviewModeRun */
export type TriggerPreviewModeRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of TriggerPreviewModeRun. */
export type TriggerPreviewModeRunPayload = {
  __typename?: 'TriggerPreviewModeRunPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type TriggerableAutomationRule = {
  childRuleTriggers?: Maybe<Array<AutomationRuleTrigger>>;
  pendingRuleExecutions?: Maybe<AutomationRulePendingExecutionConnection>;
  previewModeManualRuns: Array<AutomationRulePreviewRun>;
  previewModePendingRun?: Maybe<AutomationRulePreviewRun>;
  /** The result for a given eventable. */
  primaryResultForEventable?: Maybe<AutomationRuleTriggerResult>;
  primaryRuleTrigger?: Maybe<AutomationRuleTrigger>;
  /** The result for a given eventable. */
  secondaryResultsForEventable?: Maybe<Array<AutomationRuleTriggerResult>>;
};


export type TriggerableAutomationRulePendingRuleExecutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TriggerableAutomationRulePrimaryResultForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};


export type TriggerableAutomationRuleSecondaryResultsForEventableArgs = {
  domainType: AutomationDomainType;
  eventableToken: Scalars['ID']['input'];
};

export type TriggeredRuleTemplate = AutomationRuleTemplateBase & {
  __typename?: 'TriggeredRuleTemplate';
  actionParams?: Maybe<AutomationRuleTemplateActionParams>;
  actionText?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<AutomationActionType>;
  automationTemplateCategory?: Maybe<AutomationRuleTemplateCategory>;
  automationTriggerTemplate?: Maybe<AutomationRuleTriggerTemplate>;
  automationType?: Maybe<AutomationRuleType>;
  description?: Maybe<Scalars['String']['output']>;
  domainType?: Maybe<AutomationDomainType>;
  enabled: Scalars['Boolean']['output'];
  humanReadableSchedule?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sampleRate?: Maybe<Scalars['Int']['output']>;
  setup?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['ID']['output']>;
  token: Scalars['ID']['output'];
  triggerDescription?: Maybe<Scalars['String']['output']>;
  triggerName?: Maybe<Scalars['String']['output']>;
};

export type TypeOfReview = {
  __typename?: 'TypeOfReview';
  actionRequirements: Array<ReviewTypeActionRequirement>;
  actions: Array<ReviewTypeAction>;
  allowManualCreation?: Maybe<Scalars['Boolean']['output']>;
  canonicalReviewType: CanonicalReview;
  displayColor: Scalars['String']['output'];
  initial?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  queueRequired: Scalars['Boolean']['output'];
  timelineParams?: Maybe<ReviewTypeTimelineParams>;
  timelineType?: Maybe<ReviewTypeTimelineTypeEnum>;
  token: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
};

/** Strategy for unassigning a review */
export enum UnassignStrategyEnum {
  Assignee = 'assignee',
  Both = 'both',
  Queue = 'queue'
}

/** Autogenerated input type of UndeleteAutomationRule */
export type UndeleteAutomationRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UndeleteAutomationRule. */
export type UndeleteAutomationRulePayload = {
  __typename?: 'UndeleteAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UnlinkAccount */
export type UnlinkAccountInput = {
  accountToken: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UnlinkAccount. */
export type UnlinkAccountPayload = {
  __typename?: 'UnlinkAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of UnlockInvestigation */
export type UnlockInvestigationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of UnlockInvestigation. */
export type UnlockInvestigationPayload = {
  __typename?: 'UnlockInvestigationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

/** The connection type for Untyped. */
export type UntypedConnection = {
  __typename?: 'UntypedConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UntypedEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Scalars['Untyped']['output']>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total count of items in this connection. (total across all pages) */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UntypedEdge = {
  __typename?: 'UntypedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Scalars['Untyped']['output']>;
};

/** Autogenerated input type of UpdateAccountBadges */
export type UpdateAccountBadgesInput = {
  accountToken: Scalars['ID']['input'];
  badgeTokens: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateAccountBadges. */
export type UpdateAccountBadgesPayload = {
  __typename?: 'UpdateAccountBadgesPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateApiKey */
export type UpdateApiKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uid: Scalars['ID']['input'];
  whitelistedIps?: InputMaybe<Array<Scalars['Cidr']['input']>>;
};

/** Autogenerated return type of UpdateApiKey. */
export type UpdateApiKeyPayload = {
  __typename?: 'UpdateApiKeyPayload';
  apiKey: ApiKey;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAutomationRuleAsAdmin */
export type UpdateAutomationRuleAsAdminInput = {
  actionParams?: InputMaybe<ActionParams>;
  actionText?: InputMaybe<Scalars['String']['input']>;
  actionType?: InputMaybe<AutomationActionType>;
  automationType?: InputMaybe<AutomationRuleType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  domainType?: InputMaybe<AutomationDomainType>;
  eventText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationToken: Scalars['ID']['input'];
  sampleRate?: InputMaybe<Scalars['Int']['input']>;
  scheduleConfig?: InputMaybe<Scalars['String']['input']>;
  scheduleStartOn?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
  triggerFilters?: InputMaybe<Scalars['AutomationFiltersNode']['input']>;
};

/** Autogenerated return type of UpdateAutomationRuleAsAdmin. */
export type UpdateAutomationRuleAsAdminPayload = {
  __typename?: 'UpdateAutomationRuleAsAdminPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAutomationRule */
export type UpdateAutomationRuleInput = {
  actionParams?: InputMaybe<ActionParams>;
  actionText?: InputMaybe<Scalars['String']['input']>;
  actionType?: InputMaybe<AutomationActionType>;
  automationType?: InputMaybe<AutomationRuleType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  datasourceToken?: InputMaybe<Scalars['ID']['input']>;
  domainType?: InputMaybe<AutomationDomainType>;
  eventText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sampleRate?: InputMaybe<Scalars['Int']['input']>;
  scheduleConfig?: InputMaybe<Scalars['String']['input']>;
  scheduleStartOn?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
  triggerFilters?: InputMaybe<Scalars['AutomationFiltersNode']['input']>;
};

/** Autogenerated input type of UpdateAutomationRuleNotes */
export type UpdateAutomationRuleNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAutomationRuleNotes. */
export type UpdateAutomationRuleNotesPayload = {
  __typename?: 'UpdateAutomationRuleNotesPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateAutomationRule. */
export type UpdateAutomationRulePayload = {
  __typename?: 'UpdateAutomationRulePayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAutomationRuleSettings */
export type UpdateAutomationRuleSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  failureNotificationSetting: AutomationFailureNotificationSetting;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateAutomationRuleSettings. */
export type UpdateAutomationRuleSettingsPayload = {
  __typename?: 'UpdateAutomationRuleSettingsPayload';
  automationRule: CanonicalAutomationRule;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateAutomationTemplate */
export type UpdateAutomationTemplateInput = {
  actionParams?: InputMaybe<ActionParams>;
  actionText?: InputMaybe<Scalars['String']['input']>;
  actionType?: InputMaybe<AutomationActionType>;
  automationType?: InputMaybe<AutomationRuleType>;
  categoryToken?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainType?: InputMaybe<AutomationDomainType>;
  humanReadableSchedule?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sampleRate?: InputMaybe<Scalars['Int']['input']>;
  scheduleConfig?: InputMaybe<Scalars['String']['input']>;
  setup?: InputMaybe<Scalars['String']['input']>;
  snapshotFilters?: InputMaybe<Scalars['AutomationFiltersNode']['input']>;
  token: Scalars['ID']['input'];
  triggerDescription?: InputMaybe<Scalars['String']['input']>;
  triggerName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateAutomationTemplate. */
export type UpdateAutomationTemplatePayload = {
  __typename?: 'UpdateAutomationTemplatePayload';
  automationTemplate: AutomationRuleTemplate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBadge */
export type UpdateBadgeInput = {
  badgeInput: BadgeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateBadge. */
export type UpdateBadgePayload = {
  __typename?: 'UpdateBadgePayload';
  badge: Badge;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBulkReviewDataExclusions */
export type UpdateBulkReviewDataExclusionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exclude: Scalars['Boolean']['input'];
  reviewToken: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
  sectionType: SectionTypeEnum;
};

/** Autogenerated return type of UpdateBulkReviewDataExclusions. */
export type UpdateBulkReviewDataExclusionsPayload = {
  __typename?: 'UpdateBulkReviewDataExclusionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Autogenerated input type of UpdateDatasource */
export type UpdateDatasourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateDatasource. */
export type UpdateDatasourcePayload = {
  __typename?: 'UpdateDatasourcePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  datasource: Datasource;
};

/** Autogenerated input type of UpdateDirectorySyncBadgeAssignments */
export type UpdateDirectorySyncBadgeAssignmentsInput = {
  assignments: Array<DirectorySyncGroupBadgeAssignment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateDirectorySyncBadgeAssignments. */
export type UpdateDirectorySyncBadgeAssignmentsPayload = {
  __typename?: 'UpdateDirectorySyncBadgeAssignmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directorySyncGroups: Array<DirectorySyncGroup>;
};

/** Autogenerated input type of UpdateDirectorySyncEnabled */
export type UpdateDirectorySyncEnabledInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateDirectorySyncEnabled. */
export type UpdateDirectorySyncEnabledPayload = {
  __typename?: 'UpdateDirectorySyncEnabledPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  directorySyncConfig?: Maybe<DirectorySyncConfig>;
};

/** Autogenerated input type of UpdateInvestigation */
export type UpdateInvestigationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: InvestigationInput;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateInvestigation. */
export type UpdateInvestigationPayload = {
  __typename?: 'UpdateInvestigationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
};

/** Autogenerated input type of UpdateOtherInfoLabel */
export type UpdateOtherInfoLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dashboardVisible?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayAs: OtherInfoLabelDisplayAsEnum;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  managed?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateOtherInfoLabel. */
export type UpdateOtherInfoLabelPayload = {
  __typename?: 'UpdateOtherInfoLabelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  otherInfoLabel: OtherInfoLabel;
};

export type UpdateReviewDueDateParams = {
  relativeToCreatedDate?: InputMaybe<Scalars['Boolean']['input']>;
  relativeToDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  updateToDateRelativeIncrementUnit?: InputMaybe<AutomationDateUnitEnum>;
  updateToDateRelativeIncrementValue?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateReview */
export type UpdateReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: ReviewInput;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReview. */
export type UpdateReviewPayload = {
  __typename?: 'UpdateReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  review: Review;
};

/** Autogenerated input type of UpdateSingleReviewDataExclusion */
export type UpdateSingleReviewDataExclusionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exclude: Scalars['Boolean']['input'];
  reviewToken: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  type: ReviewDataExclusionSubjectTypes;
};

/** Autogenerated return type of UpdateSingleReviewDataExclusion. */
export type UpdateSingleReviewDataExclusionPayload = {
  __typename?: 'UpdateSingleReviewDataExclusionPayload';
  caseSubject?: Maybe<InvestigationSubject>;
  caseSubjects?: Maybe<Array<InvestigationSubject>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  librarySubject?: Maybe<LibraryObject>;
  librarySubjects?: Maybe<Array<LibraryObject>>;
  review?: Maybe<Review>;
};

/** Autogenerated input type of UpdateTransactionFlagged */
export type UpdateTransactionFlaggedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Boolean indicating what the new flagged state should be. */
  flagged: Scalars['Boolean']['input'];
  /** Token for investigation the transaction exists on */
  investigationToken: Scalars['ID']['input'];
  /** Transaction token to update flagged for. */
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTransactionFlagged. */
export type UpdateTransactionFlaggedPayload = {
  __typename?: 'UpdateTransactionFlaggedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
  transaction: Transaction;
};

/** Autogenerated input type of UpsertCaseAlert */
export type UpsertCaseAlertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: AlertData;
  /** One of investigation_token xor review_token must be provided */
  investigationToken?: InputMaybe<Scalars['String']['input']>;
  /** One of investigation_token xor review_token must be provided */
  reviewToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpsertCaseAlert. */
export type UpsertCaseAlertPayload = {
  __typename?: 'UpsertCaseAlertPayload';
  caseAlert: CaseAlert;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpsertComment */
export type UpsertCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentToken?: InputMaybe<Scalars['ID']['input']>;
  investigationToken: Scalars['ID']['input'];
  quoteText?: InputMaybe<Scalars['String']['input']>;
  reviewToken?: InputMaybe<Scalars['ID']['input']>;
  reviewTypeTaskToken?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
  threadToken?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpsertComment. */
export type UpsertCommentPayload = {
  __typename?: 'UpsertCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  comment: Comment;
};

/** Autogenerated input type of UpsertFINTRACApiSettings */
export type UpsertFintracApiSettingsInput = {
  clientId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  contactMappings: Array<FintracApiContactIdMappingInput>;
  defaultFintracContactId?: InputMaybe<Scalars['Int']['input']>;
  defaultLocationId: Scalars['String']['input'];
  filingInstitutionToken: Scalars['ID']['input'];
};

/** Autogenerated return type of UpsertFINTRACApiSettings. */
export type UpsertFintracApiSettingsPayload = {
  __typename?: 'UpsertFINTRACApiSettingsPayload';
  apiSettings?: Maybe<FintracApiSettings>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpsertIndicator */
export type UpsertIndicatorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deviceToken?: InputMaybe<Scalars['ID']['input']>;
  eventType: CyberEventIndicatorTypeEnum;
  eventTypeOther?: InputMaybe<Scalars['String']['input']>;
  reviewToken: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpsertIndicator. */
export type UpsertIndicatorPayload = {
  __typename?: 'UpsertIndicatorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  indicator: Indicator;
};

/** Autogenerated input type of UpsertMiddeskCredential */
export type UpsertMiddeskCredentialInput = {
  apiKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpsertMiddeskCredential. */
export type UpsertMiddeskCredentialPayload = {
  __typename?: 'UpsertMiddeskCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  middeskCredential?: Maybe<MiddeskCredential>;
};

/** Autogenerated input type of UpsertNarrativeTemplate */
export type UpsertNarrativeTemplateInput = {
  applicableReviewTypeToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpsertNarrativeTemplate. */
export type UpsertNarrativeTemplatePayload = {
  __typename?: 'UpsertNarrativeTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  narrativeTemplate: NarrativeTemplate;
};

/** Autogenerated input type of UpsertSavedSearch */
export type UpsertSavedSearchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dashboardType?: InputMaybe<DashboardTypeEnum>;
  name: Scalars['String']['input'];
  search: SearchRequestInput;
  token?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpsertSavedSearch. */
export type UpsertSavedSearchPayload = {
  __typename?: 'UpsertSavedSearchPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  savedSearchRequest: SavedSearchRequest;
};

/** Autogenerated input type of UpsertScreeningSearchResult */
export type UpsertScreeningSearchResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  entityToken: Scalars['ID']['input'];
  entityType: LibraryTypeEnum;
  provider: ScreeningSearchProvider;
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpsertScreeningSearchResult. */
export type UpsertScreeningSearchResultPayload = {
  __typename?: 'UpsertScreeningSearchResultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: Scalars['JSON']['output'];
};

/** Autogenerated input type of UpsertSigmaConfiguration */
export type UpsertSigmaConfigurationInput = {
  apiKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  indicators: Array<Scalars['String']['input']>;
  monitored: Scalars['Boolean']['input'];
  monitoringFiltersetId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpsertSigmaConfiguration. */
export type UpsertSigmaConfigurationPayload = {
  __typename?: 'UpsertSigmaConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sigmaConfiguration?: Maybe<SigmaConfiguration>;
};

/** Autogenerated input type of UpsertThomsonReutersClearCredential */
export type UpsertThomsonReutersClearCredentialInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  permissiblePurposeDppa: TrcPermissiblePurposeDppaEnum;
  permissiblePurposeGlb: TrcPermissiblePurposeGlbEnum;
  permissiblePurposeVoter: TrcPermissiblePurposeVoterEnum;
};

/** Autogenerated return type of UpsertThomsonReutersClearCredential. */
export type UpsertThomsonReutersClearCredentialPayload = {
  __typename?: 'UpsertThomsonReutersClearCredentialPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of UpsertTransactionEndpoint */
export type UpsertTransactionEndpointInput = {
  accountToken?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  amount?: InputMaybe<HbMoneyInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endpointType: TransactionEndpointTypeEnum;
  entityToken?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<PhoneNumberInput>;
  token?: InputMaybe<Scalars['String']['input']>;
  transactionSummaryToken: Scalars['String']['input'];
};

/** Autogenerated return type of UpsertTransactionEndpoint. */
export type UpsertTransactionEndpointPayload = {
  __typename?: 'UpsertTransactionEndpointPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transactionEndpoint: TransactionEndpoint;
};

/** Autogenerated input type of UpsertTransaction */
export type UpsertTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  investigationToken: Scalars['ID']['input'];
  transaction: TransactionInput;
};

/** Autogenerated return type of UpsertTransaction. */
export type UpsertTransactionPayload = {
  __typename?: 'UpsertTransactionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  investigation: Investigation;
  transaction: Transaction;
};

/** FINTRAC API Validation Attempt */
export type ValidationAttempt = {
  __typename?: 'ValidationAttempt';
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  launchedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reviewToken: Scalars['String']['output'];
  status: JobStatus;
  token: Scalars['ID']['output'];
  validations?: Maybe<Validations>;
};

export type Validations = {
  __typename?: 'Validations';
  errors?: Maybe<Array<ActionError>>;
  totalErrors: Scalars['Int']['output'];
  totalWarnings: Scalars['Int']['output'];
  warnings?: Maybe<Array<ActionWarning>>;
};

export type WebLink = {
  __typename?: 'WebLink';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A webhook endpoint for receiving signed events. */
export type WebhookEndpoint = {
  __typename?: 'WebhookEndpoint';
  createdAt: Scalars['ISO8601DateTime']['output'];
  createdBy: Account;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  disabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  disabledBy?: Maybe<Account>;
  discarded: Scalars['Boolean']['output'];
  enableMtls: Scalars['Boolean']['output'];
  lastSuccessfulRunAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  token: Scalars['ID']['output'];
  trustedCaCert?: Maybe<X509Certificate>;
  url: Scalars['String']['output'];
};

/** Status and possible response of a webhook endpoint test. */
export type WebhookEndpointTestJob = Job & {
  __typename?: 'WebhookEndpointTestJob';
  /** The timestamp when the job was initially enqueued. */
  enqueuedAt: Scalars['ISO8601DateTime']['output'];
  /** A user-facing error message to display, if state is failed. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The current state of the async request. */
  status: JobStatus;
  testResponse?: Maybe<WebhookEndpointTestResponse>;
  /** The token which uniquely identifies this async request. */
  token: Scalars['ID']['output'];
};

/** A webhook endpoint test response. */
export type WebhookEndpointTestResponse = {
  __typename?: 'WebhookEndpointTestResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Webhook event type enum (if displaying on the frontend, `__`s should be replaced with `.`s) */
export enum WebhookEventTypeEnum {
  ActionDecisionMade = 'action__decision_made',
  AlertProcessed = 'alert__processed',
  AlertStuck = 'alert__stuck',
  CaseCreated = 'case__created',
  FilingStatusUpdated = 'filing__status_updated',
  ReviewAssignmentChanged = 'review__assignment_changed',
  ReviewCancelled = 'review__cancelled',
  ReviewCreated = 'review__created',
  Test = 'test'
}

export type WorkflowActionReviewParams = {
  actionSlug: Scalars['String']['input'];
  canonicalReviewTypeId: Scalars['ID']['input'];
  choiceKey: Scalars['String']['input'];
};

export enum WorkflowIconEnum {
  AccountBalanceWallet = 'account_balance_wallet',
  Alarm = 'alarm',
  CheckCircle = 'check_circle',
  CircleAdd = 'circle_add',
  CustomInvestigate = 'custom_investigate',
  Edit = 'edit',
  FormatQuote = 'format_quote',
  Gavel = 'gavel',
  InfoDocumentOutlined = 'info_document_outlined',
  Input = 'input',
  LabelOutlined = 'label_outlined',
  LabelUnusual = 'label_unusual',
  Narrative = 'narrative',
  NoEncryption = 'no_encryption',
  ProductsInstruments = 'products_instruments',
  RecentActors = 'recent_actors',
  ReportProblemOutlined = 'report_problem_outlined',
  SearchDocumentOutlined = 'search_document_outlined',
  Send = 'send',
  Sharing = 'sharing',
  Transaction = 'transaction',
  Tune = 'tune'
}

export type WriteOtherInfoParams = {
  otherInfoEntries: Array<OtherInfoEntryParams>;
  overwrite: Scalars['Boolean']['input'];
};

export type X509Certificate = {
  __typename?: 'X509Certificate';
  fingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The certificate in PEM format */
  pem: Scalars['String']['output'];
  /** Prints out the certificate in text form. */
  text: Scalars['String']['output'];
};
