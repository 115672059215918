import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  ScreeningCurrentSearch,
  ScreeningUIState,
  ScreeningUIStateUpdate,
} from 'components/cases/Tabs/SanctionsScreening/ScreeningTab.types'
import { ScreeningSearchRowFragment } from 'components/cases/Tabs/SanctionsScreening/__generated__/Screening.queries.generated'

export type ScreeningState = {
  currentSearches: Array<ScreeningCurrentSearch>
  UIState: {
    [libraryToken: string]: ScreeningUIState[]
  }
}

export const initialState: ScreeningState = {
  currentSearches: [],
  UIState: {},
}

export const getUIStateIndex = (
  state: ScreeningState,
  {
    libraryToken,
    investigationToken,
    provider,
  }: Pick<ScreeningUIStateUpdate, 'investigationToken' | 'libraryToken' | 'provider'>
) => {
  const UIStates = state.UIState[libraryToken]
  if (!UIStates?.length) {
    return -1
  }
  // we need to find the index of the UIState that matches the investigationToken AND provider
  return UIStates.findIndex(
    (UIState) => UIState.investigationToken === investigationToken && UIState.provider === provider
  )
}

const ScreeningSlice = createSlice({
  name: 'Screening',
  initialState,
  reducers: {
    updateUIState(state, action: PayloadAction<ScreeningUIStateUpdate>) {
      const { libraryToken } = action.payload

      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      if (stateIndex > -1) {
        // eslint-disable-next-line no-param-reassign
        state.UIState[libraryToken][stateIndex] = {
          ...oldState,
          ...action.payload,
        }
      } else if (state.UIState[libraryToken]) {
        state.UIState[libraryToken].push(action.payload)
      } else {
        // eslint-disable-next-line no-param-reassign
        state.UIState[libraryToken] = [action.payload]
      }
    },
    storeFormState(
      state,
      action: PayloadAction<
        Pick<ScreeningUIStateUpdate, 'libraryToken' | 'investigationToken' | 'provider' | 'searchFields'>
      >
    ) {
      const { libraryToken } = action.payload

      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState

      if (status === 'unsubmitted' || !status) {
        const nextState = {
          ...action.payload,
          status: 'unsubmitted' as const,
        }
        if (stateIndex > -1) {
          // eslint-disable-next-line no-param-reassign
          state.UIState[libraryToken][stateIndex] = nextState
        } else if (state.UIState[libraryToken]) {
          state.UIState[libraryToken].push(nextState)
        } else {
          // eslint-disable-next-line no-param-reassign
          state.UIState[libraryToken] = [nextState]
        }
      }
    },
    setSelectedSearchHit(
      state,
      action: PayloadAction<
        {
          searchHit?: ScreeningSearchRowFragment | null
        } & Pick<ScreeningUIStateUpdate, 'libraryToken' | 'investigationToken' | 'provider'>
      >
    ) {
      const { libraryToken, searchHit } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}
      const { status } = oldState
      if (stateIndex > -1) {
        if (status === 'submitted' || status === 'processed') {
          // eslint-disable-next-line no-param-reassign
          state.UIState[libraryToken][stateIndex] = {
            ...oldState,
            selectedSearchHit: searchHit || undefined,
          }
        }
      }
    },
    resetSearch(
      state,
      action: PayloadAction<Pick<ScreeningUIStateUpdate, 'libraryToken' | 'investigationToken' | 'provider'>>
    ) {
      const { libraryToken, investigationToken, provider } = action.payload
      const stateIndex = getUIStateIndex(state, action.payload)
      const oldState = state.UIState[libraryToken]?.[stateIndex] || {}

      if (stateIndex > -1) {
        // eslint-disable-next-line no-param-reassign
        state.UIState[libraryToken][stateIndex] = {
          status: 'unsubmitted',
          searchFields: oldState.searchFields,
          libraryToken,
          investigationToken,
          provider,
        }
      }
    },
    addCurrentSearch(state, action: PayloadAction<ScreeningCurrentSearch>) {
      return {
        ...state,
        currentSearches: [...state.currentSearches, action.payload],
      }
    },
    removeCurrentSearch(state, action: PayloadAction<{ jobToken: string }>) {
      return {
        ...state,
        currentSearches: state.currentSearches.filter((search) => search.jobToken !== action.payload.jobToken),
      }
    },
  },
})

export const {
  updateUIState,
  addCurrentSearch,
  removeCurrentSearch,
  storeFormState,
  resetSearch,
  setSelectedSearchHit,
} = ScreeningSlice.actions
export default ScreeningSlice.reducer
