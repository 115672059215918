import { ReactNode } from 'react'

import { useSelector } from 'actions/store'
import { TitleTag } from 'components/HbComponents/Text/HbText'

import { FilterControlProps } from 'dashboards/reviews/components/Header/filtering/FilterControls'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'

import { useFeatureFlag } from 'hooks'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'

import { FeatureFlag } from 'types/api'

import { BatchActionsMenuHeader } from './BatchActionsMenuHeader'
import { FilterDashboardHeader } from './FilterDashboardHeader'
import { SearchDashboardHeader } from './SearchDashboardHeader'
import { DashboardHeaderOverrides } from './types'

export interface Props {
  openNewDialog?: (...args: unknown[]) => void
  titleTag?: TitleTag
  styleOverrides?: DashboardHeaderOverrides
  FilterControlsComponent?: (props: FilterControlProps) => JSX.Element
  ViewSelectorComponent?: (props: { size?: 'small' | 'medium' }) => JSX.Element
  showBatchActions?: boolean
}

export function DashboardHeader(props: Props) {
  const {
    openNewDialog,
    titleTag,
    styleOverrides,
    FilterControlsComponent,
    ViewSelectorComponent,
    showBatchActions = true,
  } = props
  const dashboardsSelectors = useDashboardSelectors()

  const { header } = useDashboardConfig()
  const { BatchActionsMenu, title } = header

  const query = useSelector(dashboardsSelectors.query)
  const isDashboardSearchUIEnabled = useFeatureFlag(FeatureFlag.EnableDashboardSearch)

  let headerEl: ReactNode = null

  if (query && !isDashboardSearchUIEnabled) {
    headerEl = <SearchDashboardHeader titleTag={titleTag} query={query} />
  } else {
    headerEl = (
      <FilterDashboardHeader
        FilterControlsComponent={FilterControlsComponent}
        ViewSelectorComponent={ViewSelectorComponent}
        openNewDialog={openNewDialog}
        styleOverrides={styleOverrides}
        titleTag={titleTag}
      />
    )
  }

  return (
    <>
      {showBatchActions && <BatchActionsMenuHeader batchActionsMenu={<BatchActionsMenu />} title={title} />}
      {headerEl}
    </>
  )
}
