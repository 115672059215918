import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import classNames from 'classnames'

import { editorStateToPlainText } from './utils'

import type { Props as RootProps, BaseClasses } from './TypeAheadEditor'

export type Props = Pick<RootProps, 'className' | 'theme'> & {
  classes: BaseClasses
  setViewPlainText: (viewPlainText: boolean) => unknown
}

export default function PlainTextView({ className, theme, classes, setViewPlainText }: Props) {
  const [editor] = useLexicalComposerContext()
  const editorState = editor.getEditorState()

  return (
    <div
      className={classNames(
        theme?.editorInput || classes.editorInput,
        theme?.richEditorInput || classes.richEditorInput,
        className
      )}
      role="textbox"
      tabIndex={0}
      onClick={() => setViewPlainText(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setViewPlainText(false)
        }
      }}
    >
      <pre className={classes.richEditorViewPlainText}>{editorState ? editorStateToPlainText(editorState) : null}</pre>
    </div>
  )
}
