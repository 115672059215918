import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'

import { FormSchema } from 'components/settings/user-settings/utils'
import {
  AutomationActionType,
  AutomationDateUnitEnum,
  AutomationDomainType,
  AutomationRuleType,
  SnoozeTimingPolicyEnum,
} from 'types/api'

import {
  DomainFieldSpec,
  getDefaultOperatorForField,
  getDefaultRightValueForOperator,
} from './TriggerFilterEditor/fieldConfig'

import type {
  BinaryExpression,
  StrictLogicalExpression,
  VariableNode,
  LiteralPrimitiveNode,
  LiteralArrayNode,
  TypeCastLiteral,
  TypeCastVariable,
  StrictListExpression,
  StrictLinkedTrigger,
} from 'types/automations'

export const variableNodeSchema: z.ZodType<VariableNode> = z.object({
  type: z.literal('variable'),
  path: z.array(z.string()),
})

const literalPrimitiveNodeSchema: z.ZodType<LiteralPrimitiveNode> = z.object({
  type: z.literal('literal'),
  value: z.union([z.number(), z.boolean(), z.string().nonempty({ message: 'Cannot be empty' })]),
})

const literalArrayNodeSchema: z.ZodType<LiteralArrayNode> = z.object({
  type: z.literal('literal'),
  value: z.array(z.string()).nonempty({ message: 'Cannot be empty' }),
})

const typeCastVariableSchema: z.ZodType<TypeCastVariable> = z.lazy(() =>
  z.object({
    type: z.literal('type_cast'),
    target_type: z.union([z.literal('money'), z.literal('date')]),
    value: variableNodeSchema,
  })
)

const typeCastMoneyLiteralSchema: z.ZodType<TypeCastLiteral> = z.lazy(() =>
  z.object({
    type: z.literal('type_cast'),
    target_type: z.literal('money'),
    value: z.object({
      type: z.literal('literal'),
      value: z.object({
        amount: z.string().nonempty({ message: 'Cannot be empty' }),
        currency: z.string().nonempty({ message: 'Cannot be empty' }),
      }),
    }),
  })
)

const typeCastDateLiteralSchema: z.ZodType<TypeCastLiteral> = z.lazy(() =>
  z.object({
    type: z.literal('type_cast'),
    target_type: z.literal('date'),
    value: z.object({
      type: z.literal('literal'),
      value: z.object({
        day: z.number().nonnegative({ message: 'Must be valid day' }),
        month: z.number().nonnegative({ message: 'Must be valid month' }),
        year: z.number().nonnegative({ message: 'Must be valid year' }),
      }),
    }),
  })
)

export const binaryExpressionSchema: z.ZodType<BinaryExpression> = z
  .object({
    type: z.literal('binary_expression'),
    left: z.union([variableNodeSchema, typeCastVariableSchema]),
  })
  .and(
    z.union([
      z.object({
        operator: z.union([
          z.literal('in'),
          z.literal('not_in'),
          z.literal('contained_in'),
          z.literal('not_contained_in'),
        ]),
        right: literalArrayNodeSchema,
      }),
      z.object({
        operator: z.union([
          z.literal('equal'),
          z.literal('not_equal'),
          z.literal('contains'),
          z.literal('not_contains'),
          z.literal('greater_than'),
          z.literal('greater_than_equal_to'),
          z.literal('less_than'),
          z.literal('less_than_equal_to'),
        ]),
        right: z.union([literalPrimitiveNodeSchema, typeCastMoneyLiteralSchema, typeCastDateLiteralSchema]),
      }),
    ])
  )
  .superRefine((binaryExpressionNode, ctx) => {
    if (!('path' in binaryExpressionNode.left)) {
      return
    }

    // TODO: clean this up, maybe consolodate with the fieldConfig data
    if (
      ['business_days_stale', 'days_until_due_date', 'days_past_due_date'].includes(
        binaryExpressionNode.left.path.at(-1) ?? ''
      )
    ) {
      if (typeof binaryExpressionNode.right.value === 'number' && binaryExpressionNode.right.value < 0) {
        ctx.addIssue({
          type: 'number',
          code: z.ZodIssueCode.too_small,
          minimum: 0,
          inclusive: true,
          path: ['right', 'value'],
        })
      }
    }

    if (['days_past_due_date', 'days_until_due_date'].includes(binaryExpressionNode.left.path.at(-1) ?? '')) {
      if (typeof binaryExpressionNode.right.value === 'number' && binaryExpressionNode.right.value > 7) {
        ctx.addIssue({
          type: 'number',
          code: z.ZodIssueCode.too_big,
          maximum: 7,
          inclusive: true,
          path: ['right', 'value'],
        })
      }
    }
  })
  .transform((binaryExpressionNode) => {
    // We are deprecating singular comparisons for text-based fields in favor of list comparisons
    // for simplicity
    if (
      ['equal', 'not_equal', 'contains', 'not_contains'].includes(binaryExpressionNode.operator) &&
      typeof binaryExpressionNode.right.value === 'string' &&
      binaryExpressionNode.right.type === 'literal'
    ) {
      let newOperator: 'in' | 'not_in' | 'contained_in' | 'not_contained_in'

      switch (binaryExpressionNode.operator) {
        case 'equal':
          newOperator = 'in'
          break
        case 'not_equal':
          newOperator = 'not_in'
          break
        case 'contains':
          newOperator = 'contained_in'
          break
        case 'not_contains':
          newOperator = 'not_contained_in'
          break
        default:
          throw new Error('Invalid operator')
      }

      const newVariable: LiteralArrayNode = {
        ...binaryExpressionNode.right,
        value: [binaryExpressionNode.right.value],
      }

      return {
        ...binaryExpressionNode,
        operator: newOperator,
        right: newVariable,
      }
    }

    return binaryExpressionNode
  })

const strictListExpressionSchema: z.ZodType<StrictListExpression> = z.lazy(() =>
  z.object({
    type: z.literal('list_expression'),
    operator: z.union([z.literal('any'), z.literal('all'), z.literal('none')]),
    value: variableNodeSchema,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    expression: strictLogicalExpressionSchema,
  })
)

export const domainTypeSchema = z.enum([
  AutomationDomainType.Business,
  AutomationDomainType.Case,
  AutomationDomainType.Datasource,
  AutomationDomainType.Filing,
  AutomationDomainType.Person,
  AutomationDomainType.Review,
  AutomationDomainType.MiddeskBusiness,
])

export const domainSchema = z.object({
  type: domainTypeSchema.nullable(),
  datasourceToken: z.string().nullable().optional(),
})

const strictLinkedTriggerSchema: z.ZodType<StrictLinkedTrigger> = z.lazy(() =>
  z.object({
    type: z.literal('linked_trigger'),
    operator: z.union([z.literal('any'), z.literal('all'), z.literal('none')]),
    domain_type: domainTypeSchema,
    trigger_token: z.string().nullable(),
    value: variableNodeSchema,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    expression: strictLogicalExpressionSchema,
  })
)

export const strictLogicalExpressionSchema: z.ZodType<StrictLogicalExpression> = z.object({
  type: z.literal('logical_expression'),
  operator: z.union([z.literal('or'), z.literal('and')]),
  values: z.array(
    z.object({
      type: z.literal('logical_expression'),
      operator: z.union([z.literal('or'), z.literal('and')]),
      values: z.array(z.union([binaryExpressionSchema, strictListExpressionSchema, strictLinkedTriggerSchema])),
    })
  ),
})

const sharedNoOpActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.NoOp),
    actionParams: z.object({}),
  })

const sharedLockInvestigationActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.LockInvestigation),
    actionParams: z.object({}),
  })

const sharedUnlockInvestigationActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.UnlockInvestigation),
    actionParams: z.object({}),
  })

const sharedCommentActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.Comment),
    actionParams: z.object({
      commentParams: z.object({
        text: z.string().min(1, 'Text cannot be empty').max(500),
        smartValuesEnabled: z.boolean().default(false),
      }),
    }),
  })

const sharedCancelReviewActionType = ({ shouldFix }: { shouldFix: boolean }) =>
  z.object({
    actionType: z.literal(AutomationActionType.CancelReview),
    actionParams: z.object({
      cancelReviewParams: z.object({
        cancellationReason: z.lazy(() => {
          const base = z.string().min(1, 'Cancellation reason cannot be empty').max(4000)
          return shouldFix ? base.catch('') : base
        }),
      }),
    }),
  })

const sharedCompleteReviewActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.CompleteReview),
    actionParams: z.object({}),
  })

const sharedOpenReviewActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.OpenReview),
    actionParams: z.object({}),
  })

const sharedSnoozeReviewActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.SnoozeReview),
    actionParams: z.object({
      snoozeReviewParams: z.object({
        snoozeUntilDateRelativeIncrementUnit: z.enum([
          AutomationDateUnitEnum.Days,
          AutomationDateUnitEnum.Hours,
          AutomationDateUnitEnum.Months,
          AutomationDateUnitEnum.Minutes,
        ]),
        snoozeUntilDateRelativeIncrementValue: z.coerce.number().positive({ message: 'Must be a positive value' }),
        description: z.string(), // @robobocop TODO: functionality will be expanded for this in https://thecharm.atlassian.net/browse/PROD-12145
        snoozeTimingPolicy: z.enum([SnoozeTimingPolicyEnum.Reset, SnoozeTimingPolicyEnum.KeepExisting]),
      }),
    }),
  })

const sharedUpdateReviewDueDateActionType = () =>
  z.object({
    actionType: z.literal(AutomationActionType.UpdateReviewDueDate),
    actionParams: z.object({
      updateReviewDueDateParams: z.object({
        updateToDateRelativeIncrementUnit: z.enum([
          AutomationDateUnitEnum.Days,
          AutomationDateUnitEnum.Hours,
          AutomationDateUnitEnum.Months,
          AutomationDateUnitEnum.Minutes,
        ]),
        updateToDateRelativeIncrementValue: z.number().nonnegative({ message: 'Must be a positive value' }),
        relativeToDueDate: z.boolean(),
        relativeToCreatedDate: z.boolean(),
      }),
    }),
  })

const sharedWriteOtherInfoActionType = ({ nullable }: { nullable: boolean }) => {
  const writeOtherInfoParams = z.object({
    overwrite: z.boolean(),
    otherInfoEntries: z.array(
      z.object({
        label: z.string().min(1, 'Label cannot be empty'),
        value: z.string().min(1, 'Value cannot be empty'),
      })
    ),
  })

  return z.object({
    actionType: z.literal(AutomationActionType.WriteOtherInfo),
    actionParams: z.object({
      writeOtherInfoParams: nullable ? writeOtherInfoParams.nullable() : writeOtherInfoParams,
    }),
  })
}

function makeFormSchemaForRule(shouldFix = true) {
  return z.intersection(
    z.object({
      name: z.string().nullable(),
      organizationToken: z.string().nullable().default(null),
      sampleRate: z.number().int().min(0).max(100).nullable().default(null),
      eventText: z.lazy(() => (shouldFix ? z.string().catch('Something happens...') : z.string())),
      actionText: z.string().nullable(),
    }),
    z.discriminatedUnion('actionType', [
      sharedCommentActionType(),
      sharedLockInvestigationActionType(),
      sharedUnlockInvestigationActionType(),
      sharedNoOpActionType(),
      sharedCancelReviewActionType({ shouldFix }),
      sharedCompleteReviewActionType(),
      sharedOpenReviewActionType(),
      sharedSnoozeReviewActionType(),
      sharedUpdateReviewDueDateActionType(),
      sharedWriteOtherInfoActionType({ nullable: false }),
      z.object({
        actionType: z.literal(AutomationActionType.Notification),
        actionParams: z.object({
          notificationParams: z.object({
            badgeTokens: z.lazy(() => {
              const base = z.array(z.string())
              return shouldFix ? base.catch([]) : base
            }),
            accountTokens: z.lazy(() => {
              const base = z.array(z.string())
              return shouldFix ? base.catch([]) : base
            }),
            recipientEmails: z.lazy(() => {
              const base = z.array(
                z.object({
                  email: z.lazy(() => {
                    const emailBase = z.string().trim().min(1, 'Email cannot be empty')
                    return shouldFix ? emailBase.catch('') : emailBase
                  }),
                  name: z.lazy(() => {
                    const nameBase = z.string().min(1, 'Recipient name cannot be empty')
                    return shouldFix ? nameBase.catch('') : nameBase
                  }),
                })
              )
              return shouldFix ? base.catch([]) : base
            }),
            sendToReviewAssignee: z.lazy(() => {
              const base = z.boolean().default(true)
              return shouldFix ? base.catch(false) : base
            }),
            subject: z.lazy(() => {
              const base = z.string().max(500).min(1, 'Subject cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            body: z.lazy(() => {
              const base = z.string().max(40000).min(1, 'Body cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            smartValuesEnabled: z.boolean().default(false),
          }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.ReviewDigestNotification),
        actionParams: z.object({
          reviewDigestNotificationParams: z.object({
            badgeTokens: z.lazy(() => {
              const base = z.array(z.string())
              return shouldFix ? base.catch([]) : base
            }),
            accountTokens: z.lazy(() => {
              const base = z.array(z.string())
              return shouldFix ? base.catch([]) : base
            }),
            recipientEmails: z.lazy(() => {
              const base = z.array(
                z.object({
                  email: z.lazy(() => {
                    const emailBase = z.string().trim().min(1, 'Email cannot be empty')
                    return shouldFix ? emailBase.catch('') : emailBase
                  }),
                  name: z.lazy(() => {
                    const nameBase = z.string().min(1, 'Recipient name cannot be empty')
                    return shouldFix ? nameBase.catch('') : nameBase
                  }),
                })
              )
              return shouldFix ? base.catch([]) : base
            }),
          }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.Tag),
        actionParams: z.object({
          tagParams: z
            .object({
              tagDefinitionToken: z.lazy(() => {
                const base = z.string().min(1, 'Tag cannot be empty')
                return shouldFix ? base.catch('') : base
              }),
            })
            .nullable()
            .default({}),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.CreateInvestigation),
        actionParams: z.object({
          createInvestigationParams: z.object({
            reviewTypeCanonicalId: z.lazy(() => {
              const base = z.string().min(1, 'Case type cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            description: z.string(),
            name: z.string().min(1, 'Name cannot be empty'),
            narrative: z.string().nullable(),
            activityTags: z.array(z.string()).nullable(),
            smartValuesEnabled: z.boolean().default(false),
          }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.CreateReview),
        actionParams: z.object({
          createReviewParams: z.object({
            reviewTypeCanonicalId: z.lazy(() => {
              const base = z.string().min(1, 'Review type cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            assigneeToken: z.string().nullable(),
            queueToken: z.string().nullable(),
            dueDateRelativeIncrementUnit: z
              .enum([
                AutomationDateUnitEnum.Days,
                AutomationDateUnitEnum.Hours,
                AutomationDateUnitEnum.Months,
                AutomationDateUnitEnum.Minutes,
              ])
              .nullable(),
            dueDateRelativeIncrementValue: z.number().nonnegative({ message: 'Must be a positive value' }).nullable(),
            activityTags: z.array(z.string()).nullable(),
            narrative: z.string().nullable(),
            smartValuesEnabled: z.boolean().default(false),
            transferReviewAssignee: z.boolean().default(false),
            otherInfoEntries: z.array(
              z.object({
                label: z.string().min(1, 'Label cannot be empty'),
                value: z.string().min(1, 'Value cannot be empty'),
              })
            ),
          }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.AssignReviewToQueue),
        actionParams: z.object({
          assignReviewToQueueParams: z.object({
            queueToken: z.lazy(() => {
              const base = z.string().min(1, 'Queue cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            unassignAccount: z.boolean(),
          }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.WorkflowActionReview),
        actionParams: z.object({
          workflowActionReviewParams: z.object({
            canonicalReviewTypeId: z.lazy(() => {
              const base = z.string().min(1, 'Review type cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            actionSlug: z.lazy(() => {
              const base = z.string().min(1, 'Action cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
            choiceKey: z.lazy(() => {
              const base = z.string().min(1, 'Choice cannot be empty')
              return shouldFix ? base.catch('') : base
            }),
          }),
        }),
      }),
      z.object({
        actionType: z.null(),
        actionParams: z.object({}),
      }),
    ])
  )
}

function makeFormSchemaForTemplate(shouldFix = true) {
  return z.intersection(
    z.object({
      name: z.string().nullable(),
      sampleRate: z.number().int().min(0).max(100).nullable().default(null),
      eventText: z.lazy(() => (shouldFix ? z.string().catch('Something happens...') : z.string().nullable())),
      actionText: z.string().nullable(),
      description: z.string().nullable(),
      setup: z.string().nullable(),
      triggerName: z.string().nullable(),
      categoryToken: z.string().nullable(),
    }),
    z.discriminatedUnion('actionType', [
      sharedCommentActionType(),
      sharedLockInvestigationActionType(),
      sharedUnlockInvestigationActionType(),
      sharedNoOpActionType(),
      sharedCancelReviewActionType({ shouldFix }),
      sharedCompleteReviewActionType(),
      sharedOpenReviewActionType(),
      sharedSnoozeReviewActionType(),
      sharedUpdateReviewDueDateActionType(),
      sharedWriteOtherInfoActionType({ nullable: true }),
      z.object({
        actionType: z.literal(AutomationActionType.Notification),
        actionParams: z.object({
          notificationParams: z
            .object({
              subject: z.lazy(() => {
                const base = z.string().max(500).min(1, 'Subject cannot be empty')
                return shouldFix ? base.catch('') : base
              }),
              body: z.lazy(() => {
                const base = z.string().max(40000).min(1, 'Body cannot be empty')
                return shouldFix ? base.catch('') : base
              }),
              smartValuesEnabled: z.boolean().default(false),
            })
            .nullable()
            .default({}),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.ReviewDigestNotification),
        actionParams: z.object({
          reviewDigestNotificationParams: z.object({}).nullable().default({}),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.Tag),
        actionParams: z.object({
          tagParams: z
            .object({
              tagDefinitionToken: z.string().nullable(),
            })
            .nullable()
            .default({ tagDefinitionToken: null }),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.CreateInvestigation),
        actionParams: z.object({
          createInvestigationParams: z
            .object({
              description: z.string(),
              name: z.string().min(1, 'Name cannot be empty'),
              narrative: z.string().nullable(),
              smartValuesEnabled: z.boolean().default(false),
              reviewTypeCanonicalId: z.string().default(''),
            })
            .nullable(),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.CreateReview),
        actionParams: z.object({
          createReviewParams: z
            .object({
              dueDateRelativeIncrementUnit: z
                .enum([
                  AutomationDateUnitEnum.Days,
                  AutomationDateUnitEnum.Hours,
                  AutomationDateUnitEnum.Months,
                  AutomationDateUnitEnum.Minutes,
                ])
                .nullable(),
              assigneeToken: z.string().nullable(),
              queueToken: z.string().nullable(),
              dueDateRelativeIncrementValue: z.number().nonnegative({ message: 'Must be a positive value' }).nullable(),
              narrative: z.string().nullable(),
              smartValuesEnabled: z.boolean().default(false),
              otherInfoEntries: z.array(
                z.object({
                  label: z.string().min(1, 'Label cannot be empty'),
                  value: z.string().min(1, 'Value cannot be empty'),
                })
              ),
            })
            .nullable(),
        }),
      }),
      z.object({
        actionType: z.literal(AutomationActionType.AssignReviewToQueue),
        actionParams: z.object({
          assignReviewToQueueParams: z
            .object({
              unassignAccount: z.boolean(),
              queueToken: z.string().nullable(),
            })
            .nullable(),
        }),
      }),
      z.object({
        actionType: z.null(),
        actionParams: z.object({}),
      }),
    ])
  )
}

const sharedScheduleSchema = z.object({
  scheduleStartOn: z.string().datetime(),
  scheduleConfig: z.object({
    freq: z.number(),
    interval: z.number().min(1, 'Interval must not be empty'),
    bymonthday: z.number().nullable(),
    byday: z
      .object({
        su: z.boolean(), // Sunday
        mo: z.boolean(), // Monday
        tu: z.boolean(), // Tuesday
        we: z.boolean(), // Wednesday
        th: z.boolean(), // Thursday
        fr: z.boolean(), // Friday
        sa: z.boolean(), // Saturday
      })
      .nullable(),
    byhour: z.number(),
  }),
})

const sharedTriggerSchema = () =>
  z.object({
    domain: domainSchema,
    triggerFilters: strictLogicalExpressionSchema,
  })

function makeTriggerRuleSchema() {
  return z
    .object({
      automationType: z.literal(AutomationRuleType.Trigger),
    })
    .merge(sharedTriggerSchema())
}

const makeScheduleWithTriggerRuleSchema = () =>
  z
    .object({ automationType: z.literal(AutomationRuleType.ScheduleWithTrigger) })
    .merge(sharedScheduleSchema)
    .merge(sharedTriggerSchema())

const scheduleRuleSchema = z
  .object({ automationType: z.literal(AutomationRuleType.Schedule) })
  .merge(sharedScheduleSchema)

export const formSchemaForRule = (shouldFix: boolean = false) =>
  z.intersection(
    makeFormSchemaForRule(shouldFix),
    z.discriminatedUnion('automationType', [
      makeTriggerRuleSchema(),
      scheduleRuleSchema,
      makeScheduleWithTriggerRuleSchema(),
      z.object({
        automationType: z.null(),
      }),
    ])
  )

export const formSchemaForTemplate = (shouldFix: boolean = false) =>
  z.intersection(
    makeFormSchemaForTemplate(shouldFix),
    z.discriminatedUnion('automationType', [
      makeTriggerRuleSchema(),
      scheduleRuleSchema,
      makeScheduleWithTriggerRuleSchema(),
      z.object({
        automationType: z.null(),
        domain: domainSchema,
        actionParams: z.object({
          assignReviewToQueueParams: z.null(),
          cancelReviewParams: z.null(),
          commentParams: z.null(),
          createInvestigationParams: z.null(),
          createReviewParams: z.null(),
          notificationParams: z.null(),
          snoozeReviewParams: z.null(),
          updateReviewDueDateParams: z.null(),
          writeOtherInfoParams: z.null(),
          workflowActionReviewParams: z.null(),
        }),
      }),
    ])
  )

export const fixingFormSchemaForRule = formSchemaForRule(true)

export const fixingFormSchemaForTemplate = formSchemaForTemplate(true)

export type FormSchemaForRule = z.infer<typeof fixingFormSchemaForRule>
export type FormSchemaForTemplate = z.infer<typeof fixingFormSchemaForTemplate>
export type FormSchema = FormSchemaForRule | FormSchemaForTemplate

export type TriggerFormSchema = FormSchema & {
  automationType: AutomationRuleType.Trigger | AutomationRuleType.ScheduleWithTrigger
}
export type ScheduleFormSchema = FormSchema & {
  automationType: AutomationRuleType.Schedule | AutomationRuleType.ScheduleWithTrigger
}

export type FormSchemaReturnType = UseFormReturn<FormSchema>
export type FormSchemaReturnTypeForRule = UseFormReturn<FormSchemaForRule>
export type FormSchemaReturnTypeForTemplate = UseFormReturn<FormSchemaForTemplate>

export type TriggerSchemaReturnType = UseFormReturn<TriggerFormSchema>
export type ScheduleSchemaReturnType = UseFormReturn<ScheduleFormSchema>

function getDefaultDomainTypeField(type: AutomationDomainType): string {
  switch (type) {
    case AutomationDomainType.Person:
      return 'gender'
    case AutomationDomainType.Business:
      return 'external_id'
    case AutomationDomainType.Datasource:
      // TODO(ak): Get a column from the datasource
      return 'amount'
    case AutomationDomainType.MiddeskBusiness:
      return 'status'
    case AutomationDomainType.Filing:
      return 'status'
    case AutomationDomainType.Case:
      return 'external_id'
    case AutomationDomainType.Review:
      return 'status'
    default:
      throw new Error(`Unsupported domain: ${type}`)
  }
}

export function getDefaultTriggerGroupLine(
  domainType: AutomationDomainType,
  domainFieldSpec: DomainFieldSpec,
  customPath: string[] | null | undefined = undefined,
  root: boolean = true
): BinaryExpression {
  const basePath = root ? ['event', domainType] : []

  const path =
    customPath === undefined
      ? basePath.concat(getDefaultDomainTypeField(domainType))
      : customPath === null
      ? basePath
      : customPath

  const last = path.at(-1)
  const operator = getDefaultOperatorForField(domainFieldSpec, last)

  return {
    type: 'binary_expression',
    operator,
    left: {
      type: 'variable',
      path,
    },
    right: getDefaultRightValueForOperator(operator),
  }
}

export function getDefaultTriggerGroup(
  domainType: AutomationDomainType,
  domainFieldSpec: DomainFieldSpec,
  defaultPath: string[] | null | undefined = undefined,
  root: boolean = true
): StrictLogicalExpression['values'][number] {
  return {
    type: 'logical_expression',
    operator: 'and',
    values: [getDefaultTriggerGroupLine(domainType, domainFieldSpec, defaultPath, root)],
  }
}

export function getDefaultTriggerFilters(
  domainType: AutomationDomainType,
  domainFieldSpec: DomainFieldSpec,
  defaultPath: string[] | null | undefined = undefined,
  root: boolean = true
): StrictLogicalExpression {
  return {
    type: 'logical_expression',
    operator: 'or',
    values: [getDefaultTriggerGroup(domainType, domainFieldSpec, defaultPath, root)],
  }
}
