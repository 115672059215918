import {
  ComponentProps,
  ForwardedRef,
  HTMLProps,
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { EditorState, Klass, LexicalEditor, LexicalNode } from 'lexical'

import { useIsMountedRef } from 'hooks'

import { Theme } from 'types/hb'

import AutoFocusPlugin from './AutoFocusPlugin'
import CleanCopyPlugin from './CleanCopyPlugin'
import CommentButtonPlugin from './CommentButtonPlugin'
import { useCommentMarkClasses } from './CommentMarkNode'
import EditorApiPlugin, { EditorApi } from './EditorApiPlugin'
import HandlePastePlugin from './HandlePastePlugin'
import { LiquidPlugin } from './LiquidPlugin'
import { useMentionClasses } from './MentionNode'
import PlainTextEditView from './PlainTextEditView'
import PlainTextView from './PlainTextView'
import ReadOnlyPlugin from './ReadOnlyPlugin'
import RichTextEditView from './RichTextEditView'
import { Seperator } from './Seperator'
import SubmitCommandPlugin from './SubmitCommandPlugin'
import { useCommentsEnabled } from './TypeAheadEditor.hooks'
import TypeAheadPlugin, { Props as TypeAheadProps } from './TypeAheadPlugin'

import ViewPlainTextPlugin from './ViewPlainTextPlugin'
import { editorNodes, richEditorNodes, richEditorWithLiquidNodes } from './nodes'
import {
  editorStateToPlainText,
  $prepopulatePlainText,
  $prepopulateHtml,
  makeLexicalErrorHandler,
  editorStateToHtml,
} from './utils'

import type { SetOptional, MergeExclusive, Simplify } from 'type-fest'

export type LexicalConfig = ComponentProps<typeof LexicalComposer>['initialConfig']

export type Action = { type: 'comment'; commentThreadId: string } | { type: 'edit' }

export type Props = PropsWithChildren<
  Simplify<
    {
      id?: string
      className?: string
      placeholder?: string
      placeholderClassName?: string
      autoFocus?: boolean
      readOnly?: boolean
      disableSaveAfterUnmount?: boolean
      onSubmit?: (value: string) => unknown
      initialValue?: string | null
      richText?: boolean | { liquid: boolean }
      onToggleViewPlainText?: (viewPlainText: boolean) => void
      maxPasteLength?: number
      enableComments?: boolean
      enablePlainTextToggle?: boolean
      // the floating formatting buttons will still be displayed
      // when text is selected
      hideToolbar?: boolean
    } & MergeExclusive<
      {
        saveTimeout?: number
        onSave?: (value: string, action: Action) => unknown
      },
      {
        onChange?: (value: string) => unknown
      }
    > &
      TypeAheadProps &
      SetOptional<Pick<LexicalConfig, 'onError' | 'theme' | 'namespace'>, 'namespace' | 'onError'> &
      Pick<HTMLProps<HTMLDivElement>, 'tabIndex'>
  >
>

type StyleProps = Pick<Props, 'readOnly' | 'enableComments' | 'enablePlainTextToggle'>
const useClasses = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  editor: {
    position: 'relative',
    '&:hover $richTextPlaceholder': {
      color: theme.palette.text.secondary, // Design Grey A
    },
  },
  editorInput: {
    '&:focus-visible': {
      outline: 'none',
    },
  },
  placeholder: {
    color: theme.palette.text.disabled, // Design Grey B
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    top: 0,
    left: 0,
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none',
    ...theme.typography.md,
  },
  richTextPlaceholder: {
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    top: 'auto',
  },
  richEditor: {
    background: ({ readOnly }) => (readOnly ? theme.palette.background.lightGray : 'white'),
    borderRadius: theme.spacing(1),
    marginBottom: ({ enableComments, enablePlainTextToggle }) =>
      theme.spacing(enableComments || enablePlainTextToggle ? 1 : 2.5),
    border: `1px solid ${theme.palette.styleguide.mediumGray1}`,
    '&:hover:not(:focus-within)': {
      borderColor: theme.palette.styleguide.nearBlack,
    },
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: '-1px',
    },
  },
  richEditorViewPlainText: {
    whiteSpace: 'break-spaces',
  },
  richEditorViewRichText: {
    '&:focus-within': {
      outline: `1px solid ${theme.palette.action.disabled}`, // Design Grey D
    },
    '&:not(:focus-within):hover': {
      outline: `1px solid ${theme.palette.text.secondary}`, // Design Grey A
      background: theme.palette.input.disabled, // Design Grey E
    },
  },
  richEditorInput: {
    padding: ({ readOnly }) => (readOnly ? undefined : theme.spacing(2)),
    borderRadius: theme.spacing(1),
  },
  textCursor: { cursor: 'text' },
  bottomControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 600,
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecoration: 'underline',
  },
  paragraph: {
    lineHeight: '1.5em',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  list: {
    listStyle: 'inside',
    lineHeight: '1.5em',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  orderedList: {
    listStyleType: 'number',
  },
  unorderedList: {
    listStyleType: 'disc',
  },
  nestedListItem: {
    listStyleType: 'none',
    '& $list': {
      margin: 0,
    },
  },
}))

export type BaseClasses = ReturnType<typeof useClasses>

function memoSave(
  text: string,
  action: Action,
  ref: MutableRefObject<string | undefined | null>,
  callback: (text: string, action: Action) => unknown
) {
  if (text !== ref.current) {
    callback(text, action)
    // eslint-disable-next-line no-param-reassign
    ref.current = text
  }
}

function TypeAheadEditor(
  {
    id,
    className,
    placeholder,
    placeholderClassName,
    namespace = 'TypeAheadEditor',
    onError,
    saveTimeout = 1000,
    disableSaveAfterUnmount,
    onSave,
    onSubmit,
    onChange,
    initialValue,
    autoFocus,
    suggestions = [],
    readOnly,
    tabIndex,
    richText,
    onToggleViewPlainText,
    maxPasteLength,
    enableComments = false,
    enablePlainTextToggle = false,
    children,
    theme,
    hideToolbar,
  }: Props,
  ref: ForwardedRef<EditorApi>
) {
  const commentsFlagEnabled = useCommentsEnabled()
  const showComments = commentsFlagEnabled && enableComments
  const classes = useClasses({ enableComments, readOnly, enablePlainTextToggle })
  const mentionClasses = useMentionClasses()
  const commentMarkClasses = useCommentMarkClasses()
  const saveTimer = useRef<number | undefined>(undefined)
  const lastSavedTextRef = useRef<string | undefined>(initialValue ?? '')
  const isMountedRef = useIsMountedRef()
  const [viewPlainText, setViewPlainText] = useState(false)
  const editorRef = useRef<LexicalEditor>()
  const lastCommentThreadTokenRef = useRef<string | null>(null)

  // onSave is not guaranteed to be memoized
  // TODO: maybe we should guarantee that
  const onSaveRef = useRef<typeof onSave>()
  onSaveRef.current = onSave
  const editorStateRef = useRef<EditorState | undefined>()

  const handleChange = useCallback(
    async (editorState: EditorState, editor: LexicalEditor) => {
      editorRef.current = editor

      if (!isMountedRef.current) {
        return
      }

      editorStateRef.current = editorState

      const valuePromise = richText ? editorStateToHtml(editorState) : editorStateToPlainText(editorState)
      const { current: commentThreadId } = lastCommentThreadTokenRef
      lastCommentThreadTokenRef.current = null

      if (onChange) {
        memoSave(
          await valuePromise,
          commentThreadId ? { type: 'comment', commentThreadId } : { type: 'edit' },
          lastSavedTextRef,
          onChange
        )
      } else {
        window.clearTimeout(saveTimer.current)
        saveTimer.current = window.setTimeout(async () => {
          if (onSaveRef.current) {
            memoSave(
              await valuePromise,
              commentThreadId ? { type: 'comment', commentThreadId } : { type: 'edit' },
              lastSavedTextRef,
              onSaveRef.current
            )
          }
        }, saveTimeout)
      }
    },
    [isMountedRef, richText, onChange, saveTimeout]
  )

  const handleChangeViewPlainText = useCallback(
    (op: boolean) => {
      setViewPlainText(op)
      onToggleViewPlainText?.(op)
    },
    [onToggleViewPlainText]
  )

  useEffect(() => {
    const lastSavedTextRefClosure = lastSavedTextRef.current
    return () => {
      window.clearTimeout(saveTimer.current)
      if (!disableSaveAfterUnmount && editorStateRef.current) {
        const { current: editorState } = editorStateRef
        const { current: commentThreadId } = lastCommentThreadTokenRef
        lastCommentThreadTokenRef.current = null
        const valuePromise = Promise.resolve(
          richText ? editorStateToHtml(editorState) : editorStateToPlainText(editorState)
        )
        valuePromise.then((text) => {
          if (text !== lastSavedTextRefClosure) {
            return onSaveRef.current?.(text, commentThreadId ? { type: 'comment', commentThreadId } : { type: 'edit' })
          }
          return undefined
        })
      }
    }
  }, [disableSaveAfterUnmount, richText])

  useEffect(() => {
    if (!viewPlainText) {
      editorRef.current?.focus()
    }
  }, [viewPlainText])

  const onComment = useCallback((threadToken: string) => {
    lastCommentThreadTokenRef.current = threadToken
  }, [])

  const placeholderEl = (
    <div className={classNames(classes.placeholder, placeholderClassName, { [classes.richTextPlaceholder]: richText })}>
      {placeholder}
    </div>
  )

  const enablePlainTextPlugin = richText && !readOnly && enablePlainTextToggle

  const enableLiquid = typeof richText === 'object' && richText.liquid
  let nodes: Klass<LexicalNode>[] = editorNodes
  if (enableLiquid) {
    nodes = richEditorWithLiquidNodes
  } else if (richText) {
    nodes = richEditorNodes
  }

  return (
    <LexicalComposer
      initialConfig={{
        onError: makeLexicalErrorHandler(onError),
        theme: {
          mention: mentionClasses.root,
          commentMark: commentMarkClasses.base,
          commentMarkOverlap: commentMarkClasses.overlap,
          text: {
            bold: classes.bold,
            italic: classes.italic,
            underline: classes.underline,
          },
          paragraph: classes.paragraph,
          list: {
            ol: classNames(classes.list, classes.orderedList),
            ul: classNames(classes.list, classes.unorderedList),
            listitem: classes.listItem,
            nested: {
              listitem: classes.nestedListItem,
            },
          },
        },
        namespace,
        nodes,
        editable: !readOnly,
        editorState: richText
          ? $prepopulateHtml(initialValue ?? '', suggestions)
          : $prepopulatePlainText(initialValue ?? '', suggestions),
      }}
    >
      <div id={id}>
        <div
          className={classNames(classes.editor, {
            [classes.richEditor]: richText && !readOnly,
            [classes.richEditorViewPlainText]: richText && !viewPlainText && !readOnly,
          })}
        >
          {viewPlainText ? (
            <PlainTextView
              className={className}
              classes={classes}
              setViewPlainText={handleChangeViewPlainText}
              theme={theme}
            />
          ) : richText ? (
            <>
              <RichTextEditView
                hideToolbar={hideToolbar}
                className={className}
                classes={classes}
                placeholder={placeholderEl}
                tabIndex={tabIndex}
                readOnly={readOnly}
                theme={theme}
                onComment={onComment}
                liquid={enableLiquid}
              />
              {enableLiquid ? <LiquidPlugin /> : null}
            </>
          ) : (
            <PlainTextEditView
              className={className}
              classes={classes}
              placeholder={placeholderEl}
              tabIndex={tabIndex}
              readOnly={readOnly}
              theme={theme}
            />
          )}
          <ReadOnlyPlugin readOnly={viewPlainText || readOnly} />
          <OnChangePlugin onChange={handleChange} ignoreSelectionChange />
          {autoFocus ? <AutoFocusPlugin /> : null}
          <HistoryPlugin />
          {maxPasteLength ? <HandlePastePlugin maxLength={maxPasteLength} /> : null}
          <TypeAheadPlugin suggestions={suggestions} />
          {onSubmit ? <SubmitCommandPlugin onSubmit={onSubmit} richText={!!richText} /> : null}
          <EditorApiPlugin richText={!!richText} ref={ref} />
          <CleanCopyPlugin />
        </div>
        <div className={classes.bottomControls}>
          {showComments && <CommentButtonPlugin className={classes.tool} />}
          {showComments && enablePlainTextPlugin && <Seperator />}
          {enablePlainTextPlugin && <ViewPlainTextPlugin value={viewPlainText} onChange={handleChangeViewPlainText} />}
        </div>
        {children}
      </div>
    </LexicalComposer>
  )
}

export default forwardRef<EditorApi, Props>(TypeAheadEditor)
