// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButtonSizes } from 'components/HbComponents/HbButton'
import { DashboardSearchInput } from 'dashboards/shared/components/Header/DashboardSearchInput'
import { Theme } from 'types/hb'

import FilterButton from './FilterButton'
import HideButton from './HideButton'
import SortButton from './SortButton'
import { ViewSettings } from './ViewSettings'

export const useStyles = makeStyles((theme: Theme) => ({
  controlsContainer: { display: 'flex', gap: theme.spacing(0.5), padding: `0 ${theme.spacing(2)}` },
  buttonActive: {
    backgroundColor: theme.palette.background.dark,
    color: 'white',
    cursor: 'pointer',
  },
  popoverShared: { display: 'flex', gap: theme.spacing(), flexDirection: 'column' },
  popoverHeader: { padding: `${theme.spacing(2.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(2.5)}` },
  addButtons: {
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}))

export interface Props {
  size?: HbButtonSizes
  iconOnly: boolean
  zIndex?: number
}

export interface FilterControlProps extends Props {
  buttonActive: string
  popoverShared: string
  popoverHeader?: string
  addButtons?: string
  disabled?: boolean
  iconOnly: boolean
}

const FilterControls = (props: Props) => {
  const { controlsContainer, buttonActive, popoverShared, popoverHeader, addButtons } = useStyles()

  const sharedStyles = { buttonActive, popoverShared, popoverHeader, addButtons }

  return (
    <div className={controlsContainer}>
      <DashboardSearchInput />
      <FilterButton {...sharedStyles} {...props} />
      <SortButton {...sharedStyles} {...props} />
      <HideButton {...sharedStyles} {...props} />
      <ViewSettings {...sharedStyles} {...props} />
    </div>
  )
}

export default FilterControls
