import { ComponentProps } from 'react'

import {
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  ORDERED_LIST,
  UNORDERED_LIST,
} from '@lexical/markdown'

import type { Transformer } from '@lexical/markdown'

import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'

import classNames from 'classnames'

import CommentPlugin from './CommentPlugin'
import ErrorBoundary from './ErrorBoundary'
import ToolbarPlugin from './ToolbarPlugin'

import { useCommentsEnabled } from './TypeAheadEditor.hooks'

import type { Props as RootProps, BaseClasses } from './TypeAheadEditor'

export type Props = Pick<RootProps, 'className' | 'theme' | 'tabIndex' | 'suggestions'> & {
  classes: BaseClasses
  placeholder: ComponentProps<typeof RichTextPlugin>['placeholder']
  readOnly?: boolean
  onComment: (commentThreadToken: string) => unknown
  hideToolbar?: boolean
  liquid?: boolean
}

const transformers: Array<Transformer> = [
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  ORDERED_LIST,
  UNORDERED_LIST,
]

export default function RichTextEditView({
  className,
  theme,
  classes,
  tabIndex,
  placeholder,
  readOnly,
  onComment,
  hideToolbar,
  liquid,
}: Props) {
  const commentsEnabled = useCommentsEnabled()

  return (
    <>
      {!readOnly ? (
        <>
          <ToolbarPlugin hideToolbar={hideToolbar} liquid={liquid} />
          {commentsEnabled ? <CommentPlugin onComment={onComment} /> : null}
        </>
      ) : null}
      <RichTextPlugin
        ErrorBoundary={ErrorBoundary}
        contentEditable={
          <ContentEditable
            className={classNames(
              theme?.editorInput || classes.editorInput,
              theme?.richEditorInput || classes.richEditorInput,
              className,
              { [classes.textCursor]: !readOnly }
            )}
            tabIndex={tabIndex}
          />
        }
        placeholder={placeholder}
      />
      <ListPlugin />
      <MarkdownShortcutPlugin transformers={transformers} />
    </>
  )
}
