import { ForwardedRef, ReactNode, useMemo, forwardRef, MutableRefObject } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { AuthorIcon } from 'components/library/AuthoredContent'

import TypeAheadEditor, { EditorApi, Suggestion } from 'components/library/TypeAheadEditor'

import { Account, Theme } from 'types/hb'

import AccountMenuItem from './AccountMenuItem'

interface ContainerProps {
  className?: string
  children: ReactNode
}

const useContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(),
  },
}))

function Container({ className, children }: ContainerProps) {
  const classes = useContainerStyles()
  return <div className={classnames(classes.root, className)}>{children}</div>
}

export interface Props {
  author: Account
  placeholder?: string
  autoFocus?: boolean
  onSubmit?: () => unknown
  onChange?: (text: string) => unknown
  onListChange?: (isOpen: boolean) => unknown
  initialText?: string
  teammates: Account[]
  disabled?: boolean
  hideAuthorIcon?: boolean
  className?: string
  editorClassName?: string
  placeholderClassName?: string
}

type BaseProps = Props & { classes: { editor?: string } }

function CommentEditorBaseInternal(
  {
    author,
    className,
    editorClassName,
    placeholderClassName,
    classes,
    disabled,
    hideAuthorIcon,
    initialText,
    onSubmit,
    onChange,
    placeholder,
    teammates,
  }: BaseProps,
  ref: MutableRefObject<EditorApi>
) {
  const teamSuggestions: Array<Suggestion> = useMemo(() => {
    return teammates.map(
      (a): Suggestion => ({
        value: a.username,
        additionalSearch: [a.firstName, a.lastName],
        type: 'mention',
        renderListItem: () => <AccountMenuItem account={a} />,
      })
    )
  }, [teammates])

  return (
    <Container className={className}>
      {!hideAuthorIcon && <AuthorIcon author={author} />}
      <div className={classes.editor}>
        <TypeAheadEditor
          ref={ref}
          className={editorClassName}
          placeholderClassName={placeholderClassName}
          autoFocus
          initialValue={initialText}
          onSubmit={onSubmit}
          placeholder={placeholder}
          suggestions={teamSuggestions}
          tabIndex={disabled ? -1 : 1}
          onChange={onChange}
        />
      </div>
    </Container>
  )
}

export const CommentEditorBase = forwardRef<EditorApi, BaseProps>(CommentEditorBaseInternal)

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    flex: 1,
    marginLeft: theme.spacing(),
    paddingTop: theme.spacing(0.5),
    wordBreak: 'break-word',
  },
}))

function CommentEditorInternal(props: Props, ref: ForwardedRef<EditorApi>) {
  const classes = useStyles()
  return <CommentEditorBase ref={ref} {...props} classes={classes} />
}

const CommentEditor = forwardRef<EditorApi, Props>(CommentEditorInternal)

// TODO: submit doesn't work yet
// Do not wrap this with a higher order component. We rely on exporting this component directly
// to expose reset functionality. Would be preferable to use a decoupled mechanism for resetting.
export default forwardRef<EditorApi, Props>((props, ref) => {
  return <CommentEditor ref={ref} {...props} />
})
