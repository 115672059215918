import { forwardRef, useEffect, ForwardedRef } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  LexicalEditor,
} from 'lexical'

import { $parseHtml, $textToParagraphs } from './utils'

export interface EditorApi {
  editor: LexicalEditor
  focus: () => void
  reset: () => void
  appendText: (text: string) => void
  insertText: (text: string) => void
  /**
   * This should only be used for the Narrative Generation POC/Demo.
   */
  streamText: (text: string) => void
  insertHtml: (html: string) => void
}

export interface Props {
  richText?: boolean
}

function EditorApiPlugin({ richText }: Props, ref: ForwardedRef<EditorApi>) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const api: EditorApi = {
      editor,
      focus: () => editor.focus(),
      reset: () =>
        editor.update(() => {
          const root = $getRoot()
          const para = $createParagraphNode()
          const sel = $getSelection()

          root.clear()
          root.append(para)

          if (sel !== null) {
            para.select()
          }
        }),
      appendText: (text: string) => {
        editor.update(() => {
          const root = $getRoot()
          const textNode = $createTextNode(text)
          const hasText = root.getTextContent().length > 0

          // If this isn't a rich text editor, then we need to append to the
          // only paragraph node that exists in plain text editors.
          if (!richText) {
            const para = root.getFirstChild()

            if (!$isParagraphNode(para)) {
              return
            }

            // If there is already text in the editor, then we need to create some line breaks
            if (hasText) {
              para.append($createLineBreakNode())
              para.append($createLineBreakNode())
            }

            para.append(textNode)

            return
          }

          // If this is a rich text editor, then we need to append to the root
          const nodes = $textToParagraphs(text)
          $getRoot().append(...nodes)

          // If the editor is rich and was empty, remove the initial empty paragraph
          if (richText && !hasText) {
            root.getFirstChild()?.remove()
          }
        })
      },
      insertText: (text: string) => {
        editor.update(() => {
          const selection = $getSelection()

          if (!selection) {
            api.appendText(text)
            return
          }

          // Ensure we have a range selection (not a node or grid selection) that is collapsed.
          if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
            return
          }

          const { anchor } = selection

          const anchorNode = anchor.getNode()

          if (richText) {
            const nodes = $textToParagraphs(text)
            selection.insertNodes(nodes)

            return
          }

          if (!$isTextNode(anchorNode)) {
            selection.insertText(text)
          } else {
            const [start] = anchorNode.splitText(anchor.offset)
            const nodeToInsert = $createTextNode(text)

            start.insertAfter(nodeToInsert)
            nodeToInsert.select()
          }
        })
      },
      // TODO: I just copied the insertText logic and updated it slightly. I'd like to walk through this logic with
      //  Ken before we take this out of demo.
      streamText: (text: string) => {
        editor.update(() => {
          const selection = $getRoot().selectEnd()

          if (!selection) {
            api.appendText(text)
            return
          }

          // Ensure we have a range selection (not a node or grid selection) that is collapsed.
          if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
            return
          }

          const { anchor } = selection

          const anchorNode = anchor.getNode()

          if (richText) {
            selection.insertRawText(text)

            return
          }

          if (!$isTextNode(anchorNode)) {
            selection.insertText(text)
          } else {
            const [start] = anchorNode.splitText(anchor.offset)
            const nodeToInsert = $createTextNode(text)

            start.insertAfter(nodeToInsert)
            nodeToInsert.select()
          }
        })
      },
      insertHtml: (html: string) => {
        editor.update(() => {
          const nodes = $parseHtml(editor, html)
          const selection = $getSelection()

          if (selection) {
            // Ensure we have a range selection (not a node or grid selection) that is collapsed.
            if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
              return
            }

            selection.insertNodes(nodes)
            return
          }

          const root = $getRoot()
          if (root.getTextContentSize() > 0) {
            root.append(...nodes)
          } else {
            root.select()
            $insertNodes(nodes)
          }
        })
      },
    }

    if (ref) {
      if (typeof ref === 'function') {
        ref(api)
      } else {
        ref.current = api
      }
    }

    return () => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(null)
        } else {
          ref.current = null
        }
      }
    }
  }, [editor, richText, ref])

  return null
}

export default forwardRef<EditorApi, Props>(EditorApiPlugin)
