import React, { ReactNode, useContext, useEffect, useMemo } from 'react'

import { nanoid } from 'nanoid'
import { Store } from 'redux'

import { State } from 'actions/store'
import { AnalyticsEvent, AnalyticsEventData } from 'helpers/SessionTracking/analytics.types'
import { Exact } from 'types/hb'

import { ChurnZeroSender } from './ChurnZeroSender'
import { RudderstackSender } from './RudderstackSender'
import { TimeSpentTimer } from './TimeSpentTimer'
import { UsageAreaDefinition, UsageEventProperties, UsageView } from './UsageEvents'

// Timeout after which we consider the user to be inactive and end the session
export const USER_INACTIVITY_TIMEOUT = 5 * 60 * 1000 // 5 minutes

const userActivityEvents = ['mousedown', 'mousemove', 'mouseup', 'keydown', 'keyup']

// Minimum time spent (in seconds) on a view in order for it to be logged
const MIN_TIME_SPENT = 1
// TODO-julian: leaving this here to help chase down possible entry/exit bugs with the UsageTracker, this doesn't log
//  outside of development
const LOG_USAGE_AREA_CHANGE = false

type UsageViewInternal = UsageView & {
  parentPath?: string
}

interface ViewContextTracker {
  view: UsageViewInternal
  timer: TimeSpentTimer
}

function getViewKey(view: UsageViewInternal) {
  return `${view.parentPath}_${view?.view}`
}

export class UsageTracker {
  private cz = new ChurnZeroSender()
  private rudder = new RudderstackSender()

  private userInactivityTimeout = -1
  private sessionId: string | undefined = nanoid()
  private sessionStartTime = new Date()
  private currentViews: ViewContextTracker[] = []

  public get currentSessionId() {
    return this.sessionId
  }

  public initialize(store: Store<State>) {
    this.initializeSessionTracking()
    this.cz.initialize(store)
    this.rudder.initialize(store)
  }

  public cleanup() {
    clearTimeout(this.userInactivityTimeout)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange, true)

    userActivityEvents.forEach((event) => document.removeEventListener(event, this.updateSession, true))
  }

  /**
   * Public APIs are guarded with Exact typechecks,
   * to ensure only intended properties are sent to analytics
   * in order to avoid PII and accidental data.
   */

  public logEvent<T extends AnalyticsEvent>(
    action: [AnalyticsEventData[T]] extends [undefined]
      ? { name: T; data?: AnalyticsEventData[T] }
      : { name: T; data: AnalyticsEventData[T] },
    area?: string[]
  ) {
    this.logActionInternal(action.name, { ...action.data, area })
  }

  public trackChurnZeroEvent(event: string, message = '') {
    this.cz.trackEvent(event, message)
  }

  // Starts tracking time spent on the given view
  public enterView<T extends UsageView>(view: T) {
    // Don't do anything if this view is already active
    if (this.getViewIndex(view) !== -1) {
      return
    }

    this.currentViews.push({ view, timer: new TimeSpentTimer().start() })
    if (DEBUG && LOG_USAGE_AREA_CHANGE) {
      // eslint-disable-next-line no-console
      console.log(
        'UsageTracker:view:ENTER',
        `\n\t+${view.view}`,
        `\n\t${this.currentViews.map((v) => v.view.view).join(',')}`
      )
    }
  }

  // Stops tracking time spent on the given view
  public exitView<T extends UsageView>(view: T) {
    // Don't do anything if this doesn't match the active context
    const viewIndex = this.getViewIndex(view)
    if (viewIndex === -1) {
      return
    }

    if (DEBUG && LOG_USAGE_AREA_CHANGE) {
      // eslint-disable-next-line no-console
      console.log(
        'UsageTracker:view:EXIT',
        `\n\t-${view.view}`,
        `\n\t${this.currentViews.map((v) => v.view.view).join(',')}`
      )
    }
    const [tracker] = this.currentViews.splice(viewIndex, 1)
    this.logViewTracker(tracker)
  }

  // Ensures that there's an active session
  private updateSession = () => {
    // Restart inactivity timer
    clearTimeout(this.userInactivityTimeout)
    this.userInactivityTimeout = setTimeout(() => {
      this.stopSession()
    }, USER_INACTIVITY_TIMEOUT) as unknown as number // Cast since we're inappropriately using NodeJS types

    if (this.sessionId) {
      // A session is already active
      return
    }

    // Restart timers for any active views
    this.currentViews.forEach((view) => view.timer.start())
    // Create new session
    this.sessionId = nanoid()
    this.sessionStartTime = new Date()

    // Log a session start event with additional metadata
    this.logActionInternal('SessionStart', {
      userAgent: window.navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    })
  }

  // Stops the current session
  private stopSession() {
    this.currentViews.forEach((view) => {
      // Log any time spent in the view so far
      this.logViewTracker(view)
      // Reset the timer so tracking can resume when the session restarts
      view.timer.reset()
    })

    this.sessionId = undefined
    this.cz.sendTimeInApp(this.sessionStartTime, new Date())
    this.rudder.flushEvents(true) // Try to send any queued events
    clearTimeout(this.userInactivityTimeout)
  }

  private handleVisibilityChange = () => {
    // TODO should this only happen after a timeout? Complex to implement since timers won't necessarily fire after they tab away.
    // If the user tabs away or focuses a different app, we're no longer in an active session
    if (document.hidden) {
      this.stopSession()
    } else {
      this.updateSession()
    }
  }

  // Tracks whether the user is active
  private initializeSessionTracking() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange, true)

    userActivityEvents.forEach((event) => document.addEventListener(event, this.updateSession, true))

    // Start initial session
    this.updateSession()
  }

  private logViewTracker(tracker: ViewContextTracker) {
    const { timer, view } = tracker
    const timeSpentInSeconds = timer.stop().elapsedTime

    if (timeSpentInSeconds >= MIN_TIME_SPENT) {
      this.logActionInternal('View', {
        viewName: view.view,
        timeSpentInSeconds,
        ...(view.properties ?? {}),
      })
    }
  }

  private getViewIndex(viewToFind: UsageView) {
    if (!viewToFind) {
      return -1
    }

    return this.currentViews.findIndex((currentView) => {
      const [currentViewKey, keyToFind] = [currentView.view, viewToFind].map(getViewKey)
      return currentViewKey === keyToFind
    })
  }

  // Logs a user action
  private logActionInternal(event: string, data?: UsageEventProperties) {
    // Ensure that there's an active session before sending events
    this.updateSession()

    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log(`[Session ${this.sessionId}] ${event}`, data)
    }

    this.rudder.trackEvent(event, new Date(), {
      ...data,
      sessionId: this.sessionId,
    })
  }
}

type UsageContextValue = {
  trackChurnZeroEvent: UsageTracker['trackChurnZeroEvent']
  logEvent: UsageTracker['logEvent']
  enterView: UsageTracker['enterView']
  exitView: UsageTracker['exitView']

  areaProperties?: UsageEventProperties
}

export const UsageContext = React.createContext<UsageContextValue>({} as UsageContextValue)

export const useUsage = () => {
  const usage = useContext(UsageContext)
  return usage
}

function useUserViewingInternal<T extends UsageView>(usage: UsageContextValue, view: T, active = true) {
  useEffect(() => {
    // Always defined, breaks under hot reloading with vite.
    if (active) {
      usage.enterView?.(view)
    } else {
      usage.exitView?.(view)
    }

    return () => {
      // Always defined, breaks under hot reloading with vite.
      if (active) {
        usage.exitView?.(view)
      }
    }
    // Control updates manually, we want to only update if the view key changes,
    // so that callers don't have to memoize the view.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, usage, getViewKey(view)])
}

// Tracks time spent on the given view.
// Tied to the lifecycle of the component where this is called.
export function useUserViewing<T extends UsageView>(view: Exact<T, UsageView>, active = true) {
  const usage = useUsage()
  useUserViewingInternal(usage, view, active)
}

function getParentPath(areaName: string, view: UsageViewInternal) {
  const existing = view.parentPath ?? view.view
  return areaName ? `${areaName}_${existing}` : existing
}

// Adds common event metadata to the React subtree.
export function UsageArea({
  eventPrefix,
  properties,
  userActive = true,
  children,
}: UsageAreaDefinition & {
  userActive?: boolean
  children: ReactNode
}) {
  const usage = useUsage()
  const value = useMemo<UsageContextValue>(() => {
    const newData = { ...(usage.areaProperties ?? {}), ...(properties ?? {}) }

    const extendView = (view: UsageViewInternal) => ({
      view: view.view,
      parentPath: getParentPath(eventPrefix, view),
      properties: { ...newData, ...(view.properties ?? {}) },
    })

    // When actions and views are logged, add common subtree data to the data supplied by the event creator.
    return {
      trackChurnZeroEvent: (e, m) => usage.trackChurnZeroEvent(e, m),
      logEvent: (action, area) => {
        // We type data as any here, because we want to put in any area properties that are available.
        // Unfortunately the internal logEvent uses the same type definitions as the external one.
        const updatedAction: { name: (typeof action)['name']; data: any } = {
          name: action.name,
          data: {
            ...newData,
            ...(action.data ?? {}),
          },
        }

        usage.logEvent(updatedAction, area?.length && area.length > 0 ? [eventPrefix, ...area] : [eventPrefix])
      },
      enterView: (view) => {
        // Always defined, breaks under hot reloading with vite.
        // Cast since this extension is safe due to strict checks of the UsageArea props
        usage.enterView?.(extendView(view) as any)
      },
      exitView: (view) => {
        // Always defined, breaks under hot reloading with vite.
        // Cast since this extension is safe due to strict checks of the UsageArea props
        usage.exitView?.(extendView(view) as any)
      },
      areaProperties: newData,
    }
    // Don't update when data changes, since we don't want to force callers to memoize.
    // Without memoization this may cause extra rendering down the line and result in perf issues.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage])

  // Cast since this extension is safe due to strict checks of the UsageArea props.
  // Use with new context value so we include the additional event data.
  useUserViewingInternal(
    usage,
    { view: eventPrefix, properties: { ...(usage.areaProperties ?? {}), ...(properties ?? {}) } } as any,
    userActive
  )

  return <UsageContext.Provider value={value}>{children}</UsageContext.Provider>
}
