export function saveFile(data: string, filename: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(data, filename)
  } else {
    const a = document.createElement('a')
    document.body.appendChild(a)
    const url = URL.createObjectURL(new Blob([data]))
    a.href = url
    a.download = filename
    a.style.display = 'none'
    a.click()
    setTimeout(() => {
      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }
}
