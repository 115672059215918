import { setLoading } from 'actions/viewActions'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { DashboardSlice, dashboardSlices } from 'reducers/dashboards/dashboards.constants'
import { PersistedDashboardColumn, UserSettingsState } from 'reducers/userSettingsReducer'

import { AsyncThunk } from './store'

export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_USER_DASHBOARD_SETTING = 'SET_USER_DASHBOARD_SETTING'

export function setUserSettings(userSetting: Partial<UserSettingsState>) {
  return { type: SET_USER_SETTINGS, userSetting }
}

export function updateSettings(newUserSettings: Partial<UserSettingsState>): AsyncThunk<boolean> {
  return (dispatch, getState, { api }) => {
    dispatch(setUserSettings(newUserSettings))

    return api.put('apiUserSettingsPath', { data: newUserSettings }).then((json) => {
      if (json.success) {
        return true
      }
      return false
    })
  }
}

export function fetchUserSettings(): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'userSettings'))
    return api.get('apiUserSettingsPath').then((json) => {
      if (json.success) {
        dispatch(setUserSettings(json.userSetting))
      }

      dispatch(setLoading(false, 'userSettings'))
    })
  }
}

type DashboardSettings = { dashboardColumns: PersistedDashboardColumn[]; recentDashboardView: string }

export const mergeDashboardSettings = (
  payload: DashboardSettingsPayload,
  existingDashboardSettings: Pick<UserSettingsState, 'dashboardColumns' | 'recentDashboardViews'>
) => {
  const { dashboardColumns, recentDashboardView, slice } = payload
  const newDashboardSettings = {
    ...existingDashboardSettings,
    dashboardColumns: {
      ...existingDashboardSettings.dashboardColumns,
      [slice]: dashboardColumns || existingDashboardSettings.dashboardColumns[slice],
    },
    recentDashboardViews: {
      ...existingDashboardSettings.recentDashboardViews,
      [slice]: recentDashboardView || existingDashboardSettings.recentDashboardViews[slice],
    },
  }

  return newDashboardSettings
}

const makeDashboardUserSettingsActions = (slice: DashboardSlice) => {
  const setDashboardUserSettings = (dashboardSettings: Partial<DashboardSettings>) => {
    return {
      type: SET_USER_DASHBOARD_SETTING,
      payload: {
        slice,
        ...dashboardSettings,
      },
    }
  }
  function updateDashboardUserSettings(dashboardSettings: Partial<DashboardSettings>): AsyncThunk<boolean> {
    return (dispatch, getState, { api }) => {
      const { dashboardColumns, recentDashboardViews } = getState().userSettings
      dispatch(setDashboardUserSettings(dashboardSettings))
      // manually merge with same helper function used in redux instead of relying on reading state after the reducer merge.
      const newDashboardSettings = mergeDashboardSettings(
        {
          slice,
          ...dashboardSettings,
        },
        {
          dashboardColumns,
          recentDashboardViews,
        }
      )
      return api
        .put('apiUserSettingsPath', {
          data: newDashboardSettings,
        })
        .then((json) => {
          if (json.success) {
            return true
          }
          return false
        })
    }
  }
  return {
    setDashboardUserSettings,
    updateDashboardUserSettings,
  }
}

export type DashboardSettingsPayload = ReturnType<
  ReturnType<typeof makeDashboardUserSettingsActions>['setDashboardUserSettings']
>['payload']

export const dashboardUserSettingsActionsMap = dashboardSlices.reduce((acc, cur) => {
  acc[cur] = makeDashboardUserSettingsActions(cur)
  return acc
}, {} as Record<DashboardSlice, ReturnType<typeof makeDashboardUserSettingsActions>>)

export const useDashboardUserSettingsActions = () => {
  const dashboardContextValue = useDashboardContext()
  return dashboardUserSettingsActionsMap[dashboardContextValue]
}
