import * as Sentry from '@sentry/react'

import { join } from 'lodash'

import { setFlashError } from 'actions/errorActions'
import { Thunk, AsyncThunk } from 'actions/store'
import { setLoading } from 'actions/viewActions'
import { saveFile } from 'helpers/files'
import { createReducer } from 'helpers/reducers'
import { getOrganizationToken } from 'helpers/stateHelpers'

export type ExportType = 'review' | 'case'
export type DateRangeType = 'created' | 'completed'

interface ExportCSVFile {
  fromDate: string
  type: ExportType
  toDate: string
  requestId: string
}

interface State {
  fileToDownload?: ExportCSVFile
  isDownloading: boolean
  isDownloaded: boolean
  isErrored: boolean
}

const getInitialState: () => State = () => ({
  fileToDownload: undefined,
  isDownloading: false,
  isDownloaded: false,
  isErrored: false,
})

interface ExportAction {
  type: 'SET_DOWNLOADING' | 'SET_DOWNLOADED' | 'SET_DOWNLOAD_TIMEOUT' | 'RESET_DOWNLOAD'
  fileToDownload?: ExportCSVFile
}

export const reducer = createReducer<State, ExportAction>(
  {
    SET_DOWNLOADING(state, action: ExportAction) {
      return {
        ...state,
        isDownloading: true,
        isDownloaded: false,
        isErrored: false,
        fileToDownload: action.fileToDownload,
      }
    },
    SET_DOWNLOADED(state) {
      return {
        ...state,
        isDownloading: false,
        isDownloaded: true,
        isErrored: false,
        fileToDownload: null as any,
      }
    },
    SET_DOWNLOAD_TIMEOUT(state) {
      return {
        ...state,
        isDownloading: false,
        isErrored: true,
      }
    },
    RESET_DOWNLOAD() {
      return {
        fileToDownload: undefined,
        isDownloading: false,
        isDownloaded: false,
        isErrored: false,
      }
    },
  },
  getInitialState
)

function setDownloading(fileToDownload: ExportCSVFile): ExportAction {
  return {
    type: 'SET_DOWNLOADING',
    fileToDownload,
  }
}

function setDownloaded(): ExportAction {
  return { type: 'SET_DOWNLOADED' }
}

function setDownloadTimeout(): ExportAction {
  return { type: 'SET_DOWNLOAD_TIMEOUT' }
}

function clearDownload(): ExportAction {
  return { type: 'RESET_DOWNLOAD' }
}

interface ExportResult {
  contents?: string
  name?: string
}

interface DownloadResponse {
  success: boolean
  empty?: boolean
  result: ExportResult
}

const MAX_ATTEMPTS = 10
const DELAY_BETWEEN_ATTEMPTS = 3000
export const actions = {
  downloadFile(tries = 0): AsyncThunk<void> {
    return async (dispatch, getState, { api }) => {
      const organizationToken = getOrganizationToken(getState())
      const {
        export: { fileToDownload },
      } = getState()

      const { empty, result }: DownloadResponse = await api.get('apiOrganizationExportPath', {
        urlParams: { organizationToken, id: 'token' },
        query: fileToDownload,
      })

      if (empty || !result) {
        if (tries >= MAX_ATTEMPTS) {
          // Failed to fetch the CSV file
          dispatch(setLoading(false, 'exports'))
          dispatch(setDownloadTimeout())
          Sentry.captureMessage(`Export timeout`)
        } else {
          // Retry in DELAY_BETWEEN_ATTEMPTS seconds
          setTimeout(() => dispatch(actions.downloadFile(tries + 1)), DELAY_BETWEEN_ATTEMPTS)
        }
      } else if (result.name) {
        const { name, contents } = result
        // File is fetched
        saveFile(join(contents, ''), name)
        dispatch(setLoading(false, 'exports'))
        dispatch(setDownloaded())
      }
    }
  },
  exportCSV(fromDate: string, toDate: string, dateRangeType: DateRangeType, type: ExportType): Thunk<void> {
    return async (dispatch, getState, { api }) => {
      dispatch(setLoading(true, 'exports'))
      const organizationToken = getOrganizationToken(getState())
      const fileToDownload = {
        fromDate,
        toDate,
        dateRangeType,
        type,
        requestId: Math.random().toString(36).substring(2, 15),
      }

      const response = await api.post('apiOrganizationExportsPath', {
        urlParams: { organizationToken },
        data: fileToDownload,
      })

      if (response.success) {
        dispatch(setDownloading(fileToDownload))
        await dispatch(actions.downloadFile())
      } else {
        dispatch(setLoading(false, 'exports'))
        dispatch(setFlashError(response?.error?.message))
      }
    }
  },
  resetDownload(): Thunk<void> {
    return async (dispatch) => {
      dispatch(clearDownload())
    }
  },
}
